import React, { useState, useEffect } from "react";
import { CurrentUserContext } from "./context";
import { useQuery } from "react-apollo-hooks";
import { shared } from "../../../graphql/shared";
import _ from "lodash";

export function CurrentUserProvider({ children }) {
  const [state, setState] = useState({
    currentUser: {
      id: window.localStorage.getItem("id")
    },
    currentWorkspace: {
      id: window.localStorage.getItem("workspace")
    },
    loading: true,
    error: false
  });

  const {
    error,
    data,
    loading,
    refetch: refetchCurrentUser
  } = useQuery(shared.queries.getUserById, {
    variables: { id: state.currentUser.id || "" },
    fetchPolicy: "network-only"
  });

  const signout = () => {
    window.localStorage.clear();
    window.location.href = "/";
  };

  const setWorkspace = id => {
    window.localStorage.setItem("workspace", id);

    const newWorkspace = state.currentUser.workspaces.find(workspace => {
      return workspace.id === id;
    });

    setState({ ...state, currentWorkspace: newWorkspace });
  };

  useEffect(() => {
    const newState = {
      ...state,
      loading: loading,
      error: error
    };

    if(data?.user) {
      newState.currentUser = data.user;
      newState.currentUser.completedProfile =
        data?.userProfileCompletion?.completed;
      newState.currentUser.odes = data?.odes;

      newState.currentUser.workspaces = data?.workspaces?.map(workspace => {
        const roles = workspace.memberships
          .map(membership => membership.role);

        workspace.roles = roles;

        return workspace;
      }) || [];

      if(newState.currentWorkspace.id !== null) {
        const workspace = newState.currentUser.workspaces.find(workspace => {
          return workspace.id === newState.currentWorkspace.id;
        });

        const role = _.first(workspace.roles);

        if(role) {
          newState.currentUser.systemRole = role;
        }

        newState.currentWorkspace = workspace;
      }
      else if(newState.currentUser.workspaces.length) {
        newState.currentWorkspace = _.first(newState.currentUser.workspaces);
        window.localStorage.setItem("workspace", newState.currentWorkspace.id);
      }
    }

    setState(newState);
  }, [data, loading, error]);

  const providerValue = {
    ...state,
    signout,
    refetchCurrentUser,
    setWorkspace
  };

  return (
    <CurrentUserContext.Provider value={providerValue}>
      { children }
    </CurrentUserContext.Provider>
  );
}
