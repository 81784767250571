import React from 'react';
import { EntityDetail } from '..';
import { default as InfoTab } from "./infoTab";
import { default as AssignedServicesTab } from "./assignedServicesTab";

export default ({expert, assignedExpertServiceId, history}) => {
  return (
    <EntityDetail>
      <InfoTab
        name="Información"
        expert={expert}
        active={!assignedExpertServiceId} />
      <AssignedServicesTab
        name="Servicios"
        expert={expert}
        active={(assignedExpertServiceId)}
        assignedExpertServiceId={assignedExpertServiceId}
        history={history} />
    </EntityDetail>
  );
}
