import React from "react";

const withPagination = (WrapedComponent, data) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        currentPage: 1,
        totalRecords: 0,
        totalResults: 0,
        skip: 0,
        paginationSize: 0
      };

      this.onNext = this.onNext.bind(this);
      this.onPrev = this.onPrev.bind(this);
    }

    componentDidMount () {
      const {
        paginationSize = 10
      } = data || {};

      this.setState({
        paginationSize
      });
    }

    onPrev () {
      if (this.state.currentPage > 1) {
        const skip = this.state.skip - this.state.paginationSize;
        const currentPage = this.state.currentPage - 1;

        this.setState({
          skip,
          currentPage
        });

        return skip;
      }
    }

    onNext (totalResults, totalRecords) {
      if (
        totalResults > 0 &&
        (totalResults ? totalResults === this.state.paginationSize : false)
      ) {
        if((this.state.skip + this.state.paginationSize) < totalRecords) {
          const skip = this.state.skip + this.state.paginationSize;
          const currentPage = this.state.currentPage + 1;

          this.setState({
            skip,
            currentPage
          });
          return skip;
        }
      }
    }

    render() {
      return <WrapedComponent
        onPrev={this.onPrev}
        onNext={this.onNext}
        currentPage={this.state.currentPage}
        totalRecords={this.state.totalRecords}
        totalResults={this.state.totalResults} />;
    }
  };
};

export default withPagination;
