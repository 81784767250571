import React, {useState, useEffect} from "react";
import { EntityModal, TabForm } from "../../molecules";
import { CREATE_EXPERT, UPDATE_EXPERT } from "../../../graphql/mutations/experts";
import { FormValidator } from "../../../helpers";
import validations from './validations';
import { default as GeneralTab } from "./general";
import { default as FinancialTab } from "./financial";
import { default as AccountTab } from "./account";
import { client } from "../../../graphql";
import { EXPERT } from "../../../graphql/queries/users";
import * as _ from "lodash";

export default ({open, onClose, onCreate, editId}) => {
  const [data, setData] = useState({
    userID: undefined,
    fullName: "",
    email: "",
    password: "",
    specialtyArea: "",
    expertType: "",
    expertCategory: "",
    profileTec: "",
    specify: "",
    enrollment: "",
    tabulation: "",
    status: ""
  });

  const [errors, setErrors] = useState({
    fullName: false,
    email: false,
    password: false,
    specialtyArea: false,
    expertType: false,
    expertCategory: false,
    profileTec: false,
    specify: false,
    enrollment: false,
    tabulation: false,
    status: false
  });

  useEffect(() => {
    if(editId && open) {
      const getExpertData = async () => {
        const result = await client.query({
          query: EXPERT,
          variables: {
            id: editId
          },
          fetchPolicy: "network-only"
        });

        setData({...result.data.user});
      };

      getExpertData();
    }
  }, [editId, open]);

  const handleChange = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
  };

  const validateForm = data => {
    const validator = new FormValidator(validations(data.expertCategory));
    const validation = validator.validate(data);

    if (!validation.isValid) {
      Object.keys(errors).map(error => {
        errors[error] = _.get(validation, `${error}.isInvalid`, false);
      });
      setErrors({ ...errors });
    }
    return validation;
  };

  const createExpert = async () => {
    const validation = validateForm(data);

    if (validation.isValid) {
      try {
        await CREATE_EXPERT(data);
        resetData();
        onCreate();
        onClose();
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  const updateExpert = async () => {
    const validation = validateForm(data);

    if (validation.isValid) {
      try {
        const updateId = data.id;
        delete data.id;
        delete data.__typename;
        delete data.ignore_whitespace;
        await UPDATE_EXPERT({data: data, id: updateId});
        resetData();
        onCreate();
        onClose();
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  const resetData = () => {
    setData({
      userID: undefined,
      email: "",
      password: "",
      fullName: "",
      specialtyArea: "",
      expertType: "",
      expertCategory: "",
      profileTec: "",
      specify: "",
      enrollment: "",
      tabulation: ""
    });
    setErrors({
      fullName: false,
      email: false,
      password: false,
      specialtyArea: false,
      expertType: false,
      expertCategory: false,
      profileTec: false,
      specify: false,
      enrollment: false,
      tabulation: false
    });
  };

  return (
    <EntityModal
      title={ data.id ? "Editar experto" : "Crear experto" }
      confirmLabel={ data.id ? "Guardar" : "Crear" }
      open={open}
      onClose={() => { resetData(); onClose(); }}
      onConfirm={data.id && updateExpert || createExpert}>
      <TabForm className="Modal__Form">
        <GeneralTab title="General"
          handleChange={handleChange}
          data={data}
          errors={errors} />
        <FinancialTab title="Financieros"
          handleChange={handleChange}
          data={data}
          errors={errors} />
        <AccountTab title="Cuenta"
          handleChange={handleChange}
          data={data}
          errors={errors}
          disabled={{password: (data.id !== undefined)}} />
      </TabForm>    
    </EntityModal>
  );
}
