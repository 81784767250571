import React, { useState } from "react";
import { Form, Input, Dropdown, Button, Header } from "semantic-ui-react";
import errors from "../errors";
import { FormValidator, INDUSTRIES, PHASES } from "../../../../helpers";
import validations from "../validations";
import { DeleteButton } from "../../../atoms";

const trlOptions = [
  { text: "1", key: "1", value: "ONE" },
  { text: "2", key: "2", value: "TWO" },
  { text: "3", key: "3", value: "THREE" },
  { text: "4", key: "4", value: "FOUR" },
  { text: "5", key: "5", value: "FIVE" },
  { text: "6", key: "6", value: "SIX" },
  { text: "7", key: "7", value: "SEVEN" },
  { text: "8", key: "8", value: "EIGHT" },
  { text: "9", key: "9", value: "NINE" }
];

export default props => {
  const { turnoffautocomplete, data, handlechange, update } = props;
  const [errors3, setErrors1] = useState(errors.section4); // form section 1

  const setErrors = (errors3, setErrors, validation) => {
    Object.keys(errors3).map(error => {
      errors3[error] = validation[error].isInvalid;
    });
    setErrors({ ...errors3 });
  };

  const validateFrom = form => {
    const validator = new FormValidator(validations[form]);
    const validation = validator.validate(data);
    return validation;
  };

  const _update = async () => {
    const validation = validateFrom("section4");
    if (validation.isValid) {
      setErrors(errors3, setErrors1, validation);
      await update(data);
    } else {
      setErrors(errors3, setErrors1, validation);
    }
  };

  return (
    <Form className="Padded__Form">
      <Header>Emprendimiento</Header>
      <Form.Group widths="equal">
        <Form.Field
          name="phase"
          label="Fase"
          control={Dropdown}
          selection
          options={PHASES}
          onChange={handlechange}
          error={data.phase ? false : errors.phase}
          value={data.phase}
          required
          onFocus={turnoffautocomplete} />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          name="technology"
          label="Tecnología"
          selection
          control={Input}
          onChange={handlechange}
          defaultValue={data.technology}
          error={data.technology ? false : errors.technology}
          required
          onFocus={turnoffautocomplete} />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          name="trl"
          label="Nivel de TRL"
          selection
          control={Dropdown}
          options={trlOptions}
          value={data.trl}
          onChange={handlechange}
          error={data.trl ? false : errors.trl}
          required
          onFocus={turnoffautocomplete} />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          name="industry"
          label="Industria"
          value={data.industry}
          selection
          control={Dropdown}
          selection
          options={INDUSTRIES}
          onChange={handlechange}
          error={data.industry ? false : errors.industry}
          required
          onFocus={turnoffautocomplete} />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          name="area"
          label="Área"
          defaultValue={data.area}
          control={Input}
          onChange={handlechange}
          error={data.area ? false : errors.area}
          required
          onFocus={turnoffautocomplete} />
      </Form.Group>

      <Form.Group widths="equal">
        <DeleteButton del={props.deleteODE} />
        <Button
          loading={props.loading}
          type="button"
          id="ode-form-next1"
          onClick={_update}
          primary
          fluid
          style={{ width: "30%", marginLeft: "250px" }}>
          Guardar
        </Button>
      </Form.Group>
    </Form>
  );
};
