import React from "react";
import { Modal, Form, Button, Input } from "semantic-ui-react";
import {
  UPDATE_ODE_NETWORK_ROLE,
  UPDATE_ODE_TEAM_ROLE
} from "../../../graphql/mutations/users";

const EditMemberRoleModal = ({
  isOpen,
  onClose,
  onInputChange,
  data,
  refetch,
  relation // "NETWORK" || "TEAM"
}) => {

  const saveChanges = async () => {
    try {
      const variables = {
        odeId: data.odeId || "",
        userId: data.id || "",
        role: data.role || "",
        odeIntersectionId:
          data.odeIntersectionId || "", // needed for network
        teamRelationId:
          data.teamRelationId || "" // needed for team
      };
      switch(relation) {
        case "NETWORK":
            await UPDATE_ODE_NETWORK_ROLE(variables);
          break;
        case "TEAM":
            await UPDATE_ODE_TEAM_ROLE(variables);
          break;
        default:
          break;
      }
      refetch();
      onClose();
    } catch (error) {
      onClose();
    }
  };

  return (
    <Modal
      closeIcon
      size="mini"
      open={isOpen}
      onClose={onClose}>
      <Modal.Header>Editar miembro</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field
            label="Usuario"
            control={Input}
            disabled
            defaultValue={data.fullName || ""}
            readOnly />
          <Form.Field
            name="role"
            label="Rol"
            control={Input}
            onChange={onInputChange}
            defaultValue={data.role || ""} />
          <Button
            fluid
            color="teal"
            onClick={async () => {
              await saveChanges();
            }}>
            Guardar
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default EditMemberRoleModal;
