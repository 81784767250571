import React from "react";
import { Form, Input, Button, Header } from "semantic-ui-react";
import { urlVerifierTools } from "../../../../../../helpers";

export default props => {
  const { handleChange, prev, next, errors, data, turnoffautocomplete } = props;
  return (
    <Form className="Padded__Form">
      <Header>Contacto de empresa</Header>
      <Form.Group widths="equal">
        <Form.Field
          name="website"
          label="Página web"
          control={Input}
          onChange={handleChange}
          error={urlVerifierTools.isValidDomain(data.website || "") ?
            false : errors.website}
          defaultValue={data.website}
          onFocus={turnoffautocomplete}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          name="facebook"
          label="Facebook"
          selection
          control={Input}
          onChange={handleChange}
          defaultValue={data.facebook}
          error={urlVerifierTools.isValidDomain(data.facebook) ?
            false : errors.facebook}
          onFocus={turnoffautocomplete}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          name="twitter"
          label="Twitter"
          selection
          control={Input}
          defaultValue={data.twitter}
          onChange={handleChange}
          error={urlVerifierTools.isValidDomain(data.twitter) ?
            false : errors.twitter}
          onFocus={turnoffautocomplete}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          name="instagram"
          label="Instagram"
          defaultValue={data.instagram}
          selection
          control={Input}
          onChange={handleChange}
          error={urlVerifierTools.isValidDomain(data.instagram) ?
            false : errors.instagram}
          onFocus={turnoffautocomplete}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          name="email"
          label="Correo electrónico"
          defaultValue={data.email}
          control={Input}
          onChange={handleChange}
          error={data.email ? false : errors.email}
          required
          onFocus={turnoffautocomplete}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Button type="button" id="ode-form-prev" onClick={prev} primary fluid>
          Anterior
        </Button>
        <Button type="button" id="ode-form-next1" onClick={next} primary fluid>
          Siguiente
        </Button>
      </Form.Group>
    </Form>
  );
};
