import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";
import EditInstitution from "../editInstitution";
import { MenuButton } from "../../atoms";
import "./style.css";

export default props => {
  const { idOde, onRowEvent } = props;

  const DELETE_ODE = async () => {
    try {
      await props.delete(idOde);
      props.refetch();
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <React.Fragment>
      <Dropdown icon={<MenuButton />} className="icon pointing List__Menu">
        <Dropdown.Menu style={{ marginLeft: "-35px" }}>
          <Dropdown.Item
            icon="edit"
            text="Editar"
            onClick={() => {
              onRowEvent({ type: 'onEdit', params: { idOde } })
            }}
          />
          {props.hasPrograms ? null : (
            <Dropdown.Item
              icon="trash"
              text="Eliminar"
              onClick={() => {
                onRowEvent({ type: 'onDelete', params: { idOde } })
                //props.setOdeId(idOde);
                //props.setConfirm();
              }}
            />
          )}
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};
