export default [
  {
    key: "SHARE_ECONOMY",
    text: "Economía colaborativa",
    value: "SHARE_ECONOMY",
  },
  {
    key: "INDUSTRIAL",
    text: "Industrial",
    value: "INDUSTRIAL",
  },
  {
    key: "COMMERCIAL",
    text: "Comercial",
    value: "COMMERCIAL",
  },
  {
    key: "SERVICES",
    text: "Servicios",
    value: "SERVICES",
  },
  {
    key: "FINANCIAL",
    text: "Financiero",
    value: "FINANCIAL",
  },
];
