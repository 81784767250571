import React from "react";
import { DetailGridSection } from "../../molecules";
import { ActionButton, DetailGridInfoRow } from "../../atoms";
import EditIcon from "mdi-react/EditIcon";
import { Grid } from "semantic-ui-react";
import { renderLabel } from "./renderLabel";
import { getSubtitle, formatDate, displayPrice } from "./helpers";

export default ({service, onEdit}) => {
  if(!service)
    return <Grid></Grid>;

  return (
    <Grid>
      <DetailGridSection
        title={service.expertService.name}
        subtitle={getSubtitle(service)}>
        <ActionButton
          isActionItem
          icon={<EditIcon />}
          onClick={() => onEdit(service)} />
      </DetailGridSection>
      <DetailGridSection title="Periodo de servicio">
        <DetailGridInfoRow label="Fecha de inicio">
          { formatDate(service.startDate) }
        </DetailGridInfoRow>
        <DetailGridInfoRow label="Fecha límite de servicio">
          { formatDate(service.endDate) }
        </DetailGridInfoRow>
        <DetailGridInfoRow label="Status">
          { renderLabel(service) }
        </DetailGridInfoRow>
        <DetailGridInfoRow label="Fecha de término">
          { formatDate(service.finished) }
        </DetailGridInfoRow>
        <DetailGridInfoRow label="Costo por el servicio">
          { displayPrice(service) }
        </DetailGridInfoRow>
      </DetailGridSection>
    </Grid>
  );
};
