import React from "react";
import { Query } from "react-apollo";
import { Dropdown } from "semantic-ui-react";
import { remove as removeAccents } from "remove-accents";
import { ODES } from "../../../graphql/queries/odes";

export const mapOptions = (odes, legacy = true) => {
  return odes.map(({name, id}) => {
    return {
      text: name,
      key: id,
      value: legacy ? JSON.stringify({ name, id }) : id
    };
  });
};

export default({
  onChange,
  odesToExclude,
  placeholder,
  name,
  multiple,
  value,
  legacy=true
}) => {
  return (
    <Query
      query={ODES}
      variables={{odesToExclude: odesToExclude}}>
      {({loading, error, data}) => {
        if(loading)
          return <Dropdown
            placeholder="Cargando..."
            loading
            disabled
            selection
            options={[]} />;

        if(error){
          const errorMessage = "Ha ocurrido un error al cargar \
            las ODEs. Por favor intentalo de nuevo más tarde.";
          return <Dropdown
            placeholder={errorMessage}
            error
            disabled
            selection
            options={[]} />;
        }

        const options = mapOptions(data.oDEs || [], legacy);

        const onFilter = (options, value) => {
          value = removeAccents(value).toLowerCase();
          const regx = new RegExp(value, "gi");

          return options
            .filter(({ text }) => (
              removeAccents(text)
                .toLowerCase()
                .match(regx)
            ));
        };

        return (
          <Dropdown
            search={onFilter}
            selection
            multiple={multiple}
            icon="search"
            options={options}
            onChange={onChange}
            placeholder={placeholder}
            name={name}
            value={value} />
        );
      }}
    </Query>
  );
};
