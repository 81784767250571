import userCategories from "../../../../helpers/userCategories";
import { getPercentage } from "../../../../helpers/utils";

const getDataSource = (totalEntrepreneurs, data) => {
  if(!data)
    return [];

  return Object.keys(userCategories).map(categoryKey => {
    const entrepreneursInCategory = filterEntrepreneurs(
                                    data.users,
                                    categoryKey)
                                  .length;

    return {
      label: userCategories[categoryKey],
      total: entrepreneursInCategory,
      percentage: getPercentage(
        totalEntrepreneurs,
        entrepreneursInCategory)
    };
  });
};

const filterEntrepreneurs = (users, category) => {
  const filteredODEs = (Array.isArray(users) ? users : [])
                        .filter(user => user.category === category);
  return (filteredODEs || []);
};

export default getDataSource;
