import React from 'react';
import { Grid, Header, Button } from 'semantic-ui-react';

export default ({title, big, actionText, onActionClick}) => {
  onActionClick = onActionClick ? onActionClick : () => {};

  return (
    <Grid.Row>
      <Grid.Column>
        <Grid verticalAlign="middle">
          <Grid.Row columns="equal" style={{paddingBottom: "0"}}>
            <Grid.Column>
              <Header floated="left" as={big ? "h4" : "h5"}>{ title }</Header>
            </Grid.Column>
            { actionText ?
              <Grid.Column>
                <Header floated="right">
                  <Button
                    onClick={onActionClick}
                    secondary>
                    { actionText }
                  </Button>
                </Header>
              </Grid.Column>
            : null }
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid.Row>
  );
}
