import gql from 'graphql-tag';

export default gql`
  query (
    $odesToExclude: [ID!] = []
    $first: Int
    $skip: Int
    $search: String
    $orderBy: ODEOrderByInput = id_DESC
    ) {
    oDEs (
      first: $first,
      skip: $skip,
      orderBy: $orderBy,
      where: {
        NOT: { 
          id_in: $odesToExclude
        }
        OR: [
          { iname_contains: $search},
          { icity_contains: $search },
          { istate_contains: $search },
          { icountry_contains: $search }
        ]
      }
    ) {
      id
      name
      odeID
      odeCategory
      sector
      commercialBussines
      nationality
      registrationYear
      city
      state
      phase
      industry
      trl
      technology
      organisms {
        id
      }
      team {
        id
      }
      network {
        id
      }
    }
    oDEsConnection {
      aggregate {
        count
      }
    }
  }
`;
