import React from "react";
import { DetailHeading } from "../../../../molecules";
import { EditButton } from "../../../../atoms";

export default ({userData, openEditModal, canEdit, editModal, setData}) => {
  return (
    <DetailHeading>
      <DetailHeading.LeftContainer
        title={userData.user.fullName}
        subtitle={userData.user.userID} />
      <DetailHeading.RightContainer>
        { canEdit &&
          <EditButton
            openEditModal={openEditModal}
            editModal={editModal}
            open={() => {
              setData(userData.user);
              openEditModal({ active: !editModal.active });
            }}
            name="edit"
            style={{ cursor: "pointer" }} /> }
      </DetailHeading.RightContainer>
    </DetailHeading>
  );
};
