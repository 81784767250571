import React, { useState, Fragment } from "react";
import { Feed } from "semantic-ui-react";
import Identicon from "react-identicons";

export default ({members}) => {
    if(members === undefined){
      return null
    }
    
    const membersList =  members.map((member, index) =>
        <Feed.Event key={index.toString()}>
          <Feed.Label>
            <Identicon size={35} string={member.fullName} />
          </Feed.Label>
          <Feed.Content style={{ marginTop: "-2.5px" }}>
            <Feed.Summary
              style={{ fontSzie: "11px", fontWeight: "normal" }}
            >
              {member.fullName}
            </Feed.Summary>
            <Feed.Meta
              style={{ fontSize: "11px", color: "#999999" }}
              content={member.organismRole}
            />
          </Feed.Content>
        </Feed.Event>
        );
      
      return <Fragment>{membersList}</Fragment>;
  }