import React from "react";
import { Switch, Route } from "react-router-dom";
import {
  Administrators,
  InstitutionsView,
  UsersView,
  OrganismsView,
  ODEView,
  ODEDetailView,
  Strategies,
  OrganismDetailView,
  InstitutionDetailView,
  UserDetailView,
  BatchDetailView,
  Reports,
  InvitationsView
} from "./components/pages";
import { ErrorReporter } from "./components/molecules";
import "semantic-ui-css/semantic.min.css";
import "./fontello/css/fontello.css";
import "./styles/index.css";
import { RouteGuard } from "./v2/components/shared";
import { CurrentUserProvider } from "./v2/contexts/shared";
import Login from "./v2/pages/login";
import { RequestIndex } from "./v2/pages/password-reset/request";
import { PasswordResetIndex } from "./v2/pages/password-reset";

export default class App extends React.Component {
  render() {
    return (
      <div id="app">
        <ErrorReporter />
        <CurrentUserProvider>
          <Switch>
            <Route
              exact
              path="/"
              component={Login} />
            <Route
              path="/password-reset/request"
              component={RequestIndex} />
            <Route
              path="/password-reset/:token"
              component={PasswordResetIndex} />
            <Route
              exact
              path="/invitations/:id"
              component={InvitationsView} />
          </Switch>
          <RouteGuard check={({currentUser}) => currentUser?.id !== undefined}>
            <Switch>
              <Route
                path="/admin/emprendedores"
                component={UsersView} />
              <Route
                exact
                path="/admin/user-detail/:id"
                component={UserDetailView} />
              <Route
                exact
                path="/admin/organism"
                component={OrganismsView} />
              <Route
                exact
                path="/admin/organism/:id"
                component={OrganismDetailView} />
              <Route
                exact
                path="/admin/ode"
                component={ODEView} />
              <Route
                path="/admin/estrategias/:active"
                component={Strategies} />
              <Route
                exact
                path="/admin/ode/:id"
                component={ODEDetailView} />
              <Route
                path="/admin/instituciones"
                component={InstitutionsView} />
              <Route
                path="/admin/institucion/:id"
                component={InstitutionDetailView} />
              <Route
                exact
                path="/admin/batch/:id"
                component={BatchDetailView} />
              <Route
                path="/admin/administradores"
                component={Administrators} />
              <Route
                exact
                path="/admin/estadisticas"
                component={Reports} />
            </Switch>
          </RouteGuard>
        </CurrentUserProvider>
      </div>
    );
  }
}
