import React, { useState } from "react";
import { Query } from "react-apollo";
import { Confirm, Dimmer, Loader, Grid } from "semantic-ui-react";
import { ODE_ACTIVITIES } from "../../../../../graphql/queries/odes";
import {
  CreateActivity,
  EditActivity,
  DetailsContainer
} from "../../../../organisms";
import { DELETE_ODE_ACTIVITY } from "../../../../../graphql/mutations/odes";
import "../styles/index.css";
import { OdeActivitiesHeader, ActivitiesTable } from ".";

const odeId = window.location.pathname.split("e/")[1];

const MapArea = area => {
  const Area = {
    MARKETING: "Mercadotecnia"
  };

  return Area[area];
};

export default props => {
  const [modalState, setModalState] = useState({ isOpen: false });
  const [modalDetailState, setModalDetailState] = useState({
                                                  isOpen: false,
                                                  isEditable: true
                                                });
  const [filterState, setFilterState] = useState("all");
  const [selectedActivity, setSelectedActivity] = useState({});
  const [openConfirm, handleConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [typeToDelete, setTypeToDelete] = useState(null);

  const handleFilterChange = (e, { value }) => {
    setFilterState(() => value);
  };

  /**
   * Deletes the selected activity. It uses the global property "type"
   * to determine if the activity is ODE or Batch activity
   * @param {*} refetch The query to perform after the mutation
   */
  const onDeleteActivity = async refetch => {
    try {
      await DELETE_ODE_ACTIVITY({
        odeId: odeId,
        activityId: deleteId
      });
      handleConfirm(() => false);
      refetch();
    } catch (e) {
      throw new Error(e);
    }
  };

  const verifiyIfCanEdit = (loggedUserRole, loggedUserId, odeNetwork) => {
    const isOdeAssesor = odeNetwork
      .find(({ id, systemRole }) =>
      loggedUserId === id && systemRole === "ADVISER") ?
        true : false;

    if(loggedUserRole === "SUPER_ADMIN" || loggedUserRole === "COORDINATOR") {
      return true;
    }
    else if(isOdeAssesor){
      return true;
    }
    else {
      return false;
    }
  };

  return (
    <Query
      query={ODE_ACTIVITIES}
      variables={{ id: odeId }}
      pollInterval={10000}>
      {({ loading, error, data, refetch }) => {
        if (loading) {
          return (
            <Dimmer>
              <Loader />
            </Dimmer>
          );
        }
        if (error) {
          return (
            <Dimmer>
              <Loader />
            </Dimmer>
          );
        }

        const odeActivities = data.oDE.activities.length > 0 ?
          data.oDE.activities : [];

        const allODEActivities = odeActivities;
        const odeNetwork = data.oDE.network || [];

        return (
          <div>
            <DetailsContainer>
              <DetailsContainer.Content>
                <div
                  className="details-info-tab"
                  style={{
                    height: "80%",
                    overflowY: "auto"
                  }}>
                  <Grid.Row>
                    <OdeActivitiesHeader
                      handleFilterChange={handleFilterChange}
                      setModalState={setModalState} />
                  </Grid.Row>
                  <Grid.Row>
                    <ActivitiesTable
                      allODEActivities={allODEActivities}
                      filterState={filterState}
                      setSelectedActivity={setSelectedActivity}
                      setTypeToDelete={setTypeToDelete}
                      setModalDetailState={setModalDetailState}
                      modalDetailState={modalDetailState}
                      verifiyIfCanEdit={verifiyIfCanEdit}
                      odeNetwork={odeNetwork}
                      handleConfirm={handleConfirm}
                      setDeleteId={setDeleteId} />
                  </Grid.Row>
                </div>
              </DetailsContainer.Content>
            </DetailsContainer>
            {modalState.isOpen && (
              <CreateActivity
                open={modalState.isOpen}
                close={() => setModalState({ isOpen: !modalState.isOpen })}
                refetch={refetch}
                team={props.team}
                odeId={data.oDE.id}
                ode
                showDates />
            )}
            {modalDetailState.isOpen && (
              <EditActivity
                openModal={modalDetailState.isOpen}
                closeModal={() =>
                  setModalDetailState({ isOpen: !modalDetailState.isOpen })
                }
                refetch={refetch}
                activityData={selectedActivity}
                methods={{ mapArea: MapArea }}
                team={props.team}
                ode // means it will be an OdeActivity
                type={typeToDelete}
                editable={modalDetailState.isEditable}
                showDates />
            )}
            {openConfirm ? (
              <Confirm
                open={openConfirm}
                onCancel={() => handleConfirm(() => false)}
                onConfirm={() => onDeleteActivity(refetch)}
                header="Eliminar actividad"
                content={"Esta acción no es reversible."} />
            ) : null}
          </div>
        );
      }}
    </Query>
  );
};
