import gql from "graphql-tag";

export default gql`
  query($id: ID!) {
    organism(where: { id: $id }) {
      id
      objectives {
        id
        name
        type
        description
        activities {
          id
          name
          description
          activityType
          files {
            id
            url
          }
          hasFiles
        }
      }
    }
  }
`;
