import React from "react";
import C3Chart from "react-c3js";
import "c3/c3.css";
import { GridLayout } from "../../organisms";
import { useQuery } from "react-apollo-hooks";
import { GET_ALL_ODES } from "../../../graphql/queries/odes";
import { Loader } from "semantic-ui-react";

export default ({filterVariables}) => {
  const { loading, error, data } = useQuery(GET_ALL_ODES(filterVariables), {
                                              variables: filterVariables
                                            });

  const getDataSource = data => {
    const TLR =
      [ "", "ONE", "TWO", "THREE", "FOUR",
      "FIVE", "SIX", "SEVEN", "EIGHT", "NINE" ];

    const odes = (data.oDEs || []);
    const results = ["Nivel de TRL"];

    const odesOfTRL = TLR.map(trl => (
      parseInt((filterODEs(odes, trl).length * 100) / odes.length)
    ));

    return results.concat(odesOfTRL.reverse());
  };

  const filterODEs = (odes, trl) => {
    const filteredODEs = (Array.isArray(odes) ? odes : [])
                          .filter(ode => ode.trl === trl);
    return (filteredODEs || []);
  };

  const axis = {
    rotated: true,
    x: {
      type: "categoria",
      categories: ["9", "8", "7", "6", "5", "4", "3", "2", "1", "NT"]
    },
    y: {
      label: {
        text: "Porcentaje %",
        position: "outer-center"
      }
    }
  };

  const renderLoader = () => (
    <GridLayout.Item>
      <Loader active inline="centered">
        { loading ? "Cargando..." : null}
        { error ? "Error" : null }
      </Loader>
    </GridLayout.Item>
  );

  const renderContent = () => {
    if(!data)
      return null;

    const chartData = {
      columns: [getDataSource(data)],
      type: "bar",
      colors: {
        "Nivel de TRL": "#472AAA"
      }
    };

    return (
      <GridLayout.Item>
        <C3Chart data={chartData} axis={axis} />
      </GridLayout.Item>
    );
  };

  return (
    <GridLayout>
      {
        loading || error ?
        renderLoader()
        : renderContent()
      }
    </GridLayout>
  );
};
