import gql from "graphql-tag";
import { client } from "../../index";

export default async id => {
  await client.mutate({
    mutation: gql`
      mutation {
        deleteProgram(
          where: { id: "${id}" },
        ) {
          id
        }
      }
    `,
  });
};
