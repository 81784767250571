import React from "react";
import { Grid, Header, Segment } from "semantic-ui-react";

export default ({title, subtitle, children}) => {
  const actions = [];
  const content = [];

  React.Children.forEach(children, c => {
    if(c.props.isActionItem) {
      actions.push(c);
    }
    else {
      content.push(c);
    }
  });

  return (
    <Grid.Row className="detail-grid-section">
      <Grid.Column>
        { title ?
          <Segment clearing className="no-padding">
            <Header as="h3" floated="left">
              <Header.Content>
                { title }
                { subtitle ?
                  <Header.Subheader>{ subtitle }</Header.Subheader>
                : null }
              </Header.Content>
            </Header>
            <Header
              className="action-items"
              as="h3"
              floated="right">
              <Header.Content>
                { actions }
              </Header.Content>
            </Header>
          </Segment>
        : null }
        <Grid className="no-padding">
          { content }
        </Grid>
      </Grid.Column>
    </Grid.Row>
  );
};
