import React, { useState } from "react";
import {
  Modal,
  Grid,
  Icon,
  Input,
  Form,
  Container,
  Dropdown,
  Button,
  TextArea,
  Dimmer,
  Loader
} from "semantic-ui-react";
import axios, { post } from "axios";
import validations from "./validations";
import { InstitutionID, UserDropdown } from "../../atoms";
import { DynamicHolder } from "../../molecules";
import { CREATE_INSTITUTION } from "../../../graphql/mutations/institutions";
import { FormValidator, CLEAN_TEXT } from "../../../helpers";
import { clearFileName } from "../../../helpers/utils";

export default props => {
  if (!props.openModal) return <div />;

  const [data, setData] = useState({
    name: "",
    address: "",
    zone: "",
    description: "",
    coordinator: "",
    avatarUri: ""
  });
  const [image, setImage] = useState(null);
  const [isUploading, setUploadState] = useState(false);
  const [imageShown, setImageShown] = useState(false);
  const [options, setOptions] = useState([]);

  const [errors, setErrors] = useState({
    name: false,
    address: false,
    zone: false,
    description: false,
    coordinator: false
  });

  const handleChange = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
  };

  const validateFrom = data => {
    const validator = new FormValidator(validations);
    const validation = validator.validate(data);
    return validation;
  };

  const createInstitution = async () => {
    const validation = validateFrom(data);
    data.instID = document.getElementById("inst-id").value;
    data.description = CLEAN_TEXT.STRING_TO_UNICODE(
      CLEAN_TEXT.UNICODE_TO_STRING(data.description)
    );
    if (validation.isValid) {
      Object.keys(errors).map(error => {
        errors[error] = validation[error].isInvalid;
      });
      setErrors({ ...errors });
      await CREATE_INSTITUTION(data);
      props.onSend();
      close();
    } else {
      Object.keys(errors).map(error => {
        errors[error] = validation[error].isInvalid;
      });
      setErrors({ ...errors });
    }
  };

  const close = () => {
    Object.keys(errors).map(error => {
      errors[error] = false;
    });
    setErrors({ ...errors });
    setData({});
    props.close();
  };

  const uploadFile = async e => {
    setUploadState(() => true);
    const files = e.target.files;
    const fileToSave = clearFileName(files[0]);
    const form = new FormData();
    if (files.length > 0) {
      form.append("file", fileToSave);
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      await post(process.env.REACT_APP_S3, form, config).then(res => {
        setData({ avatarUri: res.data.imageUrl });
        setImage(() => res.data.imageUrl);
        setImageShown(() => true);
        setUploadState(() => false);
      });
    }
  };

  return (
    <Modal
      className="Modal__Form-SubModal"
      centered={false}
      open={props.openModal}
      onClose={props.closeModal}
      size="tiny"
      dimmer="blurring"
      autoComplete="new-password">
      <Modal.Header as="h1">
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} floated="left">
              Nueva institución
            </Grid.Column>
            <Grid.Column width={2} floated="right">
              <Icon name="close" onClick={close} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <Container className="From__Identicon">
          {isUploading ? (
            <Dimmer active>
              <Loader />
            </Dimmer>
          ) : (
            <DynamicHolder
              uploadFile={uploadFile}
              image={image}
              imageShown={imageShown} />
          )}
        </Container>
        <Form className="Modal__Form">
          <Form.Group widths="2">
            <Form.Field
              width={11}
              control={Input}
              label="Nombre"
              type="text"
              name="name"
              onChange={handleChange}
              required
              error={data.name !== "" ? false : errors.name} />
            <Form.Field
              id="inst-id"
              width={5}
              control={InstitutionID}
              name="instID"
              label="ID"
              type="text"
              required
              onChange={handleChange}
              readOnly />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={TextArea}
              name="description"
              label="Descripción"
              type="text"
              onChange={handleChange}
              required
              error={data.description !== "" ? false : errors.description} />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              name="address"
              label="Dirección"
              type="text"
              onChange={handleChange}
              required
              error={data.address !== "" ? false : errors.address} />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={Dropdown}
              options={[{ text: "Norte", key: "NORTH", value: "NORTH" }]}
              name="zone"
              selection
              label="Región"
              type="text"
              onChange={handleChange}
              required
              error={data.zone !== "" ? false : errors.zone} />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={UserDropdown}
              systemRole={["COORDINATOR", "SUPER_ADMIN"]}
              name="coordinator"
              label="Coordinador"
              onChange={handleChange}
              required
              error={data.coordinator !== "" ? false : errors.coordinator} />
          </Form.Group>
          <Form.Group widths="equal">
            <Button
              primary fluid
              onClick={createInstitution}>
              Crear
            </Button>
          </Form.Group>
        </Form>
      </Modal.Content>
    </Modal>
  );
};
