import React, { Fragment } from "react";
import moment from "moment";
import { Grid } from "semantic-ui-react";

export default ({odeData, shouldShowAddress}) => {
  return (
    <div className="detail-section">
      <p style={{ fontSize: "15px" }}> Información </p>
      <Grid columns={4}>
        <Grid.Column width={3} textAlign="left">
          <p style={{ color: "#999999" }}> Nacionalidad </p>
          {odeData.nationality}
        </Grid.Column>
        <Grid.Column width={3} textAlign="left">
          <p style={{ color: "#999999" }}> Ciudad </p> {odeData.city}
        </Grid.Column>
        <Grid.Column width={3} textAlign="left">
          {shouldShowAddress ?
            <Fragment>
              <p style={{ color: "#999999" }}> Dirección </p>
              {odeData.physicalAddress}
            </Fragment>
            : null}
        </Grid.Column>
        <Grid.Column width={3} textAlign="left">
          <p style={{ color: "#999999" }}>Miembro desde</p>
          {moment(odeData.registrationYear).utcOffset(0).format("DD/MM/YYYY")}
        </Grid.Column>
      </Grid>
    </div>
  );
};
