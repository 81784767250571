import React, { useState, useEffect } from "react";
import {
  Grid,
  Icon,
  Modal,
  Form,
  Input,
  Button,
  Container,
} from "semantic-ui-react";
import gql from "graphql-tag";
import _ from "lodash";
import { client } from "../../../graphql/";
import { USERS } from "../../../graphql/queries/users";
import { UserDropdown } from "../../atoms";
import { CONNECT_USER } from "../../../graphql/mutations/organisms";
import { UPDATE_USER_ROLE } from "../../../graphql/mutations/users";

export default props => {
  const id = window.location.pathname.split("/")[3];
  const [data, setData] = useState({ user: "", role: "" });
  
  const handleChange = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
  };

  /**
   * Link a User to the Organism
   */
  const addUser = async () => {
    data["userId"] = escape(data["user"]);
    data["role"] = escape(data["role"]);
    data["id"] = escape(id);
    await CONNECT_USER(data);
    await UPDATE_USER_ROLE(data);
    window.location.reload();
  };

  return (
    <Modal
      className="Modal__Form-SubModal"
      centered={false}
      open={props.openModal}
      close={props.closeModal}
      size="tiny"
      dimmer="blurring"
      autoComplete="new-password"
    >
      <Modal.Header as="h1">
        <Grid>
          <Grid.Row>
            <Grid.Column width={2} floated="right">
              <Icon
                name="close"
                onClick={props.close}
                style={{ cursor: "pointer" }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>Agregar miembro</Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <Form style={{ margin: "0 auto", width: "90%" }}>
          <Form.Field
            label="Buscar usuario"
            name="user"
            onChange={handleChange}
            control={UserDropdown}
            usersToExclude={ props.usersToExclude }// will exclude users with this IDs
            type="text"
            defaultValue={data["user"]}
          />
          <Form.Field
            label="Rol"
            name="role"
            control={Input}
            defaultValue={data["role"]}
            onChange={handleChange}
          />
        </Form>
      </Modal.Content>
      <Container
        style={{ textAlign: "center", padding: "30px 43px 30px 43px" }}
      >
        <Button
          onClick={() => addUser()}
          type="button"
          color="teal"
          className="fluid ui button"
        >
          Agregar
        </Button>
      </Container>
    </Modal>
  );
};
