import gql from "graphql-tag";

export default gql`
  query($id: ID!) {
    user(where: { id: $id }) {
      fullName
      profilePicUrl
      email
      userID
      gender
      phone
      area
      school
      enrollment
      career
      graduationDate
      profileTec
      systemRole
      odes {
        id
        ode {
          name
        }
        rol
      }
      timeline {
        action
        type
        user
        ode
        timestamp
      }
      organisms {
        id
      }
    }
  }
`;
