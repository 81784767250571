import React from "react";
import { Container, Progress } from "semantic-ui-react";

export const LoadingBar = ({ loading = false }) => {
  return (
    loading ?
      <Container style={{ marginTop: "10pt" }}>
        <Progress
          color="violet"
          percent={100}
          active />
      </Container>
    : null);
};
