import gql from "graphql-tag";
import { client } from "../../index";

export default async id => {
  return await client.query({
    query: gql`
      {
        objective(where: { id: "${id}" }) {
          id
          name
          description
        }
      }
    `,
  });
};
