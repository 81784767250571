import React from "react";
import { Label } from "semantic-ui-react";
import { ASSIGNED_EXPERT_SERVICE_STATUSES } from "../../../helpers";

export const renderLabel = service => {
  const status = ASSIGNED_EXPERT_SERVICE_STATUSES.find(s => {
    return s.value === service.status;
  });

  return status ?
    <Label color={status.color}>{ status.text }</Label> :
    <Label color="grey">N/A</Label>;
};
