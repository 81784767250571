import React from "react";
import { Button, Icon } from "semantic-ui-react";

const BackButtonNavigation = ({ pathObject }) => {
  const onClick = () => {
    if(Array.isArray(pathObject)) {
      // It takes the first valid object
      const breadcrumb = pathObject
        .reverse()
        .find(item => item.href !== undefined);

        // Restore to the original order
        pathObject.reverse();

      if(breadcrumb !== undefined) {
        window.location.replace(breadcrumb.href);
      }
    }
  };
  // TODO: Use the react router history
  // to handle the navigation instead of the window location
  if (Array.isArray(pathObject) && pathObject.length > 1) {
    return (
      <Button
        style={{ marginLeft: "20px", marginRight: "20px" }}
        onClick={onClick}
        size="mini"
        icon>
        <Icon name="angle left" />
      </Button>
    );
  } else {
    return null;
  }
};

export default BackButtonNavigation;
