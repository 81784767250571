import React from "react";
import "../styles.css";

const styles = {
  activeTab: {
    marginLeft: "15px",
    padding: "3px",
    borderBottom: "1px solid #5F39DE",
    color: "#5F39DE",
  },
  disabled: {
    marginLeft: "15px",
    padding: "3px",
    color: "#686868",
  },
};

export default ({ active, tabs, setActive }) => {

  const { activeTab, disabled } = styles;
  return (
    <div style={{ padding: "5px" }}>
      {tabs.map(({ text, value }, index) => (
        <span
          className="option-tab"
          style={index === active ? activeTab : disabled}
          onClick={() => setActive(() => index)}
          key={index}
        >
          {text}
        </span>
      ))}
    </div>
  );
};
