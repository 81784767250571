import React from "react";
import { Grid, Image } from "semantic-ui-react";
import {
  calculateOdeGeneralProgress
} from "../../odeDetail/helpers/calculateProgress";
import styles from "../styles";

export function BatchOdeItem({
  activities,
  name
}) {
  return (
    <Grid.Row
      columns={2}
      padded>
      <div className="list-item-container">
        <Image
          src="https://via.placeholder.com/150"
          style={styles.odePicture}
          circular />
        <div className="labels-container">
          <p style={{ margin: 0 }}>{name}</p>
          <span>
            {calculateOdeGeneralProgress(activities)}% Avance
          </span>
        </div>
      </div>
    </Grid.Row>
  );
}
