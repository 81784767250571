import React from 'react';
import { Grid, Card, Message } from 'semantic-ui-react';
import { EntityActionMenu } from '..';

export default ({columns, items, emptyMessage, className, onCardEvent}) => {
  onCardEvent = onCardEvent ? onCardEvent : () => {};

  if(emptyMessage && items.length === 0)
    return <Message>{emptyMessage}</Message>;

  return (
    <Grid padded="vertically" className={className}>
      <Grid.Row columns={columns || 3}>
        { items.map((item) => {
          return (
            <Grid.Column id={item.id}>
              <Card fluid>
                <Card.Content>
                  <EntityActionMenu
                    simple
                    style={{float: "right"}}
                    onDelete={() => onCardEvent("delete", item)} />
                  <Card.Header>{ item.title }</Card.Header>
                  <Card.Description>{ item.description }</Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Grid className="no-padding">
                    <Grid.Row columns={2}>
                      <Grid.Column>{ item.extraLeft }</Grid.Column>
                      <Grid.Column textAlign="right">{ item.extraRight }</Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Card.Content>
              </Card>
            </Grid.Column>
          );
        }) }
      </Grid.Row>
    </Grid>
  );
}
