import React, { useState } from "react";
import {
  Modal,
  Input,
  Form,
  Dropdown,
  Button,
  Header,
  Confirm,
} from "semantic-ui-react";
import { DeleteButton } from "../../../atoms";
import { ProjectDropdown } from "../../../atoms";
import { DELETE_USER } from "../../../../graphql/mutations/users";

export default props => {
  const [openConfirm, handleConfirm] = useState(false);
  const deleteUser = async () => {
    await DELETE_USER(props.data.id);
    handleConfirm(() => false);
    window.location = "/admin/emprendedores";
  };
  return (
    <Modal.Content>
      <Form className="Modal__Form">
        <Header as="h3">Cuenta</Header>
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            type="email"
            label="Correo electrónico"
            name="email"
            defaultValue={props.data.email}
            error={props.data.email ? false : props.errors3.email}
            onChange={props.handleChange}
            required
          />
        </Form.Group>

        <Form.Group widths="equal">
          {!props.update ? (
            <Form.Field
              control={Input}
              type="password"
              label="Contraseña"
              name="password"
              defaultValue={props.data.password}
              error={props.data.password ? false : props.errors3.password}
              onChange={props.handleChange}
              required
            />
          ) : null}
        </Form.Group>
        <Form.Group widths="equal">
          {!props.update ? (
            <Form.Field
              control={Input}
              type="password"
              label="Confirmar contraseña"
              name="password_confirm"
              defaultValue={props.data.password_confirm}
              error={
                props.data.password ? false : props.errors3.password_confirm
              }
              onChange={props.handleChange}
              required
            />
          ) : null}
        </Form.Group>
        <Form.Group widths="equal">
          { props.update && <DeleteButton del={() => handleConfirm(() => true)} /> }
          <Button
            style={{ width: "150px", marginLeft: "100px" }}
            type="button"
            onClick={() => props.prev2()}
            fluid
            primary
          >
            Anterior
          </Button>
          <Button
            type="button"
            onClick={
              props.update
                ? () => props.updateUser(props.data)
                : () => props.createUser(props.data)
            }
            fluid
            color="teal"
            style={{ width: "150px" }}
          >
            {props.update ? "Guardar" : "Crear"}
          </Button>
        </Form.Group>
      </Form>
      <Confirm
        open={openConfirm}
        onCancel={() => handleConfirm(() => false)}
        onConfirm={() => deleteUser()}
        header="Eliminar usuario"
        content={"Esta acción no es reversible."}
      />
    </Modal.Content>
  );
};

/* 

*/
