import React from "react";
import { Grid } from "semantic-ui-react";

export default props => {
  const { data, onNext, onPrev, current, propertyKey, totalRecords } = props;
  return (
    <Grid columns={3}>
      <Grid.Column width={7} textAlign="left">
        <p
          style={{ cursor: "pointer", color: "#828282" }}
          onClick={onPrev}>
          Anterior
        </p>
      </Grid.Column>
      <Grid.Column
        textAlign="center"
        style={{
          display: "block",
          paddingLeft: "15px",
          paddingTop: "10px",
          width: "35px",
          height: "35px",
          borderRadius: "50%",
          background: "#5f39de",
          color: "#FFFFFF",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
          marginTop: "3px"}}>
        {current}
      </Grid.Column>
      <Grid.Column width={3} textAlign="right">
        <p
          style={{ cursor: "pointer", color: "#828282" }}
          onClick={() => onNext(data[propertyKey], totalRecords)}>
          Siguiente
        </p>
      </Grid.Column>
    </Grid>
  );
}