export default [
  {
    field: "name",
    method: "isEmpty",
    validWhen: false,
    message: "",
  },
  {
    field: "startDate",
    method: "isEmpty",
    validWhen: false,
    message: "",
  },
  {
    field: "finishDate",
    method: "isEmpty",
    validWhen: false,
    message: "",
  },
  {
    field: "description",
    method: "isEmpty",
    validWhen: false,
    message: "",
  }
];
