import React from "react";
import { Query } from "react-apollo";
import _ from "lodash";
import { ODES, USER_ODES } from "../../../graphql/queries/odes";
import { Countries } from "../../../helpers";
import { Dimmer, Loader, TableCell, TableRow } from "semantic-ui-react";
import { DELETE_ODE } from "../../../graphql/mutations/odes";
import { Row } from "./components";
import SessionContext from "../../../context/userSessionContext";

const processUserOdes = data => {
  const mergedOrganisms = [].concat.apply([], data.user.organisms);
  const mergedBatches = [].concat.apply([], processBatchArray(mergedOrganisms));
  let mergedOdes = _.mergeWith({}, ...mergedBatches, (value, objValue) =>
    (value || []).concat(objValue)
  ).odes;

  if(data.user.odes) {
    const userOdes = data.user.odes.map((i) => i.ode[0]);

    mergedOdes = mergedOdes ?
      mergedOdes.concat(userOdes) :
      userOdes;
  }

  const uniqueOdes = _.uniqBy(mergedOdes, "id").filter((i) => i !== undefined);
  return uniqueOdes;
};

const processBatchArray = mergedOrganisms => {
  const batchArrray = [];
  mergedOrganisms.forEach(i => {
    return i.program.forEach(e => {
      if (e.batches.length > 0) batchArrray.push(e.batches);
    });
  });
  return batchArrray[0];
};

export default props => (
  <SessionContext.Consumer>
    {ctx =>
      ctx.id !== null && (
        <Query
          query={ctx.role === "SUPER_ADMIN" ? ODES : USER_ODES}
          variables={{ id: ctx && ctx.id }}
          fetchPolicy="network-only"
          errorPolicy="ignore"
          pollInterval={60000}
        >
          {({ loading, error, data, refetch }) => {
            if (loading)
              return (
                <TableRow textAlign="center">
                  <TableCell className="Wrapper__Body-Loader">
                    <Dimmer active inverted>
                      <Loader
                        testid="loading-wrapper"
                        inverted
                        content="Cargando..."
                      />
                    </Dimmer>
                  </TableCell>
                </TableRow>
              );

            if (error) {
              return `Error!: ${error}`;
            }

            const _data = data.oDEs ? data.oDEs : processUserOdes(data);

            return _data.map(
              (
                {
                  name,
                  id,
                  odeCategory,
                  nationality,
                  city,
                  state,
                  industry,
                  trl,
                  phase,
                  technology,
                  organisms,
                  team,
                  network,
                },
                index
              ) => (
                <Row
                  city={city}
                  countries={Countries}
                  index={index}
                  idOde={id}
                  odeCategory={odeCategory}
                  name={name}
                  nationality={nationality}
                  state={state}
                  industry={industry}
                  trl={trl}
                  technology={technology}
                  delete_ode={DELETE_ODE}
                  refetch={refetch}
                  setOdeId={props.setOdeId}
                  editOdeIsOpen={props.editOdeIsOpen}
                  setEditOdeState={props.setEditOdeState}
                  organisms={organisms}
                  setConfirm={props.setConfirm}
                  phase={phase}
                />
              )
            );
          }}
        </Query>
      )
    }
  </SessionContext.Consumer>
);
