export default [
  ['', null],
  ['Nombre', null],
  ['Fecha de registro', null],
  ['Rol', null],
  ['Correo Electrónico', null],
  ['Teléfono', null],
  ['Asignado', null],
  ['', null],
];
