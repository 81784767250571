import React, { useState } from "react";
import {
  Container,
  Modal,
  Grid,
  Icon,
  Button,
  Dropdown,
  Form,
  Input,
  TextArea,
  Checkbox,
} from "semantic-ui-react";
import { FormValidator } from "../../../../helpers";
import { CREATE_OBJECTIVE_ACTIVITY } from "../../../../graphql/mutations/strategies";
import validations from "./validations";

export default props => {
  const { activityModal, handleActivityModal, objectiveId } = props;
  const [data, setData] = useState({
    id: "",
    name: "",
    area: "",
    description: "",
  });
  const [errors, setErrors] = useState({
    name: false,
    area: false,
    description: false,
  });

  const handleChange = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
  };

  const handleChangeCheckbox = (e, { name, checked }) => {
    data[name] = checked;
    setData({ ...data });
  };

  const save = async () => {
    const validation = validateFrom();
    handleErrors(validation);
    if (validation.isValid) {
      data["id"] = objectiveId;
      data["description"] = escape(data["description"]);
      await CREATE_OBJECTIVE_ACTIVITY(data);
      setData({ id: "", name: "", area: "", description: "" });
      handleActivityModal({ active: !activityModal.active });
      window.location.reload();
    }
  };

  const validateFrom = () => {
    const validator = new FormValidator(validations);
    const validation = validator.validate(data);
    return validation;
  };

  const handleErrors = validation => {
    Object.keys(errors).map(error => {
      errors[error] = validation[error]["isInvalid"];
    });
    setErrors({ ...errors });
  };

  return (
    <Modal open={activityModal.active} size="tiny">
      <Modal.Header as="h1">
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} floated="left">
              Nueva actividad
            </Grid.Column>
            <Grid.Column width={2} floated="right">
              <Icon
                name="close"
                onClick={() =>
                  handleActivityModal({ active: !activityModal.active })
                }
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <Form style={{ margin: "0 auto", width: "70%" }}>
          <Form.Field
            name="name"
            label="Nombre de la actividad"
            control={Input}
            onChange={handleChange}
            error={data["name"] !== "" ? false : errors["name"]}
            required
          />
          <Form.Field
            name="area"
            label="Área de la actividad"
            control={Dropdown}
            options={[
              { key: "MARKETING", text: "Mercadotécnia", value: "MARKETING" },
            ]}
            onChange={handleChange}
            error={data["area"] !== "" ? false : errors["area"]}
            selection
            required
          />
          <Form.Field
            name="description"
            label="Descripción"
            control={TextArea}
            onChange={handleChange}
            error={data["description"] !== "" ? false : errors["description"]}
            required
          />
          <br />
          <br />
          <Checkbox
            name="hasFiles"
            label="Permitir subir archivos "
            onChange={handleChangeCheckbox}
          />
          <i className="icon-attach" />
        </Form>
      </Modal.Content>
      <Container style={{ textAlign: "right", padding: "50px" }}>
        <Button color="blue" onClick={save}>
          Guardar
        </Button>
      </Container>
    </Modal>
  );
};
