import React from "react";
import { Grid } from "semantic-ui-react";

export const mapType = type => {
  const TYPES = {
    VALIDATION: "Validación",
    DISCOVERY: "Descubrimiento",
    LAUNCHING: "Lanzamiento"
  };
  return TYPES[type];
};

export const List = ({ data, active, setActive }) => {
  return (
    <Grid
      divided="vertically"
      className="scrollable"
      style={{
        background: "#FFFFFF",
        padding: 0,
        height: `${window.innerHeight - 200}px`
      }}>
      <Grid.Column style={{margin: "0px", padding: "0px"}}>
        {data.map(({ name, type }, index) => {
          return (
            <Grid.Row
              key={index}
              style={{
                background: active === index ? "#F7F9FC" : "#FFF",
                padding: "15px",
                borderRight: active === index ? "2px solid #5F39DE" : "none",
                height: "80px"
              }}
              className="selectable-item"
              onClick={() => setActive(() => index)}>
              <p style={{ padding: "5px", marginLeft: "12px" }}>
                {name.split(".")[1] === "0" ? name.split(".")[0] : name}
                <br />
                <span style={{ fontSize: "10px", opacity: "0.3" }}>
                  {mapType(type)}
                </span>
              </p>
            </Grid.Row>
          );
        })}
      </Grid.Column>
    </Grid>);
};
