import React, { useState } from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import Identicon from "react-identicons";
import {
  Feed,
  Container,
  Segment,
  Dimmer,
  Loader,
  Grid
} from "semantic-ui-react";
import { PlusButton } from "../../../atoms";

const img = <Identicon string={"randomness"} size={40} />;

const id = window.location.pathname.split("l/")[1];

export default class ODEsList extends React.Component {
  state = {
    openModal: false,
    closeModal: false,
    userData: {}
  };
  openModal = () => {
    this.setState(data => ({ ...data, openModal: true }));
  };
  closeModal = () => {
    this.setState(data => ({ ...data, openModal: false, userData: {} }));
  };
  render() {
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column
              width={8} floated="left"
              style={{ fontSize: "10px" }}>
              Compañías
            </Grid.Column>
            <Grid.Column width={3} floated="right">
              {
                this.props.userRole !== "ENTREPRENEUR" &&
                <PlusButton open={this.props.openAddUser} />
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Query
          query={gql`
                    {
                        user(where:{
                            id:"${id}"
                            }
                            ){
                                id
                                fullName
                                profilePicUrl
                                email
                                userID
                                gender
                                phone
                                area
                                school
                                enrollment
                                career
                                status
                                graduationDate
                                profileTec
                                odes {
                                    id
                                    ode {
                                    name
                                    }
                                    rol
                                }
                                timeline {
                                    action
                                    type
                                    user
                                    ode
                                    timestamp
                                }
                            }
                        }
                    `}
          fetchPolicy="network-only">
          {({ loading, error, data }) => {
            if (loading) {
              return (
                <Segment className="Ode__Detail__ContainerTabs">
                  <Dimmer>
                    <Loader />
                  </Dimmer>
                </Segment>
              );
            }
            if (error) {
              return <p>No existen compañías asignadas a este usuario</p>;
            }

            if (data.organism === null) {
              return <p>No existen compañías asignadas a este usuario</p>;
            }
            const _data = data.user ? data.user : [];
            return _data.odes.map(({ id, ode, rol }, index) => {
              if (ode[0]) {
                return (
                  <Container
                    style={{
                      width: "100%",
                      background: "white",
                      padding: "10px"
                    }}>
                    <Grid style={{ borderBottom: "1px solid #dddddd" }}>
                      <Grid.Row>
                        <Grid.Column>
                          <Feed>
                            <Feed.Event>
                              <Feed.Label>
                                <Identicon size={35} string={ode.name} />
                              </Feed.Label>
                              <Feed.Content style={{ marginTop: "-2.5px" }}>
                                <Feed.Summary
                                  style={{
                                    fontSzie: "11px",
                                    fontWeight: "normal"
                                  }}>
                                  {ode[0].name}
                                </Feed.Summary>
                                <Feed.Meta
                                  style={{ fontSize: "11px", color: "#999999" }}
                                  content={rol} />
                              </Feed.Content>
                            </Feed.Event>
                          </Feed>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Container>
                );
              }
            });
          }}
        </Query>
      </div>
    );
  }
}
