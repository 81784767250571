import { gql } from "apollo-boost";
import { client } from "../../index";

export default async data => {
  await client.mutate({
    mutation: gql`
      mutation DeleteAssignedExpertService($id: ID!){
        deleteAssignedExpertService(where: { id: $id }) {
          id
        }
      }
    `,
    variables: data
  });
};
