import React from "react";
import { Grid } from "semantic-ui-react";

export default ({children, single}) => {
  return (
    <Grid celled="internally">
      <Grid.Row>
        {single ? <Grid.Column>{ children }</Grid.Column> : children}
      </Grid.Row>
    </Grid>
  );
};
