import gql from "graphql-tag";
import { client } from "../../index";

let today = new Date();
let dd = today.getDate();
let mm = today.getMonth() + 1; //January is 0!
let yyyy = today.getFullYear();

if (dd < 10) {
  dd = "0" + dd;
}

if (mm < 10) {
  mm = "0" + mm;
}

today = mm + "/" + dd + "/" + yyyy;

export default async ({
  userID,
  fullName,
  gender,
  phone,
  email,
  career,
  area,
  category,
  school,
  graduationDate,
  enrollment,
  password,
  status,
  timeline,
  systemRole,
}) => {
  await client.mutate({
    mutation: gql`
      mutation {
        createUser(
          data:{
            userID: "${userID}"
            fullName: "${fullName}"
            gender: ${gender}
            phone: "${phone}"
            email: "${email}"
            career: ${career}
            area: ${area}
            category: ${category}
            school: ${school}
            status: ${status}
            registrationDate: "${new Date(today).toISOString()}"
            graduationDate: "${graduationDate}"
            enrollment: "${enrollment}"
            password:"${password}"
            systemRole:${systemRole}
            timeline:{
              create:{
                action:"${timeline.action}"
                user:"${timeline.user}"
                timestamp:"${timeline.timestamp}"
                type:${timeline.type}
              }
            }
          }) {
          id
          fullName
        }
      }
    `,
  });
};
