import _ from "lodash";

const getDataSource = data => {
  if(!data)
    return [];

  const programs = data.programs || [];
  const totalODEs = _.get(data, "oDEsConnection.aggregate.count", 0);

  const dataSource = programs.map(program => {
    const programODEs = getODEsInProgramBatches(program);
    return {
      label: program.name,
      percentage: totalODEs > 0 ?
        parseInt((programODEs.length * 100) / totalODEs) :  0,
      total: programODEs.length
    };
  });

  return dataSource;
};

const getODEsInProgramBatches = program => {
  const programODEs = [];

  program.batches.forEach(batch => {
    const { odes } = batch;
    odes.forEach(ode => {
      programODEs.push(ode);
    });
  });

  return programODEs;
};

export default getDataSource;
