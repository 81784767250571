import React from "react";
import { Query } from "react-apollo";
import { Input } from "semantic-ui-react";
import { ORGANISM_ID } from "../../../graphql/queries/organisms";

export default props => (
  <Query query={ORGANISM_ID} fetchPolicy="network-only">
    {({ loading, error, data }) => {
      if (loading) return "Loading...";
      if (error) return `Error! ${error.message}`;
      return (
        <Input
          id={props.id}
          name={props.name}
          value={data.organismIDs[0].organismID}
          onChange={props.onChange}
          required
          readOnly
        />
      );
    }}
  </Query>
);
