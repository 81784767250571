import React from "react";
import { Card, Icon, Image, Loader } from "semantic-ui-react";

export default props => {
  const { uploadFile, image, imageShown } = props;
  let condition = imageShown && image!==null; //&& imageID && format;

  return (
    <Card
      style={{
        margin:"0 auto",
        background: "#dddddd",
        width: "150px",
        height: "150px",
        textAlign: "center",
        padding: "5px",
        borderRadius:"90px",
        overflow:"hidden"
      }}
    >
      {condition ? (
        <Image src={image} width={150} circular alt="" />
      ) : (
        <Card.Content>
          <br />
          <div>
            <label htmlFor="file" className="ui icon">
             <Icon size="huge" name="camera" color="grey" />             
            </label>
            <input
              onChange={uploadFile}
              type="file"
              id="file"
              accept="image/png, image/jpeg, image/jpg"
              style={{ display: "none" }}
            />
          </div>
        </Card.Content>
      )}
    </Card>
  );
};
