const getEntrepreneurs = (skip = 0) => ({
  variables: {
    systemRole: "ENTREPRENEUR",
    skip,
    first: 10
  }
});

const getCoordinatorEntrepreneurs = (coordinatorId = "", skip = 0) => ({
  variables: {
    id: coordinatorId,
    systemRole: "ENTREPRENEUR",
    skip,
    first: 10
  }
});

export default {
  getEntrepreneurs,
  getCoordinatorEntrepreneurs
};
