import gql from "graphql-tag";
import { client } from "../../index";

export default async (
  objectiveId,
  objectiveActivityId,
  odeActivities) => {
  let data = await client.mutate({
    variables: {
      objectiveId,
      objectiveActivityId,
      odeActivities,
    },
    mutation: gql`
      mutation ($odeActivities: [OdeActivityCreateWithoutActivityInput!], $objectiveId: ID!, $objectiveActivityId: ID!) {
          updateObjective(
          where: { id: $objectiveId }
          data: { 
            activities: {
              update: {
                where : { id: $objectiveActivityId }
                data : {
                  odeActivities: {
                    create: $odeActivities
                  }
                }
              }
            }
          })
          {
            id
          }
        }
    `,
    variables: {
      objectiveId,
      objectiveActivityId,
      odeActivities,
    },
  });
  return data;
};
