import React from "react";
import { DetailHeading } from "../../../../molecules";
import { EditButton } from "../../../../atoms";


export default
  ({data, openEditModal, hasPermissions, editModal, organismId}) => {

  const mapType = type => {
    const TYPES = {
      COWORKING: "Co Working",
      ACCELERATOR: "Aceleradora",
      INVESTMENT_FUND: "Fondo de inversión",
      INCUBATOR: "Incubadora"
    };
    return TYPES[type];
  };

  const onOpen = () => {
    window.history.pushState(
      null,
      "",
      `/admin/organism/edit=${organismId}`
    );
    openEditModal({ active: !editModal.active });
  };

  return (
    <DetailHeading>
      <DetailHeading.LeftContainer
        title={data.organism.name}
        subtitle={mapType(data.organism.type)} />
      <DetailHeading.RightContainer>
        {hasPermissions ?
          <EditButton
            openEditModal={openEditModal}
            editModal={editModal}
            open={onOpen}
            name="edit"
            style={{ cursor: "pointer" }} />
          : null}
      </DetailHeading.RightContainer>
    </DetailHeading>
  );
};
