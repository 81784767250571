import React from "react";
import { Grid } from "semantic-ui-react";
import { CircleContainer } from "../../atoms";

/**
 * This table show a simple table
 * to resume the statistic percentage data
 * @param {*} props
 * dataSource: [
 *   {
        label: "Descubrimiento",
        percentage: 10,
        total: 23
      }
 * ]
 */
const PercentageTable = ({ dataSource, columns }) => {
  const renderHeaders = () => (
    <Grid>
      <Grid.Row columns={3}>
        {
          (Array.isArray(columns) ? columns : [])
          .map((column, index) => (
            <Grid.Column
              textAlign={column.textAlign || "center"}
              width={column.width || 1}
              key={index}>
              <span style={{fontWeight: "bold"}}>
                {column.title || ""}
              </span>
            </Grid.Column>
          ))
        }
      </Grid.Row>
    </Grid>
  );

  const getRows = () => {
    if(!dataSource)
      return null;

    return dataSource.map((data, index) => {
      return (
        <Grid.Row columns={3}>
          <Grid.Column textAlign="left" width={10}>
            <span>
              <CircleContainer style={{ marginRight: "10px" }}>
                {index + 1}
              </CircleContainer> {data.label}
            </span>
          </Grid.Column>
          <Grid.Column textAlign="right" width={3}>
            <span style={{color: "#5F39DE"}}>
              {data.percentage} %
            </span>
          </Grid.Column>
          <Grid.Column textAlign="right" width={3}>
            <span>{data.total}</span>
          </Grid.Column>
        </Grid.Row>
      );
    });
  };

  return (
    <>
      {renderHeaders()}
      <Grid>
        {getRows()}
      </Grid>
    </>
  );
};

export default PercentageTable;
