import React from "react";

export default ({url}) => {
  const getBackgroundColor = (ext) => {
    switch(ext) {
      case "xml":
        return "#5F41D9";
      case "docx":
        return "#2B5693";
      case "ppt":
      case "pptx":
        return "#C34936";
      default:
        return "#505050";
    }
  }

  const match = url.match(/.([a-z])+$/gi);
  const extension = match !== null ? match[0].substring(1) : '?';
  
  const style = {
    width: "48px",
    height: "67px",
    backgroundColor: getBackgroundColor(extension),
    textAlign: "center",
    lineHeight: "67px",
    color: "#ffffff"
  };

  return (
    <div style={style}>
      {extension}
    </div>
  )
}