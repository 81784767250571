import React, { useState } from "react";
import {
  Button,
  Grid,
  List,
  Header,
  Container,
  Table,
  Label,
} from "semantic-ui-react";
import { CreateActivity, LinkActivity } from "./components";

const ObjectivesSideList = props => {
  const { objectives, active, handleNavigation } = props;

  return (
    <List divided verticalAlign="middle">
      {objectives.map(({ name, id }, index) => {
        const isActive = active === id;
        return (
          <List.Item
            key={index}
            style={{
              background: isActive ? "#5f39de" : "#ffffff",
              padding: "20px",
              width: "100%",
            }}
            onClick={() => {
              handleNavigation(index);
              window.history.pushState(
                null,
                "",
                `/admin/estrategias/active=${index}`
              );
            }}
          >
            <List.Content>
              <List.Header>
                <span
                  style={{ color: isActive ? "#ffffff" : "rgba(0,0,0,0.5)" }}
                >
                  {name}
                </span>
              </List.Header>
            </List.Content>
          </List.Item>
        );
      })}
    </List>
  );
};

export default props => {
  const { objectives, createModalProps } = props;
  const activeObjective = window.location.pathname.split("active=")[1];
  const root = "/admin/estrategias/active=0";
  const [objective, setObjective] = useState({
    active: objectives[activeObjective]
      ? objectives[activeObjective]
      : (window.location = root),
  });
  const [activityModal, handleActivityModal] = useState({ active: false });
  const [linkModal, handleLinkModal] = useState({ active: false });
  const objectiveToMap = objective.active.activities
    ? objective.active.activities
    : [];

  const handleNavigation = index => {
    setObjective({ active: objectives[index] });
  };

  return (
    <Grid columns={2} divided style={{ height: "100%", background: "white" }}>
      <Grid.Column width={3} style={{ padding: 0 }}>
        <ObjectivesSideList
          objectives={objectives}
          active={objective.active.id}
          handleNavigation={handleNavigation}
        />
      </Grid.Column>
      <Grid.Column width={12}>
        <Container style={{ margin: "0 auto", padding: "none", width: "100%" }}>
          {/* Objective content header */}
          <Grid columns={3} style={{ width: "100%" }}>
            <Grid.Column floated="left" width={9}>
              <Header as="h2">{objective.active["name"]}</Header>
            </Grid.Column>
            <Grid.Column floated="right" width={2} textAlign="right">
              <i
                className="icon-link"
                style={{ fontSize: "25px" }}
                onClick={() =>
                  handleLinkModal({
                    active: !linkModal.active,
                  })
                }
              />
              <i
                className="icon-settings"
                style={{ fontSize: "25px" }}
                onClick={createModalProps.close}
              />
            </Grid.Column>
            <Grid.Column floated="right" width={4}>
              <Button
                color="blue"
                onClick={() =>
                  handleActivityModal({ active: !activityModal.active })
                }
              >
                Crear actividad
              </Button>
            </Grid.Column>
          </Grid>
          {/* Objective content description */}
          <Container style={{ margin: "0 auto", width: "95%" }}>
            <p>{unescape(objective.active["description"])}</p>
          </Container>
          {/* Objective activies */}
          <Container style={{ margin: "50px auto" }}>
            <Table basic selectable>
              <Table.Body>
                {objectiveToMap.map(({ name, area }, index) => {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell width={11}>
                        {name}&nbsp;&nbsp;
                        <Label color="teal">{area}</Label>{" "}
                      </Table.Cell>
                      <Table.Cell width={4} textAlign="right">
                        <i className="icon-delete" />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Container>
        </Container>
      </Grid.Column>
      {activityModal.active ? (
        <CreateActivity
          handleActivityModal={handleActivityModal}
          activityModal={activityModal}
          objectiveId={objective.active.id}
        />
      ) : null}
      {linkModal.active ? (
        <LinkActivity
          open={linkModal.active}
          handleLinkModal={handleLinkModal}
          objectiveId={objective.active.id}
          organisms={objective.active.organisms}
        />
      ) : null}
    </Grid>
  );
};
