import React from "react";
import { Grid, Loader } from "semantic-ui-react";

const Item = ({ children, isLoading, hasErrors }) => {

  const getLoader = () => {
    return (
      <Grid.Column>
        <Loader active inline="centered">
          { isLoading ? "Cargando..." : null}
          { hasErrors ? "Error" : null }
        </Loader>
      </Grid.Column>
    );
  };

  const getContent = () => {
    if(!children)
      return null;

    return React.Children.map(children, (columnContent, index) => (
      <Grid.Column key={index}>
        {columnContent}
      </Grid.Column>
    ));
  };

  return (
    <Grid.Row
      stretched
      columns={children ? children.length : 1}>
      {
        isLoading || hasErrors ?
        getLoader()
        : getContent()
      }
    </Grid.Row>
  );
};

export default Item;
