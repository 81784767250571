import React, { useContext } from "react";
import { TableRow, TableCell } from "semantic-ui-react";
import { OrganismOptions } from "../../organisms";
import { PARSE_DATE } from "../../../helpers";
import { verifyUserPermission } from "../../../helpers/permissionsValidator.js";
import SessionContext from "../../../context/userSessionContext";

const mapType = type => {
  const TYPES = {
    COWORKING: "Co Working",
    ACCELERATOR: "Aceleradora",
    INVESTMENT_FUND: "Fondo de inversión",
    INCUBATOR: "Incubadora"
  };
  return TYPES[type];
};

export default ({
  index,
  organismData,
  onRowEvent
}) => {
  const {id, name, type, wasCreatedAt, coordinator, institution} = organismData;
  const rulesToShowOptions = [
    { role: "SUPER_ADMIN" },
    { role: "COORDINATOR", id: coordinator.id },
    { role: "COORDINATOR", id: institution.coordinator.id }
  ];

  const loggedUser = useContext(SessionContext);

  return (
    <TableRow
      textAlign="center"
      key={index}
      onClick={() => {
        window.location = `/admin/organism/${id}`;
      }}>
      <TableCell>{name}</TableCell>
      <TableCell>{mapType(type)}</TableCell>
      <TableCell>{coordinator?.fullName || ""}</TableCell>
      <TableCell>{PARSE_DATE(wasCreatedAt || "")}</TableCell>
      <TableCell>{institution.name || ""}</TableCell>
      <TableCell>
        {
          verifyUserPermission(
            rulesToShowOptions,
            {
              role: loggedUser.role,
              id: loggedUser.id
            }) ?
              <OrganismOptions
                organismId={id}
                onRowEvent={onRowEvent} />
              : null
        }
      </TableCell>
    </TableRow>);
};
