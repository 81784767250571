import React from "react";
import { Form, Header, Input, Dropdown } from "semantic-ui-react";
import { CONSTANTS } from "../../../helpers";

export default () => {
  const {
    OPTIONS: {
      CATEGORY_OPTIONS, SCHOOL_OPTIONS, CAREER_OPTIONS
    }
  } = CONSTANTS;

  return (
    <>
      <Header as="h3">Académico</Header>
      <Form.Field
        control={Input}
        label="Año de graduación"
        type="number"
        name="graduationDate"
        required />
      <Form.Field
        control={Dropdown}
        label="Escuela"
        name="school"
        selection
        options={SCHOOL_OPTIONS}
        required />
      <Form.Field
        control={Dropdown}
        label="Carrera"
        name="career"
        options={CAREER_OPTIONS}
        required
        selection />
      <Form.Field
        control={Input}
        label="Matrícula"
        name="enrollment"
        type="text"
        required />
      <Form.Field
        control={Dropdown}
        label="Perfil Tec"
        name="category"
        options={CATEGORY_OPTIONS}
        required
        selection />
    </>
  );
};
