import React from "react";
import { TableRow, TableCell, Image } from "semantic-ui-react";
import { UserOptions } from "../../../organisms";

const STATUS = status => {
  const STATUS = {
    PROSPECT: "Prospecto",
    ACTIVE: "Activo",
    INACTIVE: "Inactivo",
  };
  return STATUS[status];
};

const CATEGORY = category => {
  const CATEGORY = {
    STUDENT: "Estudiante",
    TEACHER: "Maestro",
    DIRECTOR: "Director",
    DICA: "DICA",
    REASEARCHER: "Investigador",
    ENTREPRENEURSHIP_TEAM: "Equipo de emprendimiento",
    EMPLOYEE: "Empleado",
    EXATEC: "Exatec",
    ADVISER: "Consejero",
    EXTERNAL: "Externo",
  };
  return CATEGORY[category];
};

const USER_ROLE = role => {
  const USER = {
    SUPER_ADMIN: "Administrador",
    COORDINATOR: "Coordinador",
    ENTREPRENEUR: "Emprendedor",
    ADVISER: "Asesor",
  };

  return USER[role];
};

export default ({
  rowData,
  setEditModal,
  setConfirm,
  setData,
  setId,
  setRefetch,
}) => {
  const {
    ctx,
    uid,
    index,
    userID,
    fullName,
    registrationDate,
    graduationDate,
    school,
    career,
    category,
    status,
    odes,
    batches,
    systemRole,
    parseDate,
    parseSchool,
    parseCareer,
    deleteUser,
    refetch,
    data,
  } = rowData;

  return (
    <TableRow
      textAlign="center"
      key={index}
      onClick={() => {
        window.location = `/admin/user-detail/${uid}`;
      }}
    >
      <TableCell>
        <Image
          avatar
          src="https://react.semantic-ui.com/images/avatar/small/matthew.png"
        />
      </TableCell>
      <TableCell>{userID}</TableCell>
      <TableCell>{fullName}</TableCell>
      <TableCell>{parseDate(registrationDate)}</TableCell>
      <TableCell>{graduationDate}</TableCell>
      <TableCell>{parseSchool(school)}</TableCell>
      <TableCell>{parseCareer(career)}</TableCell>
      <TableCell>{CATEGORY(category)}</TableCell>
      <TableCell>{odes.length}</TableCell>
      <TableCell>{STATUS(status)}</TableCell>
      <TableCell>{USER_ROLE(systemRole)}</TableCell>
      <TableCell>
        <UserOptions
          idUser={uid}
          delete={deleteUser}
          refetch={refetch}
          hasPrograms={0 > 0}
          setEditModal={setEditModal}
          setData={setData}
          setId={setId}
          data={data.users[index]}
          setConfirm={setConfirm}
          setRefetch={setRefetch}
          hasBatches={batches.length > 0}
        />
      </TableCell>
    </TableRow>
  );
};
