import React, { useContext } from "react";
import Identicon from "react-identicons";
import { TableCell, TableRow, Image } from "semantic-ui-react";
import { InstitutionOptions } from "../../organisms";
import SessionContext from "../../../context/userSessionContext";
import { verifyUserPermission } from "../../../helpers/permissionsValidator";

const mapZone = zone => {
  const MAP = {
    NORTH: "Norte"
  };
  return MAP[zone];
};

export default ({ data, refetch, openEditModal, del }) => {

  const loggedUser = useContext(SessionContext);
  const userData = { role: loggedUser.role, id: loggedUser.id };

  return data.institutions.map(
    ({
      id,
      name,
      instID,
      address,
      zone,
      organisms,
      avatarUri,
      coordinator }, index) => {

      const permissionRules = [
        { role: "SUPER_ADMIN" },
        { // Institution coordinator
          role: "COORDINATOR",
          id: coordinator.id
        }
      ];

      const canEdit = verifyUserPermission(permissionRules, userData);

      return (
        <TableRow
          textAlign="center"
          key={index}
          onClick={() => (window.location = `/admin/institucion/inst=${id}`)}>
          <TableCell>
            {avatarUri !== null && avatarUri !== "" ? (
              <Image
                width={35}
                src={avatarUri}
                centered
                circular />
            ) : (
              <Identicon size={35} string={id} />
            )}
          </TableCell>
          <TableCell>{name}</TableCell>
          <TableCell>{address}</TableCell>
          <TableCell>{mapZone(zone)}</TableCell>
          <TableCell>
            {
              canEdit &&
              <InstitutionOptions
                idInstitution={id}
                del={del}
                refetch={refetch}
                hasPrograms={organisms.length > 0}
                openEditModal={openEditModal} />
            }
          </TableCell>
        </TableRow>
      );
    }
  );
};
