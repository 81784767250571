import React, { useState, useEffect } from "react";
import { PaginationInfo, PaginationControl } from "../../atoms";

export default props =>  {
  const { data, label, propertyKey, onNext, onPrev, current } = props;
  
  const totalRecords = data[`${propertyKey}Connection`] ?
                        data[`${propertyKey}Connection`].aggregate.count 
                        : 0;
  
  return (
    <div style={{
      bottom: window.innerHeight > 800 ? "0" : "auto",
      position: window.innerHeight > 800 ? "fixed" : "relative",
      width: window.innerHeight > 800 ? "85%" : "auto",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
      }}>
      <div>
        <PaginationInfo
          pageRecords={data[propertyKey] ? data[propertyKey].length : 0}
          totalRecords={totalRecords}
          label={label}
          propertyKey={propertyKey} />
      </div>
      <div>
        <PaginationControl
          totalRecords={totalRecords}
          current={current}
          onNext={onNext}
          onPrev={onPrev}
          propertyKey={propertyKey}
          data={data}/>
      </div>
      <div></div>
    </div>
  );
}