import React from 'react';
import { Form, Input, Dropdown } from 'semantic-ui-react';
import { EXPERT_SERVICE_TYPES } from '../../../helpers';

export default ({handleChange, data, errors, className}) => {
  return (
    <Form className={className}>
      <Form.Group widths="equal">
        <Form.Field
          width={11}
          control={Input}
          label="Nombre del servicio"
          type="text"
          name="name"
          required
          onChange={handleChange}
          defaultValue={data.name}
          error={data.name !== "" ? false : errors.name} />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          width={11}
          control={Input}
          label="Descripción"
          type="text"
          name="description"
          onChange={handleChange}
          defaultValue={data.description}
          required
          error={data.description !== "" ? false : errors.description} />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          control={Dropdown}
          options={EXPERT_SERVICE_TYPES}
          name="type"
          selection
          label="Tipo de servicio"
          type="text"
          onChange={handleChange}
          value={data.type}
          required
          error={data.type !== "" ? false : errors.type} />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label>Precio por el servicio</label>
          <Input
            width={11}
            type="number"
            name="price"
            onChange={handleChange}
            defaultValue={data.price}
            required
            error={data.price !== "" ? false : errors.price}
            label={{basic: true, content: "MXN"}}
            labelPosition="right" />
        </Form.Field>
      </Form.Group>
    </Form>
  );
}
