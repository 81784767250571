import React, { useState, useEffect, useContext } from "react";
import { Grid, Image, Dropdown, Label, Table, Input } from "semantic-ui-react";
import gql from "graphql-tag";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";
import CalendarIcon from "mdi-react/CalendarIcon";
import { client } from "../../../../graphql";
import {
  UPDATE_BATCH_START_DATE,
  UPDATE_BATCH_FINISH_DATE
} from "../../../../graphql/mutations/organisms";
import { EditButton, PlusButton, DateField } from "../../../atoms";
import { ConnectBatchToObjective, EditBatch } from "../../../organisms";
import styles from "../styles";
import "moment/locale/es";
import { batchNameFormat } from "../../../../helpers/batchNameFormat";
import { CLEAN_TEXT } from "../../../../helpers";
import SessionContext from "../../../../context/userSessionContext";
import { verifyUserPermission } from "../../../../helpers/permissionsValidator";
import _ from "lodash";

export default () => {
  const {
    batchName,
    objectivesTable,
    container1,
    container2,
    container3,
    coordinatorName,
    coordinatorDescription,
    coordinatorPicture,
    dateLabel,
    datePicker,
    detailTitle,
    editButton,
    label,
    row1,
    row2
  } = styles;

  const [data, setData] = useState({});
  const [addObjectiveModal, setAddObjectiveModal] = useState(false);
  const [editBatchModal, setEditBatchModal] = useState(false);
  const loggedUser = useContext(SessionContext);

  useEffect(() => {
    const getBatch = async () => {
      const organismId = window.location.pathname
        .split("orgid=")[1]
        .split("_")[0];
      const programId = window.location.pathname
        .split("programid=")[1]
        .split("_")[0];
      const batchId = window.location.pathname.split("batchid=")[1];

      const query = await client.query({
        query: gql`{
              organism(where:{id:"${organismId}"}){
                coordinator {
                  id
                }
                institution {
                  coordinator {
                    id
                  }
                }
                program(where:{id:"${programId}"}) {
                  coordinator {
                    id
                  }
                  batches(where:{id:"${batchId}"}){
                    id
                    batchID
                    name
                    description
                    startDate
                    finishDate
                    coordinators: users(where: { systemRole_in:  [COORDINATOR, SUPER_ADMIN] }) {
                      id
                      fullName
                      systemRole
                      profilePicUrl
                    }
                    batchObjectives {
                      id
                      objective {
                        name
                        description
                        type
                        activities {
                          id
                          name
                          description
                          activityType
                          files {
                            url
                          }
                          hasFiles
                        }
                      }
                      id
                      startDate
                      finishDate
                      status
                    }
                  }
                }
              }        
            }`,
        fetchPolicy: "no-cache"
      });
      const {
        id,
        batchID,
        name,
        description,
        startDate,
        finishDate,
        batchObjectives,
        coordinators
      } = query.data.organism.program[0].batches[0];
      const organismData = query.data.organism;

      setData({
        id,
        batchID,
        name,
        description,
        startDate,
        finishDate,
        coordinators,
        batchObjectives,
        organismData
      });
    };
    getBatch();
  }, []);

  const permissionRules = [
    { role: "SUPER_ADMIN" },
    {
      role: "COORDINATOR", // Program coordinator
      id: _.get(data, "organismData.program[0].coordinator.id", "")
    },
    {
      role: "COORDINATOR", // Institution coordinator
      id: _.get(data, "organismData.coordinator.id", "")
    },
    {
      role: "COORDINATOR", // Institution coordinator
      id: _.get(data, "organismData.institution.coordinator.id", "")
    }
  ];

  const canEditAndCreate =
    verifyUserPermission(
      permissionRules,
      {
        role: loggedUser.role,
        id: loggedUser.id
      });

  const [objectiveStatus, setObjectiveStatus] = useState("");

  const handleStartDateChange = async (e, { value }) => {
    const programId = window.location.pathname
      .split("programid=")[1]
      .split("_")[0];
    const batchId = window.location.pathname.split("batchid=")[1];

    try {
      setData({ startDate: value, ...data });
      await UPDATE_BATCH_START_DATE({
        programId,
        batchId,
        startDate: value
      });
    } catch (e) {
      throw new Error(e);
    }
  };

  const handleEndDateChange = async (e, { value }) => {
    const programId = window.location.pathname
      .split("programid=")[1]
      .split("_")[0];
    const batchId = window.location.pathname.split("batchid=")[1];

    try {
      setData({ finishDate: value, ...data });
      await UPDATE_BATCH_FINISH_DATE({
        programId,
        batchId,
        finishDate: value
      });
    } catch (e) {
      throw new Error(e);
    }
  };

  const handleObjectiveFinishDate = async (e, { value, id, index }) => {
    data.batchObjectives[index].finishDate = value;
    setData({ ...data });
    try {
      await UPDATE_BATCH_OBJECTIVE_FINISH_DATE(id, value);
      window.location.reload();
    } catch (e) {
      throw new Error(e);
    }
  };

  const handleObjectiveStartDate = async (e, { value, id, index }) => {
    data.batchObjectives[index].startDate = value;
    setData({ ...data });
    try {
      await UPDATE_BATCH_OBJECTIVE_START_DATE(id, value);
      window.location.reload();
    } catch (e) {
      throw new Error(e);
    }
  };

  const handleChangeObjectiveStatus = async (e, { value, id }) => {
    setObjectiveStatus(() => value);
    try {
      await UPDATE_BATCH_OBJECTIVE_STATUS(id, value);
    } catch (e) {
      throw new Error(e);
    }
  };

  return (
    <div
      style={{
        width: "95%",
        height: "100%",
        overflow: "visible"
      }}>
      <Grid style={container1}>
        {/* Header */}
        <Grid.Row columns="equal" style={row1}>
          <Grid.Column floated="left">
            <p style={batchName}>
              {data.name
                ? data.name.split(".")[1] === "0"
                  ? data.name.split(".")[0]
                  : data.name
                : data.name}
            </p>
          </Grid.Column>
          <Grid.Column floated="right" textAlign="right">
            {
              canEditAndCreate &&
              <div style={editButton}>
                <EditButton
                  open={() => setEditBatchModal(prevState => !prevState)} />
              </div>
            }
          </Grid.Column>
        </Grid.Row>
        {/* Coordinator */}
        <Grid.Row columns={3} style={row2}>
          <Grid.Column>
            <p style={label}>Coordinadores</p>
          </Grid.Column>
          <Grid.Column floated="left" textAlign="left">
            <Image
              src="https://via.placeholder.com/150"
              style={coordinatorPicture}
              circular />
          </Grid.Column>
          <Grid.Column>
            <p style={coordinatorName}>
              {data.coordinators ? data.coordinators.map(c => c.fullName).join(", ") : ""}
            </p>
          </Grid.Column>
        </Grid.Row>
        {/* Detalle */}
        <Grid.Row columns={2}>
          <Grid.Column>
            <p style={label}>Detalle</p>
          </Grid.Column>
          <Grid.Column>
            <div style={{ marginLeft: "-100px", marginBottom: "30px" }}>
              <p style={coordinatorDescription}>
                {data.description &&
                  CLEAN_TEXT.UNICODE_TO_STRING(data.description)}
              </p>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {/* Dates */}
      <Grid style={container2}>
        <Grid.Row>
          <Grid.Column>
            <p style={detailTitle}>Fechas de inicio / término de batch</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <p style={label}>
              <CalendarIcon color="#999999" style={dateLabel} />{" "}
              <span>Fecha de inicio</span>
            </p>
          </Grid.Column>
          <Grid.Column floated="left" textAlign="left">
            {data.startDate ?

              <DateField
                disabled={!canEditAndCreate}
                name="startDate"
                initialValue={data.startDate}
                iconPosition="left"
                onChange={handleStartDateChange}
                style={datePicker} /> :

              <Input
                iconPosition="left"
                icon="calendar"
                style={datePicker}
                value="Cargando..."
                disabled={true} />}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <p style={label}>
              <CalendarIcon color="#999999" style={dateLabel} />{" "}
              <span>Fecha de término</span>
            </p>
          </Grid.Column>
          <Grid.Column floated="left" textAlign="left">
            {data.finishDate ?

              <DateField
                disabled={!canEditAndCreate}
                name="finishDate"
                initialValue={data.finishDate}
                iconPosition="left"
                onChange={handleEndDateChange}
                style={datePicker} /> :

              <Input
                iconPosition="left"
                icon="calendar"
                style={datePicker}
                value="Cargando..."
                disabled={true} />}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {/* Objectives */}
      <Grid style={container3}>
        <Grid.Row columns="equal">
          <Grid.Column floated="left">
            <p style={detailTitle}>Objetivos asignados al batch</p>
          </Grid.Column>
          <Grid.Column floated="right" textAlign="right">
            {
              canEditAndCreate &&
              <div style={editButton}>
                <PlusButton
                  open={() => setAddObjectiveModal(prevState => !prevState)} />
              </div>
            }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Table unestackable style={objectivesTable}>
            <Table.Body>
              {data.batchObjectives
                ? data.batchObjectives.map(
                    ({
                      id,
                      status,
                      startDate,
                      finishDate,
                      objective
                      }, index) => {
                      return (
                        <Table.Row key={`batch-${id}`}>
                          <Table.Cell
                            style={{
                              padding: "20px",
                              fontWeight: "bold"
                            }}
                            textAlign="left"
                            width={4}>
                            <Label circular color="yellow">
                              {_.get(objective, "name", "").substring(0, 1)}
                            </Label>
                            &nbsp; &nbsp; {
                              batchNameFormat(objective && objective.name)
                            }
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              padding: "20px",
                              fontWeight: "bold"
                            }}
                            width={4}
                            textAlign="right">
                            <Dropdown
                              disabled={!canEditAndCreate}
                              selection
                              defaultValue={
                                objectiveStatus !== ""
                                  ? objectiveStatus
                                  : status
                              }
                              options={[
                                {
                                  key: 0,
                                  text: "Activo",
                                  value: "ACTIVE"
                                },
                                {
                                  key: 1,
                                  text: "Inactivo",
                                  value: "INACTIVE"
                                }
                              ]}
                              style={{
                                marginRight:
                                  window.innerWidth > 1280 ? "-80px" : "5px"
                              }}
                              onChange={(e, { value }) => {
                                handleChangeObjectiveStatus(null, {
                                  value,
                                  id
                                });
                              }} />
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              padding: "20px",
                              fontWeight: "bold"
                            }}
                            width={5}
                            textAlign="right">
                            <DateInput
                              disabled={!canEditAndCreate}
                              name="startDate"
                              dateFormat={"DD/MM/YYYY"}
                              placeholder="Inicio"
                              localization="es"
                              value={
                                startDate !== null
                                  ? moment(startDate).format("DD/MM/YYYY")
                                  : ""
                              }
                              iconPosition="left"
                              autoComplete="off"
                              onChange={(e, { value }) => {
                                handleObjectiveStartDate(null, {
                                  value,
                                  id,
                                  index
                                });
                              }}
                              style={{
                                ...datePicker,
                                marginRight:
                                  window.innerWidth > 1280 ? "10px" : "60px"
                              }} />
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              padding: "20px",
                              fontWeight: "bold"
                            }}
                            width={5}
                            textAlign="right">
                            <DateInput
                              disabled={!canEditAndCreate}
                              name="finishDate"
                              dateFormat={"DD/MM/YYYY"}
                              placeholder="Término"
                              localization="es"
                              value={
                                finishDate !== null &&
                                  moment(finishDate).format("DD/MM/YYYY")
                              }
                              iconPosition="left"
                              autoComplete="off"
                              onChange={(e, { value }) => {
                                handleObjectiveFinishDate(null, {
                                  value,
                                  id,
                                  index
                                });
                              }}
                              style={{
                                ...datePicker,
                                marginRight:
                                  window.innerWidth > 1280 ? "0px" : "20px"
                              }} />
                          </Table.Cell>
                        </Table.Row>
                      );
                    }
                  )
                : null}
            </Table.Body>
          </Table>
        </Grid.Row>
      </Grid>
      {addObjectiveModal ? (
        <ConnectBatchToObjective
          open={addObjectiveModal}
          close={() => setAddObjectiveModal(prevState => !prevState)} />
      ) : null}
      {editBatchModal ? (
        <EditBatch
          open={editBatchModal}
          close={() => setEditBatchModal(prevState => !prevState)} />
      ) : null}
    </div>
  );
};


const UPDATE_BATCH_OBJECTIVE_START_DATE = async (objectiveId, startDate) => {
  const batchId = window.location.pathname.split("batchid=")[1];

  await client.mutate({
    mutation: gql`
      mutation($batchId: ID!, $objectiveId: ID!, $startDate: DateTime!) {
        updateBatch(
          where: { id: $batchId }
          data: {
            batchObjectives: {
              update: {
                where: { id: $objectiveId }
                data: { startDate: $startDate }
              }
            }
          }
        ) {
          id
        }
      }
    `,
    variables: {
      batchId: batchId,
      objectiveId: objectiveId,
      startDate: moment(startDate, "DD/MM/YYYY")
        .add("days", 1)
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    }
  });
};

const UPDATE_BATCH_OBJECTIVE_FINISH_DATE = async (objectiveId, finishDate) => {
  const batchId = window.location.pathname.split("batchid=")[1];

  await client.mutate({
    mutation: gql`
      mutation($batchId: ID!, $objectiveId: ID!, $finishDate: DateTime!) {
        updateBatch(
          where: { id: $batchId }
          data: {
            batchObjectives: {
              update: {
                where: { id: $objectiveId }
                data: { finishDate: $finishDate }
              }
            }
          }
        ) {
          id
        }
      }
    `,
    variables: {
      batchId: batchId,
      objectiveId: objectiveId,
      finishDate: moment(finishDate, "DD/MM/YYYY")
        .add("days", 1)
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    }
  });
};

const UPDATE_BATCH_OBJECTIVE_STATUS = async (objectiveId, status) => {
  const batchId = window.location.pathname.split("batchid=")[1];

  await client.mutate({
    mutation: gql`
      mutation(
        $batchId: ID!
        $objectiveId: ID!
        $status: BatchObjectiveStatus!
      ) {
        updateBatch(
          where: { id: $batchId }
          data: {
            batchObjectives: {
              update: { where: { id: $objectiveId }, data: { status: $status } }
            }
          }
        ) {
          id
        }
      }
    `,
    variables: {
      batchId: batchId,
      objectiveId: objectiveId,
      status: status
    }
  });
};
