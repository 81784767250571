export const genericFieldValidationMsgs = {
  required: "Este campo es requerido.",
  pattern: {
    mismatch:"El formato no es correcto."
  },
  types: {
    email: "Ingresa un correo válido.",
    number: "Ingresa un número válido."
  }
};
