import React, { useState } from "react";
import { Modal, Form, Input, Dropdown, Button } from "semantic-ui-react";
import gql from "graphql-tag";
import _ from "lodash";
import { client } from "../../../graphql/";
import { USERS } from "../../../graphql/queries/users";
import { Query } from "react-apollo";
import { CONNECT_ODE } from "../../../graphql/mutations/users";
import {
  CONNECT_USER,
  CONNECT_NETWORK_USER
} from "../../../graphql/mutations/odes";

const Users = props => {
  const { onChange } = props;

  return (
    <Query query={USERS} fetchPolicy="network-only">
      {({ loading, error, data }) => {
        if (loading) {
          return <p>Loading...</p>;
        }
        if (error) {
          return <p>Error...</p>;
        }
        const options = [];
        data.users.map(({ fullName, id, systemRole }, index) => {
          if(props.entrepreneur && systemRole === "ENTREPRENEUR"){
            options.push({
              text: fullName,
              key: index,
              value: JSON.stringify({ fullName: fullName, id: id })
            });
          }
          if(!props.entrepreneur && systemRole !== "ENTREPRENEUR") {
            options.push({
              text: fullName,
              key: index,
              value: JSON.stringify({ fullName: fullName, id: id })
            });
          }

        });
        return (
          <Dropdown
            search
            selection
            icon="search"
            options={options}
            onChange={onChange} />
        );
      }}
    </Query>
  );
};

export default props => {
  const [data, setData] = useState({
    userName: null,
    id: null,
    odeId: null,
    action: null,
    type: null,
    timestamp: null,
    user: null,
    ode: null
  });

  const handleChange = (e, { name, value }) => {
    const v = JSON.parse(value);
    data.userID = v.id;
    data.user = v.fullName;
    data.odeID = window.location.pathname.split("e/")[1];
    data.action = `${v.fullName} ha sido agregado como miembro.`;
    data.type = "MEMBER_ADDED";
    data.user = v.fullName;
    data.timestamp = Date.now();
    data.ode = props.odeName;
    setData(data);
  };

  const handleChangeRol = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
  };

  const addMember = async () => {
    const currentMembers = await getCurrentMembers(data.odeID);
    const userExists = _.some(currentMembers, { id: data.userID });
    if (!userExists) {
      const userId = data.userID;
      const odeId = data.odeID;
      const action = data.action;
      const type = data.type;
      const timestamp = data.timestamp;
      const user = data.user;
      const ode = data.ode;
      const rol = data.rol;
      await CONNECT_ODE(userId, odeId, action, type, timestamp, user, ode, rol);
      if (!props.network) {
        await CONNECT_USER(data);
      } else {
        await CONNECT_NETWORK_USER(data);
      }
      if(props.refetch)
        props.refetch();

      props.onClose();
    } else {
      alert("Este usuario ya es miembro de la ODE");
    }
  };

  const getCurrentMembers = async id => {
    const query = await client.query({
      query: gql`{
        oDE(where:{id:"${id}"}){
          team{
            id
          }
        }
      }`
    });
    return query.data.oDE.team;
  };

  return (
    <Modal
      size="mini" open={props.open}
      onClose={props.onClose}>
      <Modal.Header>Agregar miembro</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field
            label="Buscar usuario"
            control={Users}
            entrepreneur={props.entrepreneur}
            onChange={handleChange} />
          <Form.Field
            name="rol"
            label="Rol"
            control={Input}
            onChange={handleChangeRol} />
          <Button
            onClick={addMember} fluid
            color="teal">
            Agregar
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};
