import * as _ from "lodash";

export const getUserRoleFromUserOdes = (userOdes, odeId) => {
  const userOde = userOdes.find(i => _.get(i, "ode[0].id", -1) === odeId);

  return userOde ?
    /**
     * Some records are saved as undefined as string
     * catching condition for display them correctly
     */
    (userOde.rol && userOde.rol !== "undefined" ? userOde.rol : "N/A") :
    "N/A";
};

export const getOdeRelationFromUserOdes = (userOdes, odeId) => {
  const odeRelation = userOdes.find(i => _.get(i, "ode[0].id", -1) === odeId);
  return odeRelation;
};
