import { gql } from "apollo-boost";

export default gql`
  query($expertId: ID!) {
    expertServices(where: { expert: { id: $expertId } }) {
      id
      expert {
        id
      }
      name
      description
      type
      duration
      price
    }
  }
`;
