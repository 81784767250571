import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Grid,
  Icon,
  Input,
  Modal,
  TextArea,
  Tab,
  Confirm,
  Dropdown,
} from "semantic-ui-react";
import { DeleteButton } from "../../atoms";
import {
  EDIT_OBJECTIVE,
  DELETE_OBJECTIVE,
} from "../../../graphql/mutations/objectives";
import { CLEAN_TEXT, FormValidator } from "../../../helpers";
import validations from "./validations";
import "./styles.css";

const typeOptions = [
  { key: "VALIDATION", text: "Validación", value: "VALIDATION" },
  { key: "DISCOVERY", text: "Descubrimiento", value: "DISCOVERY" },
  {
    key: "LAUNCHING",
    text: "Lanzamiento",
    value: "LAUNCHING",
  },
];

export default props => {
  // Hooks
  const [data, setData] = useState({
    name: "",
    type: "",
    description: "",
  });
  const objectiveData = props.objectiveData;
  const [errors, setErrors] = useState({
    name: false,
    type: false,
    description: false,
  });
  const [openConfirm, handleConfirm] = useState(false);

  useEffect(() => {
    setData({ ...objectiveData });
  }, []);

  const handleChange = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
  };

  const handleChangeType = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
  };

  const updateObjective = async () => {
    const validation = validateFrom(data);
    if (validation.isValid) {
      try {
        data.description = CLEAN_TEXT.STRING_TO_UNICODE(
          CLEAN_TEXT.UNICODE_TO_STRING(data.description)
        );
        await EDIT_OBJECTIVE(data);
        props.refetch();
        props.close({ active: false });
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  const deleteObjective = async () => {
    const id = objectiveData.id;
    await DELETE_OBJECTIVE(id);
    handleConfirm(() => false);
    props.refetch();
    props.close({ active: false });
  };

  const close = () => {
    props.close({ active: false });
  };

  const validateFrom = data => {
    const validator = new FormValidator(validations);
    const validation = validator.validate(data);
    if (validation.isValid) {
      Object.keys(errors).forEach(error => {
        errors[error] = validation[error]["isInvalid"];
      });
      setErrors({ ...errors });
    } else {
      Object.keys(errors).forEach(error => {
        errors[error] = validation[error]["isInvalid"];
      });
      setErrors({ ...errors });
    }
    return validation;
  };

  const panes = [
    {
      menuItem: "General",
      render: () => {
        return (
          <Form className="Modal__Form">
            <Form.Group widths="equal">
              <Form.Field
                width={11}
                control={Input}
                label="Nombre del objetivo"
                type="text"
                name="name"
                defaultValue={
                  data["name"].split(".")[1] === "0"
                    ? data["name"].split(".")[0]
                    : data["name"]
                }
                onChange={handleChange}
                required
                error={errors.name}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                name="type"
                label="Tipo de objetivo"
                control={Dropdown}
                options={typeOptions}
                onChange={handleChangeType}
                value={data.type}
                selection
                required
                error={errors.type}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                control={TextArea}
                name="description"
                label="Descripción"
                value={CLEAN_TEXT.UNICODE_TO_STRING(data["description"])}
                onChange={handleChange}
                error={errors.description}
                required
              />
            </Form.Group>
            <Form.Group className="modal-buttons">
              <DeleteButton del={() => handleConfirm(() => true)} />
              <Button
                style={{ width: "150px" }}
                onClick={() => updateObjective()}
                fluid
                primary>
                Guardar
              </Button>
            </Form.Group>
          </Form>
        );
      },
    },
  ];

  return (
    <Modal
      className="Modal__Form-SubModal"
      centered={false}
      open={props.openModal}
      onClose={props.closeModal}
      size="tiny"
      dimmer="blurring"
      autoComplete="new-password"
    >
      <Modal.Header as="h1">
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} floated="left">
              Editar objetivo
            </Grid.Column>
            <Grid.Column width={2} floated="right">
              <Icon name="close" onClick={close} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <Tab
          className="Tab__Menu"
          menu={{ secondary: true, pointing: true }}
          panes={panes}
        />
      </Modal.Content>
      <Confirm
        open={openConfirm}
        onCancel={() => handleConfirm(() => false)}
        onConfirm={() => deleteObjective()}
        header="Eliminar objetivo"
        content={"Esta acción no es reversible."}
      />
    </Modal>
  );
};
