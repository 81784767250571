import { client } from "../../index";
import gql from "graphql-tag";

export default async ({ odeId, activityId, isComplete }) => {
  return await client.mutate({
    mutation: gql`mutation {
            updateODE(
              where: { id: "${odeId}" }
              data: {
                batchActivities: {
                  update: {
                    where:{id:"${activityId}"}
                    data:{
                        isComplete:${isComplete}
                    }
                  }
                }
              }
            ) {
              id
            }
          }`,
  });
};
