import gql from "graphql-tag";
import { client } from "../../index";

export default async ({
  id,
  name,
  instID,
  address,
  zone,
  description,
  coordinatorId,
  avatarUri,
}) => {
  await client.mutate({
    mutation:
      coordinatorId || avatarUri
        ? gql`
      mutation {
        updateInstitution(
          where: { id: "${id}" },
          data: {
            name: "${name}",
            instID: "${instID}",
            address: "${address}",
            zone: ${zone}
            description:"${description}",
            avatarUri:"${avatarUri}"
            coordinator:{
              connect:{
                id:"${coordinatorId}"
              }
            }
          }
        ) {
          id
          name
          instID
          address
          zone
        }
      }
    `
        : gql`
      mutation {
        updateInstitution(
          where: { id: "${id}" },
          data: {
            name: "${name}",
            instID: "${instID}",
            address: "${address}",
            zone: ${zone}
            description:"${description}"
          }
        ) {
          id
          name
          instID
          address
          zone
        }
      }
    `,
  });
};
