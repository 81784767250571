const getUrlWithHTTP = url => {
  if(url === undefined || url === null)
    return "";

  return (!urlStartsWell(url)) ? `http://${url}` : url;
};

const urlStartsWell = url =>
  (url.startsWith("http://") || url.startsWith("https://"));

const isValidDomain = url => {
  if(url === undefined || url === null)
    return false;

  return url.match(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/);
};

export default {
  getUrlWithHTTP,
  isValidDomain
};
