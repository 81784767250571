export default [
  {
    field: "name",
    method: "isEmpty",
    validWhen: false,
    message: "",
  },
  {
    field: "area",
    method: "isEmpty",
    validWhen: false,
    message: "",
  },
  {
    field: "description",
    method: "isEmpty",
    validWhen: false,
    message: "",
  },
];
