import gql from "graphql-tag";

// ACCORDING TO THE RECEIVED PARAMETERS WE WILL PERFORM A QUERY

export default gql`
    query($exclude: [ID!] = [])
    {
      users( where: { NOT: { id_in: $exclude } } ) {
        id
        fullName
        systemRole
      }
    }
`;
