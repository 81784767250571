export default [
    {
        "key": "0",
        "value": "MINING",
        "text": "Minería"
    },
    {
        "key": "1",
        "value": "ENERGY_SUPPLY",
        "text": "Generación transmisión distribución y comercialización de energía eléctrica suministro de agua y de gas natural por ductos al consumidor final"
    },
    {
        "key": "2",
        "value": "CONSTRUCTION",
        "text": "Construcción"
    },
    {
        "key": "3",
        "value": "MANUFACTURING",
        "text": "Industrias manufactureras"
    },
    {
        "key": "4",
        "value": "WHOLE_SALE",
        "text": "Comercio al por mayor"
    },
    {
        "key": "5",
        "value": "RETAIL_TRADE",
        "text": "Comercio al por menor"
    },
    {
        "key": "6",
        "value": "TRANSPORTATION",
        "text": "Transportes correos y almacenamiento"
    },
    {
        "key": "7",
        "value": "MASS_MEDIA",
        "text": "Información en medios masivos"
    },
    {
        "key": "8",
        "value": "FINANCIAL_SERVICES",
        "text": "Servicios financieros y de seguros"
    },
    {
        "key": "9",
        "value": "REAL_STATE",
        "text": "Servicios inmobiliarios y de alquiler de bienes muebles e intangibles"
    },
    {
        "key": "10",
        "value": "PROFESSIONAL_SERVICES",
        "text": "Servicios profesionales científicos y técnicos"
    },
    {
        "key": "11",
        "value": "CORPORATIONS",
        "text": "Corporativos"
    },
    {
        "key": "12",
        "value": "WASTE_MANAGEMENT",
        "text": "Servicios de apoyo a los negocios y manejo de residuos y servicios de remediación"
    },
    {
        "key": "13",
        "value": "EDUCATONAL_SERVICES",
        "text": "Servicios educativos"
    },
    {
        "key": "14",
        "value": "SOCIAL_ASISSTANCE_SERVICES",
        "text": "Servicios de salud y de asistencia social"
    },
    {
        "key": "15",
        "value": "CULTURAL_SERVICES",
        "text": "Servicios de esparcimiento culturales y deportivos y otros servicios recreativos"
    },
    {
        "key": "16",
        "value": "HOTELS_AND_RESTAURANTS",
        "text": "Servicios de alojamiento temporal y de preparación de alimentos y bebidas"
    },
    {
        "key": "17",
        "value": "OTHERS",
        "text": "Otros servicios excepto actividades gubernamentales"
    },
    {
        "key": "18",
        "value": "GOVERNMENT_ACTIVITIES",
        "text": "Actividades legislativas gubernamentales de impartición de justicia y de organismos internacionales y extraterritoriales"
    }
]