import gql from "graphql-tag";
import { client } from "../../";

export default async ({ batchId }) => {
  return await client.query({
    variables: { id: batchId },
    query: gql`
      query($id: ID!) {
        batch(where: { id: $id }) {
          id
          name
          startDate
          finishDate
          batchObjectives {
            id
            objective {
              id
              name
              activities {
                id
                name
                description
                activityType
                files {
                  url
                }
                hasFiles
              }
            }
          }
        }
      }
    `,
    fetchPolicy: "network-only",
  });
};
