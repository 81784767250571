import gql from "graphql-tag";
import { client } from "../../index";

export default async ({ id, name, area, description, hasFiles }) => {
  const _area = area ? area : false;
  await client.mutate({
    mutation: gql`
      mutation {
        updateObjective(
          where: { id: "${id}" }
          data: {
            activities: {
              create: { name: "${name}", area: ${area}, description: "${description}", hasFiles:${
      hasFiles ? hasFiles : false
    } }
            }
          }
        ) {
          id
        }
      }
    `,
  });
};
