import React from "react";
import { Button } from "semantic-ui-react";

export default ({onClick, icon, children, color}) => {
  let style = icon && { padding: "5px", minWidth: "36.78px" };

  switch(color) {
    case "red":
      style = {
        background: "#FFD9D3",
        border: "0.4px solid rgba(237, 84, 84, 0.35)",
        ...style
      };
      break;
    default:
  }

  return (
    <Button
      style={style}
      onClick={() => onClick()}
      secondary>
      { icon ? icon : children }
    </Button>
  );
};
