import gql from "graphql-tag";
import { client } from "../../index";

export default async ({ networkId, odeId, odeRelationId }) => {
  await client.mutate({
    variables: {networkId, odeId, odeRelationId},
    mutation: gql`
      mutation($networkId: ID!, $odeId: ID!, $odeRelationId: ID!) {
      updateODE(
        where: {
          id: $odeId
        },
        data: {
          network: {
            update: {
              where: { id: $networkId }
              data: {
                odes: {
                  update: {
                    where: {id: $odeRelationId}
                    data: {
                      ode: {
                        disconnect: { id: $odeId }
                      }
                    }
                  }
                }
              }
            }
            disconnect: {
              id: $networkId
            }
          }
        }
      ){
        id
      }
    }
    `
  });
};
