import { client } from "../../index";
import gql from "graphql-tag";

export default async ({ odeId, activityId }) => {
  return await client.mutate({
    mutation: gql`mutation {
            updateODE(
              where: { id: "${odeId}" }
              data: {
                activities: {
                  delete: {
                    id:"${activityId}"
                  }
                }
              }
            ) {
              id
            }
          }`,
  });
};
