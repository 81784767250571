import React, { useState } from "react";
import {
  Loader,
  Modal,
  Dimmer,
  Grid,
  Icon,
  Input,
  Form,
  Container,
  Dropdown,
  Button,
  Image,
  TextArea,
  Confirm,
} from "semantic-ui-react";
import axios, { post } from "axios";
import PencilCircleIcon from "mdi-react/PencilCircleIcon";
import Identicon from "react-identicons";
import validations from "./validations";
import { FormValidator, CLEAN_TEXT } from "../../../helpers";
import { Query } from "react-apollo";
import { INSTITUTION_BY_ID } from "../../../graphql/queries/institutions";
import {
  EDIT_INSTITUTION,
  DELETE_INSTITUTION,
} from "../../../graphql/mutations/institutions/";
import { UserDropdown, DeleteButton } from "../../atoms";
import { clearFileName } from "../../../helpers/utils";

export default ({ idInstitution, isOpen, onClose, onSave }) => {
  const [data, setData] = useState({});
  const [image, setImage] = useState(null);
  const [isUploading, setUploadState] = useState(false);
  const [imageShown, setImageShown] = useState(false);
  const [openConfirm, handleConfirm] = useState(false);
  const [errors, setErrors] = useState({
    name: false,
    address: false,
    zone: false,
    description: false,
    coordinator: false,
  });

  const handleInputChange = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
  };

  const validateForm = data => {
    const validator = new FormValidator(validations);
    const validation = validator.validate(data);
    return validation;
  };

  const editInstitution = async () => {
    const validation = validateForm(data);
    if (validation.isValid) {
      Object.keys(errors).map(error => {
        errors[error] = validation[error]["isInvalid"];
      });
      setErrors({ ...errors });
      data["description"] = CLEAN_TEXT.STRING_TO_UNICODE(
        CLEAN_TEXT.UNICODE_TO_STRING(data.description)
      );
      
      if(!data.coordinatorId)
        data["coordinatorId"] = data.coordinator.id || "";

      await EDIT_INSTITUTION(data);
      onSave();
      onClose();
    } else {
      Object.keys(errors).map(error => {
        errors[error] = validation[error]["isInvalid"];
      });
      setErrors({ ...errors });
    }
  };

  const del = async id => {
    try {
      await DELETE_INSTITUTION(id);
      handleConfirm(() => false);
      window.location = "/admin/instituciones";
    } catch (error) {
      throw new Error(error);
    }
  };

  const uploadFile = async e => {
    setUploadState(() => true);
    const files = e.target.files;
    const fileToSave = clearFileName(files[0]);
    const form = new FormData();
    if (files.length > 0) {
      form.append("file", fileToSave);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      await post(process.env.REACT_APP_S3, form, config).then(res => {
        setData(data => ({ ...data, avatarUri: res.data.imageUrl }));
        setImage(() => res.data.imageUrl);
        setImageShown(() => true);
        setUploadState(() => false);
      });
    }
  };

  return (
    <Modal
      className="Modal__Form-SubModal"
      centered={false}
      open={isOpen}
      onClose={onClose}
      size="tiny"
      dimmer="blurring"
      autoComplete="new-password"
    >
      <Modal.Header>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} floated="left">
              Editar institución
            </Grid.Column>
            <Grid.Column width={2} floated="right">
              <Icon name="close" onClick={onClose} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Query
        query={INSTITUTION_BY_ID}
        onCompleted={data => setData({ 
          ...data.institution,
          coordinatorId: data.institution.coordinator.id
          })}
        fetchPolicy="network-only"
        variables={{ id: idInstitution }}
      >
        {({ loading }) => {
          if (loading)
            return (
              <Loader
                testid="loading-wrapper"
                inverted
                content="Cargando..."
                size="large"
              />
            );

          return (
            <Modal.Content>
              {!isUploading ? (
                <Container className="From__Identicon">
                  {data.avatarUri || imageShown ? (
                    <Image
                      src={data.avatarUri}
                      size="small"
                      centered
                      circular
                    />
                  ) : (
                    <Identicon string={"randomness"} size={100} />
                  )}
                  <div
                    style={{
                      marginTop: "-40px",
                      marginLeft: "140px",
                      zIndex: 999,
                    }}
                  >
                    <label htmlFor="file" className="ui icon">
                      <PencilCircleIcon
                        color="#999999"
                        size={40}
                        style={{ cursor: "pointer" }}
                      />
                    </label>
                    <input
                      onChange={uploadFile}
                      type="file"
                      id="file"
                      accept="image/png, image/jpeg, image/jpg"
                      style={{ display: "none" }}
                    />
                  </div>
                </Container>
              ) : (
                <Dimmer active>
                  <Loader />
                </Dimmer>
              )}
              <Form className="Modal__Form">
                <Form.Group widths="2">
                  <Form.Field
                    width={11}
                    control={Input}
                    label="Nombre de la institución"
                    type="text"
                    name="name"
                    onChange={handleInputChange}
                    required
                    onChange={handleInputChange}
                    value={data.name}
                    error={errors.name}
                  />
                  <Form.Field
                    id="inst-id"
                    width={5}
                    control={Input}
                    name="instID"
                    label="ID"
                    type="text"
                    required
                    onChange={handleInputChange}
                    value={data.instID}
                    readOnly
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    control={TextArea}
                    name="description"
                    label="Descripción"
                    type="text"
                    onChange={handleInputChange}
                    required
                    error={errors.description}
                    value={CLEAN_TEXT.UNICODE_TO_STRING(data.description)}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    control={Input}
                    name="address"
                    label="Dirección de la institución"
                    type="text"
                    onChange={handleInputChange}
                    required
                    error={errors.address}
                    value={data.address}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    control={Dropdown}
                    options={[{ text: "Norte", key: "NORTH", value: "NORTH" }]}
                    name="zone"
                    selection
                    label="Zona"
                    type="text"
                    onChange={handleInputChange}
                    required
                    value={data.zone}
                    error={errors.zone}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    control={UserDropdown}
                    systemRole={["COORDINATOR", "SUPER_ADMIN"]}
                    name="coordinatorId"
                    selection
                    label="Coordinador"
                    onChange={handleInputChange}
                    required
                    value={data.coordinatorId}
                    error={errors.zone}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  {data.organisms.length > 0 ? null : (
                    <DeleteButton del={() => handleConfirm(() => true)} />
                  )}
                  <Button
                    primary
                    fluid
                    onClick={editInstitution}
                    style={{
                      width: "140px",
                      marginLeft: data.organisms.length > 0 ? "255px" : "230px",
                    }}
                  >
                    Guardar
                  </Button>
                </Form.Group>
              </Form>
              <Confirm
                open={openConfirm}
                onCancel={() => handleConfirm(() => false)}
                onConfirm={() => del(data.id)}
                header="Eliminar institución"
                content={"Esta acción no es reversible."}
              />
            </Modal.Content>
          );
        }}
      </Query>
    </Modal>
  );
};
