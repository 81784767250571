import React, { useState } from "react";
import { Button, Container, Form, Input, Message } from "semantic-ui-react";
import { FormValidator } from "../../../../../helpers";
import validations from "./validations";
import _errors from "./errors.js";
import {
  CREATE_INVITATION
} from "../../../../../graphql/mutations/invitations";

export default props => {
  const [data, setData] = useState({
    invitationEmail: ""
  });
  const [status, setStatus] = useState({
    display: false,
    error: false
  });
  const [errors, setErrors] = useState(_errors);

  // Validate given form data
  const validateForm = () => {
    const validator = new FormValidator(validations);
    const validation = validator.validate(data);
    return validation;
  };

  const handleChange = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
  };

  const invite = async () => {
    const validation = validateForm();
    setStatus({display: false});
    if (validation.isValid) {
      try {
        await CREATE_INVITATION({
          email: data.invitationEmail,
          systemRole: "ENTREPRENEUR"
        });
        setData({invitationEmail: ""});
        setStatus({display: true, error: false});
      }
      catch(e) {
        setStatus({display: true, error: true});
      }
    } else {
      Object.keys(errors).map(error => {
        errors[error] = validation[error]["isInvalid"];
      });
      setErrors({ ...errors });
    }
  };

  const displaySuccess = () => {
    return status.display && !status.error;
  };

  const displayError = () => {
    return status.display && status.error;
  };

  return (
    <Container>
      <Container style={{marginTop: "20px"}}>
        { displaySuccess() ?
          <Message success>Se ha enviado la invitación.</Message>
        : null}
        { displayError() ?
          <Message error>
            Ha ocurrido un error al enviar la invitacion.
            Por favor, intentalo de nuevo en unos minutos.
          </Message>
        : null}
      </Container>
      <Form className="Padded__Form">
        <Form.Group widths="equal">
          <Form.Field
            label="Correo electrónico"
            name="invitationEmail"
            control={Input}
            required
            error={errors.invitationEmail}
            onChange={handleChange}
            value={data.invitationEmail} />
        </Form.Group>
        <Form.Group>
          <Button
            type="button"
            id="create-ode-btn"
            onClick={invite}
            fluid
            primary>
            Enviar
          </Button>
        </Form.Group>
      </Form>
    </Container>
  );
};
