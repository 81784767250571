import gql from "graphql-tag";
import { client } from "../../index";
import { getUpdateBatchesCoordMutationFragment } from "./helpers";

export default async ({
  id,
  programID,
  name,
  description,
  coordinator,
  type,
  batches = []
}) => {

  await client.mutate({
    mutation: gql`
      mutation {
        updateProgram(
          where:{id:"${id}"}
          data: {
            programID: "${programID}"
            name: "${name}"
            description: "${description}"
            coordinator:{
              connect:{
                id:"${coordinator.id ? coordinator.id : coordinator}"
              }
            }
            type: ${type}
            # The folowing function returns a string that adds a nested
            # mutation to update the coordinator of the connected batches.
            # This is necessary because the database schema has defined a
            # different coordinator for the program and the batches, but
            # a recent requirement indicates that it must be the same.
            ${getUpdateBatchesCoordMutationFragment(batches, coordinator)}
          }
        ) {
          id
          name
          programID
          description
          type
        }
      }
    `
  });
};
