import { gql } from "apollo-boost";
import { client } from "../../index";

export default async data => {
  await client.mutate({
    mutation: gql`
      mutation CreateUser(
        $fullName: String!,
        $email: String!,
        $password: String!,
        $specialtyArea: String!,
        $expertType: String!,
        $expertCategory: String!,
        $profileTec: String!,
        $enrollment: String!,
        $specify: String!,
        $tabulation: String!,
        $status: UserStatus!
        ){
        createUser(data: {
          userID: "",
          fullName: $fullName,
          email: $email,
          password: $password,
          specialtyArea: $specialtyArea,
          expertType: $expertType,
          expertCategory: $expertCategory,
          profileTec: $profileTec,
          enrollment: $enrollment,
          specify: $specify,
          tabulation: $tabulation,
          status: $status,
          role: EXPERT,
          phone: "",
          systemRole: ENTREPRENEUR
        }) {
          id
        }
      }
    `,
    variables: data
  });
};
