import gql from "graphql-tag";

export default gql`
  {
    users {
      id
      fullName
      profilePicUrl
      email
      userID
      gender
      phone
      area
      school
      enrollment
      career
      graduationDate
      status
      registrationDate
      profileTec
      category
      systemRole
      odes {
        id
        ode {
          name
        }
        rol
      }
      timeline {
        action
        type
        user
        ode
        timestamp
      }
      batches {
        id
      }
      organisms {
        id
      }
    }
  }
`;
