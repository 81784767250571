import { gql } from "apollo-boost";
import client from "../../apollo";
import moment from "moment";

export default async data => {
  const id = data.id;
  delete data.ignore_whitespace;

  const odes = data.ode.map(o => { return { id: o }; });
  const oldOdes = data.odes.map(o => { return { id: o.id }; });
  /* Select all items from oldOdes that don't exist in selected odes array */
  const disconnect = oldOdes
    .filter(o => odes.find(u => u.id === o.id) === undefined);

  data.expertService = { connect: { id: data.expertServiceId } };
  data.odes = {
    disconnect: disconnect,
    connect: odes
  };

  data.finished = data.finished || moment().format();

  delete data.ode;
  delete data.expertServiceId;
  delete data.id;

  return await client.mutate({
    mutation: gql`
      mutation UpdateAssignedExpertService(
          $id: ID!,
          $data: AssignedExpertServiceUpdateInput!
        ){
        updateAssignedExpertService(where: {
          id: $id
        }, data: $data) {
          id
          expert {
            id
          }
        }
      }
    `,
    variables: { data: data, id: id }
  });
};
