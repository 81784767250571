import gql from "graphql-tag";
import { client } from "../../index";

export default async id => {
  return await client.query({
    query: gql`{
            organism(where:{id:"${id}"}){
                id
                name
                organismID
                wasCreatedAt
                type
                coordinator {
                  id
                  fullName
                }
                program {
                  batches {
                    name
                  }
                }
                institution{
                    id
                    name
                }
                description
            }
        }`,
  });
};
