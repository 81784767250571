import React from "react";
import DeleteIcon from "mdi-react/DeleteIcon";

const style = {
  background: "#FFD9D3",
  border: "0.4px solid rgba(237, 84, 84, 0.35)",
};

export default ({ del }) => (
  <div className="roundedButton" style={style}>
    <DeleteIcon color="#ED5454" onClick={del} />
  </div>
);
