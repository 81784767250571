import gql from 'graphql-tag';

export default gql`
  query (
    $first: Int
    $skip: Int
    $filter: String
    $orderBy: OrganismOrderByInput = id_DESC
  ) {
    organisms (
      first: $first,
      skip: $skip,
      orderBy: $orderBy,
      where: {
        OR: [
          { name_contains: $filter }
          { coordinator: { fullName_contains: $filter } }
          { institution: { name_contains: $filter } }
        ]
      }
    ) {
      id
      organismID
      name
      coordinator {
        id
        fullName
      }
      type
      program {
        id
        name
      }
      wasCreatedAt
      institution {
        id
        name
        coordinator {
          id
        }
      }
    }
    organismsConnection {
      aggregate {
        count
      }
    }
  }
`;
