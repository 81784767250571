import gql from "graphql-tag";
import moment from "moment";
import { client } from "../../index";

export default async ({
  name,
  startDate,
  finishDate,
  coordinators = [],
  description,
  programID,
  organismID
}) => {
  coordinators = coordinators?.map(id => ({ id }));

  await client.mutate({
    variables: {
        coordinators
    },
    mutation: gql`mutation(
        $coordinators: [UserWhereUniqueInput!]
    ) {
            updateOrganism(
                where:{
                    id:"${organismID}"
                }
                data:{
                    program:{
                        update:{
                            where :{
                                id:"${programID}"
                            }
                            data:{
                                batches:{
                                    create:{
                                        batchID:"null"
                                        name:"${name}"
                                        startDate:"${moment(
                                          startDate,
                                          "DD/MM/YYYY"
                                        ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")}"
                                        finishDate:"${moment(
                                          finishDate,
                                          "DD/MM/YYYY"
                                        ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")}"
                                        description:"${description}"
                                        users: { connect: $coordinators }
                                    }
                                }
                            }
                        }                        
                    }                  
                }
            ){
                id
            }
        }`,
  });
};
