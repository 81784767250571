import gql from "graphql-tag";
import { client } from "../../index";

export default async ({
  odeId,
  name,
  description,
  activityType,
  finishDate,
  files,
  hasFiles,
  responsibles,
}) => {
  await client.mutate({
    variables: {
      odeId,
      name,
      description,
      activityType,
      files,
      hasFiles,
      responsibles,
      finishDate,
    },
    mutation: gql`
      mutation(
        $odeId: ID!
        $name: String!
        $description: String!
        $activityType: ActivityType!
        $files:FileCreateManyInput
        $hasFiles: Boolean!
        $responsibles: UserCreateManyInput
        $finishDate: DateTime
        ) {
        updateODE(
          where: { id: $odeId }
          data: {
            activities: { 
              create: {
                isComplete: false
                finishDate: $finishDate
                activity: { 
                  create: {
                    name: $name
                    description: $description
                    hasFiles: $hasFiles
                    files: $files
                    activityType: $activityType
                  }
                }
                responsibles: $responsibles
              }
            }
          }
        ) {
          id
        }
      }
    `,
  });
};
