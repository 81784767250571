import gql from 'graphql-tag';

const whereConstraint = `
  where: {
    OR: [
      { id: $id },
      {
        organisms_some: {
          OR: [
            { coordinator: { id: $id } },
            { team_some: { id: $id } },
          ]
        }
      },
      {
        odes_some: {
          ode_some: {
            OR: [
              { team_some: { id: $id } }
              { network_some: { id: $id } }
            ]
          } # END ode_some
        } # END odes_some
      },
    ],
    systemRole_in: $systemRole,
  }`;

/**
 * This query is intended to return the  users
 * which are in the same organism of the specified User.
 * An set of user roles can be specified.
 */
export default gql`
query(
  $id: ID,
  $systemRole: [SystemRole!]
  ) {
    users(${whereConstraint}) {
      id
      fullName
      profilePicUrl
      email
      userID
      gender
      phone
      area
      school
      enrollment
      career
      graduationDate
      status
      registrationDate
      profileTec
      category
      systemRole
      odes {
        id
        ode {
          id
          name
        }
        rol
      }
      timeline {
        action
        type
        user
        ode
        timestamp
      }
      batches {
        id
      }
      organisms {
        id
      }
    }
    usersConnection(${whereConstraint}) {
      aggregate {
        count
      }
    }
  }
`;
