const userCategories = {
  STUDENT: "Alumno",
  TEACHER: "Maestro",
  DIRECTOR: "Director",
  DICA: "DICA",
  REASEARCHER: "Investigador",
  ENTREPRENEURSHIP_TEAM: "Equipo de emprendimiento",
  EMPLOYEE: "Empleado",
  EXATEC: "Exatec",
  ADVISER: "Consejero",
  EXTERNAL: "Foráneo"
};

export default userCategories;
