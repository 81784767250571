import React, { useState, useContext } from "react";
import { Button, Grid } from "semantic-ui-react";
import { CreateProgram } from "../../../../organisms";
import { verifyUserPermission }
  from "../../../../../helpers/permissionsValidator";
import _ from "lodash";
import SessionContext from "../../../../../context/userSessionContext";

const mapType = type => {
  const TYPES = {
    COWORKING: "Co Working",
    ACCELERATOR: "Aceleradora",
    INVESTMENT_FUND: "Fondo de inversión",
    INCUBATOR: "Incubadora"
  };
  return TYPES[type];
};

export default props => {
  const { active, setActive, data, organismData } = props;
  const loggedUser = useContext(SessionContext);
  const permissionRules = [
    { role: "SUPER_ADMIN" },
    { // Organism coordinator
      role: "COORDINATOR",
      id: _.get(organismData, "coordinator.id", "")
    },
    { // Institution coordinator
      role: "COORDINATOR",
      id: _.get(organismData, "institution.coordinator.id")
    }
  ];
  const dataToVerify = { role: loggedUser.role, id: loggedUser.id };

  const [boolean, setBoolean] = useState(false);

  return (
    <div>
      <Grid
        style={{ width: "100%", height: "100%"}}
        verticalAlign="middle">
        <Grid.Row style={{ height: "auto", marginTop: "10px" }}>
          <Grid.Column
            floated="left"
            width={10}
            textAlign="left">
            Todos los programas
          </Grid.Column>
          <Grid.Column
            floated="right"
            width={4}>
            {
              verifyUserPermission(permissionRules, dataToVerify) ?
                <Button
                  secondary
                  name="create"
                  onClick={() => setBoolean(prevState => !prevState)}
                  style={{ cursor: "pointer" }}>
                  Nuevo
                </Button>
                : null
            }
            {boolean ? (
              <CreateProgram
                open={boolean}
                openModal={() => setBoolean(prevState => !prevState)}
                close={() => setBoolean(prevState => !prevState)}
                refetch={props.refetch} />
            ) : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid
        divided="vertically"
        className="scrollable"
        style={{
          background: "#FFFFFF",
          padding: 0,
          height: `${window.innerHeight}px`
        }}>
        <Grid.Column style={{margin: "0px", padding: "0px"}}>
          {data.map(({name, type }, index) => {
            return (
              <Grid.Row
                className="selectable-item"
                key={index}
                style={{
                  background: active === index ? "#F7F9FC" : "#FFF",
                  padding: "15px",
                  borderRight: active === index ? "2px solid #5F39DE" : "none",
                  height: "80px",
                  width: "100%"
                }}
                onClick={() => setActive(() => index)}>
                <p style={{ padding: "5px", marginLeft: "10px" }}>
                  {name}
                  <br />
                  <span style={{ fontSize: "10px", opacity: "0.3" }}>
                    {mapType(type)}
                  </span>
                </p>
              </Grid.Row>
            );
          })}
        </Grid.Column>
      </Grid>
    </div>
  );
};
