import React from "react";

const UploadIcon = props => (
    <svg width='15' height='18' viewBox='0 0 15 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M14.2756 4.34483L9.93075 0H0.310059V18H14.2756V4.34483Z' fill='#EFEBDE'
    />
    <path d='M3.41337 7.13729H11.172C11.3433 7.13729 11.4823 6.99857 11.4823 6.82695C11.4823 6.65533 11.3433 6.5166 11.172 6.5166H3.41337C3.24206 6.5166 3.10303 6.65533 3.10303 6.82695C3.10303 6.99857 3.24206 7.13729 3.41337 7.13729Z'
    fill='#D5D0BB' />
    <path d='M3.41337 4.65487H6.51682C6.68813 4.65487 6.82717 4.51615 6.82717 4.34453C6.82717 4.1729 6.68813 4.03418 6.51682 4.03418H3.41337C3.24206 4.03418 3.10303 4.1729 3.10303 4.34453C3.10303 4.51615 3.24206 4.65487 3.41337 4.65487Z'
    fill='#D5D0BB' />
    <path d='M11.172 9H3.41337C3.24206 9 3.10303 9.13872 3.10303 9.31034C3.10303 9.48197 3.24206 9.62069 3.41337 9.62069H11.172C11.3433 9.62069 11.4823 9.48197 11.4823 9.31034C11.4823 9.13872 11.3433 9 11.172 9Z'
    fill='#D5D0BB' />
    <path d='M11.172 11.4824H3.41337C3.24206 11.4824 3.10303 11.6211 3.10303 11.7928C3.10303 11.9644 3.24206 12.1031 3.41337 12.1031H11.172C11.3433 12.1031 11.4823 11.9644 11.4823 11.7928C11.4823 11.6211 11.3433 11.4824 11.172 11.4824Z'
    fill='#D5D0BB' />
    <path d='M11.172 13.9648H3.41337C3.24206 13.9648 3.10303 14.1036 3.10303 14.2752C3.10303 14.4468 3.24206 14.5855 3.41337 14.5855H11.172C11.3433 14.5855 11.4823 14.4468 11.4823 14.2752C11.4823 14.1036 11.3433 13.9648 11.172 13.9648Z'
    fill='#D5D0BB' />
    <path d='M9.93091 0V4.34483H14.2757L9.93091 0Z' fill='#D5D0BB' />
    </svg>
);

export default UploadIcon;
