import gql from "graphql-tag";
import { client } from "../../index";

export default async id => {
  return await client.query({
    query: gql`
      {
        user(where: { id: "${id}"}) {
          fullName
          userID
          gender
          area
          phone
          status
          graduationDate
          school
          enrollment
          category
          career
          email
          profilePicUrl
        }
      }
    `,
    fetchPolicy: "network-only",
  });
};
