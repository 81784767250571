import React from "react";
import { DELETE_ODE } from "../../../../graphql/mutations/odes";
import { default as TableRow } from "../../../molecules/organismsTableBody/tableRow";
import { Countries } from "../../../../helpers";

export default ({
  organismData,
  key,
  setOrganismId,
  editOrganismIsOpen,
  setEditOrganismState,
  setConfirm,
  onClick,
  onRowEvent,
  refetch
}) => {
  onClick = onClick ? onClick : () => { };
  onRowEvent = onRowEvent ? onRowEvent : () => { };
  setOrganismId = setOrganismId || (() => { });
  setEditOrganismState = setEditOrganismState || (() => { });
  setConfirm = setConfirm || (() => { });

  const props =  {
    organismData,
    key,
    setOrganismId,
    editOrganismIsOpen,
    setEditOrganismState,
    setConfirm,
    refetch,
    onClick,
    onRowEvent };

  return <TableRow {...props}/>;
}
