/**
 * @description
 * Creating an ODE manually involves a set of rules
 * determined by the ode category and nationality.
 * Based on this variables each category needs the following sections
 * COMPANY:
 * - General data
 * - Fiscal => nationality === "MX" ? personType, rfc : ein, inc
 * - Contact data
 * - Entrepreneurship data
 * PROJECT:
 * - General data
 * - Contact data
 * - Entrepreneurship data
 * SPINOFF:
 * - General data
 * - Entrepreneurship data
 * STARTUP:
 * - General data
 * - Contact data
 * - Entrepreneurship data
 */
import React, { useState, useEffect } from "react";
import { Container, Confirm } from "semantic-ui-react";
import moment from "moment";
// TODO create form sections index
import Section1 from "./sections/section1"; // General data
import Section2 from "./sections/section2"; // Fiscal data
import Section3 from "./sections/section3"; // Contact data
import Section4 from "./sections/section4"; // Entrepreneurship data
import errors from "./errors";
import {
  FormValidator,
  CONSTANTS
} from "../../../../../helpers";
import validations from "./validations";
import { CREATE_ODE } from "../../../../../graphql/mutations/odes";
import { verifyOdeNameAvailability } from "../../../../../v2/helpers";

const { TIMELINE_ACTION } = CONSTANTS;

let today = new Date();
let dd = today.getDate();
let mm = today.getMonth() + 1; //January is 0!
let yyyy = today.getFullYear();

if (dd < 10) {
  dd = "0" + dd;
}

if (mm < 10) {
  mm = "0" + mm;
}

today = dd + "/" + mm + "/" + yyyy;

export default props => {
  const { open } = props;
  // Handle form data
  const [data, setData] = useState({});

  // Handle navigation between form sections
  const [hidden1, setHidden1] = useState(true);
  const [hidden2, setHidden2] = useState(false);
  const [hidden3, setHidden3] = useState(false);
  const [hidden4, setHidden4] = useState(false);

  // Handle errrors
  const [errors1, setErrors1] = useState(errors.section1); // form section 1
  const [errors2National, setErrors2National] = useState(
    errors.section2National
  ); // form section 2
  const [errors2Foreign, setErrors2Foreign] = useState(errors.section2Foreign); // form section 2
  const [errors3, setErrors3] = useState(errors.section3); // form section 3
  const [errors4, setErrors4] = useState(errors.section4); // form section 3

  // Handle current ode category
  const [category, setType] = useState({ type: null });

  const [loading, setLoading] = useState(false);

  const [odeNameAlert, setOdeNameAlert] = useState({
    open: false,
    odeName: undefined
  });

  const nationalityCondition = data.nationality === "MX"; // Rule to determine fiscal data for the ode

  const openOdeNameAlert = (odeName = "") => {
    setOdeNameAlert({ open: true, odeName });
  };

  const closeOdeNameAlert = () => {
    setOdeNameAlert({ open: false });
  };

  // Handle inputs change
  const handleChange = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
  };

  // Handle category input change
  const handleChangeCategory = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
    setType({ type: value });
  };

  const setErrors = (errorsDefinition, errorSetter, validation) => {
    Object.keys(errorsDefinition)
      .forEach(fieldName => {
        errorsDefinition[fieldName] = validation[fieldName]?.isInvalid;
      });

    errorSetter({ ...errorsDefinition });
  };

  // ToDo: replace this for a better approach
  const resetErrors = () => {
    Object.keys(errors1).map((error, index) => {
      errors1[error] = false;
    });
    setErrors1({ ...errors1 });

    Object.keys(errors2National).map((error, index) => {
      errors2National[error] = false;
    });
    setErrors2National({ ...errors2National });

    Object.keys(errors2Foreign).map((error, index) => {
      errors2Foreign[error] = false;
    });
    setErrors2Foreign({ ...errors2Foreign });

    Object.keys(errors3).map((error, index) => {
      errors3[error] = false;
    });
    setErrors3({ ...errors3 });

    Object.keys(errors4).map((error, index) => {
      errors4[error] = false;
    });
    setErrors4({ ...errors4 });
  };

  useEffect(() => {
    return () => {
      resetErrors(); // reset erros on umount
    };
  }, [open]);

  // Handle navigation to first section
  const next1 = () => {
    const validation = validateFrom("section1");
    if (validation.isValid) {
      setHidden1(false);
      setHidden2(true);
      setErrors(errors1, setErrors1, validation);
    } else {
      setErrors(errors1, setErrors1, validation);
    }
  };

  // Handle navigation to first section
  const next1Project = () => {
    const validation = validateFrom("section1");
    if (validation.isValid) {
      setHidden1(false);
      setHidden3(true);
      setErrors(errors1, setErrors1, validation);
    } else {
      setErrors(errors1, setErrors1, validation);
    }
  };

  const next1SpinOff = () => {
    const validation = validateFrom("section1");
    if (validation.isValid) {
      setHidden1(false);
      setHidden4(true);
      setErrors(errors1, setErrors1, validation);
    } else {
      setErrors(errors1, setErrors1, validation);
    }
  };

  // Handle navigation to second section
  const next2 = () => {
    const form = nationalityCondition ? "section2National" : "section2Foreign";
    const _errors = nationalityCondition ? errors2National : errors2Foreign;
    const _setErrors = nationalityCondition
      ? setErrors2National
      : setErrors2Foreign;
    const validation = validateFrom(form);
    if (validation.isValid) {
      setHidden2(false);
      setHidden3(true);
      setErrors(_errors, _setErrors, validation);
    } else {
      setErrors(_errors, _setErrors, validation);
    }
  };

  const next3 = () => {
    const validation = validateFrom("section3");
    if (validation.isValid) {
        setHidden3(false);
        setHidden4(true);
        setErrors(errors3, setErrors3, validation);
    } else {
      setErrors(errors3, setErrors3, validation);
    }
  };

  // Handle navigation to previous first section
  const prev1 = () => {
    setHidden2(false);
    setHidden1(true);
  };

  // Handle navigation to previous second section
  const prev2 = () => {
    setHidden3(false);
    setHidden2(true);
  };

  const prev2Project = () => {
    setHidden3(false);
    setHidden1(true);
  };

  const prev3 = () => {
    setHidden4(false);
    setHidden3(true);
  };

  const prev3SpinOff = () => {
    setHidden4(false);
    setHidden1(true);
  };

  const create = async (id = "") => {
    try {
      setLoading(true);
      const validation = validateFrom("section4");

      if (validation.isValid) {
        setErrors(errors4, setErrors4, validation);

        data.id = id;
        data.timeline = TIMELINE_ACTION(null, data.name).ODE_CREATED;
        data.registrationYear = data.registrationYear ?
          data.registrationYear
          : moment().utcOffset(0).format();

        const nameIsAlreadyTaken = await verifyOdeNameAvailability(data.name);

        if (nameIsAlreadyTaken)
          return openOdeNameAlert(data.name);

        const create = CREATE_ODE[returnMutation()];
        await create(data);

        window.location = "/admin/ode";
      } else {
        setErrors(errors4, setErrors4, validation);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const createTimelineEvent = () => {
    const timeline = {
      action: `${data.name} ha sido dada de alta como ODE`,
      timestamp: Date.now(),
      type: `${data.category}`,
    };
    return timeline;
  };

  const returnMutation = () => {
    if (category.type === "COMPANY" && data.nationality === "MX") {
      return "nationalCompany";
    }
    if (category.type === "COMPANY" && data.nationality !== "MX") {
      return "foreignCompany";
    }
    if (
      category.type === "PROJECT" ||
      category.type === "STARTUP" ||
      category.type === "TEAM"
    ) {
      return "STARTUP";
    }
    if (category.type === "SPIN_OFF") {
      return "SPIN_OFF";
    }
  };

  const validateFrom = (formName = "") => {
    const validator = new FormValidator(validations[formName]);
    const validation = validator.validate(data);
    return validation;
  };

  const turnoffautocomplete = e => {
    e.target.setAttribute("autocomplete", "off");
  };

  const handleNext1 = () => {
    if (
      category.type === "PROJECT" ||
      category.type === "STARTUP" ||
      category.type === "TEAM"
    ) {
      return next1Project();
    }
    if (category.type === "SPIN_OFF") {
      return next1SpinOff();
    }
    if (category.type === "COMPANY" || category.type === null) {
      return next1();
    }
  };

  return (
    <Container>
      <Confirm
        header="Atención"
        content={`Ya existe una ODE con el nombre "${odeNameAlert.odeName}". \
          Por favor, ingresa un nombre diferente.`}
        size="tiny"
        open={odeNameAlert.open}
        onCancel={closeOdeNameAlert}
        onConfirm={closeOdeNameAlert} />
      {hidden1 ? (
        <Section1
          next={handleNext1}
          handleChange={handleChange}
          handleChangeCategory={handleChangeCategory}
          errors={errors1}
          data={data}
          turnoffautocomplete={turnoffautocomplete}
        />
      ) : null}
      {hidden2 ? (
        <Section2
          next={next2}
          prev={prev1}
          handleChange={handleChange}
          errors={nationalityCondition ? errors2National : errors2Foreign}
          data={data}
          nationalityCondition={nationalityCondition}
          turnoffautocomplete={turnoffautocomplete}
        />
      ) : null}
      {hidden3 ? (
        <Section3
          next={next3}
          prev={
            category.type === "PROJECT" ||
            category.type === "STARTUP" ||
            category.type === "TEAM"
              ? prev2Project
              : prev2
          }
          handleChange={handleChange}
          errors={errors3}
          data={data}
          turnoffautocomplete={turnoffautocomplete}
        />
      ) : null}
      {hidden4 ? (
        <Section4
          loading={loading}
          create={create}
          prev={category.type === "SPIN_OFF" ? prev3SpinOff : prev3}
          handleChange={handleChange}
          errors={errors4}
          data={data}
          turnoffautocomplete={turnoffautocomplete}
        />
      ) : null}
    </Container>
  );
};
