import gql from "graphql-tag";
import { client } from "../../index";

let today = new Date(Date.now()).toISOString();

export default async ({
  organismID,
  name,
  institution,
  description,
  coordinator,
  type,
}) => {
  await client.mutate({
    mutation: gql`
      mutation {
        createOrganism(
          data: {
            organismID: "${organismID}"
            name: "${name}"
            institution: { connect: { id: "${institution}" } }
            description: "${description}",
            coordinator: {connect:{id:"${coordinator}"}},
            wasCreatedAt: "${today}",
            type: ${type}
          }
        ) {
          id
        }
      }
    `,
  });
};
