import gql from "graphql-tag";
import { client } from "../../";
import moment from "moment";

export default async ({
  name,
  startDate,
  finishDate,
  description,
  coordinators,
  disconnectedCoordinators = []
}) => {
  const batchId = window.location.pathname.split("batchid=")[1];

  coordinators = coordinators?.map(c => ({ id: c })) || [];

  disconnectedCoordinators = disconnectedCoordinators
    ?.map(c => ({ id: c })) || [];

  return await client.mutate({
    variables: {
      id: batchId,
      name,
      startDate: moment(startDate, "DD/MM/YYYY")
        .add(1, "days")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      finishDate: moment(finishDate, "DD/MM/YYYY")
        .add(1, "days")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      description,
      coordinators,
      disconnectedCoordinators
    },
    mutation: gql`
      mutation(
        $id: ID!
        $name: String!
        $startDate: DateTime
        $finishDate: DateTime
        $description: String!,
        $coordinators: [UserWhereUniqueInput!]
        $disconnectedCoordinators: [UserWhereUniqueInput!]
      ) {
        updateBatch(
          where: { id: $id }
          data: {
            name: $name
            startDate: $startDate
            finishDate: $finishDate
            description: $description,
            users: {
              connect: $coordinators,
              disconnect: $disconnectedCoordinators
            }
          }
        ) {
          id
        }
      }
    `,
  });
};
