import React, { Fragment } from "react";
import { Grid } from "semantic-ui-react";
import { calculateOdeProgressPerBatch } from "../../helpers/calculateProgress";
import * as _ from "lodash";

const programItemStyle = {
  backgroundColor: "#F4F5F5",
  borderRadius: 5,
  border: "solid 1px #DDE"
};

const emptyListStyle = {
  container: {
    textAlign: "center",
    margin: "15pt 0 15pt 0"
  },
  label: {
    fontSize: 15,
    color: "#999999",
    fontStyle: "italic"
  }
};

const ProgramItem = ({ batch, percentProgress }) => {
  return (
    <Grid columns={5} style={ programItemStyle }>
      <Grid.Column width={3} textAlign="left">
        <p style={{ color: "#5D5D5D" }}> Institución </p>
        { _.get(batch, "program.organism.institution.name", "N/A") }
      </Grid.Column>
      <Grid.Column width={3} textAlign="left">
        <p style={{ color: "#5D5D5D" }}> Organismo </p>
        { _.get(batch, "program.organism.name", "N/A") }
      </Grid.Column>
      <Grid.Column width={3} textAlign="left">
        <p style={{ color: "#5D5D5D" }}> Programa </p>
        { _.get(batch, "program.name", "N/A") }
      </Grid.Column>
      <Grid.Column width={3} textAlign="left">
        <p style={{ color: "#5D5D5D" }}> Batch </p>
        {/*
          The batch name is saved with a version name.
          In order to show the name without the version 0 number,
          this regular expression replaces the .0 end of string
          with an empty string
        */}
        { batch.name.replace(/\.0$/i, "") || "" }
      </Grid.Column>
      <Grid.Column width={3} textAlign="left">
        <p style={{ color: "#5D5D5D" }}> Progreso </p> { percentProgress } %
      </Grid.Column>
    </Grid>
  );
};

const getList = (batches, odeActivities) => {
  return batches.map((batch, index) => {
    return (
      <ProgramItem
        key={ index }
        batch={ batch }
        percentProgress={
          calculateOdeProgressPerBatch(odeActivities, batch.id)
        } />);
  });
};

const EmptyList = () => {
  return (
    <div style={emptyListStyle.container}>
      <p style={emptyListStyle.label}>
        La ODE no tiene programas anexos
      </p>
    </div>
  );
};

export default ({ odeData, odeActivities }) => {
  return (
    <Fragment>
      <p style={{ fontSize: "15px", marginTop: "20px" }}> Programas anexos </p>
      <div style={{
        maxHeight: "400px",
        overflow: "auto",
        marginBottom: "50pt",
        paddingBottom: "10pt" }}>
        {odeData.batches.length < 1 ?
          <EmptyList />
          : getList(odeData.batches, odeActivities) }
      </div>
    </Fragment>
  );
};
