import React from "react";
import { Container, Feed, Icon, Image } from "semantic-ui-react";
import { PARSE_DATE } from "../../../../helpers";
import { DetailsContainer } from "../../../organisms";

export default props => {
  const { timeline } = props;

  const handleMessage = (type, ode, user, action) => {
    const message = {
      ODE_CREATED: `${ode} ${action}`,
      LEFT_ODE: `${user} ${action}`,
      MEMBER_ADDED: `${action}`,
      MEMBER_DELETED: `${action}`,
    };
    return message[type];
  };

  const Message = props => (
    <span>
      {handleMessage(props.type, props.ode, props.user, props.action)}
    </span>
  );

  return (
    <div>
      <DetailsContainer>
        <Container>
          <Feed style={{ margin: "30px", borderLeft: "2px solid #000" }}>
            {timeline.map((item, index) => {
              const options = {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              };
              const date = PARSE_DATE(
                new Date(parseInt(item.timestamp)).toISOString()
              );
              return (
                <Feed.Event key={index}>
                  <Feed.Label style={{ marginLeft: "-18px" }}>
                    <Icon name="circle" color="black" />
                  </Feed.Label>
                  <Feed.Label>
                    <Image
                      src="https://s3.us-east-2.amazonaws.com/jaxitank-dev/image-placeholder-350x350.png"
                      circular
                      size="mini"
                    />
                  </Feed.Label>
                  <Feed.Content>
                    <Feed.Summary>
                      <Message
                        type={item.type}
                        ode={item.ode}
                        user={item.user}
                        action={item.action}
                      />
                    </Feed.Summary>
                    <Feed.Date style={{ padding: "8px" }}>
                      <p>{date}</p>
                    </Feed.Date>
                  </Feed.Content>
                </Feed.Event>
              );
            })}
          </Feed>
        </Container>
      </DetailsContainer>
    </div>
  );
};
