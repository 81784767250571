import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Grid,
  Icon,
  Input,
  Modal,
  TextArea,
  Tab,
  Confirm,
  Dropdown,
  Loader,
} from "semantic-ui-react";
import gql from "graphql-tag";
import { client } from "../../../graphql";
import { DeleteButton, UserDropdown } from "../../atoms";
import { FormValidator, CLEAN_TEXT } from "../../../helpers";
import validations from "./validations";
import {
  EDIT_PROGRAM,
  DELETE_PROGRAM,
} from "../../../graphql/mutations/programs";
import * as _ from "lodash";

const mapTypes = type => {
  const TYPE = {
    ACCELERATOR: "Aceleradora",
    COWORKING: "Co Working",
    INVESTMENT_FUND: "Fondo de inversión",
    INCUBATOR: "Incubadora",
  };
  return TYPE[type];
};

const typeOptions = [
  { key: "COWORKING", text: "Co Working", value: "COWORKING" },
  { key: "ACCELERATOR", text: "Aceleradora", value: "ACCELERATOR" },
  {
    key: "INVESTMENT_FUND",
    text: "Fondo de inversión",
    value: "INVESTMENT_FUND",
  },
  { key: "INCUBATOR", text: "Incubadora", value: "INCUBATOR" },
];

export default props => {
  // Hooks
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({
    name: false,
    type: false,
    coordinator: false,
    description: false,
  });
  const [openConfirm, handleConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const programData = async () => {
      const query = await client.query({
        variables: {
          id: props.programData.id,
        },
        query: gql`
          query($id: ID!) {
            program(where: { id: $id }) {
              id
              name
              description
              programID
              type
              coordinator {
                id
              }
              batches {
                id
                name
                description
                startDate
                finishDate
                coordinators: users(where: { systemRole: COORDINATOR }) {
                  id
                  fullName
                }
              }
            }
          }
        `,
        fetchPolicy: "no-cache",
      });

      const { batches, ...programData } = query.data.program;

      const coordinator = programData.coordinator.id;

      setData({ coordinator, ...programData });
      setIsLoading(() => false);
    };
    programData();
    //setData(programData);
  }, []);

  const handleChange = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
  };

  const handleChangeType = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
  };

  const validateFrom = programData => {
    const validator = new FormValidator(validations);
    const validation = validator.validate(programData);
    return validation;
  };

  const editProgram = async () => {
    const validation = validateFrom(data);
    if (validation.isValid) {
      Object.keys(errors).map(error => {
        errors[error] = validation[error]["isInvalid"];
      });
      setErrors({ ...errors });
      data["description"] = CLEAN_TEXT.STRING_TO_UNICODE(
        CLEAN_TEXT.UNICODE_TO_STRING(data.description)
      );
      await EDIT_PROGRAM(data);
      props.refetch();
      props.close({ active: false });
    } else {
      Object.keys(errors).map(error => {
        errors[error] = validation[error]["isInvalid"];
      });
      setErrors({ ...errors });
    }
  };

  const deleteProgram = async () => {
    const programData = props.programData;
    const id = programData.id;
    await DELETE_PROGRAM(id);
    handleConfirm(() => false);
    props.refetch();
    props.close({ active: false });
  };

  const close = () => {
    Object.keys(errors).map(error => {
      errors[error] = false;
    });
    setData({});
    setErrors({ ...errors });
    props.close({ active: false });
  };

  const panes = [
    {
      menuItem: "General",
      render: () => {
        return !isLoading ? (
          <Form className="Modal__Form">
            <Form.Group widths="2">
              <Form.Field
                width={11}
                control={Input}
                label="Nombre del programa"
                type="text"
                name="name"
                defaultValue={data["name"]}
                onChange={handleChange}
                required
                onChange={handleChange}
                error={errors.name}
              />
              <Form.Field
                id="program-id"
                width={5}
                control={Input}
                defaultValue={data["programID"]}
                name="programID"
                label="ID"
                type="text"
                required
                onChange={handleChange}
                readOnly
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                control={UserDropdown}
                systemRole={["COORDINATOR", "SUPER_ADMIN"]}
                name="coordinator"
                label="Coordinador"
                onChange={handleChange}
                defaultValue={data.coordinator ? data.coordinator.id : ""}
                type="text"
                selection
                required
                error={errors.coordinator}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                name="type"
                label="Tipo de programa"
                control={Dropdown}
                options={typeOptions}
                onChange={handleChangeType}
                value={data.type}
                selection
                required
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                control={TextArea}
                name="description"
                label="Descripción"
                value={
                  data.description &&
                  CLEAN_TEXT.UNICODE_TO_STRING(data.description)
                }
                onChange={handleChange}
                error={errors.description}
                required
              />
            </Form.Group>
            <Form.Group widths="equal">
              <DeleteButton del={() => handleConfirm(() => true)} />
              <Button
                style={{ width: "150px", marginLeft: "213px" }}
                onClick={() => editProgram()}
                fluid
                primary
              >
                Guardar
              </Button>
            </Form.Group>
          </Form>
        ) : (
          <Loader />
        );
      },
    },
  ];

  return (
    <Modal
      className="Modal__Form-SubModal"
      centered={false}
      open={props.openModal}
      onClose={props.close}
      size="tiny"
      dimmer="blurring"
      autoComplete="new-password"
    >
      <Modal.Header as="h1">
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} floated="left">
              Editar programa
            </Grid.Column>
            <Grid.Column width={2} floated="right">
              <Icon name="close" onClick={close} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <Tab
          className="Tab__Menu"
          menu={{ secondary: true, pointing: true }}
          panes={panes}
        />
      </Modal.Content>
      <Confirm
        open={openConfirm}
        onCancel={() => handleConfirm(() => false)}
        onConfirm={() => deleteProgram()}
        header="Eliminar programa"
        content={"Esta acción no es reversible."}
      />
    </Modal>
  );
};
