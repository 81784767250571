import React, {useState} from "react";
import { EntityModal } from "../../molecules";
import { FormValidator } from "../../../helpers";
import validations from './validations';
import { default as CreateExpertForm } from "./form";
import { CREATE_EXPERT_SERVICE } from "../../../graphql/mutations/expertServices.js";

export default ({expert, open, onClose, onCreate}) => {
  const [data, setData] = useState({
    id: undefined,
    name: "",
    description: "",
    type: "",
    duration: "",
    price: ""
  });

  const [errors, setErrors] = useState({
    name: false,
    description: false,
    type: false,
    duration: false,
    price: false
  });

  const handleChange = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
  };

  const validateForm = data => {
    const validator = new FormValidator(validations);
    const validation = validator.validate(data);

    if (!validation.isValid) {
      Object.keys(errors).map(error => {
        errors[error] = validation[error]["isInvalid"];
      });
      setErrors({ ...errors });
    }
    return validation;
  };

  const createExpertService = async () => {
    const validation = validateForm(data);

    if (validation.isValid) {
      try {
        await CREATE_EXPERT_SERVICE(expert.id, data);
        resetData();
        onCreate();
        onClose();
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  const resetData = () => {
    setData({
      id: undefined,
      name: "",
      description: "",
      type: "",
      duration: "",
      price: ""
    });
    setErrors({
      name: false,
      description: false,
      type: false,
      duration: false,
      price: false
    });
  }

  return (
    <EntityModal
      title="Nuevo servicio"
      confirmLabel="Guardar"
      open={open}
      onClose={() => { resetData(); onClose(); }}
      onConfirm={createExpertService}>
      <CreateExpertForm
        handleChange={handleChange}
        data={data}
        errors={errors}
        className="Modal__Form" /> 
    </EntityModal>
  );
}
