import { gql } from "apollo-boost";
import { client } from "../../index";

export default async data => {
  await client.mutate({
    mutation: gql`
      mutation UpdateUser(
          $id: ID!,
          $data: UserUpdateInput!
        ){
        updateUser(where: {
          id: $id
        }, data: $data) {
          id
        }
      }
    `,
    variables: data
  });
};
