import React from "react";
import { Query } from "react-apollo";
import { Dropdown } from "semantic-ui-react";
import { PROJECTS } from "../../../graphql/queries/projects";

export default props => {
  let options = [];
  return (
    <Query query={PROJECTS} fetchPolicy="network-only">
      {({ loading, error, data }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        data.oDEs.map(({ id, name }, index) => {
          options.push({
            value: { id: id, name: name },
            text: name,
            key: index,
          });
        });

        return (
          <Dropdown
            selection
            fluid
            search
            options={options}
            name={props.name}
            error={props.error}
            onChange={props.onChange}
            icon="search"
          />
        );
      }}
    </Query>
  );
};
