
export const filterOptions = [
  { key: "all", text: "Todas las actividades", value: "all" },
  {
    key: "ode_activity",
    text: "Actividades de la ODE",
    value: "ode_activity"
  },
  {
    key: "program_activity",
    text: "Actividades de programas",
    value: "program_activity"
  },
  {
    key: "completed",
    text: "Actividades completadas",
    value: "completed"
  },
  {
    key: "uncompleted",
    text: "Actividades no completadas",
    value: "uncompleted"
  },
  {
    key: "ontime",
    text: "Actividades en tiempo",
    value: "ontime"
  },
  {
    key: "delayed",
    text: "Actividades retrasadas",
    value: "delayed"
  }
];

export const getActivityDetailModalData = (odeActivity = {}) => {
  return {
    odeActivityId: odeActivity.id,
    activityId: odeActivity.activity.id,
    name: odeActivity.activity.name,
    description: odeActivity.activity.description,
    activityType: odeActivity.activity.activityType,
    files: odeActivity.activity.files,
    hasFiles: odeActivity.activity.hasFiles,
    type: odeActivity.activity.activityType,
    responsibles: odeActivity.responsibles,
    isComplete: odeActivity.isComplete,
    deliverableFiles: odeActivity.files,
    hasObjectives: (odeActivity.objective),
    finishDate: odeActivity.finishDate
  };
};
