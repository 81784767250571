import React, { useState, useContext } from "react";
import { Button, Container, Grid, Confirm, Search } from "semantic-ui-react";
import { CreateUser, Dashboard } from "../../organisms";
import { default as DataTable } from "../../organisms/dataTable";
import { GET_USER_BY_ID } from "../../../graphql/queries/users";
import SessionContext from "../../../context/userSessionContext";
import { QueryParams } from "./helpers";
import { getColumnsDef } from "./helpers/columnsDef";
import { useQuery } from "react-apollo-hooks";
import {
  USERS_FILTERED_BY_ROLE,
  COORDINATOR_USERS
} from "../../../graphql/queries/users";
import { DELETE_USER } from "../../../graphql/mutations/users";
import { withPagination } from "../../HOCs";
import { PaginationBar } from "../../molecules";
import { remove as removeAccents } from "remove-accents";

const UsersView = props => {
  const [skipResults, setSkipResults] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userData, setUserData] = useState({
    fullName: "",
    userID: "",
    gender: "",
    area: "",
    phone: "",
    status: "",
    graduationDate: "",
    school: "",
    career: "",
    enrollment: "",
    category: "",
    email: "",
    systemRole: ""
  });

  const [filters, setFilters] = useState({
    search: "",
    searchString: "",
    currentPage: 1
  });

  const onSearchChange = (e, { value }) => {
    setFilters({
      search: removeAccents(value.toLowerCase()),
      searchString: value,
      currentPage: 1
    });
    setSkipResults(0);
  };

  const breadcrumbPath = [{ label: "Emprendedores", href: "./emprendedores" }];

  const handleChange = (e, { name, value }) => {
    const editingUserData = userData;
    editingUserData[name] = value;
    setUserData(editingUserData);
  };

  const deleteUserById = async () => {
    try {
      await DELETE_USER(selectedUserId);
      refetch();
      closeDeleteModal();
    } catch (error) {
      throw new Error(error);
    }
  };

  const openModal = () => {
    setShowModal(true);
  };

  const openEditModal = ({ data }) => {
    setUserData(data);
    setIsEdit(true);
    setShowModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const onClose = () => {
    setShowModal(false);
    setIsEdit(false);
    setUserData({
      fullName: "",
      userID: "",
      gender: "",
      area: "",
      phone: "",
      status: "",
      graduationDate: "",
      school: "",
      career: "",
      enrollment: "",
      category: "",
      email: "",
      systemRole: ""
    });
  };

  const onDelete = id => {
    setSelectedUserId(id);
    setShowDeleteModal(true);
  };

  const onRowEvent = ({ type, args }) => {
    switch (type) {
      case "onEdit":
        openEditModal(args);
        break;
      case "onDelete":
        onDelete(args.id);
        break;
      default:
        break;
    }
  };

  const onRowClick = args => {
    if (args.id) {
      // The origin param is used in by the breadcrumb component
      // to determine where to go when the goback button is clicked
      window.location = `/admin/user-detail/${args.id}?origin=entrepreneurs`;
    }
  };

  const renderDataTable = ({ isLoading, hasErrors, dataSource, userRole }) => {
    return (
      <DataTable
        onRowClick={onRowClick}
        selectedUserId={selectedUserId}
        isLoading={isLoading}
        hasErrors={hasErrors}
        dataSource={dataSource}
        columnsDef={
          getColumnsDef(userRole === "SUPER_ADMIN" || role === "COORDINATOR")
        }
        onRowEvent={onRowEvent} />
    );
  };

  const onPrev = () => {
    const skip = props.onPrev();
    if(filters.currentPage > 1) {
      setFilters({ ...filters, currentPage: filters.currentPage - 1 });
    }
    if (skip !== undefined) setSkipResults(skip);
  };

  const onNext = () => {
    const { users, usersConnection } = data;
    if(filters.currentPage < Math.ceil(usersConnection.aggregate.count / 10)) {
      setFilters({ ...filters, currentPage: filters.currentPage + 1 });
    }
    const skip = props.onNext(users.length, usersConnection.aggregate.count);
    if (skip !== undefined) setSkipResults(skip);
  };

  const renderPaginationBar = data => {
    return (
      <PaginationBar
        data={data || []}
        label={"Emprendedores" || ""}
        propertyKey={"users"}
        onPrev={onPrev}
        onNext={onNext}
        current={filters.currentPage} />
    );
  };

  const getQuery = role => {
    switch (role) {
      case "SUPER_ADMIN":
        return USERS_FILTERED_BY_ROLE;
      case "COORDINATOR":
        return COORDINATOR_USERS;
      case "ENTREPRENEUR":
        return GET_USER_BY_ID;
      default:
        return GET_USER_BY_ID;
    }
  };

  /* TODO: Refactor rigid implementation */
  const getQueryVariables = (id, role) => {
    let queryVariables = { variables: { id: id || "" } };

    if(role === "SUPER_ADMIN")
      queryVariables = QueryParams
        .getEntrepreneurs(skipResults);

    if(role === "COORDINATOR")
      queryVariables = QueryParams
        .getCoordinatorEntrepreneurs(id, skipResults);

    queryVariables.variables.searchEnabled = true;
    queryVariables.variables.search = filters.search;

    return queryVariables;
  };

  const { id, role } = useContext(SessionContext);
  const { data, loading, error, refetch } = useQuery(
    getQuery(role),
    getQueryVariables(id, role)
  );

  return (
       (role !== "ADVISER") ?
         <Dashboard
           breadcrumbPath={breadcrumbPath}
           active="users">
           <Container className="Body__Container">
             <Grid>
               <Grid.Row>
                 <Grid.Column width={7} floated="left">
                   <Search
                     placeholder="Buscar"
                     value={filters.searchString}
                     onSearchChange={onSearchChange}
                     showNoResults={false}
                     input={{ icon: "search", iconPosition: "left" }} />
                 </Grid.Column>
                 <Grid.Column
                   width={3}
                   floated="right"
                   textAlign="right">
                   {role === "SUPER_ADMIN" || role === "COORDINATOR" ? (
                     <Button onClick={openModal} primary>
                      Agregar usuario
                     </Button>
                  ) : null}
                 </Grid.Column>
               </Grid.Row>
             </Grid>
             {/* List */}
             <div
               style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "300px",
                  overflow: "display"
                }}>
               <div style={{ minHeight: "550px" }}>
                 {renderDataTable({
                    isLoading: loading,
                    hasErrors: error,
                    userRole: role,
                    dataSource: data ? data.users : []
                  })}
                 {/* PaginationBar */}
                 {renderPaginationBar(data)}
               </div>
             </div>
           </Container>
           {/* Create user modal */}
           <CreateUser
             userId={selectedUserId}
             openModal={showModal}
             onClose={onClose}
             close={onClose}
             handleChange={handleChange}
             defaultSystemRole="ENTREPRENEUR"
             data={userData}
             update={isEdit} />
           <Confirm
             open={showDeleteModal}
             onCancel={closeDeleteModal}
             onConfirm={deleteUserById}
             content="Esta acción no es reversible" />
         </Dashboard>
        : (window.location = "/admin/ode"));
};

export default withPagination(UsersView, { paginationSize: 10 });
