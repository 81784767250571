import React from 'react';
import { Image } from 'semantic-ui-react';
import Identicon from "react-identicons";

export default ({user, size}) => {
  size = size ? size : 35;
  const { avatarUri, id } = user;

  return (
    avatarUri && avatarUri !== null && avatarUri !== "" ?
      <Image width={35} src={avatarUri} centered circular /> :
      <Identicon size={35} string={id} />
  );
}