import { gql } from "apollo-boost";
import { client } from "../../index";

export default async data => {
  return await client.mutate({
    mutation: gql`
      mutation CreateInvitedUser($data: InvitedUserCreateInput!){
        createInvitedUser(data: $data) {
          email
        }
      }
    `,
    variables: data
  });
};
