import React from "react";
import {
  Grid,
  Label,
} from "semantic-ui-react";
import { DeleteButton } from "../../../atoms";

export default ( { odesArray, deleteOdeFromBatch } ) => {
  return odesArray.map(({ name, id }, index) => (
    <Grid.Row key={index} columns={2}>
      <Grid.Column floated="left" textAlign="left">
        <Label circular color="yellow">
          {name.substring(0, 1)}
        </Label>
        <span>{name}</span>
      </Grid.Column>
      <Grid.Column floated="right" textAlign="right">
        <div style={{ float: "right", marginTop: "-8px" }}>
          <DeleteButton del={() => deleteOdeFromBatch(id)} />
        </div>
      </Grid.Column>
    </Grid.Row>
  ));
}
