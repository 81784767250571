import { gql } from "apollo-boost";

export default gql`
  query($expertId: ID) {
    assignedExpertServices(where: { expert: { id: $expertId } }) {
      id,
      expert {
        id
        fullName
      }
      expertService {
        id
        name
        price
        type
        link
      }
      odes{
        id
        name
      }
      startDate
      endDate
      status
      finished
      duration
    }
  }
`;
