import React from "react";
import {
  Container,
  Segment
} from "semantic-ui-react";
import "./styles.css";
import MenuIcon from "mdi-react/MenuIcon";
import { SessionDropdown } from "..";
import SessionContext from "../../../context/userSessionContext";
import { BackButtonNavigation } from "../../atoms";
import { Breadcrumb } from "../../molecules";

const Topbar = props => {
  const showMenu = () => {
    const width = window.innerWidth;
    switch (true) {
      case width <= 1279:
        return true;
      case width <= 1440:
        return false;
      case width > 1440:
        return false;
      default:
        return false;
    }
  };

  return (
    <Container className="Topbar">
      <Segment clearing basic>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}>
            { showMenu() ?
              <MenuIcon onClick={() =>
                props.setShowSidebar(prevState => !prevState)} />
              : null }
            <BackButtonNavigation pathObject={props.breadcrumbPath} />
            <div>
              <Breadcrumb pathObject={props.breadcrumbPath} />
            </div>
          </div>
          <div>
            <SessionContext.Consumer>
              {ctx => {
                if (!ctx.id)
                  return;
                return <SessionDropdown userId={ctx.id} />;
              }}
            </SessionContext.Consumer>
          </div>
        </div>
      </Segment>
    </Container>
  );
};

export default Topbar;
