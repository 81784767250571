import React from "react";
import { Grid } from "semantic-ui-react";

export default ({label, children}) => {
  return (
    <Grid.Row columns="equal" className="detail-grid-info-row">
      <Grid.Column width={3}>
        <span className="label">{ label }</span>
      </Grid.Column>
      <Grid.Column>
        { children }
      </Grid.Column>
    </Grid.Row>
  );
};
