import React from "react";
import { Dropdown } from "semantic-ui-react";

const typeOptions = [
  { key: "COWORKING", text: "Co Working", value: "COWORKING" },
  { key: "ACCELERATOR", text: "Aceleradora", value: "ACCELERATOR" },
  {
    key: "INVESTMENT_FUND",
    text: "Fondo de inversión",
    value: "INVESTMENT_FUND",
  },
  { key: "INCUBATOR", text: "Incubadora", value: "INCUBATOR" },
];

export default props => {
  return (
    <Dropdown
      selection
      options={typeOptions}
      name={props.name}
      value={props.value}
      error={props.error}
      onChange={props.onChange}
    />
  );
};