import React from "react";
import { Segment, Button, Message } from "semantic-ui-react";
import General from "./general";
import Academic from "./academic";
import Account from "./account";
import { Form } from "../../atoms";
import { Query } from "react-apollo";
import { INVITATION } from "../../../graphql/queries/invitations";
import { get } from "lodash";
import { CONSTANTS } from "../../../helpers";
import { CREATE_INVITED_USER } from "../../../graphql/mutations/invitations";

export default ({invitationId}) => {
  const onSubmit = async values => {
    values.timeline = {
      create: [CONSTANTS.TIMELINE_ACTION(values.fullName).USER_CREATED]
    };
    values.invitationId = invitationId;

    delete values.passwordConfirmation;

    /* placeholder because mutation requires email to process the request
    /* the email is set from the invitation info it SHOULD NOT be
    /* sent by the client */
    values.email = "email@example.com";

    await CREATE_INVITED_USER({data: values});

    setTimeout(() => {
      window.location = "/";
    }, 1000);
  };

  return (
    <Segment>
      <Query query={INVITATION} variables={{id: invitationId}}>
        {({loading, error, data}) => {
          return (
            <>
              { loading ?
                <Message info>Cargando datos de tu invitación...</Message>
              : null }
              { (error || data.invitation === null) ?
                <Message error>
                  Ha ocurrido un error al cargar tu invitación.
                  Por favor intentalo de nuevo más tarde.
                </Message>
              : null }
              { get(data, ["invitation", "redeemed"]) === true && !loading ?
                <Message error>La invitación ya ha sido utilizada.</Message>
              : null }
              { get(data, ["invitation", "redeemed"]) === false && !loading ?
                <Form
                  onSubmit={onSubmit}
                  defaultValues={{email: get(data, ["invitation", "email"])}}>
                  { General() }
                  { Academic() }
                  { Account(data) }
                  { (!loading && !error && data.invitation) ?
                    <Button type="submit">Crear cuenta</Button>
                  : null }
                </Form>
              : null }
            </>
          );
        }}
      </Query>
    </Segment>
  );
};
