import React, { useState } from "react";
import {
  Button,
  Form,
  Grid,
  Icon,
  Input,
  Modal,
  TextArea,
  Dropdown,
} from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { CONNECT_ACTIVITY, CREATE_NEW_ODE_ACTIVITIES } from "../../../graphql/mutations/objectives";
import { GET_OBJECTIVE_BATCH_ODES } from "../../../graphql/queries/odes"
import { CREATE_ODE_ACTIVITY } from "../../../graphql/mutations/odes";
import { FileAttachment, AttachmentsBox } from "../../molecules";
import { ResponsibleConditonalDropdown } from "../../atoms";
import { FormValidator, CLEAN_TEXT } from "../../../helpers";
import validations from "./validations";
import lodash from "lodash";
import moment from "moment";

const activityOptions = [
  { key: "ADVISORY", text: "Asesoría", value: "ADVISORY" },
  { key: "COURSE", text: "Cursos", value: "COURSE" },
  { key: "TALK", text: "Charlas", value: "TALK" },
  { key: "WORKSHOP", text: "Talleres", value: "WORKSHOP" },
  { key: "ROUND_TABLE", text: "Mesa redonda", value: "ROUND_TABLE" },
  { key: "KNOWLEDGE", text: "Conocimiento", value: "KNOWLEDGE" },
  { key: "DELIVER", text: "Entrega", value: "DELIVER" },
];

export default props => {
  const objectiveId = props.objectiveId;
  const batchId = props.batchId;

  // Hooks
  const [data, setData] = useState({
    name: "",
    description: "",
    responsibles: [],
  });

  const [errors, setErrors] = useState({
    name: false,
    description: false,
    activityType: false,
  });

  const [file, setFile] = useState([]);
  const [isUploading, setUploadState] = useState(false);
  const [fileShown, setFileShown] = useState(false);

  const handleChange = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
  };

  const createActivity = async () => {
    const validation = validateFrom(data);
    if (validation.isValid) {
      data["id"] = objectiveId;
      data.files = file.map(f => ({ url: f.url, type: f.type }));
      data["description"] = CLEAN_TEXT.ESCAPE(data.description);
      data["batchId"] = batchId;
      data["objectiveId"] = objectiveId;
      try {
        const newActivity = await CONNECT_ACTIVITY(data);
        const newActivityId = lodash.get(newActivity, "data.createActivity.id", "");

        let newOdeActivitiesToCreate = await getNewOdeActivities(objectiveId);
        await CREATE_NEW_ODE_ACTIVITIES(objectiveId, newActivityId, newOdeActivitiesToCreate);

        props.refetch();
        props.close();
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  const createODEActivity = async () => {
    const validation = validateFrom(data);
    if (validation.isValid) {
      data["id"] = objectiveId; // Should send this ID or just use the ID in from the URL??
      data.files = file.map(f => ({ url: f.url, type: f.type }));
      data["description"] = CLEAN_TEXT.STRING_TO_UNICODE(data.description);
      try {
        let formatedData = prepareDataToSave(data);
        await CREATE_ODE_ACTIVITY(formatedData);
        props.refetch();
        props.close();
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  const close = () => {
    props.close();
  };

  /**
   * @param {*} link { id: uuid, url: string, type: string, isNew: true }
   */
  const handleOnAttachLink = (link = {}) => {
    file.push(link);
    setFile(file);
    setFileShown(() => true);
    setUploadState(() => false);
  };

  /**
   * @param {*} attachment { id: uuid, url: string, type: string, isNew: true }
   */
  const handleAfterUploadAttachent = (attachment = {}) => {
    file.push(attachment);
    setFile(file);
    setFileShown(() => true);
    setUploadState(() => false);
  };

  /**
   * Returns an object formated properly to pass it to the
   * @param {*} sourceData The origin data collected from the
   * form
   */
  const prepareDataToSave  = (sourceData) => {
    let data = {};
    let files = {};
    let responsibles = {};

    if(sourceData.responsibles) {
      // The needed format for this variable is:
      // responsibles: { connect: [ { id: "" }, { id: "" } ] }
      responsibles.connect = sourceData.responsibles;
    }

    if(sourceData.files) {
      // The needed format for this variable is:
      // files: { create: [ { url: "", type: "" } ] }
      files.create = sourceData.files;
    }

    data.odeId = props.odeId; // This prop is passed in the ODE activities list
    data.name = sourceData.name;
    data.description = sourceData.description;
    data.activityType = sourceData.activityType;
    data.files = files;
    data.hasFiles = (sourceData.files.length > 0) ? true : false;
    data.responsibles = responsibles;
    
    if(sourceData.finishDate) {
      data.finishDate = moment(
        sourceData.finishDate,
        "DD/MM/YYYY"
      ).format()
    }
    
    return data;
  }

  const getNewOdeActivities = async (objectiveId) => {
    const { data } = await GET_OBJECTIVE_BATCH_ODES(objectiveId);
    const batchObjectives = data.batchObjectives || [];
    
    return batchObjectives.reduce((newActivities, batchObjective) => {
      batchObjective.batch.odes.forEach( ode => {
        const newOdeActivity = {
          ode: { connect: { id: ode.id } },
          batch: { connect: { id: batchObjective.batch.id } },
          objective: { connect: { id: batchObjective.id } }
        }

        newActivities.push(newOdeActivity);
      });
      return newActivities;
    }, []);
  };

  /**
   * @param {*} attachment { id: uuid }
   */
  const handleOnRemoveAttachment = (attachment = {}) => {
    const f = file.filter(f => f.id !== attachment.id);
    setFile(f);
  };

  const validateFrom = data => {
    const validator = new FormValidator(
      props.ode ? validations.odeActivity : validations.objectiveActivity
    );
    const validation = validator.validate(data);
    if (validation.isValid) {
      Object.keys(errors).map(error => {
        errors[error] = validation[error]["isInvalid"];
      });
      setErrors({ ...errors });
    } else {
      Object.keys(errors).map(error => {
        errors[error] = validation[error]["isInvalid"];
      });
      setErrors({ ...errors });
    }
    return validation;
  };

  const handleResponsibles = () => {
    const { team } = props;
    if (team) {
      return team.map(({ id, fullName }, index) => {
        return { key: index, text: fullName, value: id };
      });
    }
  };

  const handleChangeResponsible = (e, { value }) => {
    const d = value.map(item => {
      return { id: item };
    });
    data["responsibles"] = d;
    setData({ ...data });
  };

  return (
    <Modal
      className="Modal__Form-SubModal"
      centered={false}
      open={props.open}
      onClose={props.closeModal}
      size="large"
      autoComplete="new-password"
    >
      <Modal.Header as="h1">
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} floated="left">
              Nueva actividad
            </Grid.Column>
            <Grid.Column width={2} floated="right">
              <Icon name="close" onClick={close} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <Form className="Modal__Form">
          <Form.Group widths="equal">
            <Form.Field
              width={11}
              control={Input}
              label="Nombre de la actividad"
              type="text"
              name="name"
              onChange={handleChange}
              required
              error={data["name"] ? false : errors.name}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              name="activityType"
              label="Tipo de actividad"
              control={Dropdown}
              options={activityOptions}
              onChange={handleChange}
              defaultValue={data.activityType}
              selection
              required
              error={data["activityType"] ? false : errors.activityType}
            />
          </Form.Group>
          { props.showDates &&
            (<Form.Group>
                <Form.Field
                    name="finishDate"
                    control={DateInput}
                    format="DD/MM/YYYY"
                    value={data["finishDate"]}
                    width="16"
                    placeholder="Seleccionar fecha de entrega"
                    autoComplete="off"
                    iconPosition="right"
                    onChange={handleChange}
                    label="Fecha de entrega"
                    style={!errors.finishDate ? null : { backgroundColor: "red" }}
                  />
              </Form.Group>) }
          <ResponsibleConditonalDropdown
            ode={props.ode}
            options={handleResponsibles()}
            error={
              data["responsibles"].length > 0 ? false : errors.responsibles
            }
            onChange={handleChangeResponsible}
          />
          <Form.Group widths="equal">
            <Form.Field
              control={TextArea}
              name="description"
              label="Descripción"
              onChange={handleChange}
              error={data["description"] ? false : errors.activityType}
              required
            />
          </Form.Group>
          <Form.Group widths="equal">
            <div style={{ width: "100%" }}>
              <h3>Recursos</h3>
              <p>Agrega materiales para complementar la actividad.</p>
              <AttachmentsBox
                editable
                onAttachLink={handleOnAttachLink}
                afterUpload={handleAfterUploadAttachent}
                onRemoveAttachment={handleOnRemoveAttachment} />
            </div>
          </Form.Group>
          { isUploading ? <FileAttachment loading /> : null }
          <Form.Group
            widths="equal"
            style={{ color: "#999999", fontSize: "11px", textAlign: "center" }}
          >
            Esta es una actividad personalizada, solo estara disponible en este
            programa, si desea un cambio permanente agregue esta actividad en el
            modulo, objetivos
          </Form.Group>
          <div style={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "17px",
              width: "100%"
            }}>
            <Button
              style={{ width: "130px" }}
              onClick={props.ode ? createODEActivity : createActivity}
              fluid
              primary>
              Guardar
            </Button>
          </div>
        </Form>
      </Modal.Content>
    </Modal>
  );
};
