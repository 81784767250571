import React, { useState } from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import {
  Dimmer,
  Loader,
  Segment,
  Grid
} from "semantic-ui-react";
import { CreateAdmin, CreateUser, DetailsContainer} from "../../../organisms";
import { GeneralInformation, EntrepreneurInfo, UserHeader } from "./infoTab";
import { Redirect } from "react-router";

const id = window.location.pathname.split("l/")[1];

export default props => {
  const [editModal, openEditModal] = useState({ active: false });
  const [_data, setData] = useState({});

  const handleChange = (e, { name, value }) => {
    _data[name] = value;
    setData(_data);
  };

  return (
    <Query
      query={gql`
          {
            user(where:{
                id:"${id}"
                }
                ){
                    id
                    fullName
                    profilePicUrl
                    email
                    userID
                    gender
                    phone
                    area
                    school
                    enrollment
                    career
                    status
                    graduationDate
                    category
                    profileTec
                    systemRole
                    odes {
                        id
                        ode {
                        name
                        }
                        rol
                    }
                    timeline {
                        action
                        type
                        user
                        ode
                        timestamp
                    }
                }
          }
        `}
      fetchPolicy="network-only">
      {({ loading, error, data }) => {
        if (loading) {
          return (
            <Segment className="Ode__Detail__ContainerTabs">
              <Dimmer>
                <Loader />
              </Dimmer>
            </Segment>
          );
        }
        if (error) {
          return <p>Error...</p>;
        }

        if (data.user === null) {
          return <Redirect to="/404" />;
        }

        const canEdit = props.loggedUser.role !== "ENTREPRENEUR" ?
                          true
                          : props.loggedUser.id === id ? true : false;

        const canDelete = props.loggedUser.role !== "ENTREPRENEUR";

        return (
          <div>
            <DetailsContainer>
              <DetailsContainer.Content width={16}>
                <div className="details-info-tab">
                  <Grid.Row>
                    <UserHeader
                      setData={setData}
                      userData={data}
                      canEdit={canEdit}
                      openEditModal={openEditModal}
                      editModal={editModal} />
                  </Grid.Row>
                  <GeneralInformation data={data} />
                  {data.user.systemRole === "ENTREPRENEUR" ? (
                    <EntrepreneurInfo data={data} />
                  ) : null}
                </div>
              </DetailsContainer.Content>
            </DetailsContainer>
            {editModal.active ? (
              data.user.systemRole !== "ENTREPRENEUR" ? (
                <CreateAdmin
                  userID={id}
                  open={editModal.active}
                  close={() => {
                    openEditModal({ active: false });
                  }}
                  data={_data}
                  handleChange={handleChange}
                  update />
              ) : (
                <CreateUser
                  userID={id}
                  openModal={editModal.active}
                  close={() => {
                    openEditModal({ active: false });
                  }}
                  data={_data}
                  handleChange={handleChange}
                  update
                  canDelete={ canDelete } />
              )
            ) : null}
          </div>
        );
      }}
    </Query>
  );
};
