export default {
  ESCAPE: text => {
    return text.replace(/\n/g, "\\n");
  },

  UNESCAPE: text => {
    return text.replace("\\n", /\n/g);
  },
  STRING_TO_UNICODE: string => {
    let unicodeString = "";
    for (let i = 0; i < string.length; i++) {
      let theUnicode = string
        .charCodeAt(i)
        .toString(16)
        .toUpperCase();
      while (theUnicode.length < 4) {
        theUnicode = "0" + theUnicode;
      }
      theUnicode = "\\u" + theUnicode;
      unicodeString += theUnicode;
    }
    return unicodeString;
  },
  UNICODE_TO_STRING: string => {
    return string.replace(/\\u[\dA-F]{4}/gi, match => {
      return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
    });
  },
  replaceNewline: (input = "") => {
    return input.replace(/\\n/g, String.fromCharCode(13, 10));
  }
};
