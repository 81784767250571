import gql from "graphql-tag";

export default gql`
   query(
  $programId: ID,
  $institutionId: ID,
  $organismId: ID
) {
    programs (
      where: {
        id: $programId,
        organism: {
          id: $organismId
          institution: {
            id: $institutionId
          }
        }
      }
    ) {
      id
      name
      batches {
        odes {
          id
          name
        }
      }
    }
    oDEsConnection {
      aggregate {
        count
      }
    }
  }
`;
