import React, { useState } from "react";
import { Modal, Form, Input, Dropdown, Button } from "semantic-ui-react";
import gql from "graphql-tag";
import _ from "lodash";
import { client } from "../../../graphql";
import { ODES } from "../../../graphql/queries/odes";
import { Query } from "react-apollo";
import { CONNECT_ODE } from "../../../graphql/mutations/users";
import {
  CONNECT_USER,
  CONNECT_NETWORK_USER
} from "../../../graphql/mutations/odes";
import { FormValidator } from "../../../helpers";
import validations from "./validations";

const ODEs = props => {
  const { onChange } = props;

  return (
    <Query query={ODES} fetchPolicy="network-only">
      {({ loading, error, data }) => {
        if (loading) {
          return <p>Loading...</p>;
        }
        if (error) {
          return <p>Error...</p>;
        }
        const options = [];
        data.oDEs.map(({ name, id }, index) => {
          options.push({
            text: name,
            key: index,
            value: JSON.stringify({ name: name, id: id })
          });
        });
        return (
          <Dropdown
            search
            selection
            icon="search"
            options={options}
            onChange={onChange} />
        );
      }}
    </Query>
  );
};

export default props => {
  const [data, setData] = useState({
    userName: null,
    id: null,
    odeId: null,
    action: null,
    type: null,
    timestamp: null,
    user: null,
    ode: null
  });

  const [errors, setErrors] = useState({
    odeID: false,
    rol: false
  });

  const validateFrom = data => {
    const validator = new FormValidator(validations);
    const validation = validator.validate(data);
    return validation;
  };

  const handleChange = (e, { value }) => {
    setErrors(prevState => ({ ...prevState, odeID: false }));
    const v = JSON.parse(value);
    data.userID = window.location.pathname.split("l/")[1];
    data.user = v.name;
    data.odeID = v.id;
    data.action = `${v.name} ha sido agregado como miembro.`;
    data.type = "MEMBER_ADDED";
    data.user = v.name;
    data.timestamp = Date.now();
    data.ode = props.odeName;
    setData(data);
  };

  const handleChangeRol = (e, { name, value }) => {
    setErrors(prevState => ({ ...prevState, rol: false }));
    data[name] = value;
    setData({ ...data });
  };

  const addMember = async () => {
    const validation = validateFrom(data);

    if(validation.isValid) {
      const currentMembers = await getCurrentMembers(data.odeID);
      const userExists = _.some(currentMembers, { id: data.userID });
      if (!userExists) {
        const userId = data.userID;
        const odeId = data.odeID;
        const action = data.action;
        const type = data.type;
        const timestamp = data.timestamp;
        const user = data.user;
        const ode = data.ode;
        const rol = data.rol;

        await
          CONNECT_ODE(userId, odeId, action, type, timestamp, user, ode, rol);

        if (_.get(props, "userRole.user.systemRole", "") === "ENTREPRENEUR") {
          await CONNECT_USER(data);
        } else {
          await CONNECT_NETWORK_USER(data);
        }
        props.onClose();
        window.location.reload();
      } else {
        alert("Este usuario ya es miembro de la ODE");
      }
    } else {
      Object.keys(errors).map(error => {
        errors[error] = validation[error].isInvalid;
      });
      setErrors({ ...errors });
    }
  };

  const getCurrentMembers = async id => {
    const query = await client.query({
      query: gql`{
        oDE(where:{id:"${id}"}){
          team{
            id
          }
        }
      }`
    });
    return query.data.oDE.team;
  };

  return (
    <Modal
      size="mini"
      open={props.open}
      onClose={props.onClose}>
      <Modal.Header>Agregar ODE</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field
            label="Buscar ODE"
            name="odeID"
            control={ODEs}
            error={errors.odeID}
            onChange={handleChange} />
          <Form.Field
            name="rol"
            label="Rol"
            control={Input}
            error={errors.rol}
            onChange={handleChangeRol} />
          <Button
            onClick={addMember}
            fluid
            color="teal">
            Agregar
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};
