import gql from "graphql-tag";
import { client } from "../../index";

export default async ({
  name,
  description,
  activityType,
  files,
  objectiveId,
}) => {
  let data = await client.mutate({
    variables: {
      files,
      objectiveId
    },
    mutation: gql`
            mutation($files:[FileCreateInput!], 
              $objectiveId: ID,
              $name: String!,
              $description: String!,
              $activityType: ActivityType!) {
                createActivity (
                  data : {
                    name: $name
                    description: $description
                    activityType: $activityType
                    files: { create: $files }
                    objectives: { connect: { id: $objectiveId } }
                  }
                ){
                  id
                }
            }
        `,
        variables: {
          name,
          description,
          activityType,
          files,
          objectiveId,
        },
  });
  return data;
};
