import React from "react";
import { Query } from "react-apollo";
import { Dropdown } from "semantic-ui-react";
import { EXPERT_SERVICES } from "../../../graphql/queries/expertServices";

export default ({expertId, onChange, name, value, error}) => {
  const mapOptions = expertServices => {
    return expertServices.map(i => {
      return {
        key: i.id,
        value: i.id,
        text: i.name,
        object: i
      };
    });
  };

  return (
    <Query
      query={EXPERT_SERVICES}
      variables={{expertId: expertId}}>
      {({loading, loadError, data}) => {
        if(loading || loadError)
          return <Dropdown
            selection
            options={[]}
            disabled />;

        const options = mapOptions(data.expertServices);

        return <Dropdown
          selection
          options={options}
          name={name}
          value={value}
          error={error}
          onChange={onChange} />
      }}
    </Query>
  );
};
