import gql from 'graphql-tag';

export default gql`
query(
  $first: Int
  $skip: Int
  $systemRole: [SystemRole!],
  $search: String
  ) {
    users(
      first: $first,
      skip: $skip,
      where: { systemRole_in: $systemRole, ifullName_contains: $search }
    ) {
      id
      fullName
      ifullName
      profilePicUrl
      email
      userID
      gender
      phone
      area
      school
      enrollment
      career
      graduationDate
      status
      registrationDate
      profileTec
      category
      systemRole
      odes {
        id
        ode {
          id
          name
        }
        rol
      }
      timeline {
        action
        type
        user
        ode
        timestamp
      }
      batches {
        id
      }
      organisms {
        id
      }
    }
    usersConnection(
      where: { systemRole_in: $systemRole }
    ) {
      aggregate {
        count
      }
    }
  }
`;
