import React from "react";
import { EntityDetailContent, EntityDetailSidebar, EntityDetailTab } from "../../atoms";
import { ExpertDetailInfo, ExpertDetailServices } from "..";
import { SidebarHeader, SidebarEntityList } from "../../molecules";

export default ({expert}) => {
  return (
    <EntityDetailTab>
      <EntityDetailContent>
        <ExpertDetailInfo expert={expert} />
        <ExpertDetailServices expert={expert} />
      </EntityDetailContent>
      <EntityDetailSidebar>
        <SidebarHeader title="Red del experto" />
        <SidebarEntityList />
      </EntityDetailSidebar>
    </EntityDetailTab>
  );
}
