import React from "react";
import { Query } from "react-apollo";
import { Input } from "semantic-ui-react";
import { INSTITUTION_ID } from "../../../graphql/queries/institutions";

export default props => (
  <Query query={INSTITUTION_ID} fetchPolicy="network-only">
    {({ loading, error, data }) => {
      if (loading) return "Loading...";
      if (error) return `Error! ${error.message}`;
      return (
        <Input
          id={props.id}
          name={props.name}
          value={data.instIDs[0].instID}
          onChange={props.onChange}
          required
          readOnly
        />
      );
    }}
  </Query>
);
