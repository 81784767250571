import React from "react";
import { Grid, Button } from "semantic-ui-react";

export const HeaderOptions = ({ canCreateAndEdit, setBoolean }) => {
  return (<Grid
    columns={2}
    style={{
      width: "100%"
    }}
    verticalAlign="middle">
    <Grid.Row style={{ height: "auto", marginTop: "10px" }}>
      <Grid.Column
        floated="left"
        width={10}
        textAlign="left">
        Todos los objetivos
      </Grid.Column>
      <Grid.Column
        floated="right"
        width={4}>
        {canCreateAndEdit &&
          <Button
            secondary
            name="create"
            onClick={() => setBoolean(prevState => !prevState)}
            style={{ cursor: "pointer" }}>
            Nuevo
          </Button>}
      </Grid.Column>
    </Grid.Row>
  </Grid>);
};
