import React from "react";
import { Document, Page } from "react-pdf";
import { Loader } from "semantic-ui-react";

export default ({url, width, height}) => {
  if(!width)
    width = 48;

  return (
    <Document
      file={url}
      loading="...">
      <Page pageNumber={1}
            width={width}
            height={height} />
    </Document>
  );
}