import React from "react";
import { Form, Header, Input } from "semantic-ui-react";
import * as _ from "lodash";

export default data => {
  const passwordValidation = ({ watch }) => {
    return v => v === watch("password");
  };

  return (
    <>
      <Header as="h3">Cuenta</Header>
      <Form.Field
        control={Input}
        label="Correo electrónico"
        type="email"
        value={_.get(data, ["invitation", "email"])}
        readOnly />
      <Form.Field
        control={Input}
        label="Contraseña"
        name="password"
        type="password"
        required />
      <Form.Field
        control={Input}
        label="Confirmar contraseña"
        name="passwordConfirmation"
        type="password"
        required
        validate={passwordValidation} />
    </>
  );
};
