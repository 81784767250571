import React from "react";
import { Row } from "./";

export default ({ data, onClickRow, onRowEvent }) => {
  return data.oDEs.map((e) => {
    return <Row
      odeData={e}
      key={e.id}
      onClick={onClickRow}
      onRowEvent={onRowEvent} />
  });
}
