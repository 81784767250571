import React from "react";
import { Grid, Header, Image } from "semantic-ui-react";
import { ActionButton } from "../../atoms";
import EditIcon from "mdi-react/EditIcon";

export default ({avatarUrl, title, subtitle, onEdit}) => {
  const placeholderUrl = "https://s3.us-east-2.amazonaws.com/"+
    "jaxitank-dev/image-placeholder-350x350.png";

  return (
    <Grid.Row>
      <Grid.Column>
        <Header as="h2" floated="left">
          <Image
            circular
            src={avatarUrl || placeholderUrl} />
          <Header.Content>
            { title }
            <Header.Subheader>{ subtitle }</Header.Subheader>
          </Header.Content>
        </Header>
        { onEdit ?
          <Header floated="right">
            <ActionButton
              icon={<EditIcon />}
              onClick={() => onEdit && onEdit()} />
          </Header>
        : null }
      </Grid.Column>
    </Grid.Row>
  );
};
