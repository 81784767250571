import React, { useState, useContext } from "react";
import {
  Container,
  Grid,
  Header,
  Image,
  Button,
  Table,
  Label,
  Dropdown,
  Segment
} from "semantic-ui-react";
import { EditButton } from "../../../../atoms";
import { CreateBatch, EditProgram } from "../../../../organisms";
import SessionContext from "../../../../../context/userSessionContext";
import { verifyUserPermission }
  from "../../../../../helpers/permissionsValidator";
import _ from "lodash";

const styles = {
  section1: {
    marginTop: "10px",
    borderBottom: "1px solid #dddddd"
  },
  section2: {
    overflow: "visible"
  },
  header: {
    color: "#323C47"
  },
  subheader: {
    fontSize: "13px"
  },
  editButton: {
    position: "absolute",
    right: "5px"
  },
  label: {
    color: "#999999",
    marginTop: "30px"
  },
  profilePic: {
    marginTop: "20px",
    marginLeft: "-5px"
  },
  coordinatorName: {
    color: "#323C47"
  },
  descriptionStyle: {
    marginTop: "30px",
    marginLeft: "-5px",
    color: "#323C47"
  },
  newBatchButton: {
    position: "absolute",
    right: "50px",
    marginTop: "-10px"
  },
  batchesTable: {
    margin: "30px 0 auto",
    width: "100%",
    height: "auto",
    overflowY: "auto"
  }
};

export default ({ data, refetch, organismData }) => {
  const loggedUser = useContext(SessionContext);
  const [booleanBatch, setBooleanBatch] = useState(false);
  const [editModal, openEditModal] = useState({ active: false });
  const emptyState = {
    name: null,
    type: null,
    coordinator: null,
    description: null,
    programID: null
  };
  const {
    id,
    name,
    type,
    programID,
    coordinator,
    description } = data ? data : emptyState;

  const hasPermissionsToEditAndCreate = verifyUserPermission(
    [
      { role: "SUPER_ADMIN" },
      { // Institution coordinator
        role: "COORDINATOR",
        id: _.get(organismData, "institution.coordinator.id", "")
      },
      { // Organism coordinator
        role: "COORDINATOR",
        id: _.get(organismData, "coordinator.id", "")
      },
      { // Program coordinator
        role: "COORDINATOR",
        id: _.get(coordinator, "id", "")
      }
    ],
    { id: loggedUser.id, role: loggedUser.role });

  const programId = id;
  const {
    section1,
    section2,
    header,
    subheader,
    editButton,
    label,
    descriptionStyle,
    coordinatorName,
    newBatchButton,
    batchesTable
  } = styles;

  const mapType = type => {
    const TYPES = {
      COWORKING: "Co Working",
      ACCELERATOR: "Aceleradora",
      INVESTMENT_FUND: "Fondo de inversión",
      INCUBATOR: "Incubadora"
    };
    return TYPES[type];
  };

  return (
    <div>
      { data ? (
        <Container>
          <Grid style={section1}>
            {/* Header */}
            <Grid.Row>
              <Grid.Column width={12}>
                <Header as="h1" style={header}>
                  {name}
                  <Header.Subheader style={subheader}>
                    {mapType(type)}
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column
                widt={2}
                floated="right"
                textAlign="left"
                style={editButton}>
                {
                  hasPermissionsToEditAndCreate &&
                  <EditButton
                    openEditModal={openEditModal}
                    editModal={editModal}
                    open={() => {
                      openEditModal({ active: !editModal.active });
                    }}
                    name="edit"
                    style={{ cursor: "pointer" }} />
                }
                {editModal.active ? (
                  <EditProgram
                    openModal={editModal.active}
                    close={openEditModal}
                    programData={data}
                    refetch={refetch} />
                ) : null}
              </Grid.Column>
            </Grid.Row>
            {/* Coordinator */}
            <Grid.Row columns="equal">
              <Grid.Column width={4}>
                <p style={label}>Coordinador</p>
              </Grid.Column>
              <Grid.Column>
                <Segment.Group
                  horizontal
                  compact
                  className="basic">
                  <Segment>
                    <Image
                      src={"https://via.placeholder.com/150x150.png"}
                      size="mini"
                      circular />
                  </Segment>
                  <Segment style={{alignSelf: "center"}}>
                    <p style={coordinatorName}>{coordinator?.fullName || "N/A"}</p>
                  </Segment>
                </Segment.Group>
              </Grid.Column>
            </Grid.Row>
            {/* Detail */}
            <Grid.Row columns={2} style={{ marginBottom: "30px" }}>
              <Grid.Column width={4}>
                <p style={label}>Detalle</p>
              </Grid.Column>
              <Grid.Column width={11}>
                <p style={descriptionStyle}>{unescape(description)}</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {/* Batches */}
          <Grid style={section2}>
            {/* Header */}
            <Grid.Row style={{ marginTop: "20px" }}>
              <Grid.Column width={12}>
                <Header as="h3" style={header}>
                  Batches
                </Header>
              </Grid.Column>
              <Grid.Column
                widt={2}
                floated="right"
                textAlign="left"
                style={newBatchButton}>
                {
                  hasPermissionsToEditAndCreate &&
                    <Button
                      content="Nuevo"
                      secondary
                      name="create"
                      onClick={() => setBooleanBatch(prevState => !prevState)}
                      style={{ cursor: "pointer" }} />
                }
                {booleanBatch ? (
                  <CreateBatch
                    coordinatorId={coordinator.id || ""}
                    programID={data.id}
                    open={booleanBatch}
                    openModal={() => setBooleanBatch(prevState => !prevState)}
                    close={() => setBooleanBatch(prevState => !prevState)}
                    refetch={refetch} />
                ) : null}
              </Grid.Column>
            </Grid.Row>
            {/* Batchs row */}
            <Grid.Row>
              <Table unestackable style={batchesTable}>
                <Table.Body>
                  {data.batches.map(({ id, name }, index) => {
                    return (
                      <Table.Row key={index} style={{ height: "80px" }}>
                        <Table.Cell
                          style={{ padding: "20px", fontWeight: "bold" }}
                          textAlign="left"
                          width={10}>
                          <Label circular color="green">
                            {name.substring(0, 1)}
                          </Label>
                          &nbsp; &nbsp;{" "}
                          {/**
                            The batch name is saved with a version name.
                            In order to show the name without the version
                            0 number, this regular expression replaces
                            the .0 end of string with an empty string
                          */}
                          {name.replace(/\.0$/i, "")}
                        </Table.Cell>
                        <Table.Cell
                          style={{ padding: "20px", fontWeight: "bold" }}
                          width={4}
                          textAlign="right">
                          <Dropdown
                            disabled={!hasPermissionsToEditAndCreate}
                            selection
                            defaultValue="ON_GOING"
                            options={[
                              { key: 0, text: "En curso", value: "ON_GOING" },
                              {
                                key: 1,
                                text: "Finalizado",
                                value: "FINISHED"
                              }
                            ]} />
                        </Table.Cell>
                        <Table.Cell
                          style={{ padding: "20px", fontWeight: "bold" }}
                          width={4}
                          textAlign="center">
                          <Button
                            disabled={!hasPermissionsToEditAndCreate}
                            primary
                            content="Ver"
                            onClick={() => {
                              window.location = `/admin/batch/orgid=${
                                window.location.pathname.split("/")[3]
                              }_programid=${programId}_batchid=${id}`;
                            }} />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Grid.Row>
          </Grid>
        </Container>)
        : (<div>No hay programas aun</div>)
      }
    </div>
  );
};
