import React from "react";
import { DELETE_ODE } from "../../../../graphql/mutations/odes";
import { default as TableRow } from "../../../molecules/odeTableBody/components/tableRow";
import { Countries } from "../../../../helpers";

export default ({
  odeData,
  key,
  setOdeId,
  editOdeIsOpen,
  setEditOdeState,
  setConfirm,
  refetch,
  onClick,
  onRowEvent
}) => {
  onClick = onClick ? onClick : () => { };
  onRowEvent = onRowEvent ? onRowEvent : () => { };
  setOdeId = setOdeId || (() => { });
  setEditOdeState = setEditOdeState || (() => { });
  setConfirm = setConfirm || (() => { });

  const { id, city,
    odeCategory, name,
    nationality, state,
    industry, trl,
    technology, organisms,
    phase, } = odeData;

  return (
    <TableRow
      city={city}
      countries={Countries}
      index={key}
      idOde={id}
      odeCategory={odeCategory}
      name={name}
      nationality={nationality}
      state={state}
      industry={industry}
      trl={trl}
      technology={technology}
      delete_ode={DELETE_ODE}
      refetch={refetch}
      onRowEvent={onRowEvent}
      setOdeId={setOdeId}
      editOdeIsOpen={editOdeIsOpen}
      setEditOdeState={setEditOdeState}
      organisms={organisms}
      setConfirm={setConfirm}
      phase={phase}
      onClick={onClick}
    />
  );
}
