import gql from "graphql-tag";
const coordinatorConstraints = `[
  { batches_some: { users_some: { id: $userId } } },
  { batches_some: { program: { coordinator: { id: $userId } } } },
  { organisms_some: { coordinator: { id: $userId } } },
  {
    organisms_some: {
      institution: { coordinator: { id: $userId } }
    }
  },
]`;

export default gql`
  query (
    $userId: ID!
    $first: Int = 10
    $skip: Int = 0
    $search: String = ""
    $orderBy: ODEOrderByInput = id_DESC) {
      oDEs (
    first: $first,
    skip: $skip,
    orderBy: $orderBy,
    where: {
      AND: [
        { OR: [
          { team_some: { id: $userId } },
          { network_some: { id: $userId } },
          { batches_some: { users_some: { id: $userId } } },
          { batches_some: { program: { coordinator: { id: $userId } } } },
          { organisms_some: { coordinator: { id: $userId } } },
          {
            organisms_some: {
              institution: { coordinator: { id: $userId } }
            }
          }
        ] },
        { OR: [
          { iname_contains: $search},
          { icity_contains: $search },
          { istate_contains: $search },
          { icountry_contains: $search }
        ] }
      ]
    }
  ){
      id
      name
      odeID
      odeCategory
      sector
      commercialBussines
      nationality
      registrationYear
      city
      state
      phase
      industry
      trl
      technology
      organisms {
        id
      }
      team {
        id
      }
      network {
        id
      }
    }
    oDEsConnection {
      aggregate {
        count
      }
    }
  }
`;
