const getBreadcrumbPath = data => {

  const pathObject = [{
    label: "Organismos",
    href: "../organism"
  }];

  if(data !== undefined && data !== null) {
    const { organism } = data;
    pathObject.push({
      label: organism !== undefined && organism.name,
      active: true
    });
  }

  return pathObject;
};

export { getBreadcrumbPath };
