import gql from "graphql-tag";
import { client } from "../../index";

export default ({ odeId, activities }) => {
  return client.mutate({
    variables: {
      odeId,
      activities,
    },
    mutation: gql`
      mutation($odeId: ID!, $activities: [OdeActivityCreateWithoutOdeInput!]) {
        updateODE (
          where: { id: $odeId }
          data: { activities: { create: $activities } }
        ) {
          id
        }
      }
    `,
  });
};
