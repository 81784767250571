import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Grid,
  Form,
  Icon,
  Input,
  Confirm,
  Image,
  Dropdown,
} from "semantic-ui-react";
import validations from "./validations";
import { UserID, DeleteButton } from "../../atoms";
import { client } from "../../../graphql";
import { CREATE_ADMIN, UPDATE_ADMIN } from "../../../graphql/mutations/users";
import { USER } from "../../../graphql/queries/users";
import { FormValidator } from "../../../helpers";
import SessionContext from "../../../context/userSessionContext";

export default props => {
  const [data, setData] = useState({
    userID: "",
    fullName: "",
    systemRole: "",
    phone: "",
    email: "",
    organisms: [],
  });

  const [errors, setErrors] = useState({
    fullName: false,
    systemRole: false,
    phone: false,
    email: false,
  });

  const [openConfirm, setOpenConfirm] = useState(false);

  useEffect(() => {
      const { fullName, phone, email, userID, organisms } = props.data;

      // Some users just can create users with the same role only.
      // If the current user is a coordinator, should use the default role  
      const  systemRole = props.defaultRole || props.data.systemRole;

      setData({ fullName, systemRole, phone, email, userID, organisms });
  }, []);

  const handleChange = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
  };

  const validateFrom = data => {
    const validator = new FormValidator(validations);
    const validation = validator.validate(data);
    if (validation.isValid) {
      Object.keys(errors).map(error => {
        errors[error] = validation[error]["isInvalid"];
      });
      setErrors({ ...errors });
    } else {
      Object.keys(errors).map(error => {
        errors[error] = validation[error]["isInvalid"];
      });
      setErrors({ ...errors });
    }
    return validation;
  };

  const close = () => {
    Object.keys(errors).map(error => {
      errors[error] = false;
    });
    setErrors({ ...errors });
    setData({});
    props.close();
  };

  const save = async () => {
    const validation = validateFrom(data);
    if (document.getElementById("user-id") !== null) {
      data["userID"] = document.getElementById("user-id").value;
    }
    if (validation.isValid) {
      try {
        await CREATE_ADMIN(data);
        close();
      } catch (e) {
        throw new Error(e);
      }
    }
  };

  const update = async () => {
    const { afterUpdate = () => null } = props;
    const validation = validateFrom(data);
    data.id = props.userID;
    if (validation.isValid) {
      try {
        await UPDATE_ADMIN(data);

        afterUpdate();
        close();
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  return (
    <Modal
      className="scroll Modal__Form-SubModal"
      centered={false}
      open={props.open}
      onClose={props.close}
      size="tiny"
      dimmer="blurring"
      autoComplete="new-password"
      style={{ minHeight: "600px" }}
    >
      <Modal.Header as="h1">
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} floated="left">
              {props.update ? "Editar administrador" : "Nuevo administrador"}
            </Grid.Column>
            <Grid.Column width={2} floated="right">
              <Icon name="close" onClick={close} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      {/* Form */}
      <Modal.Content>
        <Image
          src="https://react.semantic-ui.com/images/wireframe/square-image.png"
          size="tiny"
          circular
          centered
        />
        <Form className="Modal__Form">
          <Form.Group widths="2">
            <Form.Field
              width={11}
              control={Input}
              label="Nombre completo"
              type="text"
              defaultValue={data["fullName"]}
              name="fullName"
              onChange={handleChange}
              required
              error={data["fullName"] !== "" ? false : errors["fullName"]}
            />
            <Form.Field
              id="user-id"
              width={5}
              control={props.update ? Input : UserID}
              name="userID"
              label="ID"
              defaultValue={data["userID"]}
              type="text"
              required
              onChange={handleChange}
              readOnly={true}
            />
          </Form.Group>
          <SessionContext.Consumer>
            {({ role }) =>
              role === "SUPER_ADMIN" ? (
                <Form.Group widths="equal">
                  <Form.Field
                    control={Dropdown}
                    label="Rol de usuario"
                    name="systemRole"
                    fluid
                    selection
                    options={[
                      { key: 3, text: "Asesor", value: "ADVISER" },
                      { key: 0, text: "Administrador", value: "SUPER_ADMIN" },
                      { key: 1, text: "Coordinador", value: "COORDINATOR" },
                    ]}
                    value={data["systemRole"]}
                    onChange={handleChange}
                    error={
                      data["systemRole"] !== "" ? false : errors["fullName"]
                    }
                    required
                  />
                </Form.Group>
              ) : null
            }
          </SessionContext.Consumer>

          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              type="text"
              label="Teléfono"
              name="phone"
              defaultValue={data["phone"]}
              onChange={handleChange}
              error={data["phone"] !== "" ? false : errors["fullName"]}
              required
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              type="email"
              label="Correo electrónico"
              name="email"
              defaultValue={data["email"]}
              onChange={handleChange}
              error={data["email"] !== "" ? false : errors["fullName"]}
              required
            />
          </Form.Group>

          <Form.Group widths="equal">
            {props.update &&
              (data.organisms ? (
                data.organisms.length > 0
              ) : [] ? null : (
                <DeleteButton del={() => setOpenConfirm(() => true)} />
              ))}
            <Button
              style={{ width: "150px", marginLeft: "248px", marginTop: "8px" }}
              type="button"
              onClick={props.update ? update : save}
              fluid
              primary
            >
              Guardar
            </Button>
          </Form.Group>
        </Form>
        <Confirm
          open={openConfirm}
          onCancel={() => setOpenConfirm(() => false)}
          onConfirm={() => {/*deleteUser()*/}}
          header="Eliminar usuario"
          content={"Esta acción no es reversible."}
        />
      </Modal.Content>
    </Modal>
  );
};
