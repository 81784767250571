import React from "react";
import { Segment } from "semantic-ui-react";
import { AttachmentIcon, PdfPreview, DummyPreview } from "../../atoms";
import CloseIcon from "mdi-react/CloseIcon";
import Loading from "./loading";

const getFileNameFromURL = (url = "") => {
  const lastSlashPosition = url.lastIndexOf("/");
  return url.substring(lastSlashPosition + 1);
};

export default ({index, file, onDelete, deletable, loading}) => {
  if(loading)
    return <Loading />;

  deletable = deletable === undefined ? true : deletable;

  const styles = {
    container: {
      marginTop: "10px",
      fontSize: "14px",
      padding: "0px",
      marginBottom: "0px"
    },
    segment: {
      padding: "0px"
    },
    link: {
      color: "rgba(0,0,0,.87)",
      opacity: "0.5"
    }
  };

  return (
    <Segment clearing style={styles.container}>
      <Segment stacked className="shadow less-padding" floated="left" compact>
        { file.url.match(/\.pdf$/gi) !== null ?
          <PdfPreview url={file.url} />
        : <DummyPreview url={file.url} /> }
      </Segment>
      <Segment style={styles.segment} floated="left" compact>
        <AttachmentIcon /> &nbsp; <a style={styles.link} href={file.url}>
          {decodeURI(getFileNameFromURL(file.url))}
        </a> &nbsp;{" "}
        { deletable ?
          <CloseIcon
            style={{ paddingTop: "15px" }}
            onClick={() => {
              onDelete(index);
            }} />
        : null }
      </Segment>
    </Segment>
  );
}
