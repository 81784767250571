import React from "react";
import { Card as SemanticCard } from "semantic-ui-react";

const Card = ({ children, header }) => (
  <SemanticCard fluid>
    <SemanticCard.Content>
      <SemanticCard.Header>{header}</SemanticCard.Header>
      <SemanticCard.Description>{children}</SemanticCard.Description>
    </SemanticCard.Content>
  </SemanticCard>
);

export default Card;
