import gql from "graphql-tag";

const coordinatorConstraints = `OR: [
  { coordinator: { id: $coordinatorId } },
  { organisms_some: { coordinator: { id: $coordinatorId } } },
  { organisms_some: { program_some: { coordinator: { id: $coordinatorId } } } }
],`;

export default gql`
  query(
    $coordinatorId: ID!,
    $first: Int!,
    $skip: Int!,
    $filter: String = "",
    $orderBy: InstitutionOrderByInput = id_DESC
  ) {
    institutions(
      first: $first,
      skip: $skip,
      orderBy: $orderBy,
      where: {
        OR: [
          {
            ${coordinatorConstraints}
            iname_contains: $filter
          },
          {
            ${coordinatorConstraints}
            iaddress_contains: $filter
          },
          {
            ${coordinatorConstraints}
            iinstID_contains: $filter
          }
        ]
      }
    ) {
      id
      name
      instID
      address
      zone
      avatarUri
      count
      coordinator { id }
      organisms {
        id
        coordinator { id }
        program {
          coordinator {
            id
          }
        }
      }
    }
    full: institutions {
      count
    }
    institutionsConnection {
      aggregate {
        count
      }
    }
  }
`;
