import gql from "graphql-tag";

export default gql`
  query (
    $institutionId: ID
  ) {
    organisms (
      where: {
        institution: {
          id: $institutionId
        }
      }
    ) {
      id
      organismID
      name
      coordinator {
        id
        fullName
      }
      type
      program {
        id
        name
      }
      wasCreatedAt
      institution {
        id
        name
        coordinator {
          id
        }
      }
    }
    organismsConnection {
      aggregate {
        count
      }
    }
  }
`;
