import React from "react";
import { Grid, Header, Message } from "semantic-ui-react";

export default ({
  items,
  onClickItem,
  selectedId,
  emptyContent="No se encontraron elementos"
}) => {
  const isSelected = item => {
    return (selectedId && selectedId === item.id) ?
      "selected" :
      undefined;
  };

  const renderEmpty = () => {
    return (
      <Grid.Row>
        <Grid.Column>
          <Message>{emptyContent}</Message>
        </Grid.Column>
      </Grid.Row>
    );
  };

  return (
    <Grid.Row className="sidebar-list">
      <Grid.Column>
        <Grid divided="vertically">
          { (items.length) ? items.map(item => {
            return (
              <Grid.Row
                className={isSelected(item)}
                onClick={() => onClickItem && onClickItem(item)}>
                <Grid.Column>
                  <Header>{item.title}</Header>
                  {item.description}
                </Grid.Column>
              </Grid.Row>
            );
          }) : renderEmpty() }
        </Grid>
      </Grid.Column>
    </Grid.Row>
  );
};
