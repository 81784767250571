import React from "react";
import {
  TableRow,
  TableCell,
  Dimmer,
  Loader
} from "semantic-ui-react";

export default (props) => {
  return (
    <TableRow textAlign="center">
      <TableCell className="Wrapper__Body-Loader">
        <Dimmer active inverted>
          <Loader
            testid="loading-wrapper"
            inverted
            content="Cargando..."
          >
            Error...
          </Loader>
        </Dimmer>
      </TableCell>
    </TableRow>
  )
}