import React from "react";
import { Query } from "react-apollo";
import { Dropdown } from "semantic-ui-react";
import { ORGANISMS_DROPDOWN } from "../../../graphql/queries/organisms";

export default ({
  selectedInstitutionId,
  name,
  value,
  onChange,
  placeholder,
  clearable
})  => {
  return (
    <Query
      variables={{
        institutionId: selectedInstitutionId
      }}
      query={ORGANISMS_DROPDOWN}
      fetchPolicy="no-cache">
      {({ loading, error, data }) => {

        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        const options = data.organisms.map(({ id, name }) => (
          { value: id, text: name, key: id }
        ));

        return (
          <Dropdown
            clearable={clearable || false}
            selection
            options={options}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder} />
        );
      }}
    </Query>
  );
};
