import React from "react";
import { CLEAN_TEXT } from "../../../../../helpers";
import { Item } from "semantic-ui-react";

export default ({data}) => {
  const itemDesc = [
    {
      childKey: 0,
      meta: "Descripción del organismo",
      description: CLEAN_TEXT.UNESCAPE(data.organism.description)
    }
  ];

  return (
    <Item.Group
      style={{
        borderBottom: "1px solid #F0F0F0",
        padding: "8px"
      }}
      items={itemDesc} />
  );
};
