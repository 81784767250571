import React from "react";
import { TableRow, TableCell, Dropdown } from "semantic-ui-react";
import { OdeOptions } from "../../../organisms";
import { MAP_CITIES, MAP_INDUSTRIES } from "../../../../helpers";

const mapPhase = phase => {
  const PHASE = {
    LAUNCHING: "Lanzamiento",
    ACCELERATING: "Aceleración",
    VALIDATING: "Validación",
    DISCOVERY: "Descubrimiento",
  };
  return PHASE[phase];
};

const mapCategories = category => {
  const CATEGORIES = {
    STARTUP: "Startup",
    TEAM: "Equipo de trabajo",
    PROJECT: "Proyecto",
    SPIN_OFF: "Spin off",
    COMPANY: "Empresa",
  };
  return CATEGORIES[category];
};

const mapTRL = trl => {
  const TRL = {
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6,
    SEVEN: 7,
    EIGHT: 8,
    NINE: 9,
  };
  return TRL[trl];
};

export default ({
  city,
  countries,
  index,
  idOde,
  odeCategory,
  name,
  nationality,
  state,
  industry,
  trl,
  technology,
  delete_ode,
  refetch,
  setOdeId,
  editOdeIsOpen,
  setEditOdeState,
  organisms,
  setConfirm,
  phase,
  onClick,
  onRowEvent
}) => {
  return (
    <TableRow
      textAlign="center"
      key={index}
      onClick={() => { onClick(idOde) } }
    >
      <TableCell>{mapCategories(odeCategory)}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>
        <Dropdown
          options={countries}
          value={nationality}
          icon={""}
          readOnly
          disabled
          style={{ color: "black" }}
        />
      </TableCell>
      <TableCell>{city}</TableCell>
      <TableCell>{MAP_CITIES(state) ? MAP_CITIES(state) : state}</TableCell>
      <TableCell>{mapPhase(phase)}</TableCell>
      <TableCell>{MAP_INDUSTRIES(industry)}</TableCell>
      <TableCell>{mapTRL(trl)}</TableCell>
      <TableCell>{technology}</TableCell>
      <TableCell>
        <OdeOptions
          idOde={idOde}
          delete={delete_ode}
          refetch={refetch}
          hasPrograms={organisms.length > 0}
          setOdeId={setOdeId}
          editOdeIsOpen={editOdeIsOpen}
          setEditOdeState={setEditOdeState}
          setConfirm={setConfirm}
          onRowEvent={onRowEvent}
        />
      </TableCell>
    </TableRow>
  );
};
