import React, { useState, useContext } from "react";
import { Query } from "react-apollo";
import { Segment, Dimmer, Loader } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import { Dashboard, DetailComponent } from "../../organisms";
import { SearchOdeModal } from "../../molecules";
import { Information, ODEsList } from "./components";
import SessionContext from "../../../context/userSessionContext";
import { GET_USER_DETAILS } from "../../../graphql/queries/users";
import { getBreadcrumbPath } from "./helpers";

const id = window.location.pathname.split("l/")[1];

export default () => {
  const loggedUser = useContext(SessionContext);
  const [addUserModal, setAddUserModal] = useState(false);

  const renderContent = (loading, error, data, refetch) => {
    if (loading) {
      return (
        <Segment className="Ode__Detail__ContainerTabs">
          <Dimmer>
            <Loader />
          </Dimmer>
        </Segment>
      );
    }

    if (error)
      return <p>Error...</p>;

    if (data.user === null)
      return <Redirect to="/404" />;

    const closeAddUserModal = () => {
      setAddUserModal(false);
    };

    return (
      <div style={{width: "100%"}}>
        <DetailComponent
          tabs={[
            {
              index: 0,
              text: "Información",
              value: "information",
              content: <Information
                loggedUser={loggedUser}
                data={data.user} refetch={refetch} />
            },
            {
              index: 1,
              text: "Linea del Tiempo",
              value: "timeline",
              content: <div>To Be Done</div>
            }
          ]}
          rigthContent={
            <div>
              <ODEsList
                userRole={loggedUser.role}
                openAddUser={() => setAddUserModal(() => true)} />
            </div>
          }
          rigthColumn />
        {addUserModal ?
          <SearchOdeModal
            userRole={data}
            open={addUserModal}
            onClose={closeAddUserModal} />
          : null}
      </div>
    );
  };

  return (
    <Query
      query={GET_USER_DETAILS}
      variables={{id: id}}
      fetchPolicy="network-only">
      {({ loading, error, data, refetch }) => {
        return (
          <Dashboard
            breadcrumbPath={getBreadcrumbPath(data)}
            active="user">
            {renderContent(loading, error, data, refetch)}
          </Dashboard>
        );
      }}
    </Query>
  );
};
