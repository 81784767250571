import React, { useState } from "react";
import { Grid, Button } from "semantic-ui-react";
import { SearchInput } from "../../atoms/searchInput";

export default  (props) => {
  const { searchLabel, onAdd, addText } = props;

  return (
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column>
          <SearchInput
            placeholder={ searchLabel || "Buscar" }
            onChange={props.onSearchChange} />
        </Grid.Column>
        <Grid.Column textAlign="right">
          { onAdd ?
            <Button onClick={() => { onAdd() } } primary>
              {addText}
            </Button>
          : null }
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
