import React, { useState } from "react";
import { Grid } from "semantic-ui-react";
import { SidebarComponent, Topbar } from "../index";
import { OverlaySidebar } from "./components";

const STYLES = {
  RESPONSIVE: {
    WIDTH: () => {
      const width = window.innerWidth;
      switch (true) {
        case width <= 1279:
          return { width: "1024px" };
        case width <= 1440:
          return null;
        case width > 1440:
          return null;
      }
    },
  },
};

const Dashboard = props => {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <Grid className="Wrapper no-scroll" style={STYLES.RESPONSIVE.WIDTH()}>
      {/* Main grid  */}
      <Grid.Row className="Wrapper__Row">
        {!showSidebar ? (
          <SidebarComponent {...props} active={props.active} />
        ) : (
          <OverlaySidebar
            setShowSidebar={setShowSidebar}
            showSidebar={showSidebar}
            {...props}
            active={props.active}
          />
        )}
        <Grid.Column width={14} className="scrollable full-height">
          {/* Main container */}
          <Grid className="Wrapper__Body" style={STYLES.RESPONSIVE.WIDTH()}>
            <Grid.Row
              className="Wrapper__TopBar"
              style={STYLES.RESPONSIVE.WIDTH()}
            >
              <Topbar
                setShowSidebar={setShowSidebar}
                breadcrumbPath={props.breadcrumbPath} />
            </Grid.Row>
            <Grid.Row style={{ padding: 0, height: "100%" }}>
              {props.children}
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Dashboard;
