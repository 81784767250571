import React from "react";
import { Form, Input, Button, Header, Dropdown } from "semantic-ui-react";
import { INDUSTRIES, PHASES } from "../../../../../../helpers";
import SessionContext from "../../../../../../context/userSessionContext";
import lodash from "lodash";

const trlOptions = [
  { text: "1", key: "1", value: "ONE" },
  { text: "2", key: "2", value: "TWO" },
  { text: "3", key: "3", value: "THREE" },
  { text: "4", key: "4", value: "FOUR" },
  { text: "5", key: "5", value: "FIVE" },
  { text: "6", key: "6", value: "SIX" },
  { text: "7", key: "7", value: "SEVEN" },
  { text: "8", key: "8", value: "EIGHT" },
  { text: "9", key: "9", value: "NINE" },
];

export default props => {
  const {
    handleChange,
    prev,
    create,
    errors,
    data,
    turnoffautocomplete,
    loading = false
  } = props;
  return (
    <Form className="Padded__Form">
      <Header>Emprendimiento</Header>
      <Form.Group widths="equal">
        <Form.Field
          name="phase"
          label="Fase"
          control={Dropdown}
          selection
          options={PHASES}
          onChange={handleChange}
          error={data["phase"] ? false : errors.phase}
          defaultValue={ data.phase || "" }
          required
          onFocus={turnoffautocomplete}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          name="technology"
          label="Tecnología"
          selection
          control={Input}
          onChange={handleChange}
          defaultValue={ data.technology || "" }
          error={data["technology"] ? false : errors.technology}
          required
          onFocus={turnoffautocomplete}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          name="trl"
          label="Nivel de TRL"
          selection
          control={Dropdown}
          options={trlOptions}
          defaultValue={ data.trl || "" }
          onChange={handleChange}
          error={data["trl"] ? false : errors.trl}
          required
          onFocus={turnoffautocomplete}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          name="industry"
          label="Industria"
          defaultValue={ data.industry || "" }
          selection
          control={Dropdown}
          selection
          options={INDUSTRIES}
          onChange={handleChange}
          error={data["industry"] ? false : errors.industry}
          required
          onFocus={turnoffautocomplete}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          name="area"
          label="Área"
          defaultValue={ data.area || "" }
          control={Input}
          onChange={handleChange}
          error={data["area"] ? false : errors.area}
          required
          onFocus={turnoffautocomplete}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Button
          disabled={loading}
          type="button"
          id="ode-form-prev"
          onClick={prev}
          primary
          fluid>
          Anterior
        </Button>
        <SessionContext>
          {({ id }) => (
            <Button
              disabled={loading}
              loading={loading}
              type="button"
              id="ode-form-next1"
              onClick={() => create(id)}
              primary
              fluid>
              Crear
            </Button>
          )}
        </SessionContext>
      </Form.Group>
    </Form>
  );
};
