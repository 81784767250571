// # UpdateTask

export const updateActivitySentence = `
  update: {
    name: $name,
    description: $description,
    activityType: $activityType,
    files: { create: $files },
  }`;

export const createActivitySentence = newFiles => {
  return `create: {
    name: $name,
    description: $description,
    activityType: $activityType,
    files: { create: $files },
    files: { create: $connectedFiles },
    hasFiles: ${newFiles.lenght > 0}
  }`;
};

export const getFilesUrls = connectedFiles => {
  if(!Array.isArray(connectedFiles))
    return [];

  return connectedFiles.map(({url, type}) => ({ url, type }));
};
