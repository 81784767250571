import * as accents from "remove-accents";

/**
 * Returns an integer percentage.
 * @param {*} arg1 Denominator
 * @param {*} arg2 Fraction
 */
export const getPercentage = (arg1, arg2) => {
  if (arg1 > 0) {
    return parseInt(
      (arg2 * 100) / arg1
    );
  }
  return 0;
};

/**
 * Removes the accents and special characters unsupported by the S3 service
 * @param {*} file File object
 */
export const clearFileName = file => {
  if (typeof(file) === "object") {
    const {
      type: fType = "",
      name: fName = "",
      size: fSize = 0
    } = file;

    const blobFile = file.slice(0, fSize, fType);

    const rgxNotValidChars = /[{}[\]^%|><~#`\\/&()¡!].[a-z]*/gi;
    const rgxAllDotsButTheLast = /[.](?=.*[.])/gi;
    const fileNameWithoutSymbols = fName
      .replace(rgxNotValidChars, "")
      .replace(rgxAllDotsButTheLast, "");

    const fileName = accents.remove(fileNameWithoutSymbols);
    const fileWithCleanName = new File([blobFile], fileName, { type: fType });
    return fileWithCleanName;
  } else {
    return undefined;
  }
};
