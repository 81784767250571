import React from "react";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";

const style = {
  marginTop: "-15px",
};

export default ({ show }) => (
  <div className="roundedButton" style={style} >
    <DotsVerticalIcon color="#5F39DE" onClick={show} />
  </div>
);
