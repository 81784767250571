import React, { useState, useContext } from "react";
import {
  Container,
  Grid,
  Header,
  Button,
  Table,
  Dropdown
} from "semantic-ui-react";
import PencilIcon from "mdi-react/PencilIcon";
import { EditButton, PlusButton } from "../../../../atoms";
import {
  EditObjective,
  CreateActivity,
  EditActivity
} from "../../../../organisms";
import { CLEAN_TEXT } from "../../../../../helpers";
import lodash from "lodash";
import SessionContext from "../../../../../context/userSessionContext";
import { verifyUserPermission }
  from "../../../../../helpers/permissionsValidator";

const styles = {
  section1: {
    marginTop: "10px",
    borderBottom: "1px solid #dddddd"
  },
  section2: {
    overflowY: "auto",
    minHeight: "400px"
  },
  header: {
    color: "#323C47"
  },
  subheader: {
    fontSize: "13px"
  },
  editButton: {
    position: "absolute",
    right: "0px",
    display: "inline-block"
  },
  label: {
    color: "#999999",
    marginTop: "30px"
  },
  profilePic: {
    marginTop: "20px",
    marginLeft: "-5px"
  },
  coordinatorName: {
    marginTop: "30px",
    marginLeft: "-45px",
    color: "#323C47"
  },
  descriptionStyle: {
    marginTop: "30px",
    marginRight: "-9px",
    color: "#323C47"
  },
  newBatchButton: {
    position: "absolute",
    right: "20px",
    marginTop: "-10px"
  },
  batchesTable: {
    margin: "0 auto",
    width: "95%",
    height: "auto",
    marginLeft: "15px",
    marginTop: "-90px",
    overflowY: "auto",
    marginBottom: "50px"
  }
};

export default props => {
  const loggedUser = useContext(SessionContext);
  const { data, organismData } = props;
  const [boolean, setBoolean] = useState(false);
  const emptyState = { name: null, type: null, description: null };
  const { name, description, id, activities } = data ? data : emptyState;

  const [editModal, openEditModal] = useState({
    active: false,
    data: { name, description: "", activityType: "", id: "" }
  });

  const [editModalActivity, openEditModalActivity] = useState({
    active: false
  });

  const permissionVariables = [
    { role: "SUPER_ADMIN" },
    {
      role: "COORDINATOR",
      id: lodash.get(organismData, "coordinator.id", "")
    },
    {
      role: "COORDINATOR",
      id: lodash.get(organismData, "institution.coordinator.id", "")
    }
  ];

  const canCreateAndEdit = verifyUserPermission(
    permissionVariables,
    {
      role: loggedUser.role,
      id: loggedUser.id
    });

  const {
    section1,
    section2,
    header,
    editButton,
    descriptionStyle,
    newBatchButton,
    batchesTable
  } = styles;

   /**
    * Returns a readable text
    * @param {*} type The type given by the service
    * @returns String
    */
  const activityTypes = type => {
    const TYPES = {
      ADVISORY: "Asesoría",
      COURSE: "Cursos",
      TALK: "Charlas",
      WORKSHOP: "Talleres",
      ROUND_TABLE: "Mesa redonda",
      KNOWLEDGE: "Conocimiento",
      DELIVER: "Entrega"
    };
    return TYPES[type];
  };

  const colorActivityTypes = type => {
    const TYPES = {
      KNOWLEDGE: "teal",
      DELIVER: "green",
      EXECUTION: "teal"
    };
    return TYPES[type];
  };

  return (
    <div>
      { data ? (
        <Container>
          <Grid style={section1}>
            {/* Header */}
            <Grid.Row>
              <Grid.Column width={12}>
                <Header as="h1" style={header}>
                  {name.split(".")[1] === "0" ? name.split(".")[0] : name}
                </Header>
              </Grid.Column>
              <Grid.Column
                width={3}
                textAlign="left"
                style={editButton}>
                <Dropdown
                  disabled={!canCreateAndEdit}
                  selection
                  item
                  compact
                  defaultValue="ACTIVE"
                  options={[
                    { key: 0, text: "Activo", value: "ACTIVE" },
                    { key: 1, text: "Inactivo", value: "INACTIVE" }
                  ]} />
              </Grid.Column>
              <Grid.Column floated="right">
                { canCreateAndEdit &&
                  <EditButton
                    openEditModal={openEditModal}
                    editModal={editModal}
                    open={() => {
                      openEditModal({ active: !editModal.active });
                    }}
                    name="edit"
                    style={{ cursor: "pointer" }} /> }
                {editModal.active ? (
                  <EditObjective
                    openModal={editModal.active}
                    close={openEditModal}
                    objectiveData={data}
                    refetch={props.refetch} />
                ) : null}
              </Grid.Column>
            </Grid.Row>
            {/* Detail */}
            <Grid.Row columns={2} style={{ marginBottom: "30px" }}>
              <Grid.Column width={11}>
                <p style={descriptionStyle}>
                  {CLEAN_TEXT.UNICODE_TO_STRING(description)}
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {/* Batches */}
          <Grid style={section2}>
            {/* Header */}
            <Grid.Row style={{ marginTop: "40px" }}>
              <Grid.Column width={12} style={{ marginBottom: "10px" }}>
                <Header as="h3" style={header}>
                  Actividades
                </Header>
              </Grid.Column>
              <Grid.Column
                floated="right"
                textAlign="left"
                style={newBatchButton}>
                {
                  canCreateAndEdit &&
                  <PlusButton
                    secondary
                    name="create"
                    open={() => setBoolean(prevState => !prevState)}
                    style={{ cursor: "pointer" }} />
                }
                {boolean ? (
                  <CreateActivity
                    open={boolean}
                    openModal={() => setBoolean(prevState => !prevState)}
                    close={() => setBoolean(prevState => !prevState)}
                    objectiveId={id}
                    refetch={props.refetch} />
                ) : null}
              </Grid.Column>
            </Grid.Row>
            {/* Batchs row */}
            <Grid.Row>
              <Table unestackable style={batchesTable}>
                <Table.Body>
                  <Table.Row style={{ backgroundColor: "#F7F9FC" }}>
                    <Table.Cell
                      style={{ padding: "15px" }}
                      textAlign="left"
                      width={8}>
                      Nombre de actividad
                    </Table.Cell>
                    <Table.Cell
                      style={{ padding: "15px" }}
                      width={6}
                      textAlign="center">
                      Tipo de actividad
                    </Table.Cell>
                    <Table.Cell style={{ padding: "5px" }} width={1} />
                  </Table.Row>
                  {/* THIS MAP GENERATES THE ROWS OF THE OBJETIVE ACTIVITIES*/}
                  {activities.map(
                    (
                      {
                        name,
                        description,
                        activityType,
                        id,
                        files
                      },
                      index
                    ) => {
                      return (
                        <Table.Row key={index}>
                          <Table.Cell
                            style={{ padding: "15px" }}
                            textAlign="left"
                            width={8}>
                            {name}
                          </Table.Cell>
                          <Table.Cell
                            style={{ padding: "15px" }}
                            width={6}
                            textAlign="center">
                            <Button
                              color={colorActivityTypes(activityType)}
                              content={activityTypes(activityType)} />
                          </Table.Cell>
                          <Table.Cell width={1} textAlign="center">
                            {
                              canCreateAndEdit &&
                              <PencilIcon
                                openEditModalActivity={openEditModalActivity}
                                editModalActivity={editModalActivity}
                                onClick={() => {
                                  openEditModalActivity({
                                    active: !editModalActivity.active,
                                    data: {
                                      name,
                                      description,
                                      activityType,
                                      id,
                                      files
                                    }
                                  });
                                }}
                                name="edit"
                                style={{ cursor: "pointer" }} />
                            }
                          </Table.Cell>
                          <Table.Cell style={{ padding: "5px" }} width={1} />
                        </Table.Row>
                      );
                    }
                  )}
                </Table.Body>
              </Table>
            </Grid.Row>
          </Grid>
          {editModalActivity.active ? (
            <SessionContext.Consumer>
              {({ role, id }) =>
                <EditActivity
                  editable={
                    role === "SUPER_ADMIN" ?
                    true
                    : (id === lodash.get(
                      props,
                      "organismData.coordinator.id",
                      undefined))}
                  openModal={editModalActivity.active}
                  closeModal={openEditModalActivity}
                  activityData={editModalActivity.data}
                  refetch={props.refetch} />
            }
            </SessionContext.Consumer>
          ) : null}
        </Container>
      ) : (
        <div>No hay objetivos aun</div>
      )}
    </div>
  );
};
