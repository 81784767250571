export default {
  form1: [
    {
      field: "fullName",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "gender",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "phone",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "area",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "status",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "systemRole",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
  ],
  form2: [
    {
      field: "graduationDate",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "school",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "career",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "enrollment",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "category",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
  ],
  form3: [
    {
      field: "email",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "password",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "password_confirm",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
  ],
  form3Edit: [
    {
      field: "email",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
  ],
};
