import React, { Fragment } from "react";
import _ from "lodash";
import { Item } from "semantic-ui-react";

export default ({data}) => {
  return (
    <Fragment>
      <p style={{ fontSize: "15px", marginLeft: "9px" }}>
        Información general
      </p>
      <div
        style={{
          borderBottom: "1px solid #F0F0F0",
          padding: "8px"
        }}>
        <Item style={{ display: "inline-block" }}>
          <Item.Content>
            <Item.Header
              style={{ color: "#999999", marginBottom: "7px" }}>
              Coordinador
            </Item.Header>
            <Item.Meta>
              {data?.organism?.coordinator?.fullName || "N/A"}
            </Item.Meta>
          </Item.Content>
        </Item>
        <Item
          style={{ display: "inline-block", marginLeft: "25px" }}>
          <Item.Content>
            <Item.Header
              style={{ color: "#999999", marginBottom: "7px" }}>
              Teléfono
            </Item.Header>
            <Item.Meta>
              { _.get(data, "organism.coordinator.phone", "N/A") }
            </Item.Meta>
          </Item.Content>
        </Item>
      </div>
    </Fragment>
  );
};
