import React from "react";
import C3Chart from "react-c3js";
import "c3/c3.css";
import { GridLayout } from "../../organisms";
import { useQuery } from "react-apollo-hooks";
import { GET_ALL_ODES } from "../../../graphql/queries/odes";
import industries from "../../../helpers/industries";
import EmptyMessage from "./emptyMessage";

export default ({filterVariables}) => {
  const { loading, error, data } = useQuery(GET_ALL_ODES(filterVariables), {
                                              variables: filterVariables
                                            });

  const getDataSource = data => {
    if(!data)
      return [];

    return industries.map(industry => {
      return [
        industry.text,
        (filterODEs(data.oDEs, industry.value) || []).length
      ];
    });
  };

  const filterODEs = (odes, industry) => {
    const filteredODEs = (Array.isArray(odes) ? odes : [])
                          .filter(ode => ode.industry === industry);
    return (filteredODEs || []);
  };

  const donutConf = {
    title: "Proyectos",
    label: {
      format: value => value
    }
  };

  const chartDataSource = getDataSource(data);
  const projects = chartDataSource ?
    chartDataSource.reduce((acc, curr) => acc + curr[1], 0) : 0;

  return (
    <GridLayout>
      <GridLayout.Item
        isLoading={loading}
        hasErrors={error}>
        {
          projects > 0 ?
            <C3Chart
              legend={{show: false}}
              data={{
                columns: chartDataSource,
                type: "donut"
              }}
              donut={donutConf} />
            : <EmptyMessage />
        }
      </GridLayout.Item>
    </GridLayout>
  );
};
