export default [
  {
    field: "ode",
    method: "isEmpty",
    validWhen: false,
    message: ""
  },
  {
    field: "expertServiceId",
    method: "isEmpty",
    validWhen: false,
    message: ""
  },
  {
    field: "startDate",
    method: "isEmpty",
    validWhen: false,
    message: ""
  },
  {
    field: "endDate",
    method: "isEmpty",
    validWhen: false,
    message: ""
  },
  {
    field: "duration",
    method: "isEmpty",
    validWhen: false,
    message: ""
  }
];
