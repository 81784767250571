import React from "react";
import { Form, Header, Input, Dropdown } from "semantic-ui-react";
import { CONSTANTS } from "../../../helpers";

export default () => {
  const { OPTIONS: { GENDER_OPTIONS, AREA_OPTIONS } } = CONSTANTS;

  return (
    <>
      <Header as="h3">Datos generales</Header>
      <Form.Field
        control={Input}
        label="Nombre completo"
        type="text"
        name="fullName"
        required />
      <Form.Field
        control={Dropdown}
        label="Género"
        name="gender"
        selection
        options={GENDER_OPTIONS}
        required />
      <Form.Field
        control={Dropdown}
        label="Area de especialidad"
        name="area"
        options={AREA_OPTIONS}
        required
        selection />
      <Form.Field
        control={Input}
        label="Teléfono"
        name="phone"
        type="text"
        required />
    </>
  );
};
