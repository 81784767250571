export default [
  { key: "AGU", value: "AGU", text: "Aguascalientes" },
  { key: "BCN", value: "BCN", text: "Baja California" },
  { key: "BCS", value: "BCS", text: "Baja California Sur" },
  { key: "CAM", value: "CAM", text: "Campeche" },
  { key: "CHP", value: "CHP", text: "Chiapas" },
  { key: "CHH", value: "CHH", text: "Chihuahua" },
  { key: "CMX", value: "CMX", text: "Ciudad de México" },
  { key: "COA", value: "COA", text: "Coahuila" },
  { key: "COL", value: "COL", text: "Colima" },
  { key: "DUR", value: "DUR", text: "Durango" },
  { key: "GUA", value: "GUA", text: "Guanajuato" },
  { key: "GRO", value: "GRO", text: "Guerrero" },
  { key: "HID", value: "HID", text: "Hidalgo" },
  { key: "JAL", value: "JAL", text: "Jalisco" },
  { key: "MEX", value: "MEX", text: "México" },
  { key: "MIC", value: "MIC", text: "Michoacán" },
  { key: "MOR", value: "MOR", text: "Morelos" },
  { key: "NAY", value: "NAY", text: "Nayarit" },
  { key: "NLE", value: "NLE", text: "Nuevo León" },
  { key: "OAX", value: "OAX", text: "Oaxaca" },
  { key: "PUE", value: "PUE", text: "Puebla" },
  { key: "QUE", value: "QUE", text: "Querétaro" },
  { key: "ROO", value: "ROO", text: "Quintana Roo" },
  { key: "SLP", value: "SLP", text: "San Luis Potosí" },
  { key: "SIN", value: "SIN", text: "Sinaloa" },
  { key: "SON", value: "SON", text: "Sonora" },
  { key: "TAB", value: "TAB", text: "Tabasco" },
  { key: "TAM", value: "TAM", text: "Tamaulipas" },
  { key: "TLA", value: "TLA", text: "Tlaxcala" },
  { key: "VER", value: "VER", text: "Veracruz" },
  { key: "YUC", value: "YUC", text: "Yucatán" },
  { key: "ZAC", value: "ZAC", text: "Zacatecas" },
];
