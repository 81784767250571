
const getUrlParams = () => ({
  organismId: window.location.pathname
    .split("orgid=")[1].split("_")[0],
  programId: window.location.pathname
    .split("programid=")[1].split("_")[0],
  batchId: window.location.pathname.split("batchid=")[1]
});

const getBreadcrumbPath = queryDataResult => {
  let breadcrumbPath = [{ label: "Organismos", href: "../organism" }];

  if(queryDataResult !== undefined) {
    const organism = queryDataResult.organism || {};
    const program = queryDataResult.program || {};
    const batch = queryDataResult.batch || {};

    const navigation = [
      {
        label: organism.name || "",
        href: `../organism/${organism.id || ""}`
      },
      {
        label: program.name || ""
      },
      {
        label: batch.name || "",
        active: true
      }
    ];

    breadcrumbPath = breadcrumbPath.concat(navigation);
  }
  return breadcrumbPath;
};

export { getUrlParams };
export { getBreadcrumbPath };
