import React from "react";
import { Search } from "semantic-ui-react";
import { normalizeSync } from "normalize-diacritics";

export class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.placeholder = props.placeholder || "Buscar";
    this.typingTimeout = undefined;
  }

  static defaultProps = {
    normalize: true
  };

  normalize = (string) => {
    return normalizeSync(string.toLowerCase());
  };

  onSearchChange = (e, { value }) => {
    if(this.typingTimeout)
      clearTimeout(this.typingTimeout);

    if(this.props.normalize === true) {
      value = this.normalize(value);
    }

    this.typingTimeout = setTimeout(() => {
      typeof this.props.onChange == "function" && this.props.onChange(value);
    }, 600);
  }

  render() {
    return (
      <Search placeholder={this.placeholder}
              onSearchChange={this.onSearchChange}
              showNoResults={false}
              input={{ icon: "search", iconPosition: "left" }}
              fluid />
    );
  }
}