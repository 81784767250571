export default {
  // Hides sidebar on small screens
  SIDEBAR_HIDDEN: () => {
    const width = window.innerWidth;
    switch (width) {
      case 1024:
        return "none";
    }
  },
  // Selected item width
  VERTICAL_LIST_WIDTH: () => {
    const width = window.innerWidth;
    switch (width) {
      case 1024:
        return "230px";
      case 1280:
        return "283px";
      case 1440:
        return "316px";
      case 2560:
        return "545px";
    }
  },
};
