const CAREER = {
  ENGINEERING: "Ingeniería",
  BUSINESS_MANAGEMENT: "Administración de empresas",
  INTERNATIONAL_BUSINESS: "Negocios internacionales",
  AMC: "Ambiente Construido/ Exploración",
  ESC: "Estudios Creativos / Exploración",
  ARQ: "Arquitecto",
  LAD: "Licenciado en Arte Digital",
  LDI: "Licenciado en Diseño",
  LUB: "Licenciado en Urbanismo",
  CIS: "Ciencias Sociales / Exploración",
  LEC: "Licenciado en Economía",
  LED: "Licenciado en Derecho",
  LRI: "Licenciado en Relaciones Internacionales",
  LTP: "Licenciado en Gobierno y Transformación Pública",
  LC: "Licenciado en Comunicación    ",
  LEI: "Licenciado en Innovación Educativa",
  LLE: "Licenciado en Letras Hispánicas   ",
  LPE: "Licenciado en Periodismo",
  LTM: "Licenciado en Tecnología y Producción Musical ",
  IBQ:
    "Ingeniería - Bioingeniería y Procesos Químicos (avenida) / Exploración",
  IAG: "Ingeniero en Biosistemas Agroalimentarios",
  IAL: "Ingeniero en Alimentos",
  IBT: "Ingeniero en Biotecnología",
  IDS: "Ingeniero en Desarrollo Sustentable",
  IQ: "Ingeniero Químico",
  ICI: "Ingeniería - Ciencias Aplicadas (avenida) / Exploración",
  IDM: "Ingeniero en Ciencia de Datos y Matemáticas",
  IFI: "Ingeniero Físico Industrial",
  INA: "Ingeniero en Nanotecnología",
  ICT:
    "Ingeniería - Computación y Tecnologías de Información (avenida) / Exploración",
  IRS: "Ingeniero en Robótica y Sistemas Digitales",
  ITC: "Ingeniero en Tecnologías Computacionales",
  ITD: "Ingeniero en Transformación Digital de Negocios",
  IIT: "Ingeniería - Innovación y Transformación (avenida) / Exploración",
  IC: "Ingeniero Civil",
  IE: "Ingeniero en Electrónica",
  IID: "Ingeniero en Innovación y Desarrollo",
  IIS: "Ingeniero Industrial y de Sistemas",
  IM: "Ingeniero Mecánico",
  IMD: "Ingeniero Biomédico",
  IMT: "Ingeniero en Mecatrónica",
  SLD: "Salud / Exploración",
  LBC: "Licenciado en Biociencias",
  LNB: "Licenciado en Nutrición y Bienestar Integral",
  LPS: "Licenciado en Psicología Clínica y de la Salud",
  MC: "Médico Cirujano",
  MO: "Médico Cirujano Odontólogo",
  NEG: "Negocios / Exploración",
  BGB: "Licenciado en Negocios Internacionales",
  LAE: "Licenciado en Estrategia y Transformación de Negocios",
  LAF: "Licenciado en Finanzas",
  LCPF: "Licenciado en Contaduría Pública y Finanzas",
  LDE: "Licenciado en Emprendimiento",
  LDO: "Licenciado en Desarrollo de Talento y Cultura Organizacional",
  LEM: "Licenciado en Mercadotecnia",
  LIN: "Licenciado en Negocios Internacionales",
  LIT: "Licenciado en Inteligencia de Negocios"
};

export default CAREER;
