import React from "react";
import { DetailHeading } from "../../../../molecules";
import SessionContext from "../../../../../context/userSessionContext";
import { Dropdown, Button } from "semantic-ui-react";
import { filterOptions } from "./helpers";


export default ({handleFilterChange, setModalState}) => {

  const dropdownBorder = {
    borderRadius: "5px",
    background: "#F7F9FC",
    color: "#999"
  };

  const addButtonStyle = {
    background: "#EBE6FB",
    border: "0.4px solid rgba(89, 54, 207, 0.25)",
    color: "#5936CF",
    borderRadius: "100px"
  };

  return (
    <DetailHeading>
      <DetailHeading.LeftContainer>
        <Dropdown
          selection
          defaultValue="all"
          options={filterOptions}
          onChange={handleFilterChange}
          style={dropdownBorder} />
      </DetailHeading.LeftContainer>
      <DetailHeading.RightContainer>
        <SessionContext.Consumer>
          {({ role }) =>
            role === "SUPER_ADMIN" ||
            role === "COORDINATOR" ||
            role === "ADVISER" ?
              (<Button
                primary
                onClick={() => setModalState({ isOpen: true })}
                style={addButtonStyle}>
                Agregar actividad
              </Button>) : null
          }
        </SessionContext.Consumer>
      </DetailHeading.RightContainer>
    </DetailHeading>
  );
};
