import React, { useState } from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import {
  Container,
  Segment,
  Dimmer,
  Grid,
  Header,
  Image,
  Loader
} from "semantic-ui-react";
import Identicon from "react-identicons";
import { Redirect } from "react-router-dom";
import { Dashboard, DetailComponent, EditInstitution } from "../../organisms";
import { EditButton } from "../../atoms";
import SessionContext from "../../../context/userSessionContext";
import _ from "lodash";

const institutionId = window.location.pathname.split("inst=")[1];

const getBreadcrumbPath = institutionName => [
  {
    label: "Instituciones",
    href: "../instituciones"
  },
  {
    label: institutionName,
    active: true
  }];

const mapZone = zone => {
  const map = {
    NORTH: "Norte"
  };
  return map[zone];
};

const determineIfCanEdit = (role, userId, institutionCoordinatorId) => {
  switch (role) {
    case "SUPER_ADMIN":
        return true;
    case "COORDINATOR":
      return (userId === institutionCoordinatorId) ? true : false;
    default:
      return false;
  }
};

const Info = ({ data, editModal, handleEditModal, refetch }) => (
  <div className="details-info-tab">
    <Grid style={{ width: "100%" }}>
      <Grid.Row columns={3}>
        <Grid.Column width={2}>
          <span>
            {data.avatarUri ? (
              <Image
                src={data.avatarUri}
                width={100}
                circular />
            ) : (
              <Identicon string={"randomness"} size={40} />
            )}
          </span>
        </Grid.Column>
        <Grid.Column width={6}>
          <Header as="h1">{data.name}</Header>
        </Grid.Column>
        <Grid.Column
          width={2}
          floated="right"
          textAlign="right">
          <SessionContext.Consumer>
            {({role, id}) => {
              if(determineIfCanEdit(role, id, data.coordinator.id)) {
                return <EditButton
                  open={() => handleEditModal(prevState => !prevState)} />;
              }
              else
                return null;
            }}
          </SessionContext.Consumer>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Container style={{ borderBottom: "1px solid #dddddd", padding: "10px" }}>
      <p style={{ fontSize: "15px", color: "#999999" }}>
        Descripción de la institución
      </p>
      <p style={{ fontSize: "15px", color: "#323C47" }}>
        {unescape(data.description)}
      </p>
    </Container>
    <Container style={{ borderBottom: "1px solid #dddddd", padding: "10px" }}>
      <p style={{ fontSize: "18px", color: "#323C47" }}>Información general</p>
      <Grid columns={3}>
        <Grid.Column width={2} textAlign="left">
          <p style={{ color: "#999999", fontSize: "13px" }}>Zona</p>
          <p style={{ fontSize: "15px", color: "#323C47" }}>
            {mapZone(data.zone)}
          </p>
        </Grid.Column>
        <Grid.Column width={2} textAlign="left">
          <p style={{ color: "#999999", fontSize: "13px" }}>Coordinador</p>
          <p style={{ fontSize: "15px", color: "#323C47" }}>
            {data?.coordinator?.fullName || "N/A"}
          </p>
        </Grid.Column>
        <Grid.Column width={2} textAlign="left">
          <p style={{ color: "#999999", fontSize: "13px" }}>Dirección</p>
          <p style={{ fontSize: "15px", color: "#323C47" }}>{data.address}</p>
        </Grid.Column>
      </Grid>
    </Container>
    <EditInstitution
      idInstitution={institutionId}
      isOpen={editModal}
      onSave={() => { refetch(); }}
      onClose={() => {
        handleEditModal(prevState => !prevState);
      }} />
  </div>
);

export default () => {
  const [editModal, handleEditModal] = useState(false);

  return (
    <Query
      query={gql`
        {
          institution(where:{
              id:"${institutionId}"
              }
              ){
                id
                name
                instID
                avatarUri
                description
                zone
                coordinator{
                  id
                  fullName
                }
                address
                organisms{
                  id
                }
              }
        }
      `}
      fetchPolicy="network-only">
      {({ loading, error, data, refetch }) => {
        return (<Dashboard
          breadcrumbPath={
            getBreadcrumbPath(_.get(data, "institution.name", ""))
          }
          active="institution">
          {
            renderContent(
              loading,
              error,
              data,
              refetch,
              editModal,
              handleEditModal
          )}
        </Dashboard>);
      }}
    </Query>
  );
};

const renderContent = (
  loading,
  error,
  data,
  refetch,
  editModal,
  handleEditModal
  ) => {
  if (loading) {
    return (
      <Segment className="Ode__Detail__ContainerTabs">
        <Dimmer>
          <Loader />
        </Dimmer>
      </Segment>
    );
  }
  if (error) {
    return <p>Error...</p>;
  }

  if (data.institution === null) {
    return <Redirect to="/404" />;
  }

  return (
    <DetailComponent
      leftContent={
        <Info
          data={data.institution}
          editModal={editModal}
          handleEditModal={handleEditModal}
          refetch={refetch} />
      } />
  );
};
