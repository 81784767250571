import gql from "graphql-tag";
import { client } from "../../index";

export default async ({ odeId, userId, role, teamRelationId }) => {
  await client.mutate({
    variables: {odeId, userId, role, teamRelationId},
    mutation: gql`
      mutation(
        $odeId: ID!,
        $userId: ID!,
        $role: String!,
        $teamRelationId: ID!) {
        updateODE(
          where: {
            id: $odeId
          },
          data: {
            team: {
              update: { # Update specific User
                where: { id: $userId }
                data: {
                  odes : {
                    update: {
                      where: { id: $teamRelationId },
                      data: {
                        rol: $role
                      }
                    }
                  }
                }
              }
            }
          }
        ){
          id
        }
      }
    `
  });
};
