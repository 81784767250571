import React from "react";
import {
  Confirm,
  Container
} from "semantic-ui-react";
import {
  CreateOrganism,
  Dashboard,
  EntityToolbar,
  EntityList
} from "../../organisms";
import headers from "../../organisms/organismsList/headers";
import SessionContext from "../../../context/userSessionContext";
import { default as TableBody } from "./components/tableBody";
import {
  ORGANISMS,
  COORDINATOR_ORGANISMS
} from "../../../graphql/queries/organisms";
import { EditOrganism } from "../../organisms";
import { DELETE_ORGANISM } from "../../../graphql/mutations/organisms";
import { breadcrumbPath } from "./helpers";

export default class OrganismsListView extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      openModal: false,
      closeModal: false,
      editModal: false,
      organismId: "",
      confirm: false,
      userData: {},
      shouldRefetch: false,
      // This properties are used for the pagination
      first: 10,
      skip: 0,
      current: 1,
      searchQuery: "",
      orderBy: null,
      organismData: {},
      organisms: []
    };

    this.onSearchChange = this.onSearchChange.bind(this);
    this.onChangeOrderBy = this.onChangeOrderBy.bind(this);
    this.onPrev = this.onPrev.bind(this);
    this.onNext = this.onNext.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.requestRefetch = this.requestRefetch.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onRowEvent = this.onRowEvent.bind(this);
    this.getQuery = this.getQuery.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
  }

  onSearchChange (value) {
    this.setState({ searchQuery: value || "" });
  }

  onChangeOrderBy (orderBy) {
    this.setState(data => ({ ...data, orderBy }));
  }

  onPrev () {
    if (this.state.current > 1) {
      const skip = this.state.skip - 10;
      const current = this.state.current - 1;
      this.setState(data => ({
        ...data,
        current: current,
        skip: skip
      }));
    }
  }

  onNext (organisms, totalRecords) {
    if (
      organisms.length > 0 &&
      (organisms ? organisms.length === 10 : false)
    ) {
      if((this.state.skip + 10) < totalRecords) {
        const skip = this.state.skip + 10;
        const current = this.state.current + 1;

        this.setState(data => ({
          ...data,
          current: current,
          skip: skip
        }));
      }
    }
  }

  openModal () {
    this.setState(data => ({ ...data, openModal: true }));
  }

  closeModal () {
    this.setState(data => ({ ...data, openModal: false, userData: {} }));
  }

  requestRefetch (refetch) {
    if (this.state.shouldRefetch) {
      this.state.shouldRefetch = false;
      refetch();
    }
  }

  onEdit (args) {
    this.setState({
      editModal: true,
      requestRefetch: true,
      organismId: args.organismId || ""
    });
  }

  onDelete (args) {
    this.setState({
      confirm: true,
      organismId: args.organismId || ""
    });
  }

  onRowEvent (e) {
    switch(e.type || "") {
      case "onEdit":
        this.onEdit(e.params || {});
        break;
      case "onDelete":
          this.onDelete(e.params || {});
        break;
      default:
        // what to do?
        break;
    }
  }

  getQuery (systemRole) {
    switch (systemRole) {
      case "SUPER_ADMIN":
        return ORGANISMS;
      case "COORDINATOR":
        return COORDINATOR_ORGANISMS;
      default:
        return null;
    }
  }

  closeEditModal () {
    this.setState({ editModal: false });
  }

  render() {
    return (
      <SessionContext.Consumer>
        {({ role, id }) => {
          if (role) {
            return role === "SUPER_ADMIN" || role === "COORDINATOR" ? (
              <Dashboard
                breadcrumbPath={breadcrumbPath}
                active="organisms">
                <Container className="Body__Container">
                  <EntityToolbar
                    addText="Nuevo organismo"
                    searchLabel="Buscar"
                    onSearchChange={this.onSearchChange}
                    onAdd={(role === "SUPER_ADMIN") && this.openModal} />
                  {/* Organisms list */}
                  <EntityList
                    query={{
                      query: this.getQuery(role),
                      variables: {
                        userId: id,
                        first: this.state.first,
                        skip: this.state.skip,
                        filter: this.state.searchQuery,
                        orderBy: this.state.orderBy
                      },
                      fetchPolicy: "network-only",
                      errorPolicy: "ignore",
                      pollInterval: 10000
                    }}
                    paginationLabel="organismos"
                    tableBodyComponent={<TableBody />}
                    headers={headers}
                    requestRefetch={this.requestRefetch}
                    onRowEvent={this.onRowEvent}
                    onClickRow={this.onClickRow}
                    propertyKey="organisms"
                    current={this.state.current}
                    onPrev={this.onPrev}
                    onNext={this.onNext} />
                </Container>
                {/* Create organism modal */}

                {this.state.openModal && (
                  <CreateOrganism
                    openModal={this.state.openModal}
                    onClose={this.state.closeModal}
                    close={this.closeModal} />
                )}

                {this.state.editModal && (
                  <EditOrganism
                    organismId={this.state.organismId}
                    openModal={this.state.editModal}
                    close={this.closeEditModal} />
                )}
                {this.state.confirm ? (
                  <Confirm
                    open={this.state.confirm}
                    onCancel={() => { this.setState({ confirm: false }); }}
                    content="Esta acción no es reversible"
                    onConfirm={async () => {
                      await DELETE_ORGANISM(this.state.organismId);
                      this.setState({ confirm: false });
                    }} />
              ) : null}
              </Dashboard>
            ) : (
              (window.location = "/admin/ode")
            );
          }
        }}
      </SessionContext.Consumer>
    );
  }
}
