export function onCreate(service) {
  this.setState({isModalOpen: false, requestRefetch: true});

  /* TODO: Remove page refresh after editing assigned service */
  if(service){
    const expertId = service.expert.id;
    const serviceId = service.id;
    this.props.history.push(`/admin/experts/${expertId}`+
      `/assigned-services/${serviceId}`);
    window.location.reload();
  }
}
