export default [
  { key: "AGROTECH", text: "Agrotech", value: "AGROTECH" },
  { key: "AI_AND_ROBOTICS", text: "AI & Robotics", value: "AI_AND_ROBOTICS" },
  { key: "AUTOMOTIVE_TECH", text: "Automotive Tech", value: "AUTOMOTIVE_TECH" },
  { key: "CHEMICAL_TECH", text: "Chemical Tech", value: "CHEMICAL_TECH" },
  {
    key: "CONSTRUCTION_TECH",
    text: "Construction Tech",
    value: "CONSTRUCTION_TECH",
  },
  {
    key: "CORPORATE_TECH",
    text: "Coorporate Tech",
    value: "CORPORATE_TECH",
  },
  { key: "E_COMMERCE_TECH", text: "E-Commerce Tech", value: "E_COMMERCE_TECH" },
  { key: "ED_TECH", text: "ED Tech", value: "ED_TECH" },
  {
    key: "ENERGY_AND_ENVIROMENTAL",
    text: "Energy and Enviromental",
    value: "ENERGY_AND_ENVIROMENTAL",
  },
  { key: "TECH", text: "Tech", value: "TECH" },
  {
    key: "ENTERTAINING_TECH",
    text: "Entertaining Tech",
    value: "ENTERTAINING_TECH",
  },
  { key: "FASHION", text: "Fashion", value: "FASHION" },
  { key: "FINTECH", text: "Fintech", value: "FINTECH" },
  { key: "FOOD", text: "Food", value: "FOOD" },
  { key: "GOVERNMENT", text: "Government", value: "GOVERNMENT" },
  { key: "HEALTH_TECH", text: "Health ", value: "HEALTH_TECH" },
  { key: "HR_TECH", text: "HR Tech ", value: "HR_TECH" },
  { key: "INSURANCE_TECH", text: "Insurance Tech", value: "INSURANCE_TECH" },
  { key: "IOT", text: "IoT", value: "IOT" },
  { key: "LEGAL_TECH", text: "Legal Tech", value: "LEGAL_TECH" },
  {
    key: "MANUFACTORING_TECH",
    text: "Manufacturing Tech",
    value: "MANUFACTORING_TECH",
  },
  { key: "MARKETING_TECH", text: "Marketing Tech", value: "MARKETING_TECH" },
  { key: "MINING_TECH", text: "Mining Tech", value: "MINING_TECH" },
  { key: "NANO_TECH", text: "Nano Tech", value: "NANO_TECH" },
  {
    key: "NETWORKS_AND_COMPUTING",
    text: "Networks and Computing",
    value: "NETWORKS_AND_COMPUTING",
  },
  { key: "SYSTEMS", text: "Systems", value: "SYSTEMS" },
  { key: "REAL_STATE_TECH", text: "Real State Tech", value: "REAL_STATE_TECH" },
  { key: "RETAIL_TECH", text: "Retail Tech", value: "RETAIL_TECH" },
  { key: "SECURITY_TECH", text: "Security Tech", value: "SECURITY_TECH" },
  { key: "SOCIAL", text: "Social", value: "SOCIAL" },
  {
    key: "SYNTHETIC_BIOLOGY",
    text: "Synthetic Biology",
    value: "SYNTHETIC_BIOLOGY",
  },
  { key: "TELECOM_TECH", text: "Telecom Tech", value: "TELECOM_TECH" },
  { key: "TOURISM_TECH", text: "Tourism Tech", value: "TOURISM_TECH" },
  {
    key: "TRANSPOTATION_TECH",
    text: "Transportation Tech",
    value: "TRANSPOTATION_TECH",
  },
  { key: "HR_TECH", text: "Human Resources Tech", value: "HR_TECH" },
  { key: "SOFTWARE", text: "Software", value: "SOFTWARE" }
];
