export default [
  {
    "key": "0",
    "value": "PENDING",
    "text": "Pendiente",
    "color": "yellow"
  },
  {
    "key": "1",
    "value": "FINISHED",
    "text": "Finalizado",
    "color": "green"
  },
  {
    "key": "2",
    "value": "ASSIGNED",
    "text": "Asignado",
    "color": "grey"
  },
  {
    "key": "3",
    "value": "ACCEPTED",
    "text": "Aceptado",
    "color": "yellow"
  },
  {
    "key": "4",
    "value": "IN_PROGRESS",
    "text": "En curso",
    "color": "orange"
  },
  {
    "key": "5",
    "value": "DELAYED",
    "text": "Retrasado",
    "color": "pink"
  },
  {
    "key": "6",
    "value": "AUTHORIZED",
    "text": "Aut. para pago",
    "color": "blue"
  },
  {
    "key": "7",
    "value": "PAID",
    "text": "Pagado",
    "color": "purple"
  }
];
