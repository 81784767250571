import React, { useState } from "react";
import { Form, Input, Button, Header } from "semantic-ui-react";
import errors from "../errors";
import { FormValidator, urlVerifierTools } from "../../../../helpers";
import validations from "../validations";
import { DeleteButton } from "../../../atoms";
import { validateUrls } from "../../createODE/components/manual/helpers";


export default props => {
  const {
    turnoffautocomplete,
    refetch,
    onClose,
    update,
    data,
    handlechange,
  } = props;
  const [errors3, setErrors1] = useState(errors.section3); // form section 1

  const setErrors = (errors3, setErrors, validation) => {
    Object.keys(errors3).map(error => {
      errors3[error] = validation[error]["isInvalid"];
    });
    setErrors({ ...errors3 });
  };

  const validateFrom = form => {
    const validator = new FormValidator(validations[form]);
    const validation = validator.validate(data);
    return validation;
  };

  const _update = async () => {
    const validation = validateFrom("section3");

    if (validation.isValid) {
      setErrors(errors3, setErrors1, validation);
      await update(data);
    } else {
      setErrors(errors3, setErrors1, validation);
    }
  };

  return (
    <Form className="Padded__Form">
      <Header>Contacto de empresa</Header>
      <Form.Group widths="equal">
        <Form.Field
          name="website"
          label="Página web"
          control={Input}
          onChange={handlechange}
          error={urlVerifierTools.isValidDomain(data.website || "") ?
            false : errors3.website}
          defaultValue={data.website}
          onFocus={turnoffautocomplete}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          name="facebook"
          label="Facebook"
          control={Input}
          onChange={handlechange}
          defaultValue={data.facebook}
          error={urlVerifierTools.isValidDomain(data.facebook || "") ?
            false : errors3.facebook}
          onFocus={turnoffautocomplete}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          name="twitter"
          label="Twitter"
          control={Input}
          defaultValue={data.twitter}
          onChange={handlechange}
          error={urlVerifierTools.isValidDomain(data.twitter || "") ?
            false : errors3.twitter}
          onFocus={turnoffautocomplete}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          name="instagram"
          label="Instagram"
          defaultValue={data.instagram}
          control={Input}
          onChange={handlechange}
          error={urlVerifierTools.isValidDomain(data.instagram || "") ?
            false : errors3.instagram}
          onFocus={turnoffautocomplete}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          name="email"
          label="Correo electrónico"
          defaultValue={data.email}
          control={Input}
          onChange={handlechange}
          error={errors3.email}
          required
          onFocus={turnoffautocomplete}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <DeleteButton del={props.deleteODE} />
        <Button
          loading={props.loading}
          type="button"
          id="ode-form-next1"
          onClick={_update}
          primary
          fluid
          style={{ width: "30%", marginLeft: "250px" }}
        >
          Guardar
        </Button>
      </Form.Group>
    </Form>
  );
};
