import React, { useState, useContext } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Grid, Image, Dimmer, Loader } from "semantic-ui-react";
import { PlusButton } from "../../../atoms";
import { ConnectBatchToOde } from "../../../organisms";
import styles from "../styles";
import {
  calculateOdeGeneralProgress
} from "../../odeDetail/helpers/calculateProgress";
import lodash from "lodash";
import SessionContext from "../../../../context/userSessionContext";
import { verifyUserPermission } from "../../../../helpers/permissionsValidator";
import { BatchOdeItem } from "./batch-ode-item";

const { batchOdesHeader, addOdeButtonHolder, odePicture } = styles;

const BATCH_ODES = gql`
  query($organismId: ID!, $programId: ID!, $batchId: ID!) {
    organism(where: { id: $organismId }) {
      institution {
        coordinator {
          id
        }
      }
      program(where: { id: $programId }) {
        batches(where: { id: $batchId }) {
          id
          odes {
            id
            name
            activities {
              id
              isComplete
            }
          }
        }
      }
    }
  }
`;
export default () => {
  const [modal, setModal] = useState(false);
  const organismId = window.location.pathname.split("orgid=")[1].split("_")[0];
  const programId = window.location.pathname
    .split("programid=")[1]
    .split("_")[0];
  const batchId = window.location.pathname.split("batchid=")[1];
  const { id, role } = useContext(SessionContext);

  return (
    <Query
      query={BATCH_ODES}
      variables={{ organismId, programId, batchId }}
      fetchPolicy="no-cache">
      {({ loading, error, data, refetch }) => {

        if (loading) {
          return (
            <Grid.Row columns={1} padded>
              <Grid.Column textAlign="center">
                <Dimmer>
                  <Loader />
                </Dimmer>
              </Grid.Column>
            </Grid.Row>
          );
        }

        if (error) {
          return (
            <Grid.Row columns={1} padded>
              <Grid.Column textAlign="center">
                <Dimmer>
                  <Loader>Error</Loader>
                </Dimmer>
              </Grid.Column>
            </Grid.Row>
          );
        }

        const permissionRules = [
          { role: "SUPER_ADMIN" },
          {
            role: "COORDINATOR", // institutio coordinator
            id: lodash.get(data, "organism.institution.coordinator.id", "")
          },
          {
            role: "COORDINATOR", // organism coordinator
            id: lodash.get(data, "organism.coordinator.id", "")
          },
          {
            role: "COORDINATOR", // program coordinator
            id: lodash.get(data, "organism.program[0].coordinator.id")
          }
        ];

        const canCreateAndEdit = verifyUserPermission(
                                  permissionRules,
                                  { role, id });

        const odes = lodash.get(
                      data,
                      "organism.program[0].batches[0].odes",
                      []);
        return (
          <div>
            <Grid style={batchOdesHeader}>
              <Grid.Row>
                <Grid.Column floated="left" textAlign="left">
                  <p
                    style={{
                      color: "#212223",
                      fontSize: "13px",
                      paddingTop: "10px"
                    }}>
                    Compañías
                  </p>
                </Grid.Column>
                <Grid.Column floated="right" textAlign="right">
                  <div style={addOdeButtonHolder}>
                    {
                      canCreateAndEdit &&
                      <PlusButton
                        open={() => setModal(prevState => !prevState)} />
                    }
                    {modal ? (
                      <ConnectBatchToOde
                        open={modal}
                        close={() => setModal(prevState => !prevState)}
                        refetch={refetch} />
                    ) : null}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid
              className="batchDetail-batchOdesList-container"
              style={{ height: `${window.innerHeight - 160}px` }}>
              <Grid.Column style={{margin: "0px", padding: "0px"}}>
                {odes.map(({ id, name, activities }) => (
                  <>
                    <BatchOdeItem
                      key={`ode-list-item-${id}`}
                      name={name}
                      activities={activities} />
                  </>
                ))}
              </Grid.Column>
            </Grid>
          </div>
        );
      }}
    </Query>
  );
};
