import React, { Component } from "react";
import UserContext from "./userSessionContext";

export default class UserSessionProvider extends Component {
  state = {
    id: null,
    role: null,
  };

  componentDidMount() {
    this.setState({
      id: window.localStorage.getItem("id"),
      role: window.localStorage.getItem("role"),
    });
  }

  clearSession = () => {
    this.setState({
      id: null,
      role: null
    });
  };

  render() {
    const { role, id } = this.state;
    const clearSession = this.clearSession;
    return (
      <UserContext.Provider value={{ id, role, clearSession }}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
