import React, { useState } from "react";
import { Form, Input, Dropdown, Button, Header } from "semantic-ui-react";
import errors from "../errors";
import { FormValidator } from "../../../../helpers";
import validations from "../validations";
import { DeleteButton } from "../../../atoms";

const personTypeOptions = [
  { key: "FISICA", text: "Física", value: "FISICA" },
  { key: "MORAL", text: "Moral", value: "MORAL" },
];

export default props => {
  const { turnoffautocomplete, handlechange, data, update } = props;
  const [errors2, setErrors1] = useState(errors.section2); // form section 1
  const [errors2Foreign, setErrors2] = useState(errors.section2Foreign); // form section 1
  const nationalityCondition = data.nationality === "MX"; // Rule to determine fiscal data for the ode

  const setErrors = (errors2, setErrors, validation) => {
    Object.keys(errors2).map(error => {
      errors2[error] = validation[error]["isInvalid"];
    });
    setErrors({ ...errors2 });
  };

  const validateFrom = form => {
    const validator = new FormValidator(validations[form]);
    const validation = validator.validate(data);
    return validation;
  };

  const _update = async () => {
    const isNational = data["nationality"] === "MX" ? true : false;
    const validation = validateFrom(
      isNational ? "section2" : "section2Foreign"
    );

    if (validation.isValid) {
      setErrors(
        isNational ? errors2 : errors2Foreign,
        isNational ? setErrors1 : setErrors2,
        validation
      );
      await update(data);
    } else {
      setErrors(errors2, setErrors1, validation);
    }
  };
  return (
    <Form className="Padded__Form">
      <Header>Datos fiscales</Header>
      <Form.Group widths="equal">
        <Form.Field
          name="businessName"
          label="Razón social"
          control={Input}
          onChange={handlechange}
          error={data["businessName"] ? false : errors2.businessName}
          defaultValue={data.businessName}
          required
          onFocus={turnoffautocomplete}
        />
      </Form.Group>

      {nationalityCondition ? (
        <Form.Group widths="equal">
          <Form.Field
            name="personType"
            label="Tipo de persona"
            control={Dropdown}
            options={personTypeOptions}
            onChange={handlechange}
            selection
            defaultValue={data.personType}
            error={data["personType"] ? false : errors2.personType}
            required
          />
          <Form.Field
            name="rfc"
            label="RFC"
            onChange={handlechange}
            control={Input}
            defaultValue={data.rfc}
            error={data["rfc"] ? false : errors2.rfc}
            required
            onFocus={turnoffautocomplete}
          />
        </Form.Group>
      ) : (
        <Form.Group widths="equal">
          <Form.Field
            name="ein"
            label="EIN"
            control={Input}
            onChange={handlechange}
            defaultValue={data.ein !== "undefined" ? data.ein : ""}
            error={data["ein"] ? false : errors2.ein}
            required
            onFocus={turnoffautocomplete}
          />
          <Form.Field
            name="inc"
            label="INC"
            onChange={handlechange}
            control={Input}
            defaultValue={data.inc !== "undefined" ? data.inc : ""}
            error={data["inc"] ? false : errors2.inc}
            required
            onFocus={turnoffautocomplete}
          />
        </Form.Group>
      )}
      <Form.Group widths="equal">
        <Form.Field
          name="commercialAddress"
          label="Dirección comercial"
          defaultValue={data.commercialAddress}
          onChange={handlechange}
          control={Input}
          error={data["commercialAddress"] ? false : errors2.commercialAddress}
          required
          onFocus={turnoffautocomplete}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          name="physicalAddress"
          defaultValue={data.physicalAddress}
          label="Dirección fiscal"
          onChange={handlechange}
          control={Input}
          error={data["physicalAddress"] ? false : errors2.physicalAddress}
          required
          onFocus={turnoffautocomplete}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <DeleteButton del={props.deleteODE} />
        <Button
          loading={props.loading}
          type="button"
          id="ode-form-next1"
          onClick={_update}
          primary
          fluid
          style={{ width: "30%", marginLeft: "250px" }}
        >
          Guardar
        </Button>
      </Form.Group>
    </Form>
  );
};
