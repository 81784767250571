export default date => {
  return date !== null
    ? handleMonth(
        date
          .split("T")[0]
          .split("-")
          .reverse()
          .join("/")
      )
    : "";
};

const handleMonth = date => {
  const months = {
    "01": "ENR",
    "02": "FBR",
    "03": "MRZ",
    "04": "ABR",
    "05": "MAY",
    "06": "JUN",
    "07": "JUL",
    "08": "AGO",
    "09": "SEPT",
    "10": "OCT",
    "11": "NOV",
    "12": "DIC",
  };
  const day = date.split("/")[0];
  const month = date.split("/")[1];
  const year = date.split("/")[2];
  return `${day}/${months[month]}/${year}`;
};
