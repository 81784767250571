import React, { useContext } from "react";
import { Table } from "semantic-ui-react";
import SessionContext from "../../../../../context/userSessionContext";
import { getActivityDetailModalData } from "./helpers";
import { TableRow } from ".";

export default ({
  allODEActivities,
  filterState,
  setSelectedActivity,
  setTypeToDelete,
  setModalDetailState,
  modalDetailState,
  verifiyIfCanEdit,
  odeNetwork,
  handleConfirm,
  setDeleteId
}) => {
  const sessionContext = useContext(SessionContext);

  const handleOpenDetails = (odeActivity = {}) => {
    const odeActivityData = getActivityDetailModalData(odeActivity);
    setSelectedActivity(odeActivityData);
    setTypeToDelete(
      () => odeActivity.activity.activityType);
    setModalDetailState({
      isOpen: !modalDetailState.isOpen,
      isEditable: verifiyIfCanEdit(
        sessionContext.role,
        sessionContext.id,
        odeNetwork)
    });
  };

  return (
    <Table
      selectable
      striped
      style={{
        position: "static",
        display: "block",
        width: "100%",
        maxWidth:"100%",
        fontSize: "11px",
        marginBottom: "30pt"
      }}>
      <Table.Header style={{ textAlign: "left" }}>
        <Table.Row>
          <Table.HeaderCell>Nombre de actividad</Table.HeaderCell>
          <Table.HeaderCell>Batch</Table.HeaderCell>
          <Table.HeaderCell>Objetivo</Table.HeaderCell>
          <Table.HeaderCell>Fecha de inicio</Table.HeaderCell>
          <Table.HeaderCell>Fecha de fin</Table.HeaderCell>
          <Table.HeaderCell>Tipo de actividad</Table.HeaderCell>
          <Table.HeaderCell>Completada</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body style={{ textAlign: "left" }}>
        {allODEActivities.map(
          (
            odeActivity,
            index
          ) => {
            const isDelayed = false;

            return filterState === odeActivity.type ||
              filterState === "all" ||
              (odeActivity.isComplete && filterState === "completed") ||
              (!odeActivity.isComplete && filterState === "uncompleted") ||
              (!isDelayed && filterState === "ontime") ||
              (isDelayed && filterState === "delayed") ?
              (
                <TableRow
                  key={index}
                  odeActivity={odeActivity}
                  onClick={handleOpenDetails}
                  verifiyIfCanEdit={verifiyIfCanEdit}
                  handleConfirm={handleConfirm}
                  setDeleteId={setDeleteId}
                  odeNetwork={odeNetwork}
                  setTypeToDelete={setTypeToDelete} />
              ) : null;
          }
        )}
      </Table.Body>
    </Table>
  );

};
