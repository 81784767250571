import gql from "graphql-tag";
import { client } from "../../index";

export default async ({
  id,
  odeCategory,
  name,
  iname,
  sector,
  commercialBussines,
  nationality,
  state,
  city,
  registrationYear,
  businessName,
  personType,
  rfc,
  ein,
  inc,
  commercialAddress,
  physicalAddress,
  phase,
  technology,
  trl,
  industry,
  area,
  website,
  facebook,
  twitter,
  instagram,
  email,
}) => {
  await client.mutate({
    mutation: gql`
        mutation{
            updateODE(
            where:{
                id:"${id}"
            }
            data:{
                odeCategory:${odeCategory}
                name:"${name}"
                iname: "${iname}"
                sector:${sector}
                commercialBussines:${commercialBussines}
                nationality:${nationality}
                state:"${state}"
                city:"${city}"
                registrationYear:"${registrationYear}"
                businessName:"${businessName}"
                personType: ${personType}
                rfc:"${rfc}"
                ein:"${ein}"
                inc:"${inc}"
                commercialAddress:"${commercialAddress}"
                physicalAddress:"${physicalAddress}"
                phase:${phase}
                technology:"${technology}"
                trl:${trl}
                industry:${industry}
                area:"${area}"
                website:"${website}"
                facebook:"${facebook}"
                twitter:"${twitter}"
                instagram:"${instagram}"
                email:"${email}"
            }){
                id
            }
        }        
    `,
  });
};
