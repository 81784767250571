import React from "react";
import { Grid } from "semantic-ui-react";
import { MAP_TRL } from "../../../../../helpers";
import mapIndustries from "../../../../../helpers/mapIndustries";
import mapSectors from "../../../../../helpers/mapSectors";
import mapPhases from "../../../../../helpers/mapPhases";

export default ({odeData}) => {
  return (
    <div className="detail-section">
      <p style={{ fontSize: "15px" }}> Estado de ODE </p>
      <Grid columns={5}>
        <Grid.Column width={3} textAlign="left">
          <p style={{ color: "#999999" }}> Fase </p> {mapPhases(odeData.phase)}
        </Grid.Column>
        <Grid.Column width={3} textAlign="left">
          <p style={{ color: "#999999" }}> Tecnología </p> {odeData.technology}
        </Grid.Column>
        <Grid.Column width={3} textAlign="left">
          <p style={{ color: "#999999" }}> Nivel de TRL </p>
          {MAP_TRL(odeData.trl)}
        </Grid.Column>
        <Grid.Column width={3} textAlign="left">
          <p style={{ color: "#999999" }}> Industria </p>
          {mapIndustries(odeData.industry)}
        </Grid.Column>
        <Grid.Column width={3} textAlign="left">
          <p style={{ color: "#999999" }}> Sector </p>
          {mapSectors(odeData.sector)}
        </Grid.Column>
      </Grid>
    </div>
  );
};
