import moment from "moment";

export const initialState = {
  data: {
    id: undefined,
    ode: [],
    expertServiceId: "",
    startDate: moment().format(),
    endDate: moment().format(),
    selectedExpertService: undefined,
    status: "",
    duration: "",
    finished: moment().format()
  },
  errors: {
    ode: false,
    expertServiceId: false,
    startDate: false,
    endDate: false,
    duration: false
  }
};
