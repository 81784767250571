export default data => {
  const CITY = {
    AGU: "Aguascalientes",
    BCN: "Baja California",
    BCS: "Baja California Sur",
    CAM: "Campeche",
    CHP: "Chiapas",
    CHH: "Chihuahua",
    CMX: "Ciudad de México",
    COA: "Coahuila",
    COL: "Colima",
    DUR: "Durango",
    GUA: "Guanajuato",
    GRO: "Guerrero",
    HID: "Hidalgo",
    JAL: "Jalisco",
    MEX: "México",
    MIC: "Michoacán",
    MOR: "Morelos",
    NAY: "Nayarit",
    NLE: "Nuevo León",
    OAX: "Oaxaca",
    PUE: "Puebla",
    QUE: "Querétaro",
    ROO: "Quintana Roo",
    SLP: "San Luis Potosí",
    SIN: "Sinaloa",
    SON: "Sonora",
    TAB: "Tabasco",
    TAM: "Tamaulipas",
    TLA: "Tlaxcala",
    VER: "Veracruz",
    YUC: "Yucatán",
    ZAC: "Zacatecas",
  };
  return data in CITY ? CITY[data] : "N/A";
};
