import gql from "graphql-tag";
import { client } from "../../index";
import { ORGANISMS, ORGANISM } from "../../queries/organisms";

export default async ({
  id,
  organismID,
  name,
  institution,
  description,
  coordinator,
  type,
}) => {
  await client.mutate({
    mutation: gql`
      mutation {
        updateOrganism(
          where:{id:"${id}"}
          data: {
            organismID: "${organismID}"
            name: "${name}"
            institution: { connect: { id: "${institution}" } }
            description: "${description}"
            coordinator: { connect: { id: "${coordinator}" } }
            type: ${type}
          }
        ) {
          id
        }
      }
    `,
    refetchQueries: [{ query: ORGANISMS }, { query: ORGANISM, variables: {id: organismID} }],
  });
};
