import React from "react";
import { Dropdown } from "semantic-ui-react";
import { MenuButton } from "../../atoms";
import "./style.css";

export default props => {
  const { onRowEvent, rowData } = props;

  const onDelete = () => {
    onRowEvent({
      type: "onDelete",
      args: {
        id: rowData.id
      }
    });
  };

  const onEdit = () => {
    const dataCopy = {...props.rowData};
    onRowEvent({
      type: "onEdit",
      args: {
        data: dataCopy
      }
    });
  };

  const deleteAlert = () => {
    const msg = `Este usuario está asignado a un 
              batch u organismo y no puede ser eliminado.`;
    alert(msg);
  };

  const { organisms = [], batches = [] } = props.rowData;

  return (
    <React.Fragment>
      <Dropdown icon={<MenuButton />} className="icon pointing List__Menu">
        <Dropdown.Menu style={{ marginLeft: "-35px" }}>
          <Dropdown.Item
            icon="edit"
            text="Editar"
            onClick={onEdit}
          />
          {(organisms.length > 0|| batches.length > 0) ? (
            <Dropdown.Item
              icon="trash"
              text="Eliminar"
              onClick={deleteAlert} />
          ) : (
            <Dropdown.Item
              icon="trash"
              text="Eliminar"
              onClick={onDelete} />
          )}
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};
