import React from 'react';
import { Grid } from 'semantic-ui-react';

export default ({children}) => {
  return (
    <Grid.Column width={4} style={{ padding: "0" }}>
      <Grid celled="internally" padded>
        { children }
      </Grid>
    </Grid.Column>
  );
}
