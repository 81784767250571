import gql from "graphql-tag";

const getWhereConstraint = param => {

  switch(param) {
    case "program":
      return `(where: {
        batches_some: {
          program: {
            id: $programId
          }
        }
      })`;
    case "organism":
        return `(where: {
          batches_some: {
            program: {
              organism: {
                id: $organismId
              }
            }
          }
        })`;
    case "institution":
        return `(where: {
          batches_some: {
            program: {
              organism: {
                institution: {
                  id: $institutionId
                }
              }
            }
          }
        })`;
    default:
      return "";
  }
};

const getQueryParams = param => {
  switch(param) {
    case "program":
      return "($programId: ID)";
    case "organism":
      return "($organismId: ID)";
    case "institution":
      return "($institutionId: ID)";
    default:
      return "";
  }
};

const getFilteringCriteria = variables => {
  const { programId, organismId, institutionId } = variables;
  if (programId !== undefined)
    return "program";
  if (organismId !== undefined)
    return "organism";
  if (institutionId !== undefined)
    return "institution";

  return { param: "" };
};

const GET_ALL_ODES = variables => {
  const filterParams = getFilteringCriteria(variables);

  return gql`
    query ${getQueryParams(filterParams)} {
      oDEs ${getWhereConstraint(filterParams)} {
        id
        phase
        industry
        trl
        batches {
          id
          name
          program {
            organism {
              institution {
                id
              }
            }
          }
        }
        organisms {
          id
        }
      }
    }
  `;
};

export default GET_ALL_ODES;
