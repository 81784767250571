import React from "react";
import { Query } from "react-apollo";
import { Dropdown } from "semantic-ui-react";
import { USERS_DROP } from "../../../graphql/queries/users";

export default ({
  usersToExclude,
  systemRole,
  name,
  value,
  error,
  onChange,
  ...props }) => {

  return (
    <Query
      query={USERS_DROP}
      variables={ {exclude: usersToExclude} }
      fetchPolicy="network-only">
      {({ loading, error: queryError, data }) => {
        if (loading) return "Loading...";
        if (queryError) return `Error! ${queryError.message}`;

        const allowedUserRoles = Array.isArray(systemRole) ?
          systemRole : [systemRole];

        const options = data.users
          .reduce((users, userData, index) => {

            if(systemRole === undefined) {
              users.push({
                value: userData.id,
                text: userData.fullName,
                key: index
              });
            } else {
              const hasCoincidences = allowedUserRoles
                .find(role => userData.systemRole === role) !== undefined;

              if(hasCoincidences) {
                users.push({
                  value: userData.id,
                  text: userData.fullName,
                  key: index
                });
              }
            }

            return users;
          }, []);

        return (
          <Dropdown
            selection
            options={options}
            name={name}
            value={value}
            error={error}
            onChange={onChange}
            {...props} />
        );
      }}
    </Query>
  );
};
