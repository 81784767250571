export function onServicesLoad(data) {
  if(this.state.selectedService)
    return false;

  const { assignedExpertServiceId } = this.props;

  if(!data.assignedExpertServices)
    return false;

  let selectedService;

  if(!assignedExpertServiceId) {
    selectedService = data.assignedExpertServices[0];
  }
  else {
    selectedService = data
      .assignedExpertServices
      .find(i => i.id === `${assignedExpertServiceId}`);

    if(!selectedService)
      return false;
  }

  this.state.selectedService = selectedService;

  /* Execute after render pipeline */
  setTimeout(() => {
    this.forceUpdate();
  }, 1);
}
