
export const verifyUserPermission = (rules, data) =>  {
  if(!Array.isArray(rules))
    throw TypeError;

  let result = false;
  rules.forEach(({ role, id }) => {
    if(role === data.role) {
      if(id !== undefined) {
        if(id === data.id)
          result = true;
      }
      else {
        result = true;
      }
    }
  });

  return result;
};
