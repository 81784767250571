export default [
  {
    text: "N/A",
    value: "NA",
    key: "NA"
  },
  {
    text: "Tecnológico de Monterrey campus Aguascalientes",
    value: "AGU",
    key: "AGU"
  },
  {
    text: "Tecnológico de Monterrey campus Chihuahua",
    value: "CHH",
    key: "CHH"
  },
  {
    text: "Tecnológico de Monterrey campus Ciudad Obregón",
    value: "SON",
    key: "SON"
  },
  {
    text: "Tecnológico de Monterrey campus Guadalajara",
    value: "JAL",
    key: "JAL"
  },
  { text: "Tecnológico de Monterrey campus Laguna", value: "LAG", key: "LAG" },
  { text: "Tecnológico de Monterrey campus Morelia", value: "MIC", key: "MIC" },
  {
    text: "Tecnológico de Monterrey campus Saltillo",
    value: "COAH",
    key: "COAH"
  },
  { text: "Tecnológico de Monterrey campus Sinaloa", value: "SIN", key: "SIN" },
  { text: "Tecnológico de Monterrey campus Toluca", value: "TOL", key: "TOL" },
  {
    text: "Tecnológico de Monterrey campus Central de Veracruz",
    value: "VER",
    key: "VER"
  },
  {
    text: "Tecnológico de Monterrey campus Ciudad de México",
    value: "CMX",
    key: "CMX"
  },
  {
    text: "Tecnológico de Monterrey campus Cuernavaca",
    value: "MOR",
    key: "MOR"
  },
  { text: "Tecnológico de Monterrey campus Hidalgo", value: "HID", key: "HID" },
  { text: "Tecnológico de Monterrey campus León", value: "GUA", key: "GUA" },
  { text: "Tecnológico de Monterrey campus Puebla", value: "PUE", key: "PUE" },
  {
    text: "Tecnológico de Monterrey campus San Luis Potosí",
    value: "SLP",
    key: "SLP",
  },
  {
    text: "Tecnológico de Monterrey campus Sonora norte",
    value: "SNT",
    key: "SNT"
  },
  {
    text: "Tecnológico de Monterrey campus Zacatecas",
    value: "ZAC",
    key: "ZAC"
  },
  { text: "Tecnológico de Monterrey campus Chiapas", value: "CHP", key: "CHP" },
  {
    text: "Tecnológico de Monterrey campus Estado de México",
    value: "MEX",
    key: "MEX"
  },
  {
    text: "Tecnológico de Monterrey campus Irapuato",
    value: "IRA",
    key: "IRA"
  },
  {
    text: "Tecnológico de Monterrey campus Monterrey",
    value: "NLE",
    key: "NLE"
  },
  {
    text: "Tecnológico de Monterrey campus Querétaro",
    value: "QUE",
    key: "QUE"
  },
  {
    text: "Tecnológico de Monterrey campus Santa fe",
    value: "STF",
    key: "STF"
  },
  { text: "Tecnológico de Monterrey campus Tampico", value: "TAM", key: "TAM" }
];
