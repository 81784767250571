import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Form,
  Grid,
  Icon,
  Input,
  Modal,
  TextArea,
  Dropdown,
  Confirm,
  Checkbox,
  Message,
} from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { post } from "axios";

import { DeleteButton } from "../../atoms";
import {
  EDIT_ACTIVITY,
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_FILE,
} from "../../../graphql/mutations/activities";
import {
  COMPLETE_BATCH_ACTIVITY,
  UPDATE_ODE_TASK,
  DELETE_ODE_ACTIVITY_FILE,
} from "../../../graphql/mutations/odes";
import { AttachmentsBox } from "../../molecules";
import { ResponsibleConditonalDropdown, LoadingBar } from "../../atoms";
import { FormValidator, CLEAN_TEXT } from "../../../helpers";
import validations from "./validations";
import SessionContext from "../../../context/userSessionContext";
import moment from "moment";
import { FORMAT_DATE } from "../../../helpers";
import { InfoLabel } from "./InfoLabel";
import "./styles.css";

const activityOptions = [
  { key: "ADVISORY", text: "Asesoría", value: "ADVISORY" },
  { key: "COURSE", text: "Cursos", value: "COURSE" },
  { key: "TALK", text: "Charlas", value: "TALK" },
  { key: "WORKSHOP", text: "Talleres", value: "WORKSHOP" },
  { key: "ROUND_TABLE", text: "Mesa redonda", value: "ROUND_TABLE" },
  { key: "KNOWLEDGE", text: "Conocimiento", value: "KNOWLEDGE" },
  { key: "DELIVER", text: "Entrega", value: "DELIVER" },
];

export default props => {
  const sessionContext = useContext(SessionContext) || {};

  // Hooks
  const [data, setData] = useState({
    responsibles: [],
    initialResponsibles: [],
    newResponsibles: [],
    responsiblesToDisconnect: [],
    name: "",
    description: "",
    files: [],
    activityType: "",
    coordinator: {},
    activityId: 0,
    odeActivityId: 0,
    isComplete: false,
    deliverableFiles: [],
    hasObjectives: true
  });

  const {
    activityData,
    // "editable" prop Enables or Disables the form
    // fields except the deliverables one. Deliverables
    // field is always enabled for "ENTREPRENEUR" users
    editable
  } = props;

  const [type, setType] = useState({ type: null });
  const [openConfirm, handleConfirm] = useState(false);

  const [file, setFile] = useState([]);
  const [deliverableFile, setDeliverableFile] = useState([]);
  const [isUploading, setUploadState] = useState({exampleFiles: false, deliverables: false});
  const [fileShown, setFileShown] = useState(false);
  const [deliverableShown, setDeliverableShown] = useState(false);
  const [removingDeliverable, setRemovingDeliverable] = useState(false);
  const [removingExample, setRemovingExample] = useState(false);

  useEffect(() => {
    const {
      id,
      activityId,
      odeActivityId,
      name,
      description,
      files,
      activityType,
      coordinator,
      responsibles,
      isComplete,
      deliverableFiles,
      hasObjectives,
      finishDate,
    } = activityData;

    setData({
      id,
      activityId,
      odeActivityId,
      name,
      description,
      files,
      activityType,
      coordinator,
      responsibles: responsibles,
      initialResponsibles: responsibles,
      newResponsibles: [],
      responsiblesToDisconnect: [],
      isComplete,
      deliverableFiles,
      hasObjectives,
      finishDate: finishDate ? FORMAT_DATE(finishDate) : undefined,
    });
  }, []);

  const [errors, setErrors] = useState({
    name: false,
    description: false,
    activityType: false,
  });

  const handleChange = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
  };

  const handleChangeType = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
    setType({ type: value });
  };

  const updateActivity = async () => {
    const validation = validateFrom(data);
    if (validation.isValid) {
      try {
        data.newFiles = file.map(f => ({ url: f.url, type: f.type }));
        data.description = CLEAN_TEXT.ESCAPE(data.description);
        await EDIT_ACTIVITY(data);
        props.refetch();
        close();
      } catch (e) {
        throw new Error(e);
      }
    }
  };

  const updateOdeActivity = async () => {
    const validation = validateFrom(data);

    let finishDate = undefined;

    if(data.finishDate) { 
      finishDate = moment(
        data.finishDate,
        "DD/MM/YYYY"
      ).format();
    }

    if (validation.isValid) {
      try {
        const newFilesToSave = file
          .map(f => ({ url: f.url, type: f.type }));
        const newUploadedDeliverablesToSave = deliverableFile
          .map(f => ({ url: f.url, type: f.type }));

        data.newFiles = newFilesToSave;
        data.description = CLEAN_TEXT.ESCAPE(data.description);

        await UPDATE_ODE_TASK({
          ...data,
          finishDate,
          odeId: window.location.pathname.split("e/")[1],
          activityId: data.odeActivityId,
          isInherit: activityData.hasObjectives !== null,
          files: data.files,
          deliverableFiles: newUploadedDeliverablesToSave
        });

        close();
        props.refetch();
      } catch (e) {
        throw new Error(e);
      }
    }
  };

  const deleteActivity = async () => {
    const id = activityData.id;
    try {
      await DELETE_ACTIVITY(id);
      handleConfirm(() => false);
      props.refetch();
      close();
    } catch (e) {
      throw new Error(e);
    }
  };

  const close = () => {
    props.closeModal({ active: false });
  };

  /**
   * @param {*} link { id: uid, url: string, isNew: true, type: string }
   * @param {*} type "EXAMPLE" | "DELIVERABLE"
   */
  const handleAttachLink = (link = {}, type = "") => {
    if (type === "EXAMPLE") {
      file.push(link);
      setFile(file);
      setFileShown(() => true);
    }
    if (type === "DELIVERABLE") {
      deliverableFile.push(link);
      setDeliverableFile(deliverableFile);
      setDeliverableShown(() => true);
    }
  };

  /**
   * @param {*} newFile { id: uid, url: string, isNew: true, type: string }
   * @param {*} type "EXAMPLE" | "DELIVERABLE"
   */
  const handleAfterUpload = (newFile = {}, type = "") => {
    try {
      if (type === "EXAMPLE") {
        file.push(newFile);
        setFile(file);
        setFileShown(() => true);
      }
      if (type === "DELIVERABLE") {
        deliverableFile.push(newFile);
        setDeliverableFile(deliverableFile);
      }
    } finally {
      setUploadState({ exampleFiles: false });
    }
  };

  /**
   * @param {*} removedFile { id }
   * @param {*} type "EXAMPLE" | "DELIVERABLE"
   */
  const handleOnRemoveAttachment = async (removedFile = {}, type = "") => {
    if (removedFile.id === undefined) { // unsaved files have not id
      return;
    }
    const config = {headers: { "content-type": "application/json" }};
    const params = {
      Delete: {Objects: [{ Key: removedFile.url }]},
      Bucket: process.env.S3_BUCKET
    };

    try {
      if (type === "EXAMPLE") {
        setRemovingExample(true);
        const alreadySaved = data.files.filter(f => f.id !== removedFile.id);
        setData(data => ({ ...data, files: alreadySaved }));
        const newFiles = file.filter(f => f.id !== removedFile.id);
        setFile(newFiles);

        await DELETE_ACTIVITY_FILE({
          id: data.id || data.activityId,
          fileId: removedFile.id
        });
      }

      if (type === "DELIVERABLE") {
        setRemovingDeliverable(true);
        const alreadySaved = data.deliverableFiles
          .filter(f => f.id !== removedFile.id);
        setData(data => ({ ...data, deliverableFiles: alreadySaved }));
        const newFiles = deliverableFile.filter(f => f.id !== removedFile.id);
        setDeliverableFile(newFiles);

        await DELETE_ODE_ACTIVITY_FILE({
          id: data.odeActivityId,
          fileId: removedFile.id
        });
      }
      await post(process.env.REACT_APP_S3_DELETE, JSON.stringify(params), config);
      if (props.refetch) {
        props.refetch();
      }
    } catch (e) {
      throw new Error(e);
    } finally {
      setRemovingExample(false);
      setRemovingDeliverable(false);
    }
  };


  const validateFrom = data => {
    const validator = new FormValidator(validations);
    const validation = validator.validate(data);
    if (validation.isValid) {
      Object.keys(errors).map(error => {
        errors[error] = validation[error]["isInvalid"];
      });
      setErrors({ ...errors });
    } else {
      Object.keys(errors).map(error => {
        errors[error] = validation[error]["isInvalid"];
      });
      setErrors({ ...errors });
    }
    return validation;
  };

  const handleResponsibles = () => {
    const { team } = props;
    if (team) {
      return team.map(({ id, fullName }, index) => {
        return { key: index, text: fullName, value: id };
      });
    }
  };

  const getUserById = (userId) => {
    const { team } = props;
    if (team) {
      return team.find(({ id }, index) => {
        return id === userId;
      });
    }
  }

  const handleChangeResponsible = (e, { value }) => {
    if( data.responsibles.length < value.length ) { // New responsibles were added
      // Add the new responsible 
      let newId = value.find(id => {
        return !data["responsibles"].some(responsible => responsible.id === id);
      });
      data["newResponsibles"].push({ id: newId });

      // Update the responsibles to disconnect
      // If some responsibles were removed and then added again
      data["responsiblesToDisconnect"] = data.responsiblesToDisconnect.filter(responsible => {
        return  value.some(id => id === responsible.id)
      });
    } else { // Some responsible has been removed
      // Detect removed items
      const idsToDelete = [];
      data.initialResponsibles.forEach( responsible => {
        const result = value.filter(id => responsible.id === id);
        if(result.length < 1) {
          idsToDelete.push({ id: responsible.id })
        }
      });
      data["responsiblesToDisconnect"] = idsToDelete;

      // Update the new responsibles to add
      // If some responsibles were added and then removed
      data["newResponsibles"] = data.newResponsibles.filter(responsible => {
        return value.some(id => id === responsible.id);
      });
    }

    // Updates the selected values for the Dropdown control
    const selectedResponsibles = value.map( v => getUserById(v));
    data["responsibles"] = selectedResponsibles;
    setData({...data})
  };

  const handleCehckbox = async (e, { bool, id, type }) => {
    if (type === "ode_activity") {
      try {
        setData({ ...data, isComplete: bool})
      } catch (error) {
        throw new Error(e);
      }
    } else {
      try {
        await COMPLETE_BATCH_ACTIVITY({
          odeId: window.location.pathname.split("e/")[1],
          activityId: id,
          isComplete: bool,
        });
        props.refetch();
      } catch (error) {
        throw new Error(e);
      }
    }
  };

  const onClickSave = () => {
    if(props.ode){
      updateOdeActivity();
    } else {
      updateActivity();
    }
  };

  return (
    <Modal
      className="Modal__Form-SubModal"
      centered={false}
      open={props.openModal}
      onClose={props.closeModal}
      size="large"
      autoComplete="new-password"
    >
      <Modal.Header as="h1">
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} floated="left">
              Actividad
            </Grid.Column>
            <Grid.Column width={2} floated="right">
              <Icon name="close" onClick={close} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        { (!editable && sessionContext.role !== "ENTREPRENEUR") ?
          <Message warning>
            <Icon name="lock" size="small" />
            {
              `Tu rol de usuario no permite editar actividades. Sólo podrás
              visualizar la información y administrar los entregables.`
            }
          </Message>
        : null }
        <Form className="Modal__Form">
          <Form.Group widths="equal">
            <Form.Field
              width={11}
              control={Input}
              label="Nombre de la actividad"
              type="text"
              defaultValue={data["name"]}
              name="name"
              onChange={handleChange}
              required
              error={data["name"] !== "" ? false : errors.name}
              disabled={!editable}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              name="activityType"
              label="Tipo de actividad"
              control={Dropdown}
              options={activityOptions}
              onChange={handleChangeType}
              value={data.activityType}
              selection
              required
              disabled={!editable}
            />
          </Form.Group>
          <Form.Group>
            {
              props.showDates ?
                <Form.Field
                  name="finishDate"
                  control={DateInput}
                  format="DD/MM/YYYY"
                  value={data.finishDate}
                  width="16"
                  placeholder="Seleccionar fecha de entrega"
                  autoComplete="off"
                  iconPosition="right"
                  onChange={handleChange}
                  label="Fecha de entrega"
                  style={!errors.finishDate ? null : { backgroundColor: "red" }}
                  disabled={!editable} />
              : null
            }
          </Form.Group>
          <ResponsibleConditonalDropdown
            ode={props.ode}
            options={handleResponsibles()}
            error={
              data["responsibles"] && data["responsibles"].length > 0
                ? false
                : errors.responsibles
            }
            onChange={handleChangeResponsible}
            responsibles={props.ode ? data.responsibles : null}
            update
            disabled={!editable}
          />
          <Form.Group widths="equal">
            {editable ?
              <Form.Field
                control={TextArea}
                name="description"
                label="Descripción"
                value={CLEAN_TEXT.replaceNewline(
                  CLEAN_TEXT.UNICODE_TO_STRING(data.description)
                )}
                onChange={handleChange}
                error={data["description"] !== "" ? false : errors.description}
                required
                style={{ height: "200pt" }}  />
              : <Form.Field>
                <Message>
                  <p id="activity-description">
                    {CLEAN_TEXT.replaceNewline(
                      CLEAN_TEXT.UNICODE_TO_STRING(data.description)
                    )}
                  </p>
                </Message>
              </Form.Field>}
          </Form.Group>
          <SessionContext.Consumer>
            {ctx =>
              <div>
                <h3>Recursos</h3>
                {ctx.role === "ENTREPRENEUR" ? null
                  : <p>Agrega materiales para complementar la actividad.</p>}
                <LoadingBar loading={removingExample} />
                <AttachmentsBox
                  editable={editable}
                  onAttachLink={link => handleAttachLink(link, "EXAMPLE")}
                  afterUpload={f => handleAfterUpload(f, "EXAMPLE")}
                  onRemoveAttachment={f =>
                    handleOnRemoveAttachment(f, "EXAMPLE")}
                  attachments={data.files} />
                {data.deliverableFiles ?
                  <>
                    <h3>Entregables</h3>
                    <LoadingBar loading={removingDeliverable} />
                    <AttachmentsBox
                      editable
                      onAttachLink={link => handleAttachLink(link, "DELIVERABLE")}
                      afterUpload={f => handleAfterUpload(f, "DELIVERABLE")}
                      onRemoveAttachment={f =>
                        handleOnRemoveAttachment(f, "DELIVERABLE")}
                      attachments={data.deliverableFiles} />
                  </>
                  : null}
              </div>
            }
          </SessionContext.Consumer>
          <Form.Group>
            { sessionContext.role !== "ENTREPRENEUR" ?
              <InfoLabel>
                Esta es una actividad personalizada, sólo estará disponible en
                este programa. Si desea un cambio permanente agregue esta
                actividad en el módulo objetivos.
              </InfoLabel>
              : null }
          </Form.Group>
          <div style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "17px",
              width: "100%"
            }}>
            {!props.ode ? (
              <div>
                <DeleteButton del={() => handleConfirm(() => true)} />
              </div>
            ) : (
              <div>
                <p>
                  <Checkbox
                    onChange={(e, bool) =>
                      handleCehckbox(e, {
                        bool: bool.checked,
                        id: data.odeActivityId,
                        type: props.ode ? "ode_activity" : "",
                      })
                    }
                    defaultChecked={props.activityData.isComplete}
                    label="Marcar como completado"
                  />{" "}
                </p>
              </div>
            )}
            <div>
              <Button
                style={{ width: "130px" }}
                onClick={onClickSave}
                fluid
                className={"primary"}>
                Guardar
              </Button>
            </div>
          </div>
        </Form>
      </Modal.Content>
      {Confirm && (
        <Confirm
          open={openConfirm}
          onCancel={() => handleConfirm(() => false)}
          onConfirm={() => deleteActivity()}
          header="Eliminar actividad"
          content={"Esta acción no es reversible."}
        />
      )}
    </Modal>
  );
};
