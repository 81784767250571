export default {
  section1: [
    {
      field: "odeCategory",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "name",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "sector",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "commercialBussines",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "nationality",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "city",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "state",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "registrationYear",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
  ],
  section2: [
    {
      field: "businessName",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "personType",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "rfc",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "commercialAddress",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "physicalAddress",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
  ],
  section2Foreign: [
    {
      field: "businessName",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "ein",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "inc",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "commercialAddress",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "physicalAddress",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
  ],
  section3: [
    {
      field: "website",
      method: "isEmpty",
      validWhen: "always",
      message: "",
    },
    {
      field: "facebook",
      method: "isEmpty",
      validWhen: "always",
      message: "",
    },
    {
      field: "twitter",
      method: "isEmpty",
      validWhen: "always",
      message: "",
    },
    {
      field: "instagram",
      method: "isEmpty",
      validWhen: "always",
      message: "",
    },
    {
      field: "email",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
  ],
  section4: [
    {
      field: "phase",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "technology",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "trl",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "industry",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "area",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
  ],
};
