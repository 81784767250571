import gql from "graphql-tag";
import { client } from "../../index";
import {
  updateActivitySentence,
  createActivitySentence,
  getFilesUrls
} from "./helpers";

export default ({
  odeId,
  odeActivityId,
  name,
  description,
  activityType,
  newFiles = [],
  isComplete,
  deliverableFiles,
  newResponsibles,
  responsiblesToDisconnect,
  finishDate,
  isInherit = false, // determine if it's inherit from batch
  files = [] // From the batch activity
}) => client.mutate({
  variables: {
    odeId,
    odeActivityId,
    activityType,
    name,
    files: newFiles, // New files added
    deliverableFiles,
    newResponsibles,
    responsiblesToDisconnect,
    finishDate,
    description,
    isComplete,
    connectedFiles: getFilesUrls(files) // The already connected files
  },
  mutation: gql`
  mutation(
    $odeId: ID!,
    $files: [FileCreateInput!],
    $deliverableFiles:[FileCreateInput!],
    $newResponsibles: [UserWhereUniqueInput!],
    $responsiblesToDisconnect: [UserWhereUniqueInput!],
    $finishDate: DateTime,
    $odeActivityId: ID!,
    $isComplete: Boolean,
    $name: String!,
    $description: String!,
    $activityType: ActivityType!,
    ${isInherit ? "$connectedFiles: [FileCreateInput!]" : ""}
    ){
      updateODE(
        where: { id: $odeId }
        data: {
          activities: { # This type is [OdeActivity]
            update: {
              where: {id: $odeActivityId}
              data: {
                files: { create: $deliverableFiles }
                isComplete: $isComplete
                finishDate: $finishDate
                responsibles: {
                  connect: $newResponsibles
                  disconnect: $responsiblesToDisconnect
                }
                objective: {
                  disconnect: ${isInherit ? true : false}
                }
                activity: { # OdeActivity has an activity of type Activity
                  ${isInherit ?
                    createActivitySentence(files.concat(newFiles))
                    : updateActivitySentence}
                }
              }
            }
          }
        }
      ) {
        id
      }
    }`
});
