import React, { useState } from "react";
import {
  Modal,
  Form,
  Icon,
  Dropdown,
  Button,
  Grid,
  Label,
} from "semantic-ui-react";
import gql from "graphql-tag";
import _ from "lodash";
import { client } from "../../../graphql/";
import {
  OBJECTIVES,
  BATCH_OBJECTIVES,
} from "../../../graphql/queries/organisms";
import {
  GET_ODES_CONNECTED_TO_BATCH
} from "../../../graphql/queries/odes"
import { DeleteButton } from "../../atoms";
import { Query } from "react-apollo";
import { batchNameFormat } from "../../../helpers/batchNameFormat";

const DELETE_BATCH_OBJECTIVE = async objectiveId => {
  const batchId = window.location.pathname.split("batchid=")[1];
  await client.mutate({
    mutation: gql`
    mutation($objectiveId:ID!) {
        updateBatch(where:{id:"${batchId}"} data:{
                    batchObjectives:{
                        delete:{
                            id:$objectiveId
                        }
                    }
                }){
                  id
                }                   
    }`,
    variables: {
      objectiveId: objectiveId,
    },
  });
};

const Objectives = props => {
  const { onChange } = props;

  const organismId = window.location.pathname.split("orgid=")[1].split("_")[0];

  return (
    <Query
      query={OBJECTIVES}
      variables={{
        id: organismId
      }}
      fetchPolicy="no-cache">
      {({ loading, error, data }) => {
        if (loading) {
          return <p>Loading...</p>;
        }
        if (error) {
          return <p>Error...</p>;
        }
        const options = [];
        data.organism.objectives.map(
          ({ name, id, description, type, activities }, index) => {
            options.push({
              text: batchNameFormat(name),
              key: index,
              value: JSON.stringify({ name, description, type, activities, id })
            });
          }
        );
        return (
          <Dropdown
            search
            selection
            icon="search"
            options={options}
            onChange={onChange}
            placeholder={props.placeholder} />
        );
      }}
    </Query>
  );
};

export default props => {
  const [data, setData] = useState({
    userName: null,
    id: null,
    odeId: null,
    action: null,
    type: null,
    timestamp: null,
    user: null,
    ode: null,
  });

  const organismId = window.location.pathname.split("orgid=")[1].split("_")[0];
  const programId = window.location.pathname
    .split("programid=")[1]
    .split("_")[0];
  const batchId = window.location.pathname.split("batchid=")[1];

  const handleChange = (e, { name, value }) => {
    const v = JSON.parse(value);
    data["objective"] = v;
    setData(data);
  };

  const connect = async () => {
    try {
      await CONNECT_BATCH_TO_OBJECTIVE(data.objective);
      props.close();
      window.location.reload();
    } catch (e) {
      throw new Error(e);
    }
  };

  return (
    <Modal
      className="Modal__Form-SubModal"
      centered={false}
      open={props.open}
      size="tiny"
      dimmer="blurring"
      autoComplete="new-password"
      style={{ minHeight: "350px" }}
    >
      <Modal.Header>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} floated="left">
              Agregar objetivo
            </Grid.Column>
            <Grid.Column width={2} floated="right">
              <Icon name="close" onClick={props.close} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group>
            <Form.Field
              placeholder="Buscar objetivo"
              control={Objectives}
              onChange={handleChange}
              width={15}
            />
            <Form.Field width={5}>
              <Button primary content="Agregar" onClick={connect} />
            </Form.Field>
          </Form.Group>
        </Form>
        <Grid style={{ width: "100%", paddingTop: "30px" }}>
          <Query
            query={BATCH_OBJECTIVES}
            variables={{ id: organismId, batchId, programId }}
            fetchPolicy="no-cache"
          >
            {({ loading, error, data, refetch }) => {
              if (loading) {
                return <p>Cargando...</p>;
              }

              if (error) {
                return <p>Error...</p>;
              }

              const objectivesArray =
                data.organism.program[0].batches[0].batchObjectives;

              return objectivesArray.map(({ objective, id }, index) => (
                <Grid.Row key={index} columns={2}>
                  <Grid.Column floated="left" textAlign="left">
                    <Label circular color="yellow">
                      {objective.name.substring(0, 1)}
                    </Label>
                    <span>{batchNameFormat(objective.name)}</span>
                  </Grid.Column>
                  <Grid.Column floated="right" textAlign="right">
                    <div style={{ float: "right", marginTop: "-8px" }}>
                      <DeleteButton
                        del={async () => {
                          try {
                            await DELETE_BATCH_OBJECTIVE(id);
                            refetch();
                            window.location.reload();
                          } catch (e) {
                            throw new Error(e);
                          }
                        }}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              ));
            }}
          </Query>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

const getNewOdeActivities = async (objectiveActivities, batchId) => {
  let { data } = await GET_ODES_CONNECTED_TO_BATCH(batchId);
  const ODEs = data.oDEs || [];

  return ODEs.reduce((newOdeActivities, ode) => {
    objectiveActivities.forEach(objectiveActivity => {
      const newOdeActivity = {
        ode: { connect: { id: ode.id } },
        batch: { connect: { id: batchId } },
        activity: { connect: { id: objectiveActivity.id } }
      }
      newOdeActivities.push(newOdeActivity);
    });

    return newOdeActivities;
  }, []);
}

const CONNECT_BATCH_TO_OBJECTIVE = async ({
  id,
  activities
}) => {
  const batchId = window.location.pathname.split("batchid=")[1];
  const newOdeActivities = await getNewOdeActivities(activities, batchId)

  await client.mutate({
    mutation: gql`
    mutation( $objectiveId: ID!, $batchId: ID!, $newOdeActivities: [OdeActivityCreateWithoutObjectiveInput!] ) {
        updateBatch(
          where: { id: $batchId }
          data: { 
            batchObjectives: {
              create: {
                status: ACTIVE
                objective: { # This one is the Organism Objective
                  connect: { id: $objectiveId }
                }
                odeActivities: {
                  create: $newOdeActivities
                }
              }
            }
          }
        ){
            id # The connected objetive id
          }
    }`,
    variables: {
      objectiveId: id,
      batchId,
      newOdeActivities
    },
  });
};
