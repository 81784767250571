import gql from "graphql-tag";

export default gql`
  query(
    $first: Int = 10
    $skip: Int = 0
    $userId: ID!
    $orderBy: OrganismOrderByInput = id_DESC) {
    organisms(
      first: $first,
      skip: $skip,
      orderBy: $orderBy,
      where: {
        OR: [
          { coordinator: { id: $userId } },
          { institution: { coordinator: { id: $userId } } },
          { 
            program_some: {
              batches_some : { users_some : { id: $userId } }
            }
          }
        ]
      }
    ) {
      id
      organismID
      name
      coordinator {
        id
        fullName
      }
      type
      program {
        id
        name
      }
      wasCreatedAt
      institution {
        id
        name
        coordinator {
          id
        }
      }
    }
    organismsConnection {
      aggregate {
        count
      }
    }
  }
`;
