import React, { useState } from "react";
import {
  Button,
  Form,
  Grid,
  Icon,
  Input,
  Modal,
  TextArea,
  Dropdown,
} from "semantic-ui-react";
import { ProgramID, UserDropdown } from "../../atoms";
import { FormValidator, CLEAN_TEXT } from "../../../helpers";
import validations from "./validations";
import { CONNECT_PROGRAM } from "../../../graphql/mutations/organisms";

const typeOptions = [
  { key: "COWORKING", text: "Co Working", value: "COWORKING" },
  { key: "ACCELERATOR", text: "Aceleradora", value: "ACCELERATOR" },
  {
    key: "INVESTMENT_FUND",
    text: "Fondo de inversión",
    value: "INVESTMENT_FUND",
  },
  { key: "INCUBATOR", text: "Incubadora", value: "INCUBATOR" },
];

export default props => {
  const id = window.location.pathname.split("/")[3];
  // Hooks
  const [data, setData] = useState({
    name: "",
    programID: "",
    description: "",
  });

  const [type, setType] = useState({ type: null });
  const [options, setOptions] = useState([]);

  const [errors, setErrors] = useState({
    name: false,
    institution: false,
    coordinator: false,
    description: false,
    institution: false,
    coordinator: false,
    type: false,
  });

  const validateFrom = data => {
    const validator = new FormValidator(validations);
    const validation = validator.validate(data);
    return validation;
  };

  const handleChange = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
  };

  const handleChangeType = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
    setType({ type: value });
  };

  const createProgram = async () => {
    data["id"] = escape(id);
    data["description"] = CLEAN_TEXT.STRING_TO_UNICODE(data.description);
    await CONNECT_PROGRAM(data);
    props.refetch();
    props.close();
  };

  const close = () => {
    props.close();
  };

  return (
    <Modal
      className="Modal__Form-SubModal"
      centered={false}
      open={props.open}
      onClose={props.close}
      size="tiny"
      dimmer="blurring"
      autoComplete="new-password"
    >
      <Modal.Header as="h1">
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} floated="left">
              Nuevo programa
            </Grid.Column>
            <Grid.Column width={2} floated="right">
              <Icon name="close" onClick={close} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <Form className="Modal__Form">
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              label="Nombre del programa"
              type="text"
              name="name"
              onChange={handleChange}
              required
              onChange={handleChange}
              error={errors.name}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={UserDropdown}
              systemRole={["COORDINATOR", "SUPER_ADMIN"]}
              name="coordinator"
              label="Coordinador"
              onChange={handleChange}
              required
              error={data.coordinator !== "" ? false : errors.coordinator}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              name="type"
              label="Tipo de programa"
              control={Dropdown}
              options={typeOptions}
              onChange={handleChangeType}
              defaultValue={data.type}
              selection
              required
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={TextArea}
              name="description"
              label="Descripción"
              onChange={handleChange}
              error={errors.description}
              required
            />
          </Form.Group>
          <Form.Group widths="equal">
            <div style={{ marginLeft: "278px", marginTop: "17px" }}>
              <Button
                style={{ width: "130px" }}
                onClick={() => createProgram()}
                fluid
                primary
              >
                Guardar
              </Button>
            </div>
          </Form.Group>
        </Form>
      </Modal.Content>
    </Modal>
  );
};
