import gql from "graphql-tag";
import { client } from "../../index";

export default {
  nationalCompany: async ({
    category,
    name,
    businessName,
    sector,
    commercialBusiness,
    nationality,
    city,
    state,
    registrationYear,
    personType,
    rfc,
    commercialAddress,
    physicalAddress,
    website,
    facebook,
    twitter,
    instagram,
    email,
    phase,
    technology,
    trl,
    industry,
    area,
    timeline,
  }) => {
    await client.mutate({
      mutation: gql`
      mutation {
        createODE(
          data: {
            name: "${name}"
            odeCategory: ${category}
            sector: ${sector}
            commercialBussines: ${commercialBusiness}
            nationality: ${nationality}
            registrationYear: "${registrationYear}"
            businessName: "${businessName}"
            rfc: "${rfc}"
            personType: ${personType}
            commercialAddress: "${commercialAddress}"
            physicalAddress: "${physicalAddress}"
            website: "${website}"
            facebook: "${facebook}"
            twitter: "${twitter}"
            instagram: "${instagram}"
            email: "${email}"
            phase:${phase}
            technology:"${technology}"
            trl:${trl}
            industry:${industry}
            area:"${area}"
            city:"${city}"
            state:"${state}"
            timeline:{
              create:{
                action:"${timeline.action}"
                timestamp:"${timeline.timestamp}"
                ode:"${timeline.ode}"
                type:${timeline.type}
              }
            }
          }
        ) {
          id
        }
      }
    `,
    });
  },
  foreignCompany: async ({
    category,
    name,
    businessName,
    sector,
    commercialBusiness,
    nationality,
    city,
    state,
    registrationYear,
    ein,
    inc,
    commercialAddress,
    physicalAddress,
    website,
    facebook,
    twitter,
    instagram,
    email,
    phase,
    technology,
    trl,
    industry,
    area,
    timeline,
  }) => {
    await client.mutate({
      mutation: gql`
      mutation {
        createODE(
          data: {
            name: "${name}"
            odeCategory: ${category}
            sector: ${sector}
            commercialBussines: ${commercialBusiness}
            nationality: ${nationality}
            registrationYear: "${registrationYear}"
            businessName: "${businessName}"
            ein: "${ein}"
            inc: "${inc}"
            commercialAddress: "${commercialAddress}"
            physicalAddress: "${physicalAddress}"
            website: "${website}"
            facebook: "${facebook}"
            twitter: "${twitter}"
            instagram: "${instagram}"
            email: "${email}"
            phase:${phase}
            technology:"${technology}"
            trl:${trl}
            industry:${industry}
            area:"${area}"
            city:"${city}"
            state:"${state}"
            timeline:{
              create:{
                action:"${timeline.action}"
                timestamp:"${timeline.timestamp}"
                ode:"${timeline.ode}"
                type:${timeline.type}
              }
            }
          }
        ) {
          id
        }
      }
    `,
    });
  },
  STARTUP: async ({
    category,
    name,
    sector,
    commercialBusiness,
    nationality,
    city,
    state,
    registrationYear,
    website,
    facebook,
    twitter,
    instagram,
    email,
    phase,
    technology,
    trl,
    industry,
    area,
    timeline,
  }) => {
    await client.mutate({
      mutation: gql`
      mutation {
        createODE(
          data: {
            name: "${name}"
            odeCategory: ${category}
            sector: ${sector}
            commercialBussines: ${commercialBusiness}
            nationality: ${nationality}
            registrationYear: "${registrationYear}"
            website: "${website}"
            facebook: "${facebook}"
            twitter: "${twitter}"
            instagram: "${instagram}"
            email: "${email}"
            phase:${phase}
            technology:"${technology}"
            trl:${trl}
            industry:${industry}
            area:"${area}"
            city:"${city}"
            state:"${state}",
            timeline:{
              create:{
                action:"${timeline.action}"
                timestamp:"${timeline.timestamp}"
                ode:"${timeline.ode}"
                type:${timeline.type}
              }
            }
          }
        ) {
          id
        }
      }
    `,
    });
  },
  SPIN_OFF: async ({
    category,
    name,
    sector,
    commercialBusiness,
    nationality,
    city,
    state,
    registrationYear,
    phase,
    technology,
    trl,
    industry,
    area,
    timeline,
  }) => {
    await client.mutate({
      mutation: gql`
      mutation {
        createODE(
          data: {
            name: "${name}"
            odeCategory: ${category}
            sector: ${sector}
            commercialBussines: ${commercialBusiness}
            nationality: ${nationality}
            registrationYear: "${registrationYear}"
            phase:${phase}
            technology:"${technology}"
            trl:${trl}
            industry:${industry}
            area:"${area}"
            city:"${city}"
            state:"${state}"
            timeline:{
              create:{
                action:"${timeline.action}"
                timestamp:"${timeline.timestamp}"
                ode:"${timeline.ode}"
                type:${timeline.type}
              }
            }
          }
        ) {
          id
        }
      }
    `,
    });
  },
};
