import React, { useState, useEffect, Fragment } from "react";
import {
  Modal,
  Form,
  Icon,
  Button,
  Grid
} from "semantic-ui-react";
import gql from "graphql-tag";
import { client } from "../../../graphql";
import {
  GET_BATCH_OBJECTIVES_ACTIVITIES
} from "../../../graphql/queries/organisms";
import {
  CONNECT_BATCH_ACTIVITIES_TO_ODE
} from "../../../graphql/mutations/odes";
import { Query } from "react-apollo";
import lodash from "lodash";
import { ConnectedODEs } from "./components";
import { OdesDropdown } from "../../../components/atoms";

const GET_ODE_ACTIVITIES_LINKED_TO_BATCH = async (odeId, batchId) => {
  const data = await client.query({
    query: gql`
      query ($odeId: ID!, $batchId: ID!) {
      oDE (where: { id: $odeId }) {
          id
          activities(where: { batch: { id: $batchId } }) {
            id
          }
        }
      }
    `,
    variables: {
      odeId,
      batchId
    }
  });

  return data;
};

const DELETE_BATCH_ODE = async (odeId, odeActivitiesToDelete) => {
  const batchId = window.location.pathname.split("batchid=")[1];
  await client.mutate({
    mutation: gql`
    mutation($odeId:ID!, $batchId: ID!, $odeActivitiesIds: [ID!] = []) {
        updateBatch(
          where:{id:$batchId} 
          data:{
            odes:{
                disconnect:{
                    id: $odeId
                }
            }
            odeActivities: {
              deleteMany:{
                id_in: $odeActivitiesIds
              }
            }
        }
      ){
        id
      }              
    }`,
    variables: {
      odeId,
      batchId,
      odeActivitiesIds: odeActivitiesToDelete
    },
    refetchQueries: [
      {
        query: BATCH_ODES,
        variables: { id: batchId },
        fetchPolicy: "no-cache"
      }
    ]
  });
};

const BATCH_ODES = gql`
  query($id: ID!) {
    batch(where: { id: $id }) {
      id
      odes {
        id
        name
      }
    }
  }
`;

export default props => {
  const batchId = window.location.pathname.split("batchid=")[1];

  const [data, setData] = useState({
    userName: null,
    id: null,
    odeId: null,
    action: null,
    type: null,
    timestamp: null,
    user: null,
    ode: null
  });

  const [activities, setActivities] = useState(null);

  useEffect(() => {
    const objectives = async () => {
      const { data } = await GET_BATCH_OBJECTIVES_ACTIVITIES({ batchId });
      handleObjectiveActivities({
        objectives: data.batch.batchObjectives,
        startDate: data.batch.startDate,
        finishDate: data.batch.finishDate
      });
    };
    objectives();
  }, []);

  /**
   * Returns an array of activities of type Activity
   * @param {*} objectives The objectives linked to the batch (BatchObjective)
   * This ones has a propperty "objetive" of type "Objective"
   */
  const reduceActivities = objectives => {
    try{
      let activities = [];
      if(objectives) {
        activities = objectives
          .map(({id, objective}) => {
            const objectiveActivities = objective.activities ?
              objective.activities : [];

            return objectiveActivities.map(element => {
              element.objectiveId = id;
              return element;
            });
          })
          .flat();
      }
      return activities;
    }
    catch(err){
      return [];
    }
  };

  const handleObjectiveActivities = ({ objectives }) => {
    setActivities(() => reduceActivities(objectives));
  };

  const handleChange = (e, {value }) => {
    const v = JSON.parse(value);
    data.ode = v;
    setData(data);
  };

   /**
   * Returns an array of activities in a format ready to be created
   * @param {*} activities The activities of the current batch objectives
    * @param {*} batchId The Id of the batch in which the activity will
    * and the ODE be connected
    */
  const getOdeActivitiesToSave = (activities, batchId) => {
    // Just needs the Activity Id to connect it
    const activitiesToSave = activities.map(({ id, objectiveId }) => {
      return {
        activity: { connect: { id: id } },
        objective: { connect: { id: objectiveId } },
        batch: { connect: { id: batchId } }
      };
    });
    return activitiesToSave;
  };

  const connect = async () => {
    try {
      const batchId = window.location.pathname.split("batchid=")[1];

      await CONNECT_BATCH_TO_ODE({ odeId: data.ode.id });

      const activitiesToSave = getOdeActivitiesToSave(activities, batchId);

      await CONNECT_BATCH_ACTIVITIES_TO_ODE({
        activities: activitiesToSave,
        odeId: data.ode.id
      });
      props.refetch();
      props.close();
    } catch (e) {
      throw new Error(e);
    }
  };

  const deleteOdeFromBatch = async odeId => {
    try {
      // The batchId is recovered from the URL
      const odeActivitiesFound =
        await GET_ODE_ACTIVITIES_LINKED_TO_BATCH(odeId, batchId);
      const odeActivitiesIdToDelete = lodash.get(
        odeActivitiesFound,
        "data.oDE.activities",
        []
        ).map(({ id }) => {
          if(id !== undefined)
            return id;
        });
      await DELETE_BATCH_ODE(odeId, odeActivitiesIdToDelete);
      props.refetch();
      props.close();
    } catch (e) {
      throw new Error(e);
    }
  };

  const onClose = () => {
    props.close();
  };

  return (
    <Modal
      className="Modal__Form-SubModal"
      centered={false}
      open={props.open}
      size="tiny"
      dimmer="blurring"
      autoComplete="new-password"
      style={{ minHeight: "350px" }}>
      <Modal.Header>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} floated="left">
              Agregar ODE
            </Grid.Column>
            <Grid.Column width={2} floated="right">
              <Icon name="close" onClick={onClose} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <Query
          query={BATCH_ODES}
          variables={{ id: batchId }}
          fetchPolicy="no-cache">
          {({ loading, error, data }) => {
              if (loading) {
                return <p>Cargando...</p>;
              }

              if (error) {
                return <p>Error...</p>;
              }

              const odesArray =
                data.batch && data.batch !== null ? data.batch.odes : [];

              return (
                <Fragment>
                  <Form>
                    <Form.Group>
                      <Form.Field
                        placeholder="Buscar ODE"
                        control={OdesDropdown}
                        odesToExclude={ odesArray.map(({ id }) => id) }
                        onChange={handleChange}
                        width={15} />
                      <Form.Field width={5}>
                        <Button
                          primary
                          content="Agregar"
                          onClick={connect} />
                      </Form.Field>
                    </Form.Group>
                  </Form>
                  <Grid style={{ width: "100%", paddingTop: "30px" }}>
                    <ConnectedODEs
                      odesArray={odesArray}
                      deleteOdeFromBatch={deleteOdeFromBatch} />
                  </Grid>
                </Fragment>
              );
            }}
        </Query>
      </Modal.Content>
    </Modal>
  );
};

/**
 * Connects the selected ODE (identified by ID)
 * to the batch. This updates the batch to indicate it
 * that the ODE is connected to it
 * @param {*} odeId
 */
const CONNECT_BATCH_TO_ODE = async ({ odeId }) => {
  const batchId = window.location.pathname.split("batchid=")[1];

  await client.mutate({
    mutation: gql`
      mutation($batchId: ID!, $odeId: ID!) {
        updateBatch(
          where: { id: $batchId }
          data: { odes: { connect: { id: $odeId } } }
        ) {
          id
        }
      }
    `,
    variables: {
      odeId: odeId,
      batchId: batchId
    },
    refetchQueries: [
      {
        query: BATCH_ODES,
        variables: { id: batchId },
        fetchPolicy: "no-cache"
      }
    ]
  });
};
