import gql from "graphql-tag";

export default gql`
  query(
    $first: Int!,
    $skip: Int!,
    $filter: String = "",
    $orderBy: InstitutionOrderByInput = id_DESC
  ) {
    institutions(
      first: $first,
      skip: $skip,
      orderBy: $orderBy,
      where: {
        OR: [
          { iname_contains: $filter },
          { iaddress_contains: $filter },
          { iinstID_contains: $filter }
        ]
      }
    ) {
      id
      name
      instID
      address
      zone
      avatarUri
      count
      coordinator {
        id
      }
      organisms {
        id
      }
    }
    full: institutions {
      count
    }
    institutionsConnection {
      aggregate {
        count
      }
    }
  }
`;
