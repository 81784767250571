import React from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableHeader,
  TableBody
} from "semantic-ui-react";
import {
  SortableTableRow,
  LoadingTableRow,
  ErrorTableRow
} from "../../molecules";
import * as _ from "lodash";

/**
 * @param {{
 *  columnsDef: array,
 *  dataSource: any,
 *  isLoading: any,
 *  hasErrors: any,
 *  onRowClick: function,
 *  children: array
 * }} props
 */
const DataTable = props => {
  const {
    columnsDef,
    dataSource,
    isLoading,
    hasErrors,
    onRowClick,
    children
  } = props;

  const dataColumns = React.Children.toArray(children);

  const getHeadersDef = () => {
    if(!dataColumns.length)
      return getHeadersDefLegacy();

    return dataColumns.map(c =>
      [c.props.header, c.props.sortable ? c.props.name : null]
    );
  };

  const getHeadersDefLegacy = () => {
    return columnsDef.map(({headerName = ""}) => [headerName, null]);
  };

  const getRows = () => {
    if(!dataColumns.length)
      return getRowsLegacy();

    return dataSource.map((data, i) => {
      return (
        <TableRow
          key={data.id || i}
          onClick={ () => onRowClick && onRowClick(data) }>
          { getRowCells(data) }
        </TableRow>
      );
    });
  };

  const getRowsLegacy = () => {
    return dataSource.map((item, index) => {
      return <TableRow
        textAlign="center"
        key={index}
        onClick={ () => onRowClick && onRowClick(item) }>
        {getRowCellsLegacy(item)}
      </TableRow>;
    });
  };

  const getCellContent = (column, data) => {
    if(!dataColumns.length)
      return getCellContentLegacy(column, data);

    if(column.props.content &&
      typeof column.props.content === "function")
      return <>{ column.props.content(data, column) }</>;

    return (
      <>
        { _.get(
            data,
            column.props.content || column.props.name,
            column.props.default || "N/A") }
      </>
    );
  };

  const getCellContentLegacy = (column, rowData) => {
      const { map, component, mask } = column;
      const mapResolved = resolveMap(map, rowData[column.field]) || "";

      if(map) {
        return mapResolved;
      } else if(component) {
        return React.createElement(component, { rowData, ...props });
      } else if (mask) {
        return mask(rowData[column.field] || "");
      } else {
        return rowData[column.field] || "";
      }
  };

  const getRowCells = data => {
    if(!dataColumns.length)
      return getRowCellsLegacy(data);

    return dataColumns.map((c, i) => {
      return (
        <TableCell
          key={i}
          textAlign={c.props.textAlign || "center"}>
          { getCellContent(c, data) }
        </TableCell>
      );
    });
  };

  const getRowCellsLegacy = rowData => {
    return columnsDef.map((column, index) =>
      <TableCell key={index}>{getCellContent(column, rowData)}</TableCell>
    );
  };

  const resolveMap = (map, value) => {
      return map ? map[value] : "";
  };

  return (
    <Table
      striped
      className="Table__List"
      basic="very"
      selectable
      style={{top: "0px", position: "relative"}}>
      <TableHeader>
        <SortableTableRow headers={getHeadersDef()} />
      </TableHeader>
      <TableBody>
        { isLoading ? <LoadingTableRow /> : null }
        { hasErrors ? <ErrorTableRow /> : null }
        { dataSource === undefined ? <LoadingTableRow /> : null }
        {/* This results are used by the pagination*/}
        { (!isLoading && !hasErrors && dataSource) ? getRows() : null }
      </TableBody>
    </Table>
  );
};

/* Empty component to use as data container */
DataTable.Column = () => {
  return null;
};

export default DataTable;
