import gql from "graphql-tag";

export default gql`
  query ($id: ID!) {
    oDE( where:{ id: $id } ) {
        id
        name
        odeID
        odeCategory
        website
        facebook
        twitter
        instagram
        nationality
        city
        registrationYear
        physicalAddress
        businessName
        personType
        rfc
        phase
        technology
        trl
        industry
        sector
        timeline {
            type
            action
            user
            ode
            timestamp
        }
        team {
          id
          fullName
          systemRole
          profilePicUrl
          odes {
            id
            ode {
              id
            }
            rol
          }
        }
        network {
          id
          fullName
          systemRole
          profilePicUrl
          odes {
            id
            ode {
              id
            }
            rol
          }
        }
        organisms {
          id
          name
          institution {
            name
          }
          program {
            name
          }
        }
        batches {
          id
          name
          program {
            name
            organism {
              name
              institution {
                name
              }
            }
          }
        }
    }
  }
`;
