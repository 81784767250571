import React from 'react';
import { Form, Tab } from 'semantic-ui-react';

export default ({children, className}) => {
  const panes = React.Children.map(children, child => {
    return {
      menuItem: child.props.title,
      render: () => <Tab.Pane attached={false}>{child}</Tab.Pane>
    };
  });

  return (
    <Form className={className}>
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    </Form>
  );
}
