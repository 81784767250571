import React, { useState } from "react";
import { Grid, GridColumn, Icon, Modal } from "semantic-ui-react";
import { CREATE_USER, UPDATE_USER } from "../../../graphql/mutations/users";
import FormSection1 from "./components/formSection1";
import FormSection2 from "./components/formSection2";
import FormSection3 from "./components/formSection3";
import validations from "./validations";
import errors from "./errors";
import { FormValidator, CONSTANTS } from "../../../helpers";

const { TIMELINE_ACTION } = CONSTANTS;
const id = window.location.pathname.split("l/")[1];

const CreateUser = props => {
  // Hooks
  const [hidden1, setHidden1] = useState(true);
  const [hidden2, setHidden2] = useState(true);
  const [hidden3, setHidden3] = useState(true);
  const [errors1, setErrors1] = useState(errors.section1); // form section 1
  const [errors2, setErrors2] = useState(errors.section2); // form section 2
  const [errors3, setErrors3] = useState(errors.section3); // form section 3

  // Validate and navigate to section 2
  const next1 = data => {
    if (document.getElementById("user-id") !== null) {
      data["userID"] = document.getElementById("user-id").value;
    }

    if(props.defaultSystemRole) {
      data.systemRole = props.defaultSystemRole;
    }

    const validation = validateFrom(data, "form1");
    if (validation.isValid) {
      setHidden1(false);
      setHidden2(false);
      Object.keys(errors1).map(error => {
        errors1[error] = validation[error]["isInvalid"];
      });
      setErrors1({ ...errors1 });
    } else {
      Object.keys(errors1).map(error => {
        errors1[error] = validation[error]["isInvalid"];
      });
      setErrors1({ ...errors1 });
    }
  };

  // Validate and navigate to section 3
  const next2 = data => {
    const validation = validateFrom(data, "form2");
    if (validation.isValid) {
      setHidden2(true);
      setHidden3(false);
      Object.keys(errors2).map(error => {
        errors2[error] = validation[error]["isInvalid"];
      });
      setErrors2({ ...errors2 });
    } else {
      Object.keys(errors2).map(error => {
        errors2[error] = validation[error]["isInvalid"];
      });
      setErrors2({ ...errors2 });
    }
  };

  // Return to section 1
  const prev1 = () => {
    setHidden1(true);
    setHidden2(true);
  };

  // Return to section 2
  const prev2 = () => {
    setHidden2(false);
    setHidden3(true);
  };

  // Close modal
  const close = () => {
    props.close({ active: false });
    Object.keys(errors1).map(error => {
      errors1[error] = false;
    });
    Object.keys(errors2).map(error => {
      errors2[error] = false;
    });
    Object.keys(errors3).map(error => {
      errors3[error] = false;
    });
    setHidden1(true);
    setHidden2(true);
    setHidden3(true);
  };

  // Validate given form data
  const validateFrom = (data, form) => {
    const validator = new FormValidator(validations[form]);
    const validation = validator.validate(data);
    return validation;
  };

  // Create a user
  const createUser = async data => {
    const user = data;
    const { USER_CREATED } = TIMELINE_ACTION(user.fullName);
    user["timeline"] = USER_CREATED;
    const validation = validateFrom(data, "form3");
    const passwordCondition = data.password === data.password_confirm;
    if (passwordCondition) {
      if (validation.isValid) {
        Object.keys(errors1).map(error => {
          errors1[error] = false;
        });
        Object.keys(errors2).map(error => {
          errors2[error] = false;
        });
        Object.keys(errors3).map(error => {
          errors3[error] = false;
        });
        setErrors3({ ...errors3 });
        try {
          await CREATE_USER(data);
          props.close();
          setHidden1(true);
          setHidden2(true);
          setHidden3(true);
          window.location.reload();
        } catch (e) {
          if (e.message.indexOf("A unique constraint would be violated") > -1) {
            alert(`El correo ${user.email} ya existe`);
          }
        }
      } else {
        Object.keys(errors3).map(error => {
          errors3[error] = validation[error]["isInvalid"];
        });
        setErrors3({ ...errors3 });
      }
    } else {
      alert("Contraseñas nos coinciden");
    }
  };

  const updateUser = async data => {
    const user = data;
    const validation = validateFrom(data, "form3Edit");

    if (validation.isValid) {
      Object.keys(errors1).map(error => {
        errors1[error] = false;
      });
      Object.keys(errors2).map(error => {
        errors2[error] = false;
      });
      Object.keys(errors3).map(error => {
        errors3[error] = false;
      });
      setErrors3({ ...errors3 });
      await UPDATE_USER(user);
      props.close({ active: false });
      setHidden1(true);
      setHidden2(true);
      setHidden3(true);
      window.location.reload();
    } else {
      Object.keys(errors3).map(error => {
        errors3[error] = validation[error]["isInvalid"];
      });
      setErrors3({ ...errors3 });
    }
  };

  return (
    <Modal
      className="Modal__Form-SubModal"
      centered={false}
      open={props.openModal}
      onClose={props.closeModal}
      size="tiny"
      dimmer="blurring"
      autoComplete="new-password"
    >
      <Modal.Header as="h1">
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} floated="left">
              {props.update ? "Editar usuario" : "Nuevo usuario"}
            </Grid.Column>
            <GridColumn width={2} floated="right">
              <Icon name="close" onClick={close} />
            </GridColumn>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      {hidden1 ? (
        <FormSection1
          canDelete={ props.canDelete }
          data={props.data}
          errors1={errors1}
          handleChange={props.handleChange}
          next1={next1}
          update={props.update}
        />
      ) : null}
      {!hidden2 ? (
        <FormSection2
          data={props.data}
          errors2={errors2}
          handleChange={props.handleChange}
          next2={next2}
          prev1={prev1}
        />
      ) : null}
      {!hidden3 ? (
        <FormSection3
          data={props.data}
          errors3={errors3}
          handleChange={props.handleChange}
          createUser={createUser}
          updateUser={updateUser}
          prev2={prev2}
          update={props.update}
        />
      ) : null}
    </Modal>
  );
};

export default CreateUser;
