import gql from "graphql-tag";
import { client } from "../../";

export default async id => {
  return await client.query({
    query: gql`
      query($id: ID!) {
        batch(where: { id: $id }) {
          id
          name
          batchID
          startDate
          finishDate
          description
          odes {
            id
          }
          users {
            id
            fullName
            systemRole
            profilePicUrl
          }
          batchObjectives {
            id
            status
            startDate
            finishDate
            objective {
              name
              description
              type
              activities {
                name
                description
                activityType
                files {
                  url
                  type
                }
                hasFiles
              }
            }
          }
        }
      }
    `,
    variables: {
      id: id,
    },
  });
};
