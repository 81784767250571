import React from 'react';

export default ({label, value, placeholder}) => {
  return (
    <div>
      <p style={{ color: "#999999", fontSize: "13px" }}>{label}</p>
      <p style={{ fontSize: "15px", color: "#323C47" }}>
        { value || placeholder || 'N/A' }
      </p>
    </div>
  );
}
