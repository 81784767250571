/* eslint-disable max-lines */
/* TODO: Move methods to separated files */
import React, {useState, useEffect} from "react";
import { EntityModal } from "../../molecules";
import { FormValidator } from "../../../helpers";
import validations from "./validations";
import { default as CreateAssignedExpertServiceForm } from "./form";
import { initialState } from "./initialState";
import {
  CREATE_ASSIGNED_EXPERT_SERVICE, UPDATE_ASSIGNED_EXPERT_SERVICE
} from "../../../graphql/mutations/assignedExpertServices";

export default ({expert, open, onClose, onCreate, edit, onDelete}) => {
  const [data, setData] = useState({...initialState.data});
  const [errors, setErrors] = useState({...initialState.errors});

  useEffect(() => {
    if(edit) {
      setTimeout(() => {
        setData({
          id: edit.id,
          ode: edit.odes.map(o => o.id),
          expertServiceId: edit.expertService.id,
          startDate: edit.startDate,
          endDate: edit.endDate,
          selectedExpertService: edit.expertService,
          status: edit.status,
          duration: edit.duration,
          finished: edit.finished !== null ? edit.finished : undefined,
          odes: edit.odes
        });
      }, 1);
    }
  }, [edit]);

  const handleChange = (e, {name, value, options}) => {
    if(name === "expertServiceId") {
      const selectedExpertService = options.find(o => o.value === value);
      data.selectedExpertService = selectedExpertService ?
        selectedExpertService.object :
        undefined;
    }
    if(name === "duration") {
      value = value.toString();
    }

    data[name] = value;

    setData({ ...data });
  };

  const validateForm = data => {
    const validator = new FormValidator(validations);
    const validation = validator.validate(data);

    if(!validation.isValid) {
      Object.keys(errors).map(error => {
        errors[error] = validation[error].isInvalid;
      });

      setErrors({ ...errors });
    }
    return validation;
  };

  const createAssignedExpertService = async () => {
    const validation = validateForm(data);

    if (validation.isValid) {
      try {
        const newData = {...data};
        delete newData.selectedExpertService;

        await CREATE_ASSIGNED_EXPERT_SERVICE(expert.id, newData);
        resetData();
        onCreate();
        onClose();
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  const editAssignedExpertService = async () => {
    const validation = validateForm(data);

    if (validation.isValid) {
      try {
        const newData = {...data};
        delete newData.selectedExpertService;

        const response = await UPDATE_ASSIGNED_EXPERT_SERVICE(newData);
        resetData();
        onCreate(response.data.updateAssignedExpertService);
        onClose();
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  const resetData = () => {
   setData({...initialState.data});
    setErrors({...initialState.errors});
  };

  const handleDelete = service => {
    return service ? () => {
      onDelete(service);
    } : undefined;
  };

  return (
    <EntityModal
      title={(edit) ? "Editar servicio" : "Nuevo servicio"}
      confirmLabel="Guardar"
      open={open}
      onClose={() => { resetData(); onClose(); }}
      onDelete={handleDelete(edit)}
      onConfirm={(edit) ?
        editAssignedExpertService :
        createAssignedExpertService}>
      <CreateAssignedExpertServiceForm
        expert={expert}
        handleChange={handleChange}
        data={data}
        errors={errors}
        className="Modal__Form" />
    </EntityModal>
  );
};
