import gql from "graphql-tag";
import { client } from "../../index";

export default async ({
  teamId,
  odeId,
  userOdeRelationId }) => {
  await client.mutate({
    variables: {
      teamId,
      odeId,
      userOdeRelationId},
    mutation: gql`
      mutation($teamId: ID!, $odeId: ID!, $userOdeRelationId: ID!) {
      updateODE(
        where: {
          id: $odeId
        },
        data: {
          team: {
            update: {
              where: { id: $teamId },
              data: {
                odes: {
                  update: {
                    where: {id: $userOdeRelationId}
                    data: {
                      ode: {
                        disconnect: { id: $odeId }
                      }
                    }
                  }
                }
              }
            }
            disconnect: {
              id: $teamId
            }
          }
        }
      ){
        id
      }
    }
    `
  });
};
