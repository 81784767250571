import React from "react";
import { Container } from "semantic-ui-react";
import {
  Dashboard,
  InstitutionsList,
  CreateInstitution,
  EntityToolbar
} from "../../organisms";
import { Query } from "react-apollo";
import { INSTITUTIONS, COORDINATOR_INSTITUTIONS }
  from "../../../graphql/queries/institutions";
import SessionContext from "../../../context/userSessionContext";

const breadcrumbPath = [
  {
    label: "Instituciones",
    href: "./instituciones"
  }];

export default class InstitutionsListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first: 10,
      skip: 0,
      modalIsOpen: false,
      userData: {},
      searchQuery: "",
      orderBy: null
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onChangeOrderBy = this.onChangeOrderBy.bind(this);
    this.onPaginationChange = this.onPaginationChange.bind(this);
  }

  openModal () {
    this.setState(data => ({ ...data, modalIsOpen: true }));
  }

  closeModal () {
    this.setState(data => ({ ...data, modalIsOpen: false, userData: {} }));
  }

  onSearchChange (value) {
    this.setState(data => ({ ...data, searchQuery: value }));
  }

  onChangeOrderBy (orderBy) {
    this.setState(data => ({ ...data, orderBy: orderBy }));
  }

  onPaginationChange (skip, current) {
    this.setState(data => ({ ...data, skip: skip, current: current }));
  }

  renderDashboard (queryStatus) {
    return (
      <Dashboard
        breadcrumbPath={breadcrumbPath}
        active="institution">
        <Container className="Body__Container">
          <SessionContext.Consumer>
            {({role}) =>
              <EntityToolbar
                addText="Agregar institución"
                searchLabel="Buscar por nombre o especialidad"
                onSearchChange={this.onSearchChange}
                onAdd={role === "SUPER_ADMIN" && this.openModal} />
            }
          </SessionContext.Consumer>
          {/* Programs list */}
          <InstitutionsList
            queryStatus={queryStatus}
            onChangeOrderBy={this.onChangeOrderBy}
            onPaginationChange={this.onPaginationChange} />
        </Container>
        {/* Create program modal */}
        <CreateInstitution
          openModal={this.state.modalIsOpen}
          onClose={this.closeModal}
          close={this.closeModal}
          onSend={() => {
            queryStatus.refetch();
            }} />
      </Dashboard>
    );
  }

  getQuery (role) {
    switch (role) {
      case "SUPER_ADMIN":
        return INSTITUTIONS;
      case "COORDINATOR":
        return COORDINATOR_INSTITUTIONS;
      default:
        break;
    }
  }

  queryInstitutions (role, id) {
    const { first, skip, searchQuery, orderBy } = this.state;
    return (
      <Query
        query={ this.getQuery(role) }
        variables={{
          coordinatorId: id,
          first: first,
          skip: skip,
          filter: searchQuery,
          orderBy: orderBy
          }}
        fetchPolicy="network-only"
        errorPolicy="ignore"
        pollInterval={10000}>
        { queryStatus => {
          return this.renderDashboard(queryStatus);
        }}
      </Query>
    );
  }

  render() {
    return (
      <SessionContext.Consumer>
        {({ role, id }) => {
          if(role) {
            return role === "SUPER_ADMIN" || role === "COORDINATOR" ?
            this.queryInstitutions(role, id) :
            window.location = "/admin/organism";
          }
          else {
            window.location = "/admin/organism";
          }
        }}
      </SessionContext.Consumer>
    );
  }
}
