import React from "react";
import { List, Image, Icon } from "semantic-ui-react";

export default (props) => {
  return (
    <List>
      <List.Item>
        <Image src="https://s3.us-east-2.amazonaws.com/jaxitank-dev/image-placeholder-350x350.png" avatar floated="left" />
        <List.Content floated="left">
          <List.Header>EOS</List.Header>
          <List.Description>Super Admin</List.Description>
        </List.Content>
        <List.Content>
          <Icon name="circle" size="mini" />
        </List.Content>
      </List.Item>
    </List>
  )
}