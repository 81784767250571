import React from "react";
import { Progress, Container } from "semantic-ui-react";

export default () => {
  return (
    <Container>
      <Progress
        color="violet"
        percent={100}
        active />
    </Container>
  );
};
