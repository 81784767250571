import Maps from "./maps";
import PARSE_DATE from "../../../../helpers/formatDate";
import UserOptions from "../../../organisms/userOptions";

export const getColumnsDef = hasPermissions => {
  const columnsDef = [
    {
      headerName: "Nombre",
      field: "fullName"
    },
    {
      headerName: "Fecha de registro",
      field: "registrationDate",
      mask: unformatedDate => PARSE_DATE(unformatedDate)
    },
    {
      headerName: "Año de graduación",
      field: "graduationDate"
    },
    {
      headerName: "Escuela",
      field: "school",
      map: Maps.SCHOOL
    },
    {
      headerName: "Carrera",
      field: "career",
      map: Maps.CAREER
    },
    {
      headerName: "Categoría",
      field: "category",
      map: Maps.CATEGORY
    },
    {
      headerName: "Compañías",
      field: "status",
      mask: odes => odes.length
    },
    {
      headerName: "Estado de la oportunidad",
      field: "status",
      map: Maps.STATUS
    },
    {
      headerName: "Rol",
      field: "systemRole",
      map: Maps.USER_ROLE
    },
    {
      headerName: "",
      component: hasPermissions ? UserOptions : null
    }
  ];

  return columnsDef;
};
