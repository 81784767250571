import gql from "graphql-tag";

export default gql`
  query($id: ID!) {
    organism(where: { id: $id }) {
      id
      name
      organismID
      wasCreatedAt
      coordinator {
        id
        fullName
      }
      institution {
        id
        name
        coordinator {
          id
        }
      }
      description
    }
  }
`;
