import { gql } from "apollo-boost";
import { client } from "../../index";

export default async data => {
  return await client.mutate({
    mutation: gql`
      mutation CreateInvitation(
        $email: String!
        $systemRole: SystemRole!){
        createInvitation(data: {
          email: $email,
          systemRole: $systemRole
        }) {
          id,
          email
        }
      }
    `,
    variables: data
  });
};
