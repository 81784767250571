import React from "react";
import { GridLayout, Dashboard } from "../../organisms";
import { Card } from "../../molecules";
import FilteringControls from "./filteringControls";
import ProjectsStatistics from "./projectsStatistics";
import ProgramsStatistics from "./programsStatistics";
import CategoriesChart from "./categoriesChart";
import TLRChart from "./tLRChart";
import EntrepreneursStatistics from "./entrepreneursStatistics";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "300px",
    overflow: "display"
  }
};

export default class StatisticsDashboard extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      filterVariables: {}
    };
    this.onFiltering = this.onFiltering.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
  }

  onFiltering (key, val) {
    const filters = this.state.filterVariables;
    // If the user remove a filter, the subfilters should be
    // restarted to avoid an unexpected behaviour
    switch(key) {
      case "rangeDate":
        filters.startDate = val.startDate;
        filters.endDate = val.endDate;
        break;
      case "institutionId":
        filters.organismId = undefined;
        filters.programId = undefined;

        if(val.length > 0)
          filters.institutionId = val;
        else
          filters.institutionId = undefined;
        break;
      case "organismId":
        filters.programId = undefined;

        if(val.length > 0)
          filters.organismId = val;
        else
          filters.organismId = undefined;
        break;
      case "programId":
        filters.programId = val.length > 0 ? val : undefined;
        break;
      default:
        filters[key] = val.length > 0 ? val : undefined;
        break;
    }
    this.setState({ filterVariables: filters });
  }

  clearFilters () {
    this.setState({
      filterVariables: {}
    });
  }

  render () {
    return (
      <Dashboard active="reports">
        <div
          style={styles.container}>
          <GridLayout>
            <GridLayout.Item>
              <Card
                header="Escoge tus preferencias para filtrar tus estadísticas">
                <FilteringControls
                  clearFilters={this.clearFilters}
                  onFiltering={this.onFiltering} />
              </Card>
            </GridLayout.Item>
            <GridLayout.Item>
              <Card
                header="Proyectos">
                <ProjectsStatistics
                  filterVariables={this.state.filterVariables} />
              </Card>
              <Card
                header="Categoría">
                <CategoriesChart
                  filterVariables={this.state.filterVariables} />
              </Card>
              <Card
                header="Nivel TRL">
                <TLRChart
                  filterVariables={this.state.filterVariables} />
              </Card>
            </GridLayout.Item>
            <GridLayout.Item>
              <Card
                header="Programas">
                <ProgramsStatistics
                  filterVariables={this.state.filterVariables} />
              </Card>
              <Card
                header="Emprendedores">
                <EntrepreneursStatistics
                  filterVariables={this.state.filterVariables} />
              </Card>
            </GridLayout.Item>
          </GridLayout>
        </div>
      </Dashboard>
    );
  }
}
