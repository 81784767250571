import {
  DELETE_ASSIGNED_EXPERT_SERVICE
} from "../../../../graphql/mutations/assignedExpertServices";

export async function deleteAssignedService(service) {
  await DELETE_ASSIGNED_EXPERT_SERVICE({id: service.id});
  this.setState({
    isModalOpen: false,
    isConfirmOpen: false,
    requestRefetch: true,
    editService: undefined
  });
}
