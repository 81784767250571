export default [
  {
    field: "odeID",
    method: "isEmpty",
    validWhen: false,
    message: ""
  },
  {
    field: "rol",
    method: "isEmpty",
    validWhen: false,
    message: ""
  }
];
