import { gql } from "apollo-boost";
import { client } from "../../index";

export default async (expertId, data) => {
  delete data.ignore_whitespace;
  data.expert = { connect: { id: expertId } };
  await client.mutate({
    mutation: gql`
      mutation CreateExpertService($data: ExpertServiceCreateInput!){
        createExpertService(data: $data) {
          id
        }
      }
    `,
    variables: {
      data: data
    }
  });
};
