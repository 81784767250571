import React, { useState } from "react";
import {
  Button,
  Modal,
  Grid,
  Form,
  Icon,
  Input,
  TextArea
} from "semantic-ui-react";
import moment from "moment";
import { DateInput } from "semantic-ui-calendar-react";
import validations from "./validations";
import { CREATE_BATCH } from "../../../graphql/mutations/organisms";
import {
  FormValidator,
  CLEAN_TEXT,
  getOrganismIdFromPath
} from "../../../helpers";
import { UserDropdown } from "../../atoms";

export default ({
  programID,
  open,
  close,
  refetch
}) => {
  const [data, setData] = useState({
    name: "",
    finishDate: "",
    description: "",
    coordinators: [],
    startDate: ""
  });

  const [errors, setErrors] = useState({
    name: false,
    finishDate: false,
    startDate: false,
    description: false,
    coordinators: false
  });

  const handleChange = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });

    errors[name] = false;
    setErrors(errors);
  };

  const createBatch = async () => {
    data.instID = document.getElementById("batch-id").value;
    data.programID = programID;
    data.description = CLEAN_TEXT.UNICODE_TO_STRING(data.description);
    data.organismID = getOrganismIdFromPath(window.location.pathname);

    const validation = validateFrom(data);

    const datesAreValid =
      moment(data.startDate, "DD/MM/YYYY").diff(
        moment(data.finishDate, "DD/MM/YYYY"),
        "days"
      ) <= 0;

    if (datesAreValid) {
      errors.startDate = false;
      errors.finishDate = false;
      setErrors({ ...errors });
    } else {
      errors.startDate = true;
      errors.finishDate = true;
      setErrors({ ...errors });
    }

    if (validation.isValid && datesAreValid) {
        data.description = CLEAN_TEXT.ESCAPE(data.description);
        data.description = CLEAN_TEXT.STRING_TO_UNICODE(data.description);
        await CREATE_BATCH(data);
        refetch();
        onCloseModal();

    }
  };

  const validateFrom = data => {
    const validator = new FormValidator(validations);
    const validation = validator.validate(data);
    if (validation.isValid) {
      Object.keys(errors).map(error => {
        errors[error] = validation[error].isInvalid;
      });
      setErrors({ ...errors });
    } else {
      Object.keys(errors).map(error => {
        errors[error] = validation[error].isInvalid;
      });
      setErrors({ ...errors });
    }
    return validation;
  };

  const onCloseModal = () => {
    Object.keys(errors).map(error => {
      errors[error] = false;
    });
    setErrors({ ...errors });
    setData({});
    close();
  };

  return (
    <Modal
      className="Modal__Form-SubModal"
      centered={false}
      open={open}
      onClose={close}
      size="tiny"
      dimmer="blurring"
      autoComplete="new-password">
      <Modal.Header as="h1">
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} floated="left">
              Nuevo Batch
            </Grid.Column>
            <Grid.Column width={2} floated="right">
              <Icon name="close" onClick={onCloseModal} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      {/* Form */}
      <Modal.Content>
        <Form className="Modal__Form">
          <Form.Group widths="2">
            <Form.Field
              width={11}
              control={Input}
              label="Nombre"
              type="text"
              name="name"
              onChange={handleChange}
              required
              error={errors.name} />
            <Form.Field
              id="batch-id"
              width={5}
              control={Input}
              name="instID"
              label="ID"
              type="text"
              required
              onChange={handleChange}
              readOnly />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={UserDropdown}
              systemRole={["COORDINATOR", "SUPER_ADMIN"]}
              name="coordinators"
              label="Coordinadores"
              onChange={handleChange}
              value={data.coordinators}
              type="text"
              selection
              required
              multiple
              error={errors.coordinators} />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              name="startDate"
              autoComplete="off"
              control={DateInput}
              placeholder="Date"
              format="DD/MM/YYYY"
              value={data.startDate}
              iconPosition="right"
              onChange={handleChange}
              label="Fecha de inicio"
              width={5}
              required
              error={errors.startDate} />
            <Form.Field
              name="finishDate"
              control={DateInput}
              format="DD/MM/YYYY"
              placeholder="Date"
              autoComplete="off"
              value={data.finishDate}
              iconPosition="right"
              onChange={handleChange}
              label="Fecha de término"
              width={5}
              error={errors.finishDate}
              style={!errors.finishDate ? null : { backgroundColor: "red" }} />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={TextArea}
              name="description"
              label="Descripción"
              type="text"
              onChange={handleChange}
              required
              error={errors.description} />
          </Form.Group>
          <Form.Group widths="equal" />
          <Form.Group widths="equal">
            <Form.Field />
            <Form.Field>
              <div style={{ float: "right" }}>
                <Button primary onClick={createBatch}>
                  Crear
                </Button>
              </div>
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
    </Modal>
  );
};
