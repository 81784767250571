import React from "react";
import "c3/c3.css";
import { GridLayout, PercentageTable } from "../../organisms";
import { GET_PROGRAMS } from "../../../graphql/queries/programs";
import { useQuery } from "react-apollo-hooks";
import {
  GET_PROGRAMS_DATASOURCE as getDataSource
} from "./helpers";
import EmptyMessage from "./emptyMessage";

const columns = [
  {title: "", width: 10},
  {title: "", width: 3},
  {title: "Total", width: 3, textAlign: "right"}
];

export default ({filterVariables}) => {
  const { data, loading, error } = useQuery(GET_PROGRAMS, {
                                      variables: filterVariables
                                    });

  const chartDataSource = getDataSource(data);
  const projects = chartDataSource ?
                  chartDataSource.reduce((acc, curr) => acc + curr.total, 0)
                  : 0;

  return (
    <GridLayout>
      <GridLayout.Item
        isLoading={loading}
        hasErrors={error}>
        {
          projects > 0 ?
            <PercentageTable
              dataSource={chartDataSource}
              columns={columns} />
          : <EmptyMessage />
        }
      </GridLayout.Item>
    </GridLayout>
  );
};
