import gql from "graphql-tag";
import { client } from "../../index";

export default async ({
  id,
  fullName,
  userID,
  gender,
  area,
  phone,
  status,
  graduationDate,
  school,
  career,
  category,
  email,
  enrollment,
  systemRole
}) => {
  await client.mutate({
    mutation: gql`
      mutation {
        updateUser(
          where: { id: "${id}" }
          data: { 
             fullName:"${fullName}"
             userID:"${userID}"
             gender:${gender}
             area:${area}
             phone:"${phone}"
             status:${status}
             graduationDate:"${graduationDate}"
             school:${school}
             career:${career}
             category:${category}
             email:"${email}"
             enrollment:"${enrollment}"
             systemRole:${systemRole}
          }
        ){
          id
        }
      }
    `,
  });
};
