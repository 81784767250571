import React from "react";
import { Dropdown } from "semantic-ui-react";
import { MenuButton } from "../../atoms";

export default props => {
  const { organismId, onRowEvent } = props;

  return (
    <React.Fragment>
      <Dropdown icon={<MenuButton />} className="icon pointing List__Menu">
        <Dropdown.Menu style={{ marginLeft: "-35px" }}>
          <Dropdown.Item
            icon="edit"
            text="Editar"
            onClick={() => {
              onRowEvent({type: 'onEdit', params: { organismId }});
            }}
          />
          <Dropdown.Item
            icon="trash"
            text="Eliminar"
            onClick={() => {
              onRowEvent({type: 'onDelete', params: { organismId }});
            }}
          />
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};
