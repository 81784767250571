import React from "react";

/**
 * Renders a rounded container.
 * You can specify more style rules to change the default ones
 * @param {*} props
 * @description Usage:
 * <CircleContainer>
 *    <CustomContentComponent />
 * </CircleContainer>
 */
const CircleContainer = ({children, style}) => {

  const styles = {
    borderRadius: "50%",
    backgroundColor: "#000",
    color: "#FFF",
    width: "20px",
    height: "20px",
    textAlign: "center",
    padding: "2px",
    display: "inline-block",
    ...(style || {})
  };

  return (
    <div style={styles}>
      {children}
    </div>
  );
};

export default CircleContainer;