import React from "react";
import {
  Container,
  Grid,
  Image
} from "semantic-ui-react";

import logoImage from "../../../assets/EOSWHITE.svg";
import { RegistrationForm } from "../../molecules";

export default ({invitationId}) => {
  return (
    <Container className="entrepreneur-registration fill-background">
      <Grid>
        <Grid.Row>
          <Grid.Column className="heading" textAlign="center">
            <Image
              src={logoImage}
              centered
              width={60}
              verticalAlign="middle"
              alt="logo"
              className="Logo" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered columns={3}>
          <Grid.Column>
            <RegistrationForm invitationId={invitationId} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};
