export default [
  {
    "key": "0",
    "value": "MENTORSHIP",
    "text": "Mentoría"
  },
  {
    "key": "1",
    "value": "CONSULTING",
    "text": "Asesoría"
  },
  {
    "key": "1",
    "value": "EXPERT_SERVICE",
    "text": "Servicio de experto"
  }
]
