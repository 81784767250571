import gql from "graphql-tag";

export default gql`
  {
    objectives {
      id
      name
      description
      activities {
        id
        name
        # area
        description
        hasFiles
      }
      # programs {
      #  id
      #  name
      # }
    }
  }
`;
