import React, { useState } from "react";
import { DetailAvatarSection, DetailGridSection } from "../../molecules";
import { Grid } from "semantic-ui-react";
import { InfoCell } from "../../atoms";
import {
  MAP_EXPERT_TYPES,
  MAP_EXPERT_CATEGORIES,
  MAP_TEC_PROFILES,
  MAP_EXPERT_STATUSES
} from "../../../helpers";
import { CreateExpert } from "..";

export default ({
  expert: {
    id,
    email,
    fullName,
    avatarUri,
    expertType,
    expertCategory,
    profileTec,
    specialtyArea,
    enrollment,
    status
  }
}) => {
  const initialState = {
    isModalOpen: false,
    editId: id
  };

  const [state, setState] = useState(initialState);

  const onEdit = () => {
    setState({...state, isModalOpen: true});
  };

  const onClose = () => {
    setState({...state, isModalOpen: false, editId: id});
    /* TODO: Research why refetch is not working and remove this */
    window.location.reload();
  };

  return (
    <Grid divided="vertically">
      <CreateExpert
        open={state.isModalOpen}
        onClose={onClose}
        editId={state.editId}
        onCreate={onClose} />
      <DetailAvatarSection
        avatarUrl={avatarUri}
        title={fullName}
        subtitle={email}
        onEdit={onEdit} />
      <DetailGridSection title="Información general">
        <Grid.Row columns="equal">
          <Grid.Column>
            <InfoCell
              label="Tipo de experto"
              value={MAP_EXPERT_TYPES(expertType)} />
          </Grid.Column>
          <Grid.Column>
            <InfoCell
              label="Categoría"
              value={MAP_EXPERT_CATEGORIES(expertCategory)} />
          </Grid.Column>
          <Grid.Column>
            <InfoCell
              label="Perfil Tec"
              value={MAP_TEC_PROFILES(profileTec) || "N/A"} />
          </Grid.Column>
          <Grid.Column>
            <InfoCell
              label="Especialidad"
              value={specialtyArea} />
          </Grid.Column>
        </Grid.Row>
      </DetailGridSection>
      <DetailGridSection>
        <Grid.Row width="equal">
          <Grid.Column>
            <InfoCell
              label="Número de matrícula o nómina"
              value={enrollment} />
          </Grid.Column>
        </Grid.Row>
      </DetailGridSection>
      <DetailGridSection>
        <Grid.Row width="equal">
          <Grid.Column>
            <InfoCell
              label="Estatus"
              value={MAP_EXPERT_STATUSES(status)} />
          </Grid.Column>
        </Grid.Row>
      </DetailGridSection>
    </Grid>
  );
};
