import { client } from "../../index";
import gql from "graphql-tag";

export default async odeId => {
  return await client.mutate({
    mutation: gql`mutation {
            deleteODE(
              where: { id: "${odeId}" }              
            ) {
              id
            }
          }`,
  });
};
