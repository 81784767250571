import React, { useState, useEffect } from "react";
import {
  Grid,
  Icon,
  Modal,
  Form,
  Input,
  TextArea,
  Button,
  Container,
} from "semantic-ui-react";
import { validations } from "./validations";
import { FormValidator } from "../../../helpers";
import {
  CREATE_STRATEGY,
  UPDATE_STRATEGY,
} from "../../../graphql/mutations/strategies";
import { OBJECTIVE } from "../../../graphql/queries/strategies";

export default props => {
  const { open, close, isUpdate, objectiveId, activeObjective } = props;
  const [data, setData] = useState({ name: "", description: "" });
  const [errors, setErrors] = useState({ name: false, description: false });

  useEffect(() => {
    if (isUpdate) {
      resetHook();
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    const fetchResult = await OBJECTIVE(objectiveId);
    const objective = fetchResult.data.objective;
    data["name"] = objective["name"];
    data["description"] = objective["description"];
    setData({ ...data });
  };

  const resetHook = () => {
    setData({ name: "", description: "" });
  };

  const handleChange = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
  };

  const save = async () => {
    const validation = validateFrom(data);
    handleErrors(validation);
    if (validation.isValid) {
      data["description"] = escape(data["description"]);
      await CREATE_STRATEGY(data);
      onClose();
      window.location = "/admin/estrategias/active=0";
    }
  };

  const update = async () => {
    data["id"] = objectiveId;
    await UPDATE_STRATEGY(data);
    onClose();
    window.location = `admin/estrategias/active=${activeObjective}`;
  };

  const onClose = () => {
    setData({});
    close();
  };

  const validateFrom = form => {
    const validator = new FormValidator(validations);
    const validation = validator.validate(form);
    return validation;
  };

  const handleErrors = validation => {
    Object.keys(errors).map(error => {
      errors[error] = validation[error]["isInvalid"];
    });
    setErrors({ ...errors });
  };

  return (
    <Modal open={open} size="tiny">
      <Modal.Header as="h1">
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} floated="left">
              {isUpdate ? "Editar Objetivo" : "Nuevo objetivo"}
            </Grid.Column>
            <Grid.Column width={2} floated="right">
              <Icon name="close" onClick={onClose} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <Form style={{ margin: "0 auto", width: "80%" }}>
          <Form.Field
            label="Nombre del objetivo"
            name="name"
            control={Input}
            defaultValue={data["name"]}
            onChange={handleChange}
            error={data["name"] && data["name"] !== "" ? false : errors["name"]}
            required
          />
          <Form.Field
            label="Descripción"
            name="description"
            control={TextArea}
            value={unescape(data["description"])}
            onChange={handleChange}
            error={
              data["description"] && data["description"] !== ""
                ? false
                : errors["description"]
            }
            required
          />
        </Form>
      </Modal.Content>
      <Container style={{ textAlign: "right", padding: "50px" }}>
        <Button type="button" color="blue" onClick={isUpdate ? update : save}>
          Guardar
        </Button>
      </Container>
    </Modal>
  );
};
