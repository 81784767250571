/* eslint-disable max-lines */
/* TODO: Pass methods to single files */
import React, {useState} from "react";
import { Grid, Header, Message, Confirm, Icon, Modal } from "semantic-ui-react";
import { DetailCardsSection } from '../../molecules';
import { Query } from 'react-apollo';
import { EXPERT_SERVICES } from '../../../graphql/queries/expertServices';
import { MAP_EXPERT_SERVICE_TYPES } from '../../../helpers';
import { PlusButton } from '../../atoms';
import { CreateExpertService } from '..';
import deleteExpertService from '../../../graphql/mutations/expertServices/deleteExpertService';
import { async } from 'q';
import { DELETE_EXPERT_SERVICE } from '../../../graphql/mutations/expertServices';
import AlertIcon from "mdi-react/AlertIcon";

export default ({expert}) => {
  const [state, setState] = useState({
    isModalOpen: false,
    isConfirmOpen: false,
    shouldRefetch: false,
    selectedService: undefined,
    showAlert: false
  });

  const deleteServiceAlert = <Modal.Content>
    <Message icon error>
      <Icon as={AlertIcon} />
      <Message.Content>
        No se puede eliminar el servicio porque se encuentra asignado.
        Si deseas eliminarlo primero bórralo de
        los <strong>servicios asignados</strong> de este experto.
      </Message.Content>
    </Message>
  </Modal.Content>;

  /* TODO: Move to helper */
  const requestRefetch = (refetch) => {
    if(state.shouldRefetch) {
      state.shouldRefetch = false;
      refetch();
    }
  };

  const onAdd = () => {
    setState({isModalOpen: true});
  }

  const onClose = () => {
    setState({isModalOpen: false});
  }

  const onCreate = () => {
    setState({shouldRefetch: true});
  }

  const onCardEvent = (eventType, service) => {
    if(eventType === "delete") {
      setState({isConfirmOpen: true, selectedService: service});
    }
  };

  const deleteExpertService = async () => {
    try {
      await DELETE_EXPERT_SERVICE({id: state.selectedService.id});
      setState({
        isConfirmOpen: false,
        shouldRefetch: true,
        selectedService: undefined
      });
    }
    catch(e) {
      if(e.message.match(/required relation/gi)) {
        setState({...state, isConfirmOpen: false, showAlert: true});
      }
    }
  };

  const formatItems = (items) => {
    if(!items)
      return [];

    return items.map(i => {
      const hours = `${i.duration} horas`;
      const price = `${i.price}MXN`;

      const extraRight = i.duration !== "" ?
        `${hours}-${price}` :
        price;

      return {
        id: i.id,
        title: i.name,
        description: i.description,
        extraLeft: MAP_EXPERT_SERVICE_TYPES(i.type),
        extraRight: extraRight
      };
    });
  };

  return (
    <div>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header as="h3" floated="left">
              <Header.Content>
                Servicios
              </Header.Content>
            </Header>
            <Header as="h3" floated="right">
              <Header.Content>
                <PlusButton open={() => onAdd()} />
              </Header.Content>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Query query={EXPERT_SERVICES} variables={{ expertId: expert.id }}>
        {({loading, error, data, refetch}) => {
          requestRefetch(refetch);

          if(loading)
            return null;

          if(error)
            return <Message negative>Ha ocurrido un error al cargar los servicios del experto. Intentalo de nuevo más tarde</Message>

          return <DetailCardsSection
            onCardEvent={onCardEvent}
            className="expert-services-cards"
            items={formatItems(data.expertServices)}
            emptyMessage="No hay servicios registrados para este experto. Utiliza el botón del lado derecho para agregar uno nuevo." />
        }}
      </Query>

      <CreateExpertService
        expert={expert}
        open={state.isModalOpen}
        onClose={onClose}
        onCreate={onCreate} />
      <Confirm
        open={state.showAlert}
        header="Eliminar servicio"
        content={deleteServiceAlert}
        onConfirm={() => setState({...state, showAlert: false})}
        cancelButton={<></>} />
      <Confirm
        open={state.isConfirmOpen}
        onCancel={() => setState({isConfirmOpen: false})}
        onConfirm={() => deleteExpertService()}
        header="Eliminar servicio"
        content="Esta acción no es reversible." />
    </div>
  )
}
