import React from "react";
import {
  Container,
  Form,
  Grid,
  Label,
  Loader,
  Dimmer,
  Input,
} from "semantic-ui-react";
import { Query } from "react-apollo";
import { ProjectDropdown } from "../../../atoms";
import { CONSTANTS } from "../../../../helpers";

const { TIMELINE_ACTION } = CONSTANTS;

const UserOdes = props => {
  const {
    disconnectOde,
    disconnectUserFromOde,
    refetch,
    odes,
    userId,
    odeId,
    userName,
    odeName,
  } = props;

  return (
    <Grid>
      {odes.map((ode, index) => {
        const userOdeId = ode.id;
        if (ode.ode[0]) {
          return (
            <Grid.Row key={index}>
              <Grid.Column width={5}>
                <span style={{ color: "#4c4c4c" }}>ODE</span>
                <br />
                <Label
                  as="a"
                  basic
                  color="blue"
                  style={{ width: "100px", overflow: "hidden" }}
                >
                  <p style={{ color: "#4c4c4c" }}>{ode.ode[0].name}</p>
                </Label>
              </Grid.Column>
              <Grid.Column width={3}>
                <span style={{ color: "#4c4c4c" }}>Rol</span>
                <br />
                <Label
                  as="a"
                  basic
                  color="blue"
                  style={{ width: "170px", overflow: "hidden" }}
                >
                  <p style={{ color: "#4c4c4c" }}>{ode.rol}</p>
                </Label>
              </Grid.Column>
              <Grid.Column
                width={8}
                floated="right"
                style={{ textAlign: "right" }}
              >
                <a
                  href="#"
                  onClick={async () => {
                    const {
                      action,
                      user,
                      ode,
                      type,
                      timestamp,
                    } = TIMELINE_ACTION(userName, odeName).MEMBER_DELETED;

                    await disconnectOde(
                      userId,
                      userOdeId,
                      action,
                      type,
                      timestamp,
                      user,
                      ode,
                      "Dev"
                    );
                    await disconnectUserFromOde({
                      userID: userId,
                      odeID: odeId,
                      action,
                      type,
                      timestamp,
                      user,
                      ode,
                    });
                    refetch();
                  }}
                >
                  Eliminar
                </a>
              </Grid.Column>
            </Grid.Row>
          );
        }
      })}
    </Grid>
  );
};

export default class Odes extends React.Component {
  constructor(props) {
    super(props);
    const {
      queryManual,
      userId,
      disconnectOde,
      connectOde,
      connectUserToOde,
      disconnectUserFromOde,
    } = this.props;

    this.state = {
      query: queryManual,
      userId,
      odeName: "",
      disconnectOde,
      connectOde,
      connectUserToOde,
      disconnectUserFromOde,
      odeId: null,
      rolError: false,
    };
  }

  handleChange = (e, { value }) => {
    let odeId = this.state.odeId;
    odeId = value.id;
    this.setState(data => ({ ...data, odeId, odeName: value.name }));
  };

  handleChangeRol = (e, { name, value }) => {
    this.setState(data => ({ ...data, [name]: value }));
  };

  render() {
    const {
      query,
      userId,
      odeId,
      odeName,
      disconnectOde,
      connectOde,
      connectUserToOde,
      disconnectUserFromOde,
    } = this.state;
    return (
      <Query
        query={query}
        variables={{ id: userId }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, refetch }) => {
          if (loading) {
            return (
              <Dimmer active>
                <Loader>Cargando</Loader>
              </Dimmer>
            );
          }
          if (error) {
            return (
              <Dimmer active>
                <Loader>Error</Loader>
              </Dimmer>
            );
          }

          return (
            <Container style={{ margin: "30px auto", width: "100%" }}>
              <Form style={{ margin: "0 auto", width: "100%" }}>
                <Form.Group widths="equal">
                  <Form.Field
                    name="ode-name"
                    control={ProjectDropdown}
                    onChange={this.handleChange}
                    placeholder="Escribe el nombre del proyecto"
                    width={8}
                  />
                  <Form.Field
                    name="rol"
                    control={Input}
                    onChange={this.handleChangeRol}
                    placeholder="Rol de usuario"
                    width={4}
                    required
                    error={
                      this.state.rol && this.state.rol !== ""
                        ? false
                        : this.state.rolError
                    }
                  />
                  <Form.Button
                    width={3}
                    onClick={async () => {
                      const { rol } = this.state;
                      if (rol && rol !== "") {
                        const {
                          action,
                          user,
                          ode,
                          type,
                          timestamp,
                        } = TIMELINE_ACTION(
                          data.user.fullName,
                          odeName
                        ).MEMBER_ADDED;
                        await connectOde(
                          userId,
                          odeId,
                          action,
                          type,
                          timestamp,
                          user,
                          ode,
                          this.state.rol
                        );
                        await connectUserToOde({
                          userID: userId,
                          odeID: odeId,
                          action,
                          type,
                          timestamp,
                          user,
                          ode,
                        });
                        refetch();
                      } else {
                        this.setState({ rolError: true });
                      }
                    }}
                    content="Agregar"
                    color="blue"
                    fluid
                  />
                </Form.Group>
              </Form>
              <br />
              <UserOdes
                disconnectOde={disconnectOde}
                disconnectUserFromOde={disconnectUserFromOde}
                refetch={refetch}
                odes={data.user.odes}
                userId={userId}
                odeId={odeId}
                userName={data.user.fullName}
                odeName={this.state.odeName}
              />
            </Container>
          );
        }}
      </Query>
    );
  }
}
