import React from "react";
import { default as Row } from "./row";

export default ({ data, onClickRow, onRowEvent }) => {
  return data.organisms.map(organism => {
    return <Row
          organismData={organism}
          key={organism.id}
          onClick={onClickRow}
          onRowEvent={onRowEvent} />
  });
}
