import gql from "graphql-tag";
import { client } from "../../index";

export default async ({ id, name, description }) => {
  await client.mutate({
    mutation: gql`
      mutation{
        updateObjective(
            where:{
                id:"${id}"
            }
            data:{
                name:"${name}"
                description:"${description}"
            }
        ){
            id
        }
    }
    `,
  });
};
