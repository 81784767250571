import React, { useState, useEffect } from "react";
import { Dropdown, Image } from "semantic-ui-react";
import { client } from "../../../graphql";
import { USER } from "../../../graphql/queries/users";
import { ProfileItem } from "../../molecules";
import { getUserProfilePicUrl } from "../../../helpers/getUserProfilePicUrl";
import { AuthConsumer } from "../../../AuthContext";
import SessionContext from "../../../context/userSessionContext";

export default props => {
  /**
   * Antipattern warning!
   * _initialState is just for testing, don't use it outside
   * that context
   */
  const [data, setData] = useState(props._initialState || { user: null });

  useEffect(() => {
    const getUser = async () => {
      const query = await client.query({
        fetchPolicy: "no-cache",
        variables: { id: props.userId },
        query: USER
      });
      const { user } = query.data;
      const { fullName, systemRole, phone, email, userID, organisms } = user;
      setData({user: { fullName, systemRole, phone, email, userID, organisms }});
    };
    getUser();
  }, []);

  const dropdownRef = React.createRef();

  const handleClick = e => {
    dropdownRef.current.toggle();
  };

  return (
    <AuthConsumer>
      {({logout}) => {
        return(
          <SessionContext>
            {({clearSession}) => {
              return(
                <span onClick={handleClick} className="SessionDropdown">
                  <Image
                    src={getUserProfilePicUrl(data.user)}
                    avatar
                    size="mini" />
                  <Dropdown
                    ref={dropdownRef} floating
                    direction="left">
                    <Dropdown.Menu className="inverted">
                      <Dropdown.Header>Perfil</Dropdown.Header>
                      <Dropdown.Item>
                        <ProfileItem user={data.user} editable={false} />
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item onClick={() => { clearSession(); logout(); }}>Cerrar sesión</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
              );
            }}
          </SessionContext>

        );
      }}
    </AuthConsumer>
  );
};
