import gql from "graphql-tag";

/**
 * This query allows to get the programs
 * of an specific organism
 */
export default gql`
    query ($organismId: ID, $institutionId: ID) {
    programs (
      where: {
        organism: {
          AND: [
            { id: $organismId },
            {
              institution: {
                id: $institutionId
              }
            }
          ]
        }
      }
    ) {
      id
      name
  	}
  }
`;
