import React from "react";
import WebIcon from "mdi-react/WebIcon";
import TwitterIcon from "mdi-react/TwitterIcon";
import FacebookIcon from "mdi-react/FacebookIcon";
import InstagramIcon from "mdi-react/InstagramIcon";
import { urlVerifierTools } from "../../../helpers";

export default ({website, twitter, facebook, instagram}) => {
  const valid = url => {
    return url && (
        url !== "undefined" &&
        url !== undefined &&
        url !== null &&
        url !== "" &&
        url !== "null"
      );
  };

  let urls = {website, twitter, facebook, instagram};
  urls = Object.keys(urls).map(key => {
    return urlVerifierTools.getUrlWithHTTP(urls[key]);
  });

  return (
    <div>
      { valid(website) ?
        <a
          style={{ marginRight: 10 }}
          href={urls[0]}
          target="_blank">
          <WebIcon color="#0094D4" />
        </a>
      : null }

      { valid(twitter) ?
        <a
          style={{ marginRight: 10 }}
          href={urls[1]}
          target="_blank">
          <TwitterIcon color="#0094D4" />
        </a>
      : null }

      { valid(facebook) ?
        <a
          style={{ marginRight: 10 }}
          href={urls[2]}
          target="_blank">
          <FacebookIcon color="#0094D4" />
        </a>
      : null }

      { valid(instagram) ?
        <a
          style={{ marginRight: 10 }}
          href={urls[3]}
          target="_blank">
          <InstagramIcon color="#0094D4" />
        </a>
      : null }
    </div>
  );
}
