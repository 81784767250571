import React, { useState } from 'react';
import { Grid, Menu } from 'semantic-ui-react';

export default ({children}) => {
  
  const menuColumnStyle = {
    padding: "0px 0px 4px"
  }

  const menuStyle = {
    float: "right"
  }

  const tabs = React.Children.map(children, child => {
    return {
      name: child.props.name,
      active: child.props.active,
      component: child
    };
  });

  const activeTab = tabs.find(tab => tab.active);

  const [state, setState] = useState({activeTab: activeTab});

  const onTabClick = (tab) => {
    setState({activeTab: tab});
  };

  return (
    <Grid celled="internally">
      <Grid.Row>
        <Grid.Column
          floated="right"
          width={12}
          style={menuColumnStyle}>
          <Menu
            pointing
            secondary
            compact
            style={menuStyle}>
            {tabs.map((tab) => {
              return (
                <Menu.Item
                  name={tab.name}
                  active={tab.name === state.activeTab.name}
                  onClick={() => onTabClick(tab)} />
              );
            })}
          </Menu>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className="no-padding">
          { state.activeTab ? state.activeTab.component : children }
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
