import React, { Fragment } from "react";
import { Container, Confirm } from "semantic-ui-react";
import {
  Dashboard,
  CreateODE,
  EntityToolbar
} from "../../organisms";
import SessionContext from "../../../context/userSessionContext";
import { EntityList } from "../../organisms";
import { TableBody } from "./components";
import { ODES, USER_ODES, EXPERT_ODES } from "../../../graphql/queries/odes";
import { EditODE } from "../../organisms";
import { DELETE_ODE } from "../../../graphql/mutations/odes";
import { remove as removeAccents } from "remove-accents";

const headers = [
  ["Categoría", null],
  ["Nombre", null],
  ["Nacionalidad", null],
  ["Ciudad", null],
  ["Estado", null],
  ["Fase", null],
  ["Industria", null],
  ["Nivel de TRL", null],
  ["Tecnología", null],
  ["", null]
];

const breadcrumbPath = [
  {
    label: "Compañías",
    href: "./ode"
  }];

export default class ODESListView extends React.Component {
  state = {
    idOde: "",
    confirm: false,
    editOdeIsOpen: false,
    current: 1,
    sortColumn: null,
    sortDirection: "ascending",
    openModal: false,
    closeModal: false,
    userData: {},
    first: 10,
    skip: 0,
    searchQuery: "",
    orderBy: null,
    odeData: {},
    odes: []
  };

  openModal = () => {
    this.setState(data => ({ ...data, openModal: true }));
  }

  closeModal = () => {
    this.setState(data => ({ ...data, openModal: false, userData: {} }));
  }

  onSearchChange = value => {
    this.setState(data => ({
      ...data,
      searchQuery: removeAccents(value.toLowerCase()),
      skip: 0,
      current: 1
    }));
  }

  onChangeOrderBy = orderBy => {
    this.setState(data => ({ ...data, orderBy }));
  }

  onPaginationChange = (skip, current) => {
    this.setState(data => ({ ...data, skip, current }));
  }

  requestRefetch = refetch => {
    if (this.state.shouldRefetch) {
      this.state.shouldRefetch = false;
      refetch();
    }
  }

  setEditOdeState = idOde => {
    this.setState({ idOde, editOdeIsOpen: true });
  }

  setConfirm = () => {
    this.setState({ confirm: true });
  }

  setOdeId = idOde => {
    this.setState({ idOde });
  }

  onRowEvent = event => {
    switch (event.type || "") {
      case "onEdit":
        const selectedOdeId1 = event.params ? (event.params.idOde || "") : "";
        this.setState({ idOde: selectedOdeId1, editOdeIsOpen: true });
        break;
      case "onDelete":
        const selectedOdeId2 = event.params ? (event.params.idOde || "") : "";
        this.setState({ idOde: selectedOdeId2, confirm: true });
        break;
      default:
        break;
    }
  }

  onClickRow = idOde => {
    window.location = `/admin/ode/${idOde}`;
  }

  onPrev = () => {
    if (this.state.current > 1) {
      const skip = this.state.skip - 10;
      const current = this.state.current - 1;
      this.setState(data => ({
        ...data,
        current: current,
        skip: skip
      }));
    }
  }

  onNext = (odes, totalRecords) => {
    if (
      odes.length > 0 &&
      (odes ? odes.length === 10 : false)
    ) {
      if ((this.state.skip + 10) < totalRecords) {
        const skip = this.state.skip + 10;
        const current = this.state.current + 1;

        this.setState(data => ({
          ...data,
          current: current,
          skip: skip
        }));
      }
    }
  }

  render() {
    return (
      <Dashboard
        breadcrumbPath={breadcrumbPath}
        active="ode">
        <Container className="Body__Container">
          <SessionContext.Consumer>
            {({ role, id }) => {
              return (
                <Fragment>
                  {/* Toolbar with search input  & create button */}
                  <EntityToolbar
                    addText="Crear compañía"
                    searchLabel="Buscar"
                    onSearchChange={this.onSearchChange}
                    onAdd={this.openModal}
                    showCreateButton={(role === "SUPER_ADMIN" || role === "COORDINATOR") ? true : false} />
                  {/* ODES list */}
                  { role ?
                    <EntityList
                      query={{
                        query: role ? (role === "SUPER_ADMIN" ? ODES : (role === "ADVISER" ? EXPERT_ODES : USER_ODES)) : "",
                        variables: {
                          userId: id,
                          first: this.state.first,
                          skip: this.state.skip,
                          search: this.state.searchQuery,
                          orderBy: this.state.orderBy
                        },
                        fetchPolicy: "network-only",
                        errorPolicy: "ignore",
                        pollInterval: 10000
                      }}
                      paginationLabel="Compañías"
                      tableBodyComponent={<TableBody />}
                      headers={headers}
                      requestRefetch={this.requestRefetch}
                      role={role}
                      onRowEvent={this.onRowEvent}
                      onClickRow={this.onClickRow}
                      propertyKey="oDEs"
                      current={this.state.current}
                      onPrev={this.onPrev}
                      onNext={this.onNext} />
                    : null}
                </Fragment>
              );
            }}
          </SessionContext.Consumer>
          {/*<ODEList/>*/}
        </Container>
        {/* Create ODE modal */}
        <CreateODE
          openModal={this.state.openModal}
          onClose={this.state.closeModal}
          close={this.closeModal} />

        {this.state.editOdeIsOpen && (
          <EditODE
            idOde={this.state.idOde}
            open={this.state.editOdeIsOpen}
            onClose={() => {
              this.setState({
                editOdeIsOpen: !this.state.editOdeIsOpen
              });
            }} />)}
        {this.state.confirm ? (
          <Confirm
            open={this.state.confirm}
            onCancel={() => {
              this.setState({ confirm: !this.state.confirm });
            }}
            onConfirm={async () => {
              await DELETE_ODE(this.state.idOde);
              window.location.reload();
            }}
            content="Esta acción no es reversible" />
        ) : null}
      </Dashboard>
    );
  }
}
