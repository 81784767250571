import gql from "graphql-tag";
import { client } from "../../index";

export default async ({ id, name, description, type, activities }) => {
  const _activities = [];
  const _activitiesCondition = activities ? activities : [];
  _activitiesCondition.map(activity => {
    delete activity["id"];
    delete activity["__typename"];
    activity["activityType"] = activity["activityType"].replace(/"/g, "");
    const _files = [];
    activity["files"].map(file => {
      delete file["__typename"];
      delete file["id"];
      file["type"] = "null";
      _files.push(file);
    });
    activity["files"] = { create: _files };
    _activities.push(activity);
  });
  await client.mutate({
    variables: {
      activities: _activities,
      name,
      description,
      type,
      id
    },
    mutation: gql`
            mutation($activities:[ActivityCreateWithoutObjectivesInput!],
                      $id: ID!,
                      $name: String!,
                      $description: String!,
                      $type: ObjectiveType!) {
                        updateOrganism (
                            where: { id: $id }
                            data: {
                              objectives: {
                                create: {
                                  name: $name,
                                  description: $description,
                                  type: $type
                                  activities: { create: $activities }
                                }
                              }
                            }
                        ) {
                            id
                          }
                        }
        `,
  });
};
