import React, { Fragment } from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import lodash from "lodash";
import {
  Feed,
  Container,
  Segment,
  Dimmer,
  Loader,
  Grid,
} from "semantic-ui-react";
import Identicon from "react-identicons";
import { AddUser } from "../../../../organisms";
import { PlusButton } from "../../../../atoms";
import OrganismMembersList from "./OrganismMembersList";

const id = window.location.pathname.split("/")[3];

export default class UserList extends React.Component {
  state = {
    openModal: false,
    closeModal: false,
    userData: {},
  };
  openModal = () => {
    this.setState(data => ({ ...data, openModal: true }));
  };
  
  closeModal = () => {
    this.setState(data => ({ ...data, openModal: false, userData: {} }));
  };

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} floated="left" style={{ fontSize: "10px" }}>
              Miembros de Organismo
            </Grid.Column>
            <Grid.Column width={3} floated="right">
              {
                this.props.hasPermissions &&
                <PlusButton open={this.openModal} style={{ padding: "15px" }} />
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Query
          query={gql`
                    {
                        organism(where:{ id:"${id}" } )
                        {
                            id
                            team {
                              id
                              fullName
                              organismRole
                              userID
                            }
                        }
                    }
                    `}
          fetchPolicy="network-only"
        >
          {({ loading, error, data }) => {

            if (loading) {
              return (
                <Segment className="Ode__Detail__ContainerTabs">
                  <Dimmer>
                    <Loader />
                  </Dimmer>
                </Segment>
              );
            }
            if (error) {
              return <p>No existen usuarios asignados a este organismo</p>;
            }

            if (data.organism === null) {
              return <p>No existen usuarios asignados a este organismo</p>;
            }

            const _data = data.organism ? data.organism : [];
            const { fullName, systemRole } = _data?.coordinator || {};
            const organismMembers = lodash.get(data, "organism.team", []);
            
            // This const contains the user IDs to exclude from the Dropdown list items
            const usersToExclude = organismMembers
                                    .map(member => member.id);
            return (
              <Fragment>
                <AddUser
                  usersToExclude={usersToExclude}
                  openModal={this.state.openModal}
                  onClose={this.state.closeModal}
                  close={this.closeModal} />
                <Container
                  style={{ width: "100%", background: "white", padding: "10px" }}
                >
                  <Grid style={{ borderBottom: "1px solid #dddddd" }}>
                    <Grid.Row>
                      <Grid.Column>
                        <Feed>
                          <Feed.Event>
                            <Feed.Label>
                              <Identicon size={35} string={fullName} />
                            </Feed.Label>
                            <Feed.Content style={{ marginTop: "-2.5px" }}>
                              <Feed.Summary
                                style={{ fontSzie: "11px", fontWeight: "normal" }}
                              >
                                {fullName}
                              </Feed.Summary>
                              <Feed.Meta
                                style={{ fontSize: "11px", color: "#999999" }}
                                content={systemRole}
                              />
                            </Feed.Content>
                          </Feed.Event>
                          <OrganismMembersList members={organismMembers} />
                        </Feed>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Container>
              </Fragment>
            );
          }}
        </Query>
      </div>
    );
  }
}
