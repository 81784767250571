import React from "react";
import { Query } from "react-apollo";
import { Segment, Dimmer, Loader } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import { Dashboard, DetailComponent } from "../../organisms";
import { ORGANISM } from "../../../graphql/queries/organisms";
import { getBreadcrumbPath } from "./helpers";
import { InformationTab, ProgramsTab, ObjectivesTab } from "./components";

const id = window.location.pathname.split("/")[3];
export default () => {

  const renderContent = (loading, error, data, refetch) => {
    if (loading) {
      return (
        <Segment className="Ode__Detail__ContainerTabs">
          <Dimmer>
            <Loader />
          </Dimmer>
        </Segment>
      );
    }
    if (error) {
      return <p>Error...</p>;
    }

    if (data.organism === null) {
      return <Redirect to="/404" />;
    }

    return (
      <DetailComponent
        tabs={[
          {
            index: 0,
            text: "Información",
            value: "information",
            content: (
              <InformationTab data={data.organism} refetch={refetch} />
            )
          },
          {
            index: 1,
            text: "Programas",
            value: "programs",
            content: <ProgramsTab
              organismData={data.organism}
              refetch={refetch} />
          },
          {
            index: 2,
            text: "Objetivos",
            value: "objectives",
            content: <ObjectivesTab
              organismData={data.organism}
              refetch={refetch} />
          }
        ]} />
    );
  };

  return (
    <Query
      query={ORGANISM}
      variables={{ id: id }}
      fetchPolicy="network-only">
      {({ loading, error, data, refetch }) => {
        return (
          <Dashboard
            active="organism"
            breadcrumbPath={getBreadcrumbPath(data)}>
            {renderContent(loading, error, data, refetch)}
          </Dashboard>
        );
      }}
    </Query>
  );
};
