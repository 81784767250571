import React from "react";
import {
  Modal,
  Message,
  Icon,
  Loader,
  Dimmer,
  Segment
} from "semantic-ui-react";
import AlertIcon from "mdi-react/AlertIcon";
import CheckIcon from "mdi-react/CheckIcon";

export default ({sending, sessionId}) => {
  return (
    <Modal.Content>
      <Message icon error>
        <Icon as={AlertIcon} />
        <Message.Content>
          Por favor, espera un momento en lo que generamos
          un reporte.
        </Message.Content>
      </Message>
      { !sending ?
        <Message icon success>
          <Icon as={CheckIcon} />
          <Message.Content>
            Se ha enviado el reporte del error. Disculpa las molestias.
            Puedes <strong>continuar</strong> utilizando la aplicación
            pero podría comportarse de manera inestable, te
            recomendamos <strong>refrescar</strong> la página.

            { sessionId ? <p>ID: {sessionId}</p> : null }
          </Message.Content>
        </Message>
      : <Segment><Dimmer active inverted><Loader active /></Dimmer></Segment> }
    </Modal.Content>
  );
};
