import React from "react";
import { Grid, Segment } from "semantic-ui-react";
import "./styles.css";

const DetailsContainer = ({children}) => {
  return (
    <>
      <Segment
        className="detail-container"
        style={{ padding: "0px" }}>
        <Grid className="grid-container">
          <Grid.Row>
            <Grid.Column
              className="main-content-column"
              style={{height: `${window.innerHeight}px`, padding: "0px"}}
              width={16}>
              {/*children*/}
              <Grid className="grid-container">
                <Grid.Row>
                  {children}
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
};

DetailsContainer.Content =
  (props = {
    children: undefined,
    width: undefined,
    floated: undefined
  }) => {
  return (
    <Grid.Column
      className="details-container-content"
      style={{height: `${window.innerHeight}px`, padding: "0px"}}
      width={props.width || 16}
      {...props}>
      {props.children}
    </Grid.Column>
  );
};

export default DetailsContainer;
