import React from "react";

export const InfoLabel = ({ children = null }) => {
  const style = {
    color: "#999999",
    fontSize: "11px",
    textAlign: "center"
  };
  return (
    <>
      <p style={style}>
        {children}
      </p>
    </>);
};
