import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, Typography, message, Alert } from "antd";
import { ExpertAuthService } from "../../../services/expert/auth";
import { genericFieldValidationMsgs } from "../../../utils";
import { formStyle, loginErrorLabel } from "./styles";
import { clearString, removeNonAscii } from "../../../helpers";
const logoImg = require("../../../../assets/EOSPURPLE@3x.png");

/**
 * @param {*} mode "signin" | "signup"
 * @param {*} signingFunction the query or mutation function
 * to perform on submit
 */
export default function RegisterForm({
  mode = "signup",
  signingFunction,
  invitedEmail
}) {
  const [state, setState] = useState({
    loading: false,
    loginError: false,
    signUpError: false,
    credentials: {
      email: undefined,
      password: undefined
    },
    showPasteWarning: false
  });

  const [form] = Form.useForm();
  const expertAuthService = new ExpertAuthService();

  useEffect(() => {
    if (invitedEmail) {
      form.setFieldsValue({ email: invitedEmail?.trim() });
    }
  }, [invitedEmail]);

  const setLoading = (loading = false) =>
    setState(state => ({
      ...state,
      loading
    }));

  const setLoginError = (loginError = false) =>
    setState(state => ({
      ...state,
      loginError
    }));

  const setSignUpError = (signUpError = false) =>
    setState(state => ({
      ...state,
      signUpError
    }));

  const updateCredentials = (updatedData = {}) => {
    expertAuthService.setUpdateCredentials(updatedData, state, setState);
  };

  const handleOnFieldsChange = (changedFields = []) => {
    state.loginError && setLoginError(false);
    state.signUpError && setSignUpError(false);

    const { name = [], value } = changedFields[0] || {};
    const fieldName = name[0];

    fieldName && updateCredentials({
        [fieldName]: value
      });
  };

  const handleSubmit = async () => {
    await form.validateFields();
    const fieldsValues = form.getFieldsValue();
    fieldsValues.email = clearString(removeNonAscii(fieldsValues.email));
    fieldsValues.password = clearString(fieldsValues.password);

    try {
      setLoading(true);
      await expertAuthService.setAuthAccount(
        fieldsValues,
        signingFunction
      );
    } catch (error) {
      if (mode === "signin" && error === "INVALID_CREDENTIALS") {
        setLoginError(true);
      } else if (mode === "signup" && error.message.match(/unique constraint would be violated/)) {
        setSignUpError(true);
      } else {
        console.error("unexpected error: ", error);
        message.error("Ocurrió un error");
      }
    } finally {
      setLoading(false);
    }
  };

  const checkPasteWarning = () => {
    setState(prevState => ({
      ...prevState,
      showPasteWarning: true
    }));
  };

  return (
    <>
      <Form
        form={form}
        validateMessages={genericFieldValidationMsgs}
        onFieldsChange={handleOnFieldsChange}
        style={formStyle}>
        <div style={{ marginBottom: "14px" }}>
          <img src={logoImg} width="87px" />
        </div>
        <Typography.Paragraph>
          {mode === "signup" ?
            "Bienvenido. Ingresa una contraseña para tu nueva cuenta."
            : "Bienvenido. Es un placer tenerte de vuelta." }
        </Typography.Paragraph>
        {state.showPasteWarning ?
          <Alert
            style={{ marginTop: "14pt", marginBottom: "14pt" }}
            type="warning"
            message={"Al copiar y pegar tu correo o contraseña verifica que\
              no tenga caracteres extra o incorrectos."} />
          : null}
        <Form.Item
          rules={[{ required: true, type: "email" }]}
          name="email">
          <Input
            onPaste={checkPasteWarning}
            autoFocus={!invitedEmail}
            disabled={!!invitedEmail}
            placeholder="Correo electrónico" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="password">
          <Input.Password
            onPaste={checkPasteWarning}
            onPressEnter={handleSubmit}
            placeholder="Contraseña" />
        </Form.Item>
        <p style={loginErrorLabel}>
          { state.loginError && "Usuario o contraseña incorrectos" }
          { state.signUpError && "El correo ya se encuentra registrado" }
        </p>
        <Button
          onClick={handleSubmit}
          loading={state.loading}
          style={{ width: "100%" }}
          type="primary">
          { mode === "signin" && "Iniciar sesión" }
          { mode === "signup" && "Crear cuenta" }
        </Button>
        <Link to="/password-reset/request">
          <Button
            style={{ width: "100%" }}
            type="link">
            Recuperar contraseña
          </Button>
        </Link>
      </Form>
      <div id="privacy-policy-terms">
        Al crear tu cuenta aceptas los&nbsp;
        <strong>
          Términos y Condiciones <br /> y Políticas de Privacidad&nbsp;
        </strong>
        disponibles en los siguientes enlaces:
      </div>
      <div id="privacy-policy-terms">
        <a target="_blank" href="https://tec.mx/es/aviso-privacidad-instituto-emprendimiento">
          Política de privacidad aplicable para emprendedores
        </a>
        <br />
        <a target="_blank" href="https://tec.mx/es/aviso-privacidad-mentores-de-emprendimiento">
          Política de privacidad aplicable para expertos
        </a>
      </div>
    </>
  );
}
