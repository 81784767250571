import React from "react";
import { Dropdown } from "semantic-ui-react";
import { ASSIGNED_EXPERT_SERVICE_STATUSES } from "../../../helpers";
import {
  renderLabel
} from "../../organisms/assignedExpertServiceDetail/renderLabel";

export default ({name, error, onChange, upward, value}) => {
  const options = ASSIGNED_EXPERT_SERVICE_STATUSES.map(s => {
    s.content = renderLabel({status: s.value});
    return s;
  });

  return (
    <>
      <Dropdown
        upward={upward}
        selection
        options={options}
        name={name}
        error={error}
        onChange={onChange}
        value={value} />
    </>
  );
};
