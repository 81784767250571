import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Grid,
  Icon,
  Input,
  Loader,
  Modal,
  TextArea
} from "semantic-ui-react";
import moment from "moment";
import { DateInput } from "semantic-ui-calendar-react";
import validations from "./validations";
import { DeleteButton, UserDropdown } from "../../atoms";
import { client } from "../../../graphql";
import {
  UPDATE_BATCH,
  DELETE_BATCH
} from "../../../graphql/mutations/organisms";
import { USERS } from "../../../graphql/queries/users";
import { BATCH } from "../../../graphql/queries/organisms";
import { FormValidator, CLEAN_TEXT } from "../../../helpers";
import "./styles.css";

export default ({ open, close }) => {
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isLoadingCoordinators, setIsLoadingCoordinators] = useState(true);
  const [data, setData] = useState({
    name: "",
    finishDate: "",
    description: "",
    coordinators: [],
    originalCoordinators: [],
    startDate: ""
  });

  const [errors, setErrors] = useState({
    name: false,
    finishDate: false,
    startDate: false,
    description: false,
    coordinators: false
  });

  const [options, setOptions] = useState([]);

  useEffect(() => {
    /* By now this is querying only regular users */
    const getCoordinators = async () => {
      const query = await client.query({
        query: USERS
      });
      query.data.users.map(({ id, fullName }, index) => {
        options.push({ key: index, value: id, text: fullName });
      });
      setOptions(options);
      setIsLoadingCoordinators(prevState => !prevState);
    };
    const getBatch = async () => {
      const batchId = window.location.pathname.split("batchid=")[1];
      const query = await BATCH(batchId);
      const { data } = query;
      const coordinators = data?.batch?.users?.map(u => u.id);
      setData({
        id: data.batch.id,
        name: data.batch.name,
        currentName: data.batch.name,
        batchID: data.batch.batchID,
        startDate: moment(data.batch.startDate).format("DD/MM/YYYY"),
        finishDate: moment(data.batch.finishDate).format("DD/MM/YYYY"),
        description: data.batch.description,
        coordinators: coordinators,
        originalCoordinators: coordinators,
        objectives: data.batch.objectives,
        odes: data.batch.odes
      });

      setIsLoadingData(prevState => !prevState);
    };
    getCoordinators();
    getBatch();
  }, []);

  const handleChange = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
  };

  const updateBatch = async () => {
    const updatedData = { ...data }; // avoid side effects by mutating data
    const validation = validateFrom(updatedData);
    updatedData.description = CLEAN_TEXT.STRING_TO_UNICODE(
      CLEAN_TEXT.UNICODE_TO_STRING(updatedData.description)
    );

    updatedData.disconnectedCoordinators =  updatedData
      .originalCoordinators
      .filter(old => !updatedData.coordinators
        .some(updated => updated === old));

    const datesAreValid =
      moment(updatedData.startDate, "DD/MM/YYYY").diff(
        moment(updatedData.finishDate, "DD/MM/YYYY"),
        "days"
      ) <= 0;

    if (datesAreValid) {
      errors.startDate = false;
      errors.finishDate = false;
      setErrors({ ...errors });
    } else {
      errors.startDate = true;
      errors.finishDate = true;
      setErrors({ ...errors });
    }

    if (validation.isValid && datesAreValid) {
      try {
        await UPDATE_BATCH(updatedData);
        window.location.reload();
        close();
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  const validateFrom = data => {
    const validator = new FormValidator(validations);
    const validation = validator.validate(data);
    if (validation.isValid) {
      Object.keys(errors).map(error => {
        errors[error] = validation[error]?.isInvalid;
      });
      setErrors({ ...errors });
    } else {
      Object.keys(errors).map(error => {
        errors[error] = validation[error].isInvalid;
      });
      setErrors({ ...errors });
    }
    return validation;
  };

  const _close = () => {
    Object.keys(errors).map(error => {
      errors[error] = false;
    });
    setErrors({ ...errors });
    setData({});
    close();
  };

  const redirectToOrganism = organismId => {
    window.location = `/admin/organism/${organismId}`;
  };

  const onDeleteBatch = async () => {
    const batchId = window.location.pathname.split(
      "batchid="
    )[1];
    const organismId = window.location.pathname
      .split("orgid=")[1]
      .split("_")[0];
    try {
      await DELETE_BATCH(batchId);
      redirectToOrganism(organismId);
    } catch (e) {
      throw new Error(e);
    }
  };

  return (
    <Modal
      className="Modal__Form-SubModal"
      centered={false}
      open={open}
      size="tiny"
      dimmer="blurring"
      autoComplete="new-password">
      <Modal.Header>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} floated="left">
              Editar batch
            </Grid.Column>
            <Grid.Column width={2} floated="right">
              <Icon name="close" onClick={_close} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      {/* Form */}
      <Modal.Content>
        {!isLoadingData && !isLoadingCoordinators ? (
          <Form className="Modal__Form">
            <Form.Group widths="equal">
              <Form.Field
                control={Input}
                label="Nombre"
                type="text"
                name="name"
                onChange={handleChange}
                value={
                  data.name.split(".")[1] === "0"
                    ? data.name.split(".")[0]
                    : data.name
                }
                required
                error={data.name !== "" ? false : errors.name} />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                name="startDate"
                autoComplete="off"
                control={DateInput}
                placeholder="Date"
                format="DD/MM/YYYY"
                value={data.startDate}
                iconPosition="right"
                onChange={handleChange}
                label="Fecha de inicio"
                width={5}
                required
                error={errors.startDate !== "" ? false : errors.startDate} />
              <Form.Field
                name="finishDate"
                control={DateInput}
                format="DD/MM/YYYY"
                placeholder="Date"
                autoComplete="off"
                value={data.finishDate}
                iconPosition="right"
                onChange={handleChange}
                label="Fecha de término"
                width={5}
                error={errors.finishDate !== "" ? false : errors.finishDate}
                style={!errors.finishDate ?
                        null : { backgroundColor: "red" }} />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                control={TextArea}
                name="description"
                label="Descripción"
                value={
                  data.description &&
                  CLEAN_TEXT.UNICODE_TO_STRING(data.description)
                }
                type="text"
                onChange={handleChange}
                required
                error={data.description !== "" ? false : errors.description} />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                control={UserDropdown}
                systemRole={["COORDINATOR", "SUPER_ADMIN"]}
                name="coordinators"
                label="Coordinadores"
                onChange={handleChange}
                value={data.coordinators}
                type="text"
                selection
                multiple
                search
                required
                error={errors.coordinators} />
            </Form.Group>
            <Form.Group widths="equal" />
            <Form.Group className="modal-buttons">
              <Form.Field>
                <DeleteButton del={onDeleteBatch} />
              </Form.Field>
              <Form.Field />
              <Form.Field>
                <Button primary onClick={updateBatch}>
                  Guardar
                </Button>
              </Form.Field>
            </Form.Group>
          </Form>
        ) : (
          <div>
            <Loader active className="workaround" />
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
};
