import React from "react";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloProviderHooks } from "react-apollo-hooks";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import SessionProvider from "./context/provider";
import { client } from "./graphql";
import { ErrorBoundary } from "./bugsnag";
import { Error404 } from "./components/pages";
import { ErrorFallbackIndex } from "./v2/pages/error-fallback";
import V1 from "./v1";
import "antd/dist/antd.css";
import "./v2/styles/index.sass";
import { PaginationContextProvider } from "./v2/contexts/shared";

export default class App extends React.Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <ApolloProviderHooks client={client}>
          <ErrorBoundary FallbackComponent={ErrorFallbackIndex}>
            <BrowserRouter>
              <AuthProvider>
                <SessionProvider>
                  <PaginationContextProvider>
                    <Switch>
                      <Route
                        path="/"
                        component={V1} />
                      <Route component={Error404} />
                    </Switch>
                  </PaginationContextProvider>
                </SessionProvider>
              </AuthProvider>
            </BrowserRouter>
          </ErrorBoundary>
        </ApolloProviderHooks>
      </ApolloProvider>
    );
  }
}
