import { MAP_EXPERT_SERVICE_TYPES as MAP_TYPES } from "../../../helpers";
import moment from "moment";

export const getSubtitle = service => {
  const odeNames = service.odes.map(o => o.name).join(" - ");

  return `${MAP_TYPES(service.expertService.type)} - ${odeNames}`;
};

export const formatDate = date => {
  const formatted = moment(date).format("D MMMM YYYY");
  return formatted === "Invalid date" ? "-" : formatted;
};

export const displayPrice = service => {
  if(!service || !service.expertService)
    return "N/A";

  return service.expertService.price ?
    `$${service.expertService.price}` :
    "N/A";
};
