export default [
  {
    "key": "0",
    "value": "TECHNIC",
    "text": "Asesor técnico"
  },
  {
    "key": "1",
    "value": "BUSINESS",
    "text": "Asesor de negocios"
  },
  {
    "key": "2",
    "value": "LEADER",
    "text": "Asesor líder"
  },
  {
    "key": "3",
    "value": "SPECIALIST",
    "text": "Especialista"
  }
]