import gql from "graphql-tag";
import { client } from "../../index";

export default async ({ id, userId }) => {
  await client.mutate({
    mutation: gql`
            mutation {
                updateOrganism(
                    where: { id: "${id}" }
                    data: { team: { connect: {id:"${userId}"} }}
                ) {
                    id
                }
            }
        `,
  });
};
