import React, { useState } from "react";
import {
  Button,
  Form,
  Grid,
  Icon,
  Input,
  Modal,
  TextArea,
  Dropdown,
} from "semantic-ui-react";
import { CONNECT_OBJECTIVE } from "../../../graphql/mutations/organisms";
import { CLEAN_TEXT } from "../../../helpers";

const typeOptions = [
  { key: "VALIDATION", text: "Validación", value: "VALIDATION" },
  { key: "DISCOVERY", text: "Descubrimiento", value: "DISCOVERY" },
  {
    key: "LAUNCHING",
    text: "Lanzamiento",
    value: "LAUNCHING",
  },
];

export default props => {
  const id = window.location.pathname.split("/")[3];
  // Hooks
  const [data, setData] = useState({
    name: "",
    description: "",
  });

  const [type, setType] = useState({ type: null });

  const [errors, setErrors] = useState({
    name: false,
    description: false,
    type: false,
  });

  const handleChange = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
  };

  const handleChangeType = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
    setType({ type: value });
  };

  const createObjective = async () => {
    data["id"] = escape(id);
    data["description"] = CLEAN_TEXT.UNICODE_TO_STRING(data.description);
    await CONNECT_OBJECTIVE(data);
    props.refetch();
    props.close();
  };

  const close = () => {
    props.close();
  };

  return (
    <Modal
      className="Modal__Form-SubModal"
      centered={false}
      open={props.open}
      onClose={props.closeModal}
      size="tiny"
      dimmer="blurring"
      autoComplete="new-password"
    >
      <Modal.Header as="h1">
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} floated="left">
              Nuevo objetivo
            </Grid.Column>
            <Grid.Column width={2} floated="right">
              <Icon name="close" onClick={close} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <Form className="Modal__Form">
          <Form.Group widths="equal">
            <Form.Field
              width={11}
              control={Input}
              label="Nombre del objetivo"
              type="text"
              name="name"
              onChange={handleChange}
              required
              onChange={handleChange}
              error={errors.name}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              name="type"
              label="Tipo de objetivo"
              control={Dropdown}
              options={typeOptions}
              onChange={handleChangeType}
              defaultValue={data.type}
              selection
              required
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={TextArea}
              name="description"
              label="Descripción"
              onChange={handleChange}
              error={errors.description}
              required
            />
          </Form.Group>
          <Form.Group widths="equal">
            <div style={{ marginLeft: "278px", marginTop: "17px" }}>
              <Button
                style={{ width: "130px" }}
                onClick={() => createObjective()}
                fluid
                primary
              >
                Guardar
              </Button>
            </div>
          </Form.Group>
        </Form>
      </Modal.Content>
    </Modal>
  );
};
