import React from 'react';
import { Placeholder, Grid, Container } from 'semantic-ui-react';

export default () => {
  return (
    <Grid celled="internally">
      <Grid.Row>
        { /* tabs here */ }
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={12}>
          <Container>
            <Grid divided="vertically">
              <Grid.Row>
                <Grid.Column>
                  <Placeholder fluid>
                    <Placeholder.Header image>
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Header>
                  </Placeholder>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                <Placeholder fluid>
                    <Placeholder.Header>
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Header>
                    <Placeholder.Header>
                      <Placeholder.Line />
                    </Placeholder.Header>
                  </Placeholder>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Placeholder fluid>
                    <Placeholder.Paragraph>
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Paragraph>
                    <Placeholder.Header>
                      <Placeholder.Line />
                    </Placeholder.Header>
                    <Placeholder.Paragraph>
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Paragraph>
                  </Placeholder>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Placeholder fluid>
                  <Placeholder.Header>
                      <Placeholder.Line />
                    </Placeholder.Header>
                    <Placeholder.Paragraph>
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Paragraph>
                  </Placeholder>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Grid.Column>
        <Grid.Column width={4}>
          
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
