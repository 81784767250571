import React, { useState, useEffect } from "react";
import { Form, Input, Dropdown, Button, Header } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import lodash from "lodash";

import {
  Countries,
  STATES_AND_CITIES,
  STATES,
  SECTORS,
  COMERCIAL_BUSINESS,
} from "../../../../../../helpers";
import { DateField } from "../../../../../atoms";

const categoryOptions = [
  { key: "COMPANY", text: "Empresa", value: "COMPANY" },
  { key: "STARTUP", text: "Startup", value: "STARTUP" },
  { key: "TEAM", text: "Equipo de trabajo", value: "TEAM" },
  { key: "PROJECT", text: "Proyecto", value: "PROJECT" },
  { key: "SPIN_OFF", text: "Spin off", value: "SPIN_OFF" },
];

let today = new Date();
let dd = today.getDate();
let mm = today.getMonth() + 1; //January is 0!
let yyyy = today.getFullYear();

if (dd < 10) {
  dd = "0" + dd;
}

if (mm < 10) {
  mm = "0" + mm;
}

today = dd + "/" + mm + "/" + yyyy;

export default props => {
  const {
    handleChange,
    next,
    errors,
    data,
    handleChangeCategory,
    turnoffautocomplete,
  } = props;

  const [cities, setCity] = useState([]);

  const handleCity = data => {
    const cities = STATES_AND_CITIES[data["state"]];
    const options = [];
    if (cities) {
      cities.map((item, index) => {
        options.push({ key: index, text: item, value: item });
      });
    }
    setCity(() => options);
  };

  useEffect(() => {
      // Load the cities to the city input options data source
      handleCity(data);
  }, []); // The empty array allows to skip the effect after the render

  return (
    <Form className="Padded__Form">
      <Header>Datos generales</Header>
      <Form.Group widths="equal">
        <Form.Field
          name="category"
          label="Categoría"
          control={Dropdown}
          options={categoryOptions}
          onChange={handleChangeCategory}
          error={data["category"] ? false : errors.category}
          defaultValue={data.category}
          selection
          required
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          name="name"
          label="Nombre comercial"
          onChange={handleChange}
          control={Input}
          error={data?.name ? false : errors.name}
          defaultValue={ lodash.get(data, '["name"]', "") }
          required
          onFocus={turnoffautocomplete}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          name="sector"
          label="Sector"
          control={Dropdown}
          onChange={handleChange}
          options={SECTORS}
          defaultValue={data.sector}
          selection
          error={data["sector"] ? false : errors.sector}
          required
        />
        <Form.Field
          name="commercialBusiness"
          label="Giro"
          onChange={handleChange}
          control={Dropdown}
          defaultValue={data.commercialBusiness}
          selection
          options={COMERCIAL_BUSINESS}
          error={data["commercialBusiness"] ? false : errors.commercialBusiness}
          required
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          name="nationality"
          label="Nacionalidad"
          autoComplete="off"
          selection
          onChange={handleChange}
          defaultValue={data.nationality}
          options={Countries}
          search
          icon="search"
          control={Dropdown}
          error={data["nationality"] ? false : errors.nationality}
          required
        />
      </Form.Group>
      <Form.Group widths="equal">
        {data.nationality !== "MX" ? (
          <Form.Field
            name="state"
            autoComplete="off"
            label="Estado"
            onChange={handleChange}
            defaultValue={data.state}
            control={Input}
            error={data["state"] ? false : errors.state}
            required
          />
        ) : (
          <Form.Field
            name="state"
            autoComplete="off"
            label="Estado"
            search
            selection
            icon="search"
            options={STATES}
            onChange={(e, { name, value }) => {
              handleChange(e, { name, value });
              handleCity(data);
            }}
            defaultValue={data.state}
            control={Dropdown}
            error={data["state"] ? false : errors.state}
            required
          />
        )}
        {data.nationality !== "MX" ? (
          <Form.Field
            name="city"
            autoComplete="off"
            label="Ciudad"
            onChange={handleChange}
            defaultValue={data.city}
            control={Input}
            error={data["city"] ? false : errors.city}
            required
          />
        ) : (
          <Form.Field
            name="city"
            autoComplete="off"
            label="Ciudad"
            search
            selection
            icon="search"
            options={cities}
            onChange={handleChange}
            defaultValue={data.city}
            control={Dropdown}
            error={data["city"] ? false : errors.city}
            required
          />
        )}
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          autoComplete="off"
          name="registrationYear"
          label="Año de ingreso"
          control={DateField}
          onChange={handleChange}
          icon="calendar"
          format="DD/MM/YYYY"
          placeholder="Fecha"
          error={data["registrationYear"] ? false : errors.registrationYear}
          required />
      </Form.Group>
      <Form.Group widths="equal">
        <Button
          type="button"
          id="ode-form-next1"
          onClick={next}
          primary
          fluid
        >
          Siguiente
        </Button>
      </Form.Group>
    </Form>
  );
};
