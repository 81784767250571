import React, { Fragment, useState, useEffect } from "react";
import { Table, TableRow, TableCell, Dimmer, Loader, TableHeader, TableBody } from "semantic-ui-react";
import { SortableTableRow } from "../../molecules";
import { Query } from "react-apollo";
import { PaginationBar } from "../../molecules";
import { useQuery } from "react-apollo-hooks";

const LoadingTableContent = props => {
  return (<TableBody>
    <TableRow>
      <TableCell>
        <Dimmer active inverted>
          <Loader
            testid="loading-wrapper"
            inverted
            content="Cargando..." />
        </Dimmer>
      </TableCell>
    </TableRow>
  </TableBody>);
}

const ErrorTableContent = props => {
  return (<TableBody>
    <TableRow>
      <TableCell>
        <Dimmer active inverted>
          <Loader
            testid="loading-wrapper"
            inverted
            content="Error..." />
        </Dimmer>
      </TableCell>
    </TableRow>
  </TableBody>);
}


export default ({
  headers,
  query,
  tableBodyComponent,
  onClickRow,
  onRowEvent,
  requestRefetch,
  propertyKey,
  role,

  onPrev,
  onNext,
  current,
  paginationLabel,
}) => {

  const { loading, error, data, refetch } = useQuery(query.query, query);
  requestRefetch(refetch);

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      height: "300px",
      overflow: "display",
    }}>
      <div style={{ minHeight: "550px" }}>
        <Table
          striped
          className="Table__List"
          basic="very"
          selectable
          style={{ top: '0px', position: 'relative' }}>
          <TableHeader>
            <SortableTableRow headers={headers} />
          </TableHeader>
          {loading ? <LoadingTableContent /> : null}
          {error ? <ErrorTableContent /> : null}
          {data === undefined ? <LoadingTableContent /> : null}
          {/* This results are used by the pagination*/}
          {!loading && !error && data ?
            <TableBody>
              {
                React.cloneElement(
                  tableBodyComponent,
                  {
                    data: data,
                    onClickRow: onClickRow,
                    onRowEvent: onRowEvent
                  }
                )}
            </TableBody>
            : null}
        </Table>
      </div>
      { data ?
        <PaginationBar
          data={data}
          label={paginationLabel || ""}
          propertyKey={propertyKey}
          onPrev={onPrev}
          onNext={onNext}
          current={current} />
        : null}
    </div>
  );
}
