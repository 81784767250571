import React, { useState, useEffect } from "react";
import { Button, Container, Form, Input, Dropdown } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";
import {
  Countries,
  STATES_AND_CITIES,
  STATES,
  COMERCIAL_BUSINESS,
} from "../../../../helpers";
import errors from "../errors";
import { FormValidator, SECTORS } from "../../../../helpers";
import { DeleteButton, DateField } from "../../../atoms";
import validations from "../validations";

const mapCategoires = category => {
  const CATEGORY = {
    COMPANY: "Empresa",
    STARTUP: "Startup",
    TEAM: "Equipo de trabajo",
    PROJECT: "Proyecto",
    SPIN_OFF: "Spin off",
  };
  return CATEGORY[category];
};

const comcercialBusinessOptions = [
  {
    key: "SHARE_ECONOMY",
    text: "Economía colaborativa",
    value: "SHARE_ECONOMY",
  },
];

export default props => {
  const { data, handlechange, update } = props;
  const [errors1, setErrors1] = useState(errors.section1); // form section 1
  const [cities, setCity] = useState([]);

  useEffect(() => {
    handleCity(data);
  }, []);

  const handleCity = data => {
    const cities = STATES_AND_CITIES[data["state"]];
    const options = [];
    if (cities) {
      cities.map((item, index) => {
        options.push({ key: index, text: item, value: item });
      });
    }

    setCity(() => options);
  };

  const setErrors = (errors, setErrors, validation) => {
    Object.keys(errors).map(error => {
      errors[error] = validation[error]["isInvalid"];
    });
    setErrors({ ...errors });
  };

  const validateFrom = form => {
    const validator = new FormValidator(validations[form]);
    const validation = validator.validate(data);
    return validation;
  };

  const turnoffautocomplete = e => {
    e.target.setAttribute("autocomplete", "off");
  };

  const updateGeneralInfo = async () => {
    const validation = validateFrom("section1");
    if (validation.isValid) {
      setErrors(errors1, setErrors1, validation);
      await update(data);
    } else {
      setErrors(errors1, setErrors1, validation);
    }
  };

  return (
    <Container>
      <Form className="Padded__Form">
        <Form.Group widths="equal">
          <Form.Field
            name="odeCategory"
            label="Categoría"
            control={Input}
            value={mapCategoires(data["odeCategory"])}
            readOnly
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            name="name"
            label="Nombre comercial"
            onChange={handlechange}
            control={Input}
            error={data["name"] ? false : errors1.name}
            defaultValue={data.name}
            required
            onFocus={turnoffautocomplete}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            name="sector"
            label="Sector"
            control={Dropdown}
            onChange={handlechange}
            options={SECTORS}
            value={data.sector}
            selection
            error={data["sector"] ? false : errors1.sector}
            required
          />
          <Form.Field
            name="commercialBussines"
            label="Giro"
            onChange={handlechange}
            control={Dropdown}
            value={data.commercialBussines}
            selection
            options={COMERCIAL_BUSINESS}
            error={
              data["commercialBussines"] ? false : errors1.commercialBussines
            }
            required
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            name="nationality"
            label="Nacionalidad"
            autoComplete="off"
            selection
            onChange={handlechange}
            defaultValue={data.nationality}
            options={Countries}
            search
            icon="search"
            control={Dropdown}
            error={data["nationality"] ? false : errors1.nationality}
            required
          />
        </Form.Group>
        <Form.Group widths="equal">
          {data.nationality !== "MX" ? (
            <Form.Field
              name="state"
              autoComplete="off"
              label="Estado"
              onChange={handlechange}
              defaultValue={data.state}
              control={Input}
              error={data["state"] ? false : errors1.state}
              required
            />
          ) : (
            <Form.Field
              name="state"
              autoComplete="off"
              label="Estado"
              search
              selection
              icon="search"
              options={STATES}
              onChange={(e, { name, value }) => {
                handlechange(e, { name, value });
                handleCity(data);
              }}
              defaultValue={data.state}
              control={Dropdown}
              error={data["state"] ? false : errors1.state}
              required
            />
          )}
          {data.nationality !== "MX" ? (
            <Form.Field
              name="city"
              autoComplete="off"
              label="Ciudad"
              onChange={handlechange}
              defaultValue={data.city}
              control={Input}
              error={data["city"] ? false : errors1.city}
              required
            />
          ) : (
            <Form.Field
              name="city"
              autoComplete="off"
              label="Ciudad"
              search
              selection
              icon="search"
              options={cities}
              onChange={handlechange}
              value={data.city}
              control={Dropdown}
              error={data["state"] ? false : errors1.state}
              required
            />
          )}
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            autoComplete="off"
            name="registrationYear"
            label="Año de ingreso"
            control={DateField}
            onChange={handlechange}
            icon="calendar"
            initialValue={data["registrationYear"]}
            placeholder="Date"
            format="DD/MM/YYYY"
            error={data["registrationYear"] ? false : errors1.registrationYear}
            required
          />
        </Form.Group>
        <Form.Group widths="equal">
          <DeleteButton del={props.deleteODE} />
          <Button
            loading={props.loading}
            type="button"
            id="ode-form-next1"
            onClick={updateGeneralInfo}
            primary
            fluid
            style={{ width: "30%", marginLeft: "250px" }}
          >
            Guardar
          </Button>
        </Form.Group>
      </Form>
    </Container>
  );
};
