import React, { Fragment } from "react";
import Identicon from "react-identicons";
import { Image, Header } from "semantic-ui-react";
import "./styles.css";

const Heading = ({children}) => {
  return (
    <div className="header-container">
      {children}
    </div>
  );
};

const renderHeaderWithImage = (imageUri, title, subtitle) => (
  <Fragment>
    {imageUri !== undefined ?
      <Image
        src={imageUri}
        width={100}
        circular />
      : <Identicon string={"randomness"} size={40} />}
    <div style={{marginLeft: "20px"}}>
      <Header as="h1">
        {title || ""}
        <Header.Subheader>
          {subtitle || ""}
        </Header.Subheader>
      </Header>
    </div>
  </Fragment>
);

Heading.LeftContainer = ({
  imageUri,
  title,
  subtitle,
  children
}) => {
  return (
    <div
      className="left-container"
      style={{
      display: "flex",
      flexDirection: "row"
    }}>
      {children !== undefined ?
        children : renderHeaderWithImage(imageUri, title, subtitle)}
    </div>
  );
};

Heading.RightContainer = ({children}) => {
  return (
    <div className="right-container">
      {children}
    </div>
  );
};

export default Heading;
