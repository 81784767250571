import gql from "graphql-tag";

export default gql`
  query($id: ID!, $programId: ID!, $batchId: ID!) {
    organism(where: { id: $id }) {
      id
      program(where: { id: $programId }) {
        id
        batches(where: { id: $batchId }) {
          id
          batchObjectives {
            id
            objective {
              id
              name
            }
          }
        }
      }
    }
  }
`;
