import gql from "graphql-tag";

/**
 * This query gets one user specified by ID.
 * The user is returned in an array. This is needed
 * in the entrepreneurs list
 */
export default gql`
  query($id: ID!) {
    users (
      where: {
        id: $id,
      }
    ){
      id
      fullName
      profilePicUrl
      email
      userID
      gender
      phone
      area
      school
      enrollment
      career
      graduationDate
      status
      registrationDate
      profileTec
      category
      systemRole
      odes {
        id
        ode {
          name
        }
        rol
      }
      timeline {
        action
        type
        user
        ode
        timestamp
      }
      batches {
        id
      }
      organisms {
        id
      }
    }
    usersConnection {
      aggregate {
        count
      }
    }
  }
`;
