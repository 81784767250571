import gql from "graphql-tag";
import { client } from "../../";

export default async id => {
  return await client.mutate({
    variables: {
      id,
    },
    mutation: gql`
      mutation($id: ID!) {
        deleteBatch(where: { id: $id }) {
          id
        }
      }
    `,
  });
};
