import React, { useState, useEffect } from "react";
import { DateInput } from "semantic-ui-calendar-react";
import * as _ from "lodash";
import moment from "moment";

export default ({
  placeholder="Fecha",
  iconPosition="right",
  returnFormat="YYYY-MM-DDTHH:mm:ss.SSS[Z]",
  initialValue=undefined,
  localization="es",
  autoComplete="off",
  onChange=() => {},
  disabled,
  style,
  name
}) => {
  const DISPLAY_FORMAT = "DD/MM/YYYY";

  const [state, setState] = useState({
    displayValue: "",
    value: ""
  });

  useEffect(() => {
    setState({
      ...state,
      displayValue: formatDisplayValue(initialValue),
      value: initialValue
    });
  }, [initialValue]);

  const formatDisplayValue = value => {
    return moment(value)
      .utcOffset(0)
      .format(DISPLAY_FORMAT);
  };

  /* Date format DD/MM/YYYY to YYYY/MM/DD */
  const reformatDate = string => {
    return string.split("/").reverse().join("-");
  };

  const onChangeCallback = (event, {value}) => {
    const returnValue = moment(reformatDate(value))
                        .utcOffset(0)
                        .format(returnFormat);
    setState({ displayValue: value, value: returnValue });
    onChange(event, {name: name, value: returnValue});
  };

  return (
    <DateInput
      disabled={disabled !== undefined && disabled}
      dateFormat={DISPLAY_FORMAT}
      name={name}
      placeholder={placeholder}
      value={state.displayValue}
      iconPosition={iconPosition}
      localization={localization}
      autoComplete={autoComplete}
      style={style}
      onChange={onChangeCallback} />
  );
};
