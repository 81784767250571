export default {
  section1: {
    fullName: false,
    gender: false,
    phone: false,
    area: false,
    status: false,
    systemRole: false,
  },
  section2: {
    graduationDate: false,
    school: false,
    career: false,
    enrollment: false,
    category: false,
  },
  section3: {
    email: false,
    password: false,
    password_confirm: false,
  },
};
