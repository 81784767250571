import React, { useContext } from "react";
import { AuthServiceContext } from "../../contexts/shared/auth";
import { LoginLayout } from "../../components/shared";
import { withRouter } from "../../helpers";
import RegisterForm from "../../components/shared/register-form";
import { CurrentUserContext, CurrentUserProvider } from "../../contexts/shared";
import { Result, Spin } from "antd";

function Login() {
  const { authService } = useContext(AuthServiceContext);
  // TODO: Implement session context
  const login = async (args = {}) => {
    const { systemRole } = await authService.login(args);

    redirect(systemRole);
  };

  const redirect = () => {
    window.location = "/admin/instituciones";
  };

  return (
    <CurrentUserProvider>
      <CurrentUserContext.Consumer>
        {({ currentUser, loading, error }) => {
          if (currentUser.id && !loading && !error) {
            return redirect();
          }

          if (loading) {
            return (
              <LoginLayout>
                <Result
                  icon={<Spin size="large" />} />
              </LoginLayout>
            );
          }

          return (
            <LoginLayout>
              <RegisterForm
                badCredentials
                signingFunction={login}
                mode="signin" />
            </LoginLayout>
          );
        }}
      </CurrentUserContext.Consumer>
    </CurrentUserProvider>
  );
}

export default withRouter(Login);
