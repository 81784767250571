export default {
  odeActivity: [
    {
      field: "name",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "description",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "activityType",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "responsibles",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
  ],
  objectiveActivity: [
    {
      field: "name",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "description",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "activityType",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
  ],
};
