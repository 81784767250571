import gql from "graphql-tag";
import { client } from "../../index";

export default async batchId => {
  return await client.query({
    variables: {
      batchId
    },
    query: gql`
      query($batchId: ID!) {
        oDEs(
          where: {
            batches_some: { id: $batchId }
          }
        ){
          id
        }
      }
    `
  });
};
