
const getBatchesUpdateMutations = (batches, coordinatorId) => {
  let batchesMutations = "";

  batches.forEach(({ id }) => {
    batchesMutations = `{
      where: { id: "${id}" }
      data: {
        coordinator: {
          connect: { id: "${coordinatorId}" }
        }
      }
    },
    ${batchesMutations}`;
  });

  return batchesMutations;
};

export const getUpdateBatchesCoordMutationFragment =
  (batches, coordinator) => {
    if(!Array.isArray(batches))
      return "";

    // There is a bug while handling the modal state
    // because the coordinators dropdown is not well implemented.
    // Please, remove this validation when the modal has been fixed
    if(typeof(coordinator) === "object")
      return "";

    const batchesUpdateMutations =
      getBatchesUpdateMutations(batches, coordinator);

    const mutationFragment = batchesUpdateMutations.length > 0 ?
      `batches: { update: [${batchesUpdateMutations}]}`
      : "";

    return mutationFragment;
};
