import _ from "lodash";

export const getPermissionVariables = organismData => {
  return [
    { role: "SUPER_ADMIN" },
    { // Organism coordinator
      role: "COORDINATOR",
      id: _.get(organismData, "coordinator.id", "")
    },
    { // Institution coodinator
      role: "COORDINATOR",
      id: _.get(organismData, "institution.coordinator.id", "")
    }
  ];
};
