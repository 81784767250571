import React from "react";
import { Query } from "react-apollo";
import { Dimmer, Loader, Image, TableCell, TableRow } from "semantic-ui-react";
import _ from "lodash";
import { USERS_FILTERED_BY_ROLE, COORDINATOR_USERS } from "../../../graphql/queries/users";
import { DELETE_USER } from "../../../graphql/mutations/users";
import { PARSE_DATE } from "../../../helpers";
import CAREER from "./careers";
import SCHOOL from "./schools";
import SessionContext from "../../../context/userSessionContext";
import { Row } from "./components";

export default props => (
  <SessionContext.Consumer>
    {ctx =>
      ctx.id !== null && (
        <Query
          query={
            ctx.role === "SUPER_ADMIN" || ctx.role === "ENTREPRENEUR"
              ? USERS_FILTERED_BY_ROLE
              : ctx.role === "COORDINATOR"
              ? COORDINATOR_USERS
              : null
          }
          variables={{ id: ctx && ctx.id, systemRole: ["ENTREPRENEUR"] }}
          fetchPolicy="network-only"
          errorPolicy="ignore"
          pollInterval={3000}
        >
          {({ loading, error, data, refetch }) => {
            if (loading)
              return (
                <TableRow>
                  <TableCell>
                    <Dimmer active inverted>
                      <Loader
                        testid="loading-wrapper"
                        inverted
                        content="Cargando..."
                      />
                    </Dimmer>
                  </TableCell>
                </TableRow>
              );
            if (error) {
              return (
                <TableRow>
                  <TableCell>
                    <Dimmer active inverted>
                      <Loader
                        testid="loading-wrapper"
                        inverted
                        content="Error..."
                      />
                    </Dimmer>
                  </TableCell>
                </TableRow>
              );
            }

            return data.users.map(
              (
                {
                  id,
                  userID,
                  fullName,
                  registrationDate,
                  graduationDate,
                  school,
                  career,
                  category,
                  status,
                  odes,
                  batches,
                  systemRole,
                },
                index
              ) => {
                const rowData = {
                  index,
                  uid: id,
                  userID,
                  fullName,
                  registrationDate,
                  graduationDate,
                  school,
                  career,
                  category,
                  status,
                  odes,
                  batches,
                  systemRole,
                  parseDate: PARSE_DATE,
                  parseSchool: SCHOOL,
                  parseCareer: CAREER,
                  deleteUser: DELETE_USER,
                  ctx,
                  refetch,
                  data,
                };

                return (ctx.role === "SUPER_ADMIN" || ctx.role === "COORDINATOR") ?
                  <Row
                    rowData={rowData}
                    setData={props.setData}
                    setId={props.setId}
                    setRefetch={props.setRefetch}
                    setConfirm={props.setConfirm}
                    setEditModal={props.setEditModal}/>
                  : ctx.id === id ?
                    <Row
                      rowData={rowData}
                      setData={props.setData}
                      setId={props.setId}
                      setConfirm={props.setConfirm}
                      setRefetch={props.setRefetch}
                      setEditModal={props.setEditModal}/>
                    : null;
              }
            );
          }}
        </Query>
      )
    }
  </SessionContext.Consumer>
);
