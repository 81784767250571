import React from 'react';
import { TableRow, TableCell } from "semantic-ui-react";
import { Avatar } from "..";
import { EntityActionMenu } from "../../molecules";
import { MAP_EXPERT_CATEGORIES, MAP_EXPERT_TYPES, MAP_TEC_PROFILES } from "../../../helpers"

export default ({expert, onClick, onRowEvent, key}) => {
  onClick = onClick ? onClick : () => {};
  onRowEvent = onRowEvent ? onRowEvent : () => {};
  const {
    id,
    userID,
    fullName,
    specialtyArea,
    expertCategory,
    profileTec,
    expertType,
    odes
  } = expert;

  return (
    <TableRow
      textAlign="center"
      key={key}
      onClick={onClick}>
      <TableCell>
        <Avatar user={expert} />
      </TableCell>
      <TableCell>{fullName}</TableCell>
      <TableCell>{specialtyArea}</TableCell>
      <TableCell>{MAP_EXPERT_TYPES(expertType)}</TableCell>
      <TableCell>{MAP_EXPERT_CATEGORIES(expertCategory)}</TableCell>
      <TableCell>{MAP_TEC_PROFILES(profileTec) || "N/A"}</TableCell>
      <TableCell>{odes.length}</TableCell>
      <TableCell>
        <EntityActionMenu
          id={userID}
          onEdit={() => onRowEvent({type: "rowEdit", data: { id: id }})}
          onDelete={() => onRowEvent({type: "rowDelete", data: { id: id }})} />
      </TableCell>
    </TableRow>
  );
}
