import React from "react";
import { Query } from "react-apollo";
import { Input } from "semantic-ui-react";
import { USER_ID } from "../../../graphql/queries/users";

export default props => (
  <Query query={USER_ID} fetchPolicy="network-only">
    {({ loading, error, data }) => {
      if (loading) return "Loading...";
      if (error) return `Error! ${error.message}`;
      return (
        <Input
          id="user-id"
          name={props.name}
          value={data.userIDs[0].userID}
          onChange={props.onChange}
          required
          readOnly
        />
      );
    }}
  </Query>
);
