export default {
  section1: {
    category: false,
    name: false,
    sector: false,
    commercialBusiness: false,
    nationality: false,
    city: false,
    state: false,
  },
  section2National: {
    businessName: false,
    personType: false,
    rfc: false,
    commercialAddress: false,
    physicalAddress: false,
  },
  section2Foreign: {
    businessName: false,
    ein: false,
    inc: false,
    commercialAddress: false,
    physicalAddress: false,
  },
  section3: {
    website: false,
    facebook: false,
    twitter: false,
    instagram: false,
    email: false,
  },
  section4: {
    phase: false,
    technology: false,
    trl: false,
    industry: false,
    area: false,
  },
};
