import React from "react";
import { Query } from "react-apollo";
import { Dimmer, Loader, Image, TableCell, TableRow } from "semantic-ui-react";
import _ from "lodash";
import { USERS } from "../../../graphql/queries/users";
import { DELETE_USER } from "../../../graphql/mutations/users";
import { PARSE_DATE } from "../../../helpers";
import { UserOptions } from "../../organisms";
import SessionContext from "../../../context/userSessionContext";

const STATUS = status => {
  const STATUS = {
    PROSPECT: "Prospecto",
    ACTIVE: "Activo",
    INACTIVE: "Inactivo",
  };
  return STATUS[status];
};

const CATEGORY = category => {
  const CATEGORY = {
    STUDENT: "Estudiante",
    TEACHER: "Maestro",
    DIRECTOR: "Director",
    DICA: "DICA",
    REASEARCHER: "Investigador",
    ENTREPRENEURSHIP_TEAM: "Equipo de emprendimiento",
    EMPLOYEE: "Empleado",
    EXATEC: "Exatec",
    ADVISER: "Consejero",
    EXTERNAL: "Externo",
  };
  return CATEGORY[category];
};

const USER_ROLE = role => {
  const USER = {
    SUPER_ADMIN: "Administrador",
    COORDINATOR: "Coordinador",
    ENTREPRENEUR: "Emprendedor",
    ADVISER: "Asesor",
  };

  return USER[role];
};

export default ({ data, onRowEvent, refetch, showOptions }) => {
  return data.users.map((user) =>
    <Row
      userData={user}
      key={user.id}
      onClick={() => {}}
      showOptions={showOptions || false}
      onRowEvent={onRowEvent}
      refetch={refetch}/>
  );
};

const Row = ({ userData, key, onRowEvent, showOptions }) => {

  const {
    id,
    userID,
    fullName,
    registrationDate,
    systemRole,
    email,
    phone,
    odes,
    batches,
    organisms
  } = userData;

  const onClickRow = () => {
    // The param "origin" is used for the breadcrumb
    window.location = `/admin/user-detail/${id}?origin=admins`;
  };

  return (
    <TableRow
      textAlign="center"
      key={key}
      onClick={onClickRow}>
      <TableCell>
        <Image
          avatar
          src="https://react.semantic-ui.com/images/avatar/small/matthew.png"
        />
      </TableCell>
      <TableCell>{fullName}</TableCell>
      <TableCell>{PARSE_DATE(registrationDate)}</TableCell>
      <TableCell>{USER_ROLE(systemRole)}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{phone}</TableCell>
      <TableCell>
        {odes.length > 0 ||
        batches.length > 0 ||
        organisms.length > 0
          ? "Sí"
          : "No"}
      </TableCell>
      <TableCell>
        { 
          showOptions &&
          <UserOptions
            onRowEvent={onRowEvent}
            rowData={userData}
            hasBatches={batches.length > 0}
            hasOrganisms={organisms.length > 0} /> 
        }
      </TableCell>
    </TableRow>
  );
};
