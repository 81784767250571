/**
 * Return the progress as an integet percent from 0 to 100
 * considering the completed activities
 * @param {*} odeActivities   An array of the ODE activities added directly
 */
export const calculateOdeGeneralProgress = (odeActivities) => {
  if (odeActivities !== undefined) {
    const totalActivities = odeActivities.length;
    const completedODEActivities = odeActivities.filter(activity => activity.isComplete === true).length;
    const totalCompletedActivities = completedODEActivities;

    if (totalActivities > 0)
      return parseInt((totalCompletedActivities * 100) / totalActivities);
  }
  return 0;
};

/**
 * Caculates the Batch progress of an ODE.
 * Returns the progress as an integer that represents the percent from 0 to 100
 * considering the completed activities
 * @param {*} odeActivities All the ODEActivities connected to the ODE. This could be
 * activities inherit from a Batch or just activities created to the ODE
 * @param {*} batchId Needed to identify the Activities of a Batch
 */
export const calculateOdeProgressPerBatch = (odeActivities, batchId) => { 
  let progress = 0;

  if (Array.isArray(odeActivities)) {
    const batchActivities = odeActivities.filter( batchActivity => {
      if (batchActivity.batch !== null) {
        return batchActivity.batch.id === batchId && batchActivity
      }
    });

    const odeActivitiesWithoutBatch = odeActivities.filter( activity =>  activity.batch === null );
    const totalActivities = batchActivities.concat(odeActivitiesWithoutBatch);
    const completedActivities = totalActivities.filter(activity => activity.isComplete === true ).length;

    if (totalActivities.length > 0) {
      progress = parseInt((completedActivities * 100) / totalActivities.length);
    }
  }

  return progress;
}
