export default [
  {
    field: "name",
    method: "isEmpty",
    validWhen: false,
    message: ""
  },
  {
    field: "description",
    method: "isEmpty",
    validWhen: false,
    message: ""
  },
  {
    field: "type",
    method: "isEmpty",
    validWhen: false,
    message: ""
  },
  {
    field: "price",
    method: "isEmpty",
    validWhen: false,
    message: ""
  }
];
