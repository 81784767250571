import React, { useContext } from "react";
import { Table, Button } from "semantic-ui-react";
import lodash from "lodash";

import SessionContext from "../../../../../context/userSessionContext";
import { FORMAT_DATE } from "../../../../../helpers";
import { DeleteButton } from "../../../../atoms";
import { SelectableCell } from ".";

export const TableRow = (props = {}) => {
  const {
    odeActivity = {},
    onClick,
    verifiyIfCanEdit,
    handleConfirm,
    setDeleteId,
    odeNetwork,
    setTypeToDelete
  } = props;
  const sessionContext = useContext(SessionContext);
  const {role = "", id: userId = ""} = sessionContext;

  const handleOnClick = () => {
    if (onClick) {
      onClick(odeActivity);
    }
  };

  const colorActivityTypes = type => {
    const TYPES = {
      KNOWLEDGE: "teal",
      DELIVER: "green",
      EXECUTION: "teal"
    };
    return TYPES[type];
  };

  const activityTypes = type => {
    const TYPES = {
      ADVISORY: "Asesoría",
      COURSE: "Cursos",
      TALK: "Charlas",
      WORKSHOP: "Talleres",
      ROUND_TABLE: "Mesa redonda",
      KNOWLEDGE: "Conocimiento",
      DELIVER: "Entrega"
    };
    return TYPES[type];
  };

  const handleOnDelete = () => {
    handleConfirm(() => true);
    setDeleteId(() => odeActivity.id);
    setTypeToDelete(() => "ode_activity");
  };

  return (
    <Table.Row name={odeActivity.activity.activityType}>
      <SelectableCell
        activity={odeActivity}
        onClick={handleOnClick}>
        {odeActivity.activity.name}
      </SelectableCell>
      <SelectableCell
        activity={odeActivity}
        onClick={handleOnClick}>
        { odeActivity.objective ?
          odeActivity.objective.batch.name.split(".")[0]
          : "N/A" }
      </SelectableCell>
      <SelectableCell
        activity={odeActivity}
        onClick={handleOnClick}>
        {odeActivity.objective
          ? lodash(odeActivity, "objective.objective.name", "").split(".")[0]
          : "N/A"}
      </SelectableCell>
      <SelectableCell
        activity={odeActivity}
        onClick={handleOnClick}>
        {odeActivity.objective ?
          (odeActivity.objective.startDate ?
          FORMAT_DATE(odeActivity.objective.startDate)
          : "N/A")
        : "N/A"}
      </SelectableCell>
      <SelectableCell
        activity={odeActivity}
        onClick={handleOnClick}>
        { odeActivity.finishDate ?
          FORMAT_DATE(odeActivity.finishDate)
          : (odeActivity.objective ?
            odeActivity.objective.finishDate ?
            FORMAT_DATE(odeActivity.objective.finishDate)
            : "N/A"
          : "N/A") }
      </SelectableCell>
      <SelectableCell>
        <Button
          onClick={handleOnClick}
          color={
            colorActivityTypes(odeActivity.activity.activityType)
          }
          content={
            activityTypes(odeActivity.activity.activityType)
          } />
      </SelectableCell>
      <SelectableCell>
        { odeActivity.isComplete ? "Sí" : "No" }
      </SelectableCell>
      <Table.Cell>
        {verifiyIfCanEdit(role, userId, odeNetwork) ?
          <DeleteButton
            del={handleOnDelete} />
        : null }
      </Table.Cell>
    </Table.Row>
  );
};
