import React from "react";
import { Query } from "react-apollo";
import { ODE_ACTIVITIES } from "../../../../../graphql/queries/odes";
import { Dimmer, Loader } from "semantic-ui-react";
import { InfoContent } from ".";

export default ({ odeData }) => {

  return (
    <Query
      query={ODE_ACTIVITIES}
      variables={{ id: odeData.id }}
      pollInterval={10000}>
      {
        ({ loading, error, data, refetch }) => {
          if (loading) {
            return (
              <Dimmer>
                <Loader />
              </Dimmer>
            );
          }
          if (error) {
            return (
              <Dimmer>
                <Loader />
              </Dimmer>
            );
          }

          return <InfoContent
            odeData={odeData}
            odeActivities={data.oDE.activities || []}
            refetch={refetch} />;
        }
      }
    </Query>
  );
};
