import gql from "graphql-tag";
import { client } from "../../index";

export default async ({
  userId,
  role
}) => {
  await client.mutate({
    mutation: gql`
      mutation {
        updateUser(
          where: { id: "${userId}" }
          data: { 
             organismRole:"${role}"
          }
        ){
          id
        }
      }
    `,
  });
};
