import React from "react";
import { TableRow, TableHeaderCell } from "semantic-ui-react";

export default props => {
  var { headers, sortColumn, sortDirection, onSort } = props;

  onSort = onSort ? onSort : () => {};

  return (
    <TableRow textAlign="center">
      {headers.map(([headerCell, column], index) => {
        return (
          <TableHeaderCell
            key={index}
            sorted={sortColumn === column && sortColumn ? sortDirection : null}
            onClick={() => onSort(column)}>
            {headerCell}
          </TableHeaderCell>
        );
      })}
    </TableRow>
  );
}