import gql from "graphql-tag";
import { client } from "../..";

export default async ({ programId, batchId, startDate }) => {
  await client.mutate({
    mutation: gql`mutation{
            updateProgram(
              where:{ id:"${programId}" }
              data:{
                batches:{
                  update:{
                    where:{id:"${batchId}"}
                    data:{
                      startDate:"${startDate}"
                    }
                  }
                }
              }
            ){ id }
          }`,
  });
};
