import gql from "graphql-tag";
import { client } from "../../index";

export default async ({
  id,
  name,
  description,
  activityType,
  newFiles,
}) => {
  await client.mutate({
    variables: {
      files: newFiles,
    },
    mutation: gql`
      mutation($files:[FileCreateInput!]) {
        updateActivity(
          where:{id:"${id}"}
          data: {
            name: "${name}"
            description: "${description}",
            activityType: ${activityType}
            files:{create:$files}
          }
        ) {
          id
        }
      }
    `,
  });
};
