const formats = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "text/xml",
  "application/xml",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ".pptx",
  ".xlsx",
  ".doc",
  ".docx",
  ".xmls",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
];

export const allowedFiletypes = formats.join(" ");
