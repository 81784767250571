import gql from "graphql-tag";
import { client } from "../../index";

export default async ({ id, fileId }) => {
  await client.mutate({
    variables: {
      fileId: [{ id: fileId }],
    },
    mutation: gql`
      mutation($fileId:[FileWhereUniqueInput!]) {
        updateActivity(
          where:{id:"${id}"}
          data: {
            files:{delete:$fileId}
          }
        ) {
          id
        }
      }
    `,
  });
};
