import React, { useState } from "react";
import { Query } from "react-apollo";
import { Segment, Dimmer, Loader } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import { Dashboard, DetailComponent } from "../../organisms";
import { SearchUserModal } from "../../molecules";
import { Information, Activities, Feed, ODEUsers, Mentors } from "./components";
import { GET_ODE_DETAILS } from "../../../graphql/queries/odes";
import _ from "lodash";

const id = window.location.pathname.split("e/")[1];

const getBreadcrumbPath = odeName => [
  {
    label: "Compañías",
    href: "../ode"
  },
  {
    label: odeName,
    active: true
  }
];

export default () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalNetwork, setOpenModalNetwork] = useState(false);

  const handleModal = () => {
    setOpenModal(prevState => !prevState);
  };

  const handleModalNetwork = () => {
    setOpenModalNetwork(prevState => !prevState);
  };

  const renderContent = (loading, error, data, refetch) => {
    refetch();

    if (loading) {
      return (
        <Segment className="Ode__Detail__ContainerTabs">
          <Dimmer>
            <Loader />
          </Dimmer>
        </Segment>
      );
    }
    if (error) {
      return <p>Error...</p>;
    }

    if (data.oDE === null) {
      return <Redirect to="/404" />;
    }

    // data could be undefined when the refetch is performed
    if (!data.oDE) {
      return (
        <Segment className="Ode__Detail__ContainerTabs">
          <Dimmer>
            <Loader />
          </Dimmer>
        </Segment>
      );
    }

    return (
      <div style={{ width: "100%" }}>
        <DetailComponent
          tabs={[
            {
              index: 0,
              text: "Información",
              value: "information",
              content: <Information odeData={data.oDE} refetch={refetch} />
            },
            {
              index: 1,
              text: "Actividades",
              value: "activities",
              content: <Activities ode={data} team={data.oDE.team} />
            },
            {
              index: 2,
              text: "Línea de tiempo",
              value: "timeline",
              content: <Feed timeline={data.oDE.timeline} />
            }
          ]}
          rigthContent={
            <div>
              <ODEUsers
                refetch={refetch}
                odeId={data.oDE !== null ? data.oDE.id : null}
                team={data.oDE !== null ? data.oDE.team : null}
                openUserModal={handleModal} />
              <Mentors
                refetch={refetch}
                odeId={data.oDE !== null ? data.oDE.id : null}
                team={data.oDE !== null ? data.oDE.network : null}
                openUserModal={handleModalNetwork} />
            </div>
          }
          rigthColumn />
        {openModal ? (
          <SearchUserModal
            odeName={data.oDE.name}
            open={openModal}
            refetch={refetch}
            onClose={handleModal}
            entrepreneur />
        ) : null}
        {openModalNetwork ? (
          <SearchUserModal
            odeName={data.oDE.name}
            open={openModalNetwork}
            refetch={refetch}
            onClose={handleModalNetwork}
            network />
        ) : null}
      </div>
    );
  };

  return (
    <Query
      query={GET_ODE_DETAILS}
      variables={{ id: id }}
      fetchPolicy="network-only">
      {/* @TODO: this should query more data, but by now is enough */}
      {({ loading, error, data, refetch }) => {
        return (
          <Dashboard
            breadcrumbPath={getBreadcrumbPath(_.get(data, "oDE.name", ""))}
            active="ode">
            { renderContent(loading, error, data, refetch) }
          </Dashboard>
        );
      }}
    </Query>
  );
};

