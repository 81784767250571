import React, { Fragment } from "react";
import { Item } from "semantic-ui-react";
import mapSchools from "../../../../../helpers/mapSchools";
import mapCareers from "../../../../../helpers/mapCareers";

export default ({data}) => {
  return (
    <Fragment>
      <p
        style={{
          fontSize: "15px",
          marginLeft: "9px",
          marginTop: "20px"
        }}>
        Académico
      </p>
      <div
        style={{
          borderBottom: "1px solid #F0F0F0",
          padding: "8px"
        }}>
        <Item style={{ display: "inline-block" }}>
          <Item.Content>
            <Item.Header
              style={{ color: "#999999", marginBottom: "7px" }}>
              Escuela
            </Item.Header>
            <Item.Meta>{mapSchools(data.user.school)}</Item.Meta>
          </Item.Content>
        </Item>
        <Item
          style={{ display: "inline-block", marginLeft: "25px" }}>
          <Item.Content>
            <Item.Header
              style={{ color: "#999999", marginBottom: "7px" }}>
              Carrera
            </Item.Header>
            <Item.Meta>{mapCareers(data.user.career)}</Item.Meta>
          </Item.Content>
        </Item>
        <Item
          style={{ display: "inline-block", marginLeft: "25px" }}>
          <Item.Content>
            <Item.Header
              style={{ color: "#999999", marginBottom: "7px" }}>
              Matricula
            </Item.Header>
            <Item.Meta>{data.user.enrollment}</Item.Meta>
          </Item.Content>
        </Item>
        <Item
          style={{ display: "inline-block", marginLeft: "38px" }}>
          <Item.Content>
            <Item.Header
              style={{ color: "#999999", marginBottom: "7px" }}>
              Año de graduación
            </Item.Header>
            <Item.Meta>{data.user.graduationDate}</Item.Meta>
          </Item.Content>
        </Item>
        <Item style={{ marginTop: "25px", marginBottom: "35px" }}>
          <Item.Content>
            <Item.Header
              style={{ color: "#999999", marginBottom: "7px" }}>
              Perfil Tec
            </Item.Header>
            <Item.Meta>No disponible de perfil tec</Item.Meta>
          </Item.Content>
        </Item>
      </div>
    </Fragment>
  );
};
