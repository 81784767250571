import React from "react";
import { DetailHeading, OdeSocialLinks } from "../../../../molecules";
import SessionContext from "../../../../../context/userSessionContext";
import { EditButton } from "../../../../atoms";


export default ({odeData, openEditModal}) => {

  const renderSocialMediaLinks = ({website, instagram, twitter,facebook}) => (
    <OdeSocialLinks
      website={website}
      instagram={instagram}
      twitter={twitter}
      facebook={facebook} />
  );

  const verifyIfCanEdit = (loggedUserId, loggedUserRole, odeNetwork) => {
    if(loggedUserRole !== "ADVISER")
      return true;

    const isOdeAdviser = (odeNetwork || []).find(({ id, systemRole}) =>
      (loggedUserId === id && loggedUserRole === systemRole)) ?
      true : false;

    return isOdeAdviser;
  };

  return (
    <DetailHeading>
      <DetailHeading.LeftContainer
        imageUri={odeData.avatarUri}
        title={odeData.name}
        subtitle={renderSocialMediaLinks(odeData)} />
      <DetailHeading.RightContainer>
        <SessionContext.Consumer>
          {({ role, id }) =>
            verifyIfCanEdit(id, role, odeData.network) ?
              <EditButton
                id="open-edit-ode-modal"
                odeData={odeData.odeCategory}
                open={openEditModal} />
            : null
          }
        </SessionContext.Consumer>
      </DetailHeading.RightContainer>
    </DetailHeading>
  );
};
