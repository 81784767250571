import gql from "graphql-tag";
/* @TODO: this should query more data, but by now is enough */
export default gql`
  query($id: ID!) {
    user(where:{ id: $id }) {
        id
        fullName
        profilePicUrl
        systemRole
        odes {
            id
            ode {
            name
            }
            rol
        }
        timeline {
            action
            type
            user
            ode
            timestamp
        }
    }
  }
`;
