import React, { useState, useContext } from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { Grid, Dimmer, Loader, Segment } from "semantic-ui-react";
import { EditOrganism, DetailsContainer } from "../../../../organisms";
import SessionContext from "../../../../../context/userSessionContext";
import { verifyUserPermission }
  from "../../../../../helpers/permissionsValidator";
import _ from "lodash";
import {
  OrganismHeader,
  GeneralInformation,
  OrganismDescription,
  UsersList
} from "./index";
import { Redirect } from "react-router";

const id = window.location.pathname.split("/")[3];

export default props => {
  const [editModal, openEditModal] = useState({ active: false });
  const loggedUser = useContext(SessionContext);

  return (
    <Query
      query={gql`
          {
            organism(where:{
                id:"${id}"
                }
                ){
                  id
                name
                organismID
                wasCreatedAt
                type
                coordinator {
                  id
                  fullName
                  phone
                }
                institution{
                    id
                    name
                    coordinator {
                      id
                    }
                }
                description
                }
          }
        `}
      fetchPolicy="network-only">
      {({ loading, error, data }) => {
        if (loading) {
          return (
            <Segment className="Ode__Detail__ContainerTabs">
              <Dimmer>
                <Loader />
              </Dimmer>
            </Segment>
          );
        }
        if (error) {
          return <p>Error...</p>;
        }

        if (data.organism === null) {
          return <Redirect to="/404" />;
        }

        const permissionRules = [
            { role: "SUPER_ADMIN" },
            { // Organism coordinator
              role: "COORDINATOR",
              id: _.get(data, "organism.coordinator.id", "")
            },
            { // Institution coordinator
              role: "COORDINATOR",
              id: _.get(data, "organism.institution.coordinator.id", "")
            }
          ];

        const hasPermissions = verifyUserPermission(
                        permissionRules,
                        {
                          role: loggedUser.role,
                          id: loggedUser.id
                        });

        return (
          <div>
            <DetailsContainer>
              <DetailsContainer.Content width={12}>
                <div className="details-info-tab">
                  <Grid.Row>
                    <OrganismHeader
                      data={data}
                      openEditModal={openEditModal}
                      hasPermissions={hasPermissions}
                      editModal={editModal}
                      organismId={id} />
                  </Grid.Row>
                  <Grid.Row>
                    <OrganismDescription data={data} />
                  </Grid.Row>
                  <GeneralInformation data={data} />
                </div>
              </DetailsContainer.Content>
              <DetailsContainer.Content width={4}>
                <UsersList hasPermissions={hasPermissions} />
              </DetailsContainer.Content>
            </DetailsContainer>
            {editModal.active ? (
              <EditOrganism
                openModal={editModal.active}
                close={openEditModal}
                refetch={props.refetch} />)
              : null}
          </div>
        );
      }}
    </Query>
  );
};
