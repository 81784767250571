export default {
  OPTIONS: {
    AREA_OPTIONS: [
      { text: "Administración", key: "management", value: "MANAGEMENT" },
      { text: "Diseño", key: "design", value: "DESIGN" },
      { text: "Marketing", key: "marketing", value: "MARKETING" },
      { text: "Software", key: "software", value: "SOFTWARE" },
      { text: "Ventas", key: "sales", value: "SALES" },
    ],
    CAREER_OPTIONS: [
      { text: "N/A", value: "NA", key: "NA" },
      { text: "Ambiente Construido/ Exploración", value: "AMC", key: "AMC" },
      { text: "Estudios Creativos / Exploración", value: "ESC", key: "ESC" },
      { text: "Arquitecto", value: "ARQ", key: "ARQ" },
      { text: "Licenciado en Arte Digital", value: "LAD", key: "LAD" },
      { text: "Licenciado en Diseño", value: "LDI", key: "LDI" },
      { text: "Licenciado en Urbanismo", value: "LUB", key: "LUB" },
      { text: "Ciencias Sociales / Exploración", value: "CIS", key: "CIS" },
      { text: "Licenciado en Economía", value: "LEC", key: "LEC" },
      { text: "Licenciado en Derecho", value: "LED", key: "LED" },
      {
        text: "Licenciado en Relaciones Internacionales",
        value: "LRI",
        key: "LRI",
      },
      {
        text: "Licenciado en Gobierno y Transformación Pública",
        value: "LTP",
        key: "LTP",
      },
      { text: "Licenciado en Comunicación    ", value: "LC", key: "LC" },
      { text: "Licenciado en Innovación Educativa", value: "LEI", key: "LEI" },
      { text: "Licenciado en Letras Hispánicas   ", value: "LLE", key: "LLE" },
      { text: "Licenciado en Periodismo", value: "LPE", key: "LPE" },
      {
        text: "Licenciado en Tecnología y Producción Musical ",
        value: "LTM",
        key: "LTM",
      },
      {
        text:
          "Ingeniería - Bioingeniería y Procesos Químicos (avenida) / Exploración",
        value: "IBQ",
        key: "IBQ",
      },
      {
        text: "Ingeniero en Biosistemas Agroalimentarios",
        value: "IAG",
        key: "IAG",
      },
      { text: "Ingeniero en Alimentos", value: "IAL", key: "IAL" },
      { text: "Ingeniero en Biotecnología", value: "IBT", key: "IBT" },
      { text: "Ingeniero en Desarrollo Sustentable", value: "IDS", key: "IDS" },
      { text: "Ingeniero Químico", value: "IQ", key: "IQ" },
      {
        text: "Ingeniería - Ciencias Aplicadas (avenida) / Exploración",
        value: "ICI",
        key: "ICI",
      },
      {
        text: "Ingeniero en Ciencia de Datos y Matemáticas",
        value: "IDM",
        key: "IDM",
      },
      { text: "Ingeniero Físico Industrial", value: "IFI", key: "IFI" },
      { text: "Ingeniero en Nanotecnología", value: "INA", key: "INA" },
      {
        text:
          "Ingeniería - Computación y Tecnologías de Información (avenida) / Exploración",
        value: "ICT",
        key: "ICT",
      },
      {
        text: "Ingeniero en Robótica y Sistemas Digitales",
        value: "IRS",
        key: "IRS",
      },
      {
        text: "Ingeniero en Tecnologías Computacionales",
        value: "ITC",
        key: "ITC",
      },
      {
        text: "Ingeniero en Transformación Digital de Negocios",
        value: "ITD",
        key: "ITD",
      },
      {
        text:
          "Ingeniería - Innovación y Transformación (avenida) / Exploración",
        value: "IIT",
        key: "IIT",
      },
      { text: "Ingeniero Civil", value: "IC", key: "IC" },
      { text: "Ingeniero en Electrónica", value: "IE", key: "IE" },
      {
        text: "Ingeniero en Innovación y Desarrollo",
        value: "IID",
        key: "IID",
      },
      { text: "Ingeniero Industrial y de Sistemas", value: "IIS", key: "IIS" },
      { text: "Ingeniero Mecánico", value: "IM", key: "IM" },
      { text: "Ingeniero Biomédico", value: "IMD", key: "IMD" },
      { text: "Ingeniero en Mecatrónica", value: "IMT", key: "IMT" },
      { text: "Salud / Exploración", value: "SLD", key: "SLD" },
      { text: "Licenciado en Biociencias", value: "LBC", key: "LBC" },
      {
        text: "Licenciado en Nutrición y Bienestar Integral       ",
        value: "LNB",
        key: "LNB",
      },
      {
        text: "Licenciado en Psicología Clínica y de la Salud ",
        value: "LPS",
        key: "LPS",
      },
      { text: "Médico Cirujano    ", value: "MC", key: "MC" },
      { text: "Médico Cirujano Odontólogo     ", value: "MO", key: "MO" },
      { text: "Negocios / Exploración", value: "NEG", key: "NEG" },
      {
        text: "Licenciado en Negocios Internacionales",
        value: "BGB",
        key: "BGB",
      },
      {
        text: "Licenciado en Estrategia y Transformación de Negocios",
        value: "LAE",
        key: "LAE",
      },
      { text: "Licenciado en Finanzas ", value: "LAF", key: "LAF" },
      {
        text: "Licenciado en Contaduría Pública y Finanzas    ",
        value: "LCPF",
        key: "LCPF",
      },
      { text: "Licenciado en Emprendimiento    ", value: "LDE", key: "LDE" },
      {
        text: "Licenciado en Desarrollo de Talento y Cultura Organizacional",
        value: "LDO",
        key: "LDO",
      },
      { text: "Licenciado en Mercadotecnia    ", value: "LEM", key: "LEM" },
      {
        text: "Licenciado en Negocios Internacionales ",
        value: "LIN",
        key: "LIN",
      },
      {
        text: "Licenciado en Inteligencia de Negocios ",
        value: "LIT",
        key: "LIT",
      },
    ],
    CATEGORY_OPTIONS: [
      { text: "Alumno", key: "student", value: "STUDENT" },
      { text: "Maestro", key: "teacher", value: "TEACHER" },
      { text: "Director", key: "director", value: "DIRECTOR" },
      { text: "DICA", key: "dica", value: "DICA" },
      { text: "Investigador", key: "researcher", value: "REASEARCHER" },
      { text: "Exatec", key: "exatec", value: "EXATEC" },
      {
        text: "Equipo de emprendimiento",
        key: "equipo",
        value: "ENTREPRENEURSHIP_TEAM",
      },
      { text: "Empleado", key: "employee", value: "EMPLOYEE" },
      { text: "Consejero", key: "adviser", value: "ADVISER" },
      { text: "External", key: "external", value: "EXTERNAL" },
    ],
    GENDER_OPTIONS: [
      { text: "Mujer", key: "female", value: "FEMALE" },
      { text: "Hombre", key: "male", value: "MALE" },
      { text: "Otro", key: "other", value: "OTHER" },
      { text: "Prefiero no decirlo", key: "not_sat", value: "NOT_SAY" },
    ],
    SCHOOL_OPTIONS: [
      { text: "Tecnológico de Monterrey", key: "tech_mont", value: "TEC_MONT" },
    ],
    STATUS_OPTIONS: [
      { text: "Activo", key: "active", value: "ACTIVE" },
      { text: "Inactivo", key: "inactive", value: "INACTIVE" },
    ],
  },
  TIMELINE_ACTION: (user, ode) => {
    return {
      USER_CREATED: {
        action: "ha sido agredado como usuario",
        timestamp: Date.now(),
        user,
        type: "USER_CREATED",
      },
      ODE_CREATED: {
        action: "ha sido creada",
        timestamp: Date.now(),
        ode,
        type: "ODE_CREATED",
      },
      MEMBER_ADDED: {
        action: "ha sido añadido como miembro a",
        timestamp: Date.now(),
        ode,
        user,
        type: "MEMBER_ADDED",
      },
      MEMBER_DELETED: {
        action: "ha dejado de ser miembro de",
        timestamp: Date.now(),
        ode,
        user,
        type: "MEMBER_DELETED",
      },
    };
  },
};
