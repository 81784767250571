import React, { useState, useEffect } from "react";
import { Segment, Grid } from "semantic-ui-react";
import { Navigation } from "./components";
import "./styles.css";

const styles = {
  container: {
    margin: "0 auto",
    width: "100%",
    minHeight: "100%",
    background: "#FFFFFF",
    padding: 0,
  },
  grid: {
    margin: "0 auto",
    width: "100%",
    heigth: "auto",
    marginTop: "0px",
    paddingLeft: 0,
    paddingRight: 0,
  },
  column: {
    margin: "0px",
    height: "auto",
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    padding: "0px"
  },
  menuColumn: { height: "30px", padding: 0 },
};



/**
 * @description Detail component
 * @props
 * 1. rightColumn: Set this prop to show the right column
 * 2. leftContent: The content to be rendred on left column
 * 3. rigthContent: The content to be rendred on rigth column
 * 4. tabs: The tab menu items to be rendered on navigation
 *
 */
const DetailComponent = props => {
  const { leftContent, rigthContent, tabs } = props;
  const { column, container, grid, menuColumn } = styles;

  const [active, setActive] = useState(0);

  return (
    <Segment style={container}>
      {/* Menu */}
      {tabs ? (
        <Grid style={{ borderBottom: "1px solid #dddddd" }}>
          <Grid.Column width={10} style={menuColumn} floated="left" />
          <Grid.Column
            width={5}
            floated="right"
            style={{
              ...menuColumn,
              padding: "0px"
            }}
          >
            <Navigation tabs={tabs} setActive={setActive} active={active} />
          </Grid.Column>
        </Grid>
      ) : null}
      {/* Content */}
      <Grid style={grid} className="detail-grid full-height" divided>
        <Grid.Column
          style={column}
          width={rigthContent !== undefined ? 12 : 16}
          floated="left"
          className="full-height"
        >
          {tabs && tabs.length > 0
            ? tabs.map(({ content }, index) =>
                active === index ? content : null
              )
            : leftContent}
        </Grid.Column>
        {rigthContent !== undefined ? (
          <Grid.Column
            width={4}
            floated="right"
            style={{ height: "100%", padding: 0 }}>
            {rigthContent}
          </Grid.Column>
        ) : null}
      </Grid>
    </Segment>
  );
};

export default DetailComponent;
