import React from "react";
import { Dropdown, Form } from "semantic-ui-react";

export default props => {
  const currentResponsibles =
    props.responsibles &&
    props.responsibles.map(({ id }) => {
      return id;
    });
  return props.ode ? (
    <Form.Group widths="equal">
      {props.update ? (
        <Form.Field
          label="Responsables"
          control={Dropdown}
          name="responsible"
          options={props.options}
          onChange={props.onChange}
          search
          selection
          multiple
          required
          error={props.error}
          value={currentResponsibles}
          disabled={props.disabled} />
      ) : (
        <Form.Field
          label="Responsables"
          control={Dropdown}
          name="responsible"
          options={props.options}
          onChange={props.onChange}
          search
          selection
          multiple
          required
          error={props.error}
          disabled={props.disabled} />
      )}
    </Form.Group>
  ) : null;
};
