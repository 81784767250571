import gql from 'graphql-tag';

export default gql`
  query(
    $id: ID!
    $first: Int
    $skip: Int
    $search: String
  ) {
    users (
      first: $first,
      skip: $skip,
      where: {
        ifullName_contains: $search
        systemRole: ENTREPRENEUR
        OR: [
          { organisms_some: { coordinator : { id: $id } } },
          {
            odes_some: {
              ode_some: {
                OR: [
                  { team_some: { id: $id } },
                  { network_some: { id: $id } }
                  {
                    batches_some: {
                      OR: [
                        { users_some: { id: $id } },
                        {
                          program: {
                            OR: [
                              { coordinator: { id: $id } },
                              {
                                organism: { 
                                  OR: [
                                    { coordinator: { id: $id } },
                                    { team_some: { id: $id } }
                                    {
                                      institution: {
                                        coordinator: { id: $id }
                                      }
                                    }
                                  ]
                                } # END OR
                              }
                            ] # END OR
                          } #  END program
                        }
                      ] # END OR
                    } # END batches_some
                  }
                ]
              } # END ode_some
            } # END odes_some
          }
        ] # END OR
      } # END where
    )
    {
      id
      fullName
      ifullName
      profilePicUrl
      email
      userID
      gender
      phone
      area
      school
      enrollment
      career
      graduationDate
      status
      registrationDate
      profileTec
      category
      systemRole
      odes {
        id
        ode {
          name
        }
        rol
      }
      timeline {
        action
        type
        user
        ode
        timestamp
      }
      batches {
        id
      }
      organisms {
        id
      }
    }
    usersConnection {
      aggregate {
        count
      }
    }
  }
`;
