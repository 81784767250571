import React from "react";
import { Grid } from "semantic-ui-react";

export default ({odeData}) => {
  return (
    <div className="detail-section">
      <p style={{fontSize: "15px"}}> Datos fiscales </p>
      <Grid columns={3}>
        <Grid.Column width={3} textAlign="left">
          <p style={{color: "#999999"}}> Razón social </p>
          {odeData.businessName}
        </Grid.Column>
        <Grid.Column width={3} textAlign="left">
          <p style={{color: "#999999"}}> Tipo de persona </p>
          {odeData.personType}
        </Grid.Column>
        <Grid.Column width={3} textAlign="left">
          <p style={{ color: "#999999" }}> RFC </p> {odeData.rfc}
        </Grid.Column>
      </Grid>
    </div>
  );
};
