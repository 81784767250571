import phases from "../../../../helpers/phases";
import { getPercentage } from "../../../../helpers/utils";

const getDataSource = (totalODEs, data) => {
  if(!data)
    return [];

  const results = phases.map(phase => {
    const filteredODEs = filterODEs(data.oDEs, phase.value).length;
    return {
      label: phase.text,
      total: filteredODEs,
      percentage: getPercentage(totalODEs, filteredODEs)
    };
  });

  return results;
};

const filterODEs = (odes, phase) => {
  const filteredODEs = (Array.isArray(odes) ? odes : [])
                        .filter(ode => ode.phase === phase);
  return (filteredODEs || []);
};

export default getDataSource;
