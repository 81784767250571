import gql from "graphql-tag";

export default gql`
query(
  $programId: ID,
  $organismId: ID,
  $institutionId: ID
) {
    users(
      first: 2,
      where: {
        systemRole: ENTREPRENEUR,
        odes_some: {
          ode_some: {
            batches_some: {
              program: {
                id: $programId
                organism: {
                  id: $organismId,
                  institution: {
                    id: $institutionId
                  }
                }
              }
            }
          }
        }
      }
  ) {
  	id
  	category
  }
}
`;
