import React from "react";
import { Modal, Grid, Icon, Button, Form } from "semantic-ui-react";
import { ActionButton } from "../../atoms";
import TrashIcon from "mdi-react/TrashCanIcon";

export default ({
  open,
  onClose,
  title,
  children,
  onConfirm,
  onDelete,
  confirmLabel
}) => {
  return (
    <Modal
      className="Modal__Form-SubModal"
      centered={false}
      open={open}
      onClose={onClose}
      size="tiny">
      <Modal.Header as="h1">
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>{title}</Grid.Column>
            <Grid.Column textAlign="right">
              <Icon name="close" onClick={onClose} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        {children}
        <Form className="Modal__Form">
          <Form.Group widths="equal">
            { onDelete ?
              <Form.Field>
                <ActionButton
                  color="red"
                  icon={<TrashIcon color="#ED5454" />}
                  onClick={onDelete} />
              </Form.Field>
            : null }
            <Form.Field>
              <Button
                primary fluid
                onClick={onConfirm}>
                {confirmLabel}
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
    </Modal>
  );
};
