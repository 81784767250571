import { gql } from "apollo-boost";

export default gql`
  {
    users(where: { role: EXPERT }) {
      id
      fullName
      userID
      specialtyArea
      expertType
      expertCategory
      profileTec
      enrollment
      odes {
        ode {
          name
        }
      }
    }
  }
`;
