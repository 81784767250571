import React, { useState } from "react";
import { Confirm } from "semantic-ui-react";
import { Bugsnag } from "../../../bugsnag";
import ReportingErrorMessage from "./reportingErrorMessage";

export default () => {
  const initialState = {
    isActive: false,
    sending: false,
    sessionId: undefined
  };

  const [state, setState] = useState(initialState);

  const errorHandler = (event, source, line, col, error) => {
    setState({sending: true, isActive: true});
    Bugsnag.notify(error, null, (err, report) => {
      setState({sending: false, sessionId: report.session.id});
    });
  };

  //window.onerror = errorHandler;

  const close = () => {
    setState(initialState);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const alertContent = <ReportingErrorMessage
    sending={state.sending}
    sessionId={state.sessionId} />;

  return (
    <Confirm
      header="Ha ocurrido un error"
      content={alertContent}
      confirmButton="Refrescar"
      cancelButton="Continuar sin refrescar"
      open={state.isActive}
      onConfirm={refreshPage}
      onCancel={close} />
  );
};
