import gql from "graphql-tag";

export default gql`
  query($organismId: ID!, $programId: ID!, $batchId: ID!) {
    program(where: { id: $programId }) {
      id
      name
    }
    organism(where: { id: $organismId }) {
      id
      name
    }
    batch(where: { id: $batchId }) {
      id
      name
    }
  }
`;
