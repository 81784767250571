import React, { Fragment } from "react";
import { Item } from "semantic-ui-react";

const STATUS = status => {
  const STATUS = {
    PROSPECT: "Prospecto",
    ACTIVE: "Activo",
    INACTIVE: "Inactivo"
  };
  return STATUS[status];
};

const GENDER = gender => {
  const GENDER = {
    MALE: "Masculino",
    FEMALE: "Femenino",
    OTHER: "Otro",
    NOT_SAY: "Prefiere no decir"
  };
  return GENDER[gender];
};

const CATEGORY = category => {
  const CATEGORY = {
    STUDENT: "Estudiante",
    TEACHER: "Maestro",
    DIRECTOR: "Director",
    DICA: "DICA",
    REASEARCHER: "Investigador",
    ENTREPRENEURSHIP_TEAM: "Equipo de emprendimiento",
    EMPLOYEE: "Empleado",
    EXATEC: "Exatec",
    ADVISER: "Consejero",
    EXTERNAL: "Externo"
  };
  return CATEGORY[category];
};

export default ({data}) => {

  const isNotAdmin = () => {
    const adminRoles = [
      "SUPER_ADMIN",
      "COORDINATOR",
      "ADVISER"
    ];

    return adminRoles.indexOf(data.user.systemRole) === -1;
  };

  return (
    <Fragment>
      <p style={{ fontSize: "15px", marginLeft: "9px" }}>
        Información general
      </p>
      <div
        style={{ borderBottom: "1px solid #F0F0F0", padding: "8px" }}>
        { isNotAdmin(data.user) ?
          <Item style={{ display: "inline-block" }}>
            <Item.Content>
              <Item.Header
                style={{ color: "#999999", marginBottom: "7px" }}>
                Genero
              </Item.Header>
              <Item.Meta>{GENDER(data.user.gender) || "N/A"}</Item.Meta>
            </Item.Content>
          </Item>
        : null }
        { isNotAdmin(data.user) ?
          <Item style={{ display: "inline-block", marginLeft: "25px" }}>
            <Item.Content style={{ marginLeft: "100px" }}>
              <Item.Header
                style={{ color: "#999999", marginBottom: "7px" }}>
                Estado de la op.
              </Item.Header>
              <Item.Meta>{STATUS(data.user.status) || "N/A"}</Item.Meta>
            </Item.Content>
          </Item>
        : null}
        <Item
          style={{
            display: "inline-block",
            marginLeft: isNotAdmin(data.user) ? "103px" : null}}>
          <Item.Content>
            <Item.Header
              style={{ color: "#999999", marginBottom: "7px" }}>
              Correo electrónico
            </Item.Header>
            <Item.Meta>{data.user.email}</Item.Meta>
          </Item.Content>
        </Item>
        <Item style={{ display: "inline-block", marginLeft: "80px" }}>
          <Item.Content>
            <Item.Header
              style={{ color: "#999999", marginBottom: "7px" }}>
              Teléfono
            </Item.Header>
            <Item.Meta>{data.user.phone}</Item.Meta>
          </Item.Content>
        </Item>
        {data.user.systemRole === "ENTREPRENEUR" ? (
          <Item
            style={{ display: "inline-block", marginLeft: "25px" }}>
            <Item.Content>
              <Item.Header
                style={{ color: "#999999", marginBottom: "7px" }}>
                Area de especialidad
              </Item.Header>
              <Item.Meta>{CATEGORY(data.user.category)}</Item.Meta>
            </Item.Content>
          </Item>
        ) : null}
      </div>
    </Fragment>
  );
};
