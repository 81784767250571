import gql from "graphql-tag";
import { client } from "../../index";

export default async (
    objectiveId
) => {
    let data = await client.query({
        variables: {
            objectiveId
        },
        query: gql`
            query($objectiveId: ID!) {
                batchObjectives (
                    where: { 
                    objective: { id: $objectiveId }
                    }
                )
                {
                    id
                    batch {
                        id
                        odes {
                            id
                        }
                    }
                }
            }
        `,
        variables: {
            objectiveId
        },
    });
    return data;
};
