import React from "react";
import { SidebarList } from "../../molecules";
import { Query } from "react-apollo";
import { ASSIGNED_EXPERT_SERVICES } from "../../../graphql/queries/assignedExpertServices";

export default ({
  expert,
  requestRefetch,
  selectedId,
  onClickService,
  onLoad
}) => {
  return (
    <Query
      query={ASSIGNED_EXPERT_SERVICES}
      variables={{expertId: expert.id}}>
      {({ loading, error, data, refetch }) => {
        requestRefetch(refetch);

        if(loading)
          return null;

        if(error)
          return null;

        typeof onLoad === "function" && onLoad(data);

        const items = data.assignedExpertServices ? data.assignedExpertServices.map(i => {
          return {
            id: i.id,
            title: i.expertService.name,
            description: i.expertService.description,
            object: i
          };
        }) : [];

        return <SidebarList
          items={items}
          onClickItem={onClickService}
          selectedId={selectedId}
          emptyContent="Este experto no tiene servicios asignados" />;
      }}
    </Query>
  )
}
