import gql from "graphql-tag";

export default gql`
  query($email: String, $password: String) {
    login(where: { email: $email, password: $password }) {
      email
      session
    }
  }
`;
