import React, { useState, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import { EditODE, DetailsContainer } from "../../../../organisms";
import {
  NexusProgramsList,
  Header as OdeHeader,
  OdeInformation,
  FiscalData,
  OdeStatus
} from ".";

export default ({odeData, odeActivities, refetch}) => {
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [shouldShowAddress, setShouldShowAddress] = useState(false);

  useEffect(() => {
    // This field should be shown for COMPANY ODEs only
    if (odeData.odeCategory === "COMPANY") {
      setShouldShowAddress(true);
    }
  });

  const openEditModal = () => {
    setOpenModalEdit(true);
  };

  const closeEditModal = () => {
    setOpenModalEdit(false);
  };

  return (
    <div>
      {openModalEdit ? (
        <EditODE
          odeData={odeData}
          open={openModalEdit}
          onClose={closeEditModal}
          close={openEditModal}
          refetch={refetch} />
      ) : null}
      <DetailsContainer>
        <DetailsContainer.Content>
          <div className="details-info-tab">
            <Grid.Row>
              <OdeHeader
                odeData={odeData}
                openEditModal={openEditModal} />
            </Grid.Row>
            <Grid.Row>
              <OdeInformation
                odeData={odeData}
                shouldShowAddress={shouldShowAddress} />
            </Grid.Row>
            <Grid.Row>
              {odeData.odeCategory === "COMPANY" ?
                <FiscalData odeData={odeData} />
                : null}
            </Grid.Row>
            <Grid.Row>
              <OdeStatus
                odeData={odeData} />
            </Grid.Row>
            <Grid.Row>
              <NexusProgramsList
                odeData={ odeData }
                odeActivities={ odeActivities } />
            </Grid.Row>
          </div>
        </DetailsContainer.Content>
      </DetailsContainer>
    </div>
  );
};
