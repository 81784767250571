import { gql } from "apollo-boost";

export default gql`
  query($id: ID!) {
    user(where: { id: $id }) {
      id
      fullName
      userID
      password
      email
      specialtyArea
      expertType
      expertCategory
      profileTec
      enrollment
      specify
      profilePicUrl
      odes {
        ode {
          name
        }
      }
      tabulation
      status
    }
  }
`;
