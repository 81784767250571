import React, { useState, useEffect } from "react";
import { Modal, Tab, Grid, Confirm } from "semantic-ui-react";
import { remove as removeAccents } from "remove-accents";
import gql from "graphql-tag";
import panes from "./panes";
import { client } from "../../../graphql/";
import {
  UPDATE_ODE_GENERAL,
  DELETE_ODE,
} from "../../../graphql/mutations/odes";
import { verifyOdeNameAvailability } from "../../../v2/helpers";

export default props => {
  const { open, close, onClose, refetch } = props;
  const [data, setData] = useState({});
  const [originalOdeName, setOriginalOdeName] = useState(undefined);
  const [type, setType] = useState("");
  const [confirm, setConfirm] = useState(false);

  const [loading, setLoading] = useState(false);
  const [odeNameAlert, setOdeNameAlert] = useState({
    open: false,
    odeName: undefined
  });

  useEffect(() => {
    loadData();
  }, []);

  const openOdeNameAlert = (odeName = "") => {
    setOdeNameAlert({ open: true, odeName });
  };

  const closeOdeNameAlert = () => {
    setOdeNameAlert({ open: false });
  };

  const handleChange = (e, { name: fieldName, value }) => {
    data[fieldName] = value;

    if (fieldName === "name") {
      data.iname = removeAccents(value).toLowerCase();
    }

    setData({ ...data });
  };

  const id = window.location.pathname.split("e/")[1]
    ? window.location.pathname.split("e/")[1]
    : props.idOde;

  const loadData = async () => {
    const ode = await client.query({
      query: gql`{
        oDE(where:{
            id:"${id}"
        }){
          id
          nationality
          businessName
          personType
          rfc
          ein
          inc
          commercialAddress
          physicalAddress
          id
          odeCategory
          name
          sector
          commercialBussines
          state
          city
          registrationYear
          phase
          technology
          trl
          industry
          area
          facebook
          website
          twitter
          instagram
          email
        }
      }`,
      options: { fetchPolicy: "network-only" },
    });

    setData(ode.data.oDE);
    setOriginalOdeName(ode.data.oDE?.name);

    setType(() => ode.data.oDE.odeCategory);
  };

  const update = async () => {
    setLoading(true);
    try {
      if (data.name !== originalOdeName) {
        const nameIsAlreadyTaken = await verifyOdeNameAvailability(data?.name);

        if (nameIsAlreadyTaken)
          return openOdeNameAlert(data.name);
      }

      await UPDATE_ODE_GENERAL(data);

      window.location.reload();
    } catch (e) {
      throw new Error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = () => {
    setConfirm(() => !confirm);
  };

  const deleteODE = async () => {
    try {
      await DELETE_ODE(id);
      window.location = "/admin/ode";
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <>
      <Confirm
        header="Atención"
        content={`Ya existe una ODE con el nombre "${odeNameAlert.odeName}". \
          Por favor, ingresa un nombre diferente.`}
        size="tiny"
        open={odeNameAlert.open}
        onCancel={closeOdeNameAlert}
        onConfirm={closeOdeNameAlert} />
      <Modal size="tiny" centered={false} open={open} onClose={close}>
        <Modal.Header>
          <Grid style={{ width: "100%" }} columns={2}>
            <Grid.Row>
              <Grid.Column floated="left">Editar</Grid.Column>
              <Grid.Column floated="right" style={{ textAlign: "right" }}>
                <i className="icon-cancel" onClick={() => onClose(refetch)} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          <Tab
            menu={{ secondary: true, pointing: true }}
            panes={panes(type)}
            style={{ margin: "0 auto", width: "90%" }}
            handlechange={handleChange}
            data={data}
            refetch={refetch}
            renderActiveOnly={true}
            onClose={onClose}
            update={update}
            loading={loading}
            deleteODE={handleConfirm}
          />
        </Modal.Content>
        {confirm ? (
          <Confirm
            open={confirm}
            onCancel={handleConfirm}
            onConfirm={deleteODE}
            content="Esta acción es irreversible"
          />
        ) : null}
      </Modal>
    </>
  );
};
