export default school => {
  const SCHOOL = {
    TEC_MONT: "Tecnológico de Monterrey",
    AGU: "Tecnológico de Monterrey campus Aguascalientes",
    CHH: "Tecnológico de Monterrey campus Chihuahua",
    SON: "Tecnológico de Monterrey campus Ciudad Obregón",
    JAL: "Tecnológico de Monterrey campus Guadalajara",
    LAG: "Tecnológico de Monterrey campus Laguna",
    MIC: "Tecnológico de Monterrey campus Morelia",
    COAH: "Tecnológico de Monterrey campus Saltillo",
    SIN: "Tecnológico de Monterrey campus Sinaloa",
    TOL: "Tecnológico de Monterrey campus Toluca",
    VER: "Tecnológico de Monterrey campus Central de Veracruz",
    CMX: "Tecnológico de Monterrey campus Ciudad de México",
    MOR: "Tecnológico de Monterrey campus Cuernavaca",
    HID: "Tecnológico de Monterrey campus Hidalgo",
    GUA: "Tecnológico de Monterrey campus León",
    PUE: "Tecnológico de Monterrey campus Puebla",
    SLP: "Tecnológico de Monterrey campus San Luis Potosí",
    SNT: "Tecnológico de Monterrey campus Sonora norte",
    ZAC: "Tecnológico de Monterrey campus Zacatecas",
    CHP: "Tecnológico de Monterrey campus Chiapas",
    MEX: "Tecnológico de Monterrey campus Estado de México",
    IRA: "Tecnológico de Monterrey campus Irapuato",
    NLE: "Tecnológico de Monterrey campus Monterrey",
    QUE: "Tecnológico de Monterrey campus Querétaro",
    STF: "Tecnológico de Monterrey campus Santa fe",
    TAM: "Tecnológico de Monterrey campus Tampico",
  };
  return SCHOOL[school];
};
