import gql from "graphql-tag";
import { client } from "../../index";

export default async (id, odeId, action, type, timestamp, user, ode, rol) => {
  await client.mutate({
    mutation: gql`
      mutation {
        updateUser(
          where: { id: "${id}" }
          data: { timeline:{
            create:{
              action:"${action}"
              type:${type}
              ode:"${ode}"
              user:"${user}"
              timestamp:"${timestamp}"
            }
          } odes: { delete: {id:"${odeId}"} } } 
        ){
          id
        }
      }
    `,
  });
};
