export default [
  {
    "key": "0",
    "value": "ACTIVE",
    "text": "Activo"
  },
  {
    "key": "1",
    "value": "INACTIVE",
    "text": "Inactivo"
  }
];
