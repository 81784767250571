import React from "react";
import { Form, Input } from "semantic-ui-react";
import {
  ExpertServicesDropdown, DateField,
  OdesDropdown, ServiceStatusDropdown
} from "../../atoms";

export default ({
  handleChange,
  data,
  errors,
  className,
  expert
}) => {
  return (
    <Form className={className}>
      <Form.Group widths="equal">
        <Form.Field
          multiple
          width={12}
          control={OdesDropdown}
          legacy={false}
          label="Seleccionar ODE"
          type="text"
          name="ode"
          required
          onChange={handleChange}
          value={data.ode}
          error={data.ode !== "" ? false : errors.ode} />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          width={12}
          control={ExpertServicesDropdown}
          name="expertServiceId"
          label="Tipo de servicio"
          expertId={expert.id}
          onChange={handleChange}
          value={data.expertServiceId}
          required
          error={data.expertServiceId !== "" ? false : errors.expertServiceId} />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          width={12}
          error={data.duration !== "" ? false : errors.duration}>
          <label>Horas de servicio</label>
          <Input
            type="number"
            name="duration"
            defaultValue={data.duration}
            label={{basic: true, content: "hrs"}}
            labelPosition="right"
            onChange={handleChange}
            required />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field width={6}>
          <label>Fecha de inicio de servicio</label>
          <DateField
            name="startDate"
            initialValue={data.startDate}
            iconPosition="left"
            onChange={handleChange} />
        </Form.Field>
        <Form.Field width={6}>
          <label>Fecha límite de servicio</label>
          <DateField
            name="endDate"
            initialValue={data.endDate}
            iconPosition="left"
            onChange={handleChange} />
        </Form.Field>
      </Form.Group>
      { data.id ?
        <Form.Group widths="equal">
          <Form.Field
            upward
            width={12}
            control={ServiceStatusDropdown}
            name="status"
            label="Estatus"
            onChange={handleChange}
            value={data.status}
            required
            error={data.status !== "" ? false : errors.status} />
        </Form.Group>
      : null }
      { data.status === "FINISHED" ?
        <Form.Group widths="equal">
          <Form.Field width={6}>
            <label>Fecha de término</label>
            <DateField
              name="finished"
              initialValue={data.finished}
              iconPosition="left"
              onChange={handleChange} />
          </Form.Field>
        </Form.Group>
      : null }
    </Form>
  );
};
