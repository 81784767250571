export default expertCategory => {
  let rules = [
    {
      field: "fullName",
      method: "isEmpty",
      validWhen: false,
      message: ""
    },
    {
      field: "email",
      method: "isEmpty",
      validWhen: false,
      message: ""
    },
    {
      field: "password",
      method: "isEmpty",
      validWhen: false,
      message: ""
    },
    {
      field: "specialtyArea",
      method: "isEmpty",
      validWhen: false,
      message: ""
    },
    {
      field: "expertType",
      method: "isEmpty",
      validWhen: false,
      message: ""
    },
    {
      field: "expertCategory",
      method: "isEmpty",
      validWhen: false,
      message: ""
    },
    {
      field: "tabulation",
      method: "isEmpty",
      validWhen: false,
      message: ""
    },
    {
      field: "status",
      method: "isEmpty",
      validWhen: false,
      message: ""
    }
  ];

  const externRules = [
    {
      field: "specify",
      method: "isEmpty",
      validWhen: false,
      message: ""
    }
  ];

  const internRules = [
    {
      field: "profileTec",
      method: "isEmpty",
      validWhen: false,
      message: ""
    },
    {
      field: "enrollment",
      method: "isEmpty",
      validWhen: false,
      message: ""
    }
  ];

  if(expertCategory === "INTERN")
    rules = rules.concat(internRules);

  if(expertCategory === "EXTERN")
    rules = rules.concat(externRules);

  return rules;
};
