import React, { useState } from "react";
import {
  Button,
  Form,
  Grid,
  Icon,
  Input,
  Modal,
  TextArea,
} from "semantic-ui-react";
import {
  InstitutionDropdown,
  OrganismID,
  OrganismTypeDropdown,
  UserDropdown
} from "../../atoms";
import { FormValidator, CLEAN_TEXT } from "../../../helpers";
import validations from "./validations";
import { CREATE_ORGANISM } from "../../../graphql/mutations/organisms";

export default props => {
  // Hooks
  const [data, setData] = useState({
    name: "",
    organismID: "",
    description: "",
  });

  const [type, setType] = useState({ type: null });

  const [errors, setErrors] = useState({
    name: false,
    institution: false,
    coordinator: false,
    description: false,
    institution: false,
    coordinator: false,
    type: false,
  });

  const validateFrom = data => {
    const validator = new FormValidator(validations);
    const validation = validator.validate(data);
    return validation;
  };

  const handleChange = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
  };

  const handleChangeType = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
    setType({ type: value });
  };

  const createOrganism = async () => {
    const validation = validateFrom(data);
    data["organismID"] = document.getElementById("organism-id").value;
    if (validation.isValid) {
      Object.keys(errors).map(error => {
        errors[error] = validation[error]["isInvalid"];
      });
      setErrors({ ...errors });
      data["description"] = CLEAN_TEXT.ESCAPE(data["description"]);
      await CREATE_ORGANISM(data);

      props.close();
    } else {
      Object.keys(errors).map(error => {
        errors[error] = validation[error]["isInvalid"];
      });
      setErrors({ ...errors });
    }
  };

  const close = () => {
    Object.keys(errors).map(error => {
      errors[error] = false;
    });
    setErrors({ ...errors });
    setData({});
    props.close();
  };

  return (
    <Modal
      className="Modal__Form-SubModal"
      centered={false}
      open={props.openModal}
      onClose={close}
      size="tiny"
      dimmer="blurring"
      autoComplete="new-password"
    >
      <Modal.Header as="h1">
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} floated="left">
              Nuevo organismo
            </Grid.Column>
            <Grid.Column width={2} floated="right">
              <Icon name="close" onClick={close} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <Form className="Modal__Form">
          <Form.Group widths="2">
            <Form.Field
              width={11}
              control={Input}
              label="Nombre del organismo"
              type="text"
              name="name"
              onChange={handleChange}
              required
              onChange={handleChange}
              error={errors.name}
            />
            <Form.Field
              id="organism-id"
              width={5}
              control={OrganismID}
              name="organismID"
              label="ID"
              type="text"
              required
              onChange={handleChange}
              readOnly
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={InstitutionDropdown}
              name="institution"
              label="Institución"
              onChange={handleChange}
              type="text"
              required
              error={errors.institution}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              name="type"
              label="Tipo"
              control={OrganismTypeDropdown}
              onChange={handleChangeType}
              error={data["type"] ? false : errors.type}
              defaultValue={data.type}
              selection
              required
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={UserDropdown}
              systemRole={["COORDINATOR", "SUPER_ADMIN"]}
              name="coordinator"
              label="Coordinador"
              onChange={handleChange}
              type="text"
              required
              error={errors.coordinator}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={TextArea}
              name="description"
              label="Descripción"
              onChange={handleChange}
              error={errors.description}
              required
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Button onClick={() => createOrganism()} fluid primary>
              Guardar
            </Button>
          </Form.Group>
        </Form>
      </Modal.Content>
    </Modal>
  );
};
