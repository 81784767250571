import React from "react";
import { useQuery } from "react-apollo-hooks";
import "c3/c3.css";
import { GridLayout, PercentageTable } from "../../organisms";
import {
  GET_ALL_ODES as GET_ALL_ODES_QUERY
} from "../../../graphql/queries/odes";
import { GET_PROJECTS_DATASOURCE as getDataSource } from "./helpers";
import _ from "lodash";

const columns = [
  {title: "Etapa", width: 10, textAlign: "left"},
  {title: "", width: 3},
  {title: "Total", width: 3, textAlign: "right"}
];

export default ({filterVariables}) => {
  const {loading, error, data} = useQuery(GET_ALL_ODES_QUERY(filterVariables), {
                                  variables: filterVariables
                                });

  return (
    <GridLayout>
      <GridLayout.Item
        isLoading={loading}
        hasErrors={error}>
      </GridLayout.Item>
      <PercentageTable
        dataSource={
          getDataSource(
            _.get(data, "oDEs", []).length,
            data)
          }
        columns={columns} />
    </GridLayout>
  );
};
