import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Grid,
  Icon,
  Input,
  Modal,
  TextArea,
  Tab,
  Confirm,
  Image,
} from "semantic-ui-react";
import {
  InstitutionDropdown,
  DeleteButton,
  OrganismTypeDropdown,
  UserDropdown
} from "../../atoms";
import { FormValidator, CLEAN_TEXT } from "../../../helpers";
import validations from "./validations";
import { ORGANISM_MANUAL } from "../../../graphql/queries/organisms";
import {
  EDIT_ORGANISM,
  DELETE_ORGANISM,
} from "../../../graphql/mutations/organisms";

export default props => {
  // Hooks
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({
    name: false,
    institution: false,
    coordinator: false,
    description: false,
  });
  const [openConfirm, handleConfirm] = useState(false);
  const [type, setType] = useState({ type: null });

  // call use effect only one
  useEffect(() => {

    const getOrganism = async () => {
      const id = window.location.pathname.split("=")[1]
        ? window.location.pathname.split("=")[1]
        : props.organismId;

      const result = await ORGANISM_MANUAL(id);
      setData(result["data"]["organism"]);
    };

    getOrganism();
  }, []);

  const validateFrom = data => {
    const validator = new FormValidator(validations);
    const validation = validator.validate(data);
    return validation;
  };

  const handleChange = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
  };

  const handleChangeType = (e, { name, value }) => {
    data[name] = value;
    setData({ ...data });
    setType({ type: value });
  };

  const editOrganism = async () => {
    const id = window.location.pathname.split("=")[1]
                ? window.location.pathname.split("=")[1]
                : props.organismId;

    const validation = validateFrom(data);
    data["organismID"] = document.getElementById("organism-id").value;
    data["id"] = id;

    data["description"] = CLEAN_TEXT.STRING_TO_UNICODE(
      CLEAN_TEXT.UNICODE_TO_STRING(data.description)
    );

    data["institution"] =
      typeof data["institution"] === "object"
        ? data["institution"]["id"]
        : data["institution"];
    data["coordinator"] =
      typeof data["coordinator"] === "object"
        ? data["coordinator"]["id"]
        : data["coordinator"];

    if (validation.isValid) {
      Object.keys(errors).map(error => {
        errors[error] = validation[error]["isInvalid"];
      });
      setErrors({ ...errors });
      close();
      await EDIT_ORGANISM(data);
      window.location = `/admin/organism/${id}`;
    } else {
      Object.keys(errors).map(error => {
        errors[error] = validation[error]["isInvalid"];
      });
      setErrors({ ...errors });
    }
  };

  const deleteOrganism = async () => {
    const id = window.location.pathname.split("=")[1] || props.organismId;
    await DELETE_ORGANISM(id);
    handleConfirm(() => false);
    window.location = "/admin/organism";
  };

  const close = () => {
    Object.keys(errors).map(error => {
      errors[error] = false;
    });
    setErrors({ ...errors });
    setData({});
    props.close({ active: false });
    window.history.pushState(null, "", `/admin/organism`);
  };

  const panes = [
    {
      menuItem: "General",
      render: () => {
        return (
          <Form className="Modal__Form">
            <Form.Group widths="2">
              <Form.Field
                width={11}
                control={Input}
                label="Nombre del organismo"
                type="text"
                name="name"
                defaultValue={data["name"]}
                onChange={handleChange}
                required
                onChange={handleChange}
                error={errors.name}
              />
              <Form.Field
                id="organism-id"
                width={5}
                control={Input}
                defaultValue={data["organismID"]}
                name="organismID"
                label="ID"
                type="text"
                required
                onChange={handleChange}
                readOnly
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                control={InstitutionDropdown}
                name="institution"
                label="Institución"
                value={data["institution"] ? data["institution"]["id"] : 0}
                onChange={handleChange}
                type="text"
                required
                error={errors.institution}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                name="type"
                label="Tipo"
                control={OrganismTypeDropdown}
                onChange={handleChangeType}
                error={data["type"] ? false : errors.type}
                value={data.type}
                selection
                required
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                control={UserDropdown}
                systemRole={["COORDINATOR", "SUPER_ADMIN"]}
                name="coordinator"
                label="Coordinador"
                value={data["coordinator"] ? data["coordinator"]["id"] : 0}
                onChange={handleChange}
                type="text"
                required
                error={data.coordinator !== "" ? false : errors.coordinator}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                control={TextArea}
                name="description"
                label="Descripción"
                value={
                  data["description"] &&
                  CLEAN_TEXT.UNICODE_TO_STRING(data["description"])
                }
                onChange={handleChange}
                error={errors.description}
                required
              />
            </Form.Group>
            <Form.Group widths="equal">
              <DeleteButton del={() => handleConfirm(() => true)} />
              <Button
                style={{ width: "150px", marginLeft: "213px" }}
                onClick={() => editOrganism()}
                fluid
                primary
              >
                Guardar
              </Button>
            </Form.Group>
          </Form>
        );
      },
    },
  ];

  return (
    <Modal
      className="Modal__Form-SubModal"
      centered={false}
      open={props.openModal}
      onClose={props.close}
      size="tiny"
      dimmer="blurring"
      autoComplete="new-password"
    >
      <Modal.Header as="h1">
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} floated="left">
              Editar organismo
            </Grid.Column>
            <Grid.Column width={2} floated="right">
              <Icon name="close" onClick={close} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <Image
          src="https://s3.us-east-2.amazonaws.com/jaxitank-dev/image-placeholder-350x350.png"
          size="tiny"
          circular
          style={{ margin: "0 auto" }}
        />
        <Tab
          className="Tab__Menu"
          menu={{ secondary: true, pointing: true }}
          panes={panes}
        />
      </Modal.Content>
      <Confirm
        open={openConfirm}
        onCancel={() => handleConfirm(() => false)}
        onConfirm={() => deleteOrganism()}
        header="Eliminar organismo"
        content={"Esta acción no es reversible."}
      />
    </Modal>
  );
};
