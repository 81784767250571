import React from 'react';
import { Grid, Container } from 'semantic-ui-react';

export default ({children}) => {
  return (
    <Grid.Column width={12}>
      <Container>
        { children }
      </Container>
    </Grid.Column>
  );
}
