import React from "react";
import {
  TabGeneral,
  TabFiscal,
  TabContact,
  TabEntrepreneurship,
  TabMembers,
} from "./tabs";

export default type => {
  return TYPES[type];
};

const TYPES = {
  COMPANY: [
    {
      menuItem: "General",
      render: props => <TabGeneral {...props} />,
    },
    {
      menuItem: "Fiscales",
      render: props => <TabFiscal {...props} />,
    },
    {
      menuItem: "Contacto",
      render: props => <TabContact {...props} />,
    },
    {
      menuItem: "Emprendimiento",
      render: props => {
        return <TabEntrepreneurship {...props} />;
      },
    },
  ],
  STARTUP: [
    {
      menuItem: "General",
      render: props => <TabGeneral {...props} />,
    },
    {
      menuItem: "Contacto",
      render: props => <TabContact {...props} />,
    },
    {
      menuItem: "Emprendimiento",
      render: props => <TabEntrepreneurship {...props} />,
    },
  ],
  PROJECT: [
    {
      menuItem: "General",
      render: props => <TabGeneral {...props} />,
    },
    {
      menuItem: "Contacto",
      render: props => {
        return <TabContact {...props} />;
      },
    },
    {
      menuItem: "Emprendimiento",
      render: props => {
        return <TabEntrepreneurship {...props} />;
      },
    },
  ],
  TEAM: [
    {
      menuItem: "General",
      render: props => <TabGeneral {...props} />,
    },
    {
      menuItem: "Contacto",
      render: props => <TabContact {...props} />,
    },
    {
      menuItem: "Emprendimiento",
      render: props => {
        return <TabEntrepreneurship {...props} />;
      },
    },
  ],
  SPIN_OFF: [
    {
      menuItem: "General",
      render: props => <TabGeneral {...props} />,
    },
    {
      menuItem: "Emprendimiento",
      render: props => <TabEntrepreneurship {...props} />,
    },
  ],
};
