import React, { Fragment } from "react";
import { Breadcrumb } from "semantic-ui-react";

const CustomBreadcrumb = ({pathObject}) => {

  const getLabel = (item, index) => {
    if(index > 0)
      return <h4>{item.label || ""}</h4>;
    else
      return <h3>{item.label || ""}</h3>;
  };

  if (Array.isArray(pathObject)) {
    return (
      <Breadcrumb>
        {pathObject.map((item, index) => {
          return (
            <Fragment key={index}>
              { index !== 0 && <Breadcrumb.Divider size="huge" /> }
              <Breadcrumb.Section
                href={item.href || undefined}
                active={item.active}>
                {getLabel(item, index)}
              </Breadcrumb.Section>
            </Fragment>
          );
        })}
      </Breadcrumb>
    );
  }
  else {
    return <div></div>;
  }
};

export default CustomBreadcrumb;
