export default {
  container1: {
    width: "100%",
    height: "auto",
    borderBottom: "1px solid #dddddd",
  },
  container2: {
    width: "100%",
    height: "auto",
    borderBottom: "1px solid #dddddd",
  },
  container3: {
    width: "100%",
    height: "auto",
  },
  row1: {
    width: "100%",
    height: "100px",
  },
  row2: {
    width: "100%",
    height: "100px",
    marginTop: "15px",
    marginBottom: "15px",
  },
  batchName: {
    color: "#323C47",
    fontSize: "26px",
  },
  detailTitle: {
    color: "#323C47",
    fontSize: "19px",
  },
  label: {
    color: "#323C47",
    fontSize: "15px",
    opacity: "0.5",
  },
  editButton: {
    float: "right",
  },
  coordinatorPicture: {
    width: "50px",
    marginLeft: "-80px",
    marginTop: "-15px",
  },
  odePicture: {
    width: "40px",
    height: "40px",
    margin: "10px"
  },
  coordinatorName: {
    marginLeft: "-150px",
    color: "#323C47",
    fontSie: "15px",
  },
  coordinatorDescription: {
    marginLeft: "-120px",
    color: "#323C47",
    fontSie: "15px",
  },
  dateLabel: {
    paddingTop: "12px",
  },
  datePicker: {
    border: "1px solid #dddddd",
    marginLeft: "-200px",
  },
  objectivesTable: {
    margin: "0 auto",
    width: "90%",
    marginLeft: "20px",
    marginTop: "20px",
    marginBottom: "200px",
  },
  batchOdesHeader: {
    borderBottom: "1px solid #dddddd",
  },
  addOdeButtonHolder: {
    float: "right",
  },
};
