import gql from "graphql-tag";

export default gql`
  query($id: ID) {
    institution(where: { id: $id }) {
      id
      name
      instID
      address
      zone
      description
      avatarUri
      coordinator {
        id
        fullName
      }
      organisms {
        id
      }
    }
  }
`;
