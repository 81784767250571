import { Button } from "antd";
import React from "react";

const WarningEmoji = () => <span style={{ // show colors in browser
  fontFamily: `
  "Apple Color Emoji",
  "Segoe UI Emoji",
  NotoColorEmoji,
  "Segoe UI Symbol",
  "Android Emoji",
  EmojiSymbols`
}}>⚠</span>;

export function ErrorFallbackIndex() {
  const img = require("../../../assets/error-fallback-image.svg");

  const onGoBack = () => {
    window.location.replace("/");
  };

  return (
    <div className="error-fallback-container">
      <img
        src={img}
        style={{ width: "50vw", marginBottom: "2rem" }}
        alt="Error fallback" />
      <h1>Error <WarningEmoji /></h1>
      <h2>¡Oh no! Parece que ha ocurrido un error.</h2>
      <h2>Se ha enviado un reporte, nos encargaremos de inmediato. 🙌 </h2>
      <Button
        href="/"
        type="primary"
        onClick={onGoBack}>
        Volver a EOS
      </Button>
    </div>
  );
}
