const getUrlGETParams = () => {
  const urlParams = window.location.search.slice(1);

  if(urlParams.length > 0) {
    return window.location.search.slice(1)
    .split("&")
    .reduce((obj, str) => {
      str = str.split("=");
      obj[str[0]] = decodeURIComponent(str[1]);
      return obj;
    }, {});
  }
  return {};
};

export default getUrlGETParams;
