import React from "react";
import { Dashboard, DetailComponent } from "../../organisms";
import { BatchDetail, BatchOdes } from "./components";
import { getUrlParams, getBreadcrumbPath } from "./helpers";
import { useQuery } from "react-apollo-hooks";
import {
  GET_PARENT_ORGANISM_AND_PROGRAM
} from "../../../graphql/queries/batches";

export default () => {
  const { organismId, programId, batchId } = getUrlParams();

  const { data } = useQuery(
    GET_PARENT_ORGANISM_AND_PROGRAM,
    {
      variables: {
        organismId: organismId,
        programId: programId,
        batchId: batchId
      }
    });

  return (

    <Dashboard
      active="organisms"
      breadcrumbPath={getBreadcrumbPath(data)}>
      <DetailComponent
        leftContent={<BatchDetail />}
        rigthContent={<BatchOdes />}
        rigthColumn
      />
    </Dashboard>
  );
};
