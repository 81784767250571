export default [
  {
    "key": "6",
    "value": "NA",
    "text": "N/A"
  },
  {
    "key": "0",
    "value": "PROFESSOR",
    "text": "Profesor"
  },
  {
    "key": "1",
    "value": "RESEARCHER",
    "text": "Investigador"
  },
  {
    "key": "2",
    "value": "DIRECTOR",
    "text": "Director"
  },
  {
    "key": "3",
    "value": "DICA",
    "text": "DICA"
  },
  {
    "key": "4",
    "value": "EXATEC",
    "text": "EXATEC"
  }
];
