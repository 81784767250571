import React from "react";
import { ExpertsRow } from "../../atoms";

export default ({data, onClickRow, onRowEvent}) => {
  return data.users.map(e => {
    return (
      <ExpertsRow
        expert={e}
        key={e.id}
        onClick={() => onClickRow(e)}
        onRowEvent={onRowEvent} />
    );
  });
};
