const CATEGORY = {
  STUDENT: "Estudiante",
  TEACHER: "Maestro",
  DIRECTOR: "Director",
  DICA: "DICA",
  REASEARCHER: "Investigador",
  ENTREPRENEURSHIP_TEAM: "Equipo de emprendimiento",
  EMPLOYEE: "Empleado",
  EXATEC: "Exatec",
  ADVISER: "Consejero",
  EXTERNAL: "Externo"
};

export default CATEGORY;
