import gql from "graphql-tag";
import { client } from "../../index";

export default async ({
  userID,
  odeID,
  action,
  type,
  timestamp,
  user,
  ode,
}) => {
  await client.mutate({
    mutation: gql`
        mutation{
            updateODE(
            where:{
                id:"${odeID}"
            }
            data:{
                network:{
                    connect:{
                        id:"${userID}"
                    }
                }
                timeline:{
                    create:{
                        action:"${action}"
                        type:${type}
                        timestamp:"${timestamp}"
                        user:"${user}"
                        ode:"${ode}"
                    }
                }
            }){
                id
            }
        }
    `,
  });
};
