export default [
  {
    "key": "0",
    "value": "INTERN",
    "text": "Interno"
  },
  {
    "key": "1",
    "value": "EXTERN",
    "text": "Externo"
  }
]