import React, { useState } from "react";
import gql from "graphql-tag";
import { Redirect } from "react-router";
import { Query } from "react-apollo";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import { DetailList, DetailsContainer } from "../../../../organisms";
import { ProgramsList, ProgramsDetail } from "./index";

const id = window.location.pathname.split("/")[3];

export default props => {
  const [active, setActive] = useState(0);
  return (
    <Query
      query={gql`
          {
            organism(where:{
                id:"${id}"
                }
            ){
              id
              name
              program {
                id
                name
                coordinator {
                  id
                  fullName
                }
                description
                programID
                type
                batches {
                  id
                  name
                  description
                  startDate
                  finishDate
                }
              }
            }
          }
        `}
      fetchPolicy="network-only">
      {({ loading, error, data, refetch }) => {
        if (loading) {
          return (
            <Segment className="Ode__Detail__ContainerTabs">
              <Dimmer>
                <Loader />
              </Dimmer>
            </Segment>
          );
        }
        if (error) {
          return <p>Error...</p>;
        }

        if (data.organism === null) {
          return <Redirect to="/404" />;
        }

        const _data = data.organism.program ? data.organism.program : [];
        return (
          <div>
            <DetailsContainer>
              <DetailsContainer.Content>
                <DetailList
                  leftContent={
                    <ProgramsList
                      active={active}
                      setActive={setActive}
                      data={_data}
                      organismData={props.organismData}
                      refetch={refetch} />
                  }
                  leftColumn
                  rightContent={
                    <ProgramsDetail
                      organismId={id}
                      data={_data[active]}
                      organismData={props.organismData}
                      refetch={refetch} />
                } />
              </DetailsContainer.Content>
            </DetailsContainer>
          </div>
        );
      }}
    </Query>
  );
};
