import { default as STATUS } from "./status";
import { default as CATEGORY } from "./categories";
import { default as USER_ROLE } from "./userRoles";
import { default as SCHOOL } from "./schools";
import { default as CAREER } from "./careers";

export default {
  STATUS,
  CATEGORY,
  USER_ROLE,
  CAREER,
  SCHOOL
};
