/**
 * Returns an integer number according to a
 * provided string number. Valid strings:
 * - ONE-NINE
 * @param trl STRING The number string
 * @returns INTEGER
 */
export default (trl) => {
    const TRL = {
      ONE: 1,
      TWO: 2,
      THREE: 3,
      FOUR: 4,
      FIVE: 5,
      SIX: 6,
      SEVEN: 7,
      EIGHT: 8,
      NINE: 9,
    };
    return trl in TRL ? TRL[trl] : "N/A";
  };
