import { getUrlGETParams } from "../../../../helpers";

const getBreadcrumbPath = data => {
  const getParams = getUrlGETParams();
  const pathObject = [];
  switch(getParams.origin) {
    case "admins":
      pathObject.push({
        label: "Administradores",
        href: "../administradores"
      });
      break;
    case "entrepreneurs":
        pathObject.push({
          label: "Emprendedores",
          href: "../emprendedores"
        });
      break;
    default:
      break;
  }

  if(data !== undefined && data !== null) {
    const { user } = data;
    pathObject.push({
      label: user !== undefined && user.fullName,
      active: true
    });
  }

  return pathObject;
};

export default getBreadcrumbPath;