import React from "react";
import _ from "lodash";
import { DELETE_INSTITUTION } from "../../../graphql/mutations/institutions";
import headers from "./headers";
import { EditInstitution } from "..";

import {
  LoadingTableRow,
  ErrorTableRow,
  SortableTableRow,
  InstitutionsTable,
  PaginationBar
} from "../../molecules";

import {
  Segment,
  Table,
  TableHeader,
  TableBody,
  Confirm
} from "semantic-ui-react";

export default class InstitutionsList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      current: 1,
      editModal: false,
      insId: null,
      confirm: false,
      refetch: null,
      sortColumn: null,
      sortDirection: "ascending",
      orderBy: null,
      skip: 0
    };

    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.handleSort = this.handleSort.bind(this);
  }

  next(institutions, queryData) {
    const path = "institutionsConnection.aggregate.count";
    const totalRecords = _.get(queryData, path, 0);

    if (
      institutions.length > 0 &&
      (institutions ? institutions.length === 10 : false)
    ) {
      if((this.state.skip + 10) < totalRecords) {
        const skip = this.state.skip + 10;
        const current = this.state.current + 1;

        this.setState(data => ({
          ...data,
          current: current,
          skip: skip
        }));

        this.props.onPaginationChange(skip, current);
      }
    }
  }

  prev() {
    if (this.state.current > 1) {
      const skip = this.state.skip - 10;
      const current = this.state.current - 1;
      this.setState(data => ({
        ...data,
        current: current,
        skip: skip
      }));

      this.props.onPaginationChange(skip, current);
    }
  }

  async _DELETE_INSTITUTION () {
    try {
      await DELETE_INSTITUTION(this.state.id);
      this.setState({ confirm: false });
    } catch (error) {
      throw new Error(error);
    }
  }

  async openEditModal (id) {
    this.setState({ editModal: true, id });
  }

  closeEditModal () {
    this.setState({
      editModal: false,
      id: null
    });
  }

  formatOrderBy (column, direction) {
    return `${column}_${direction === "ascending" ? "ASC" : "DESC"}`;
  }

  handleSort (clickedColumn) {
    if(clickedColumn === null)
      return;

    const { sortColumn, sortDirection } = this.state;

    if (sortColumn !== clickedColumn) {
      this.setState({
        sortColumn: clickedColumn,
        sortDirection: "ascending",
        orderBy: this.formatOrderBy(clickedColumn, "ascending")
      }, () => {
        this.props.onChangeOrderBy(this.state.orderBy);
      });

      return;
    }

    const direction = (sortDirection === "ascending") ?
                    "descending"
                    : "ascending";

    this.setState({
      sortDirection: direction,
      orderBy: this.formatOrderBy(sortColumn, direction)
    }, () => {
      this.props.onChangeOrderBy(this.state.orderBy);
    });
  }

  render() {
    const { loading, error, data, refetch } = this.props.queryStatus;
    const { sortColumn, sortDirection, current } = this.state;

    return (
      <Segment>
        <div style={{
          display: "flex",
          flexDirection: "column",
          height: "300px",
          overflow: "display"
          }}>
          <div style={{minHeight: "550px"}}>
            <Table
              sortable
              striped
              selectable
              className="Table__List"
              basic="very">
              <TableHeader>
                <SortableTableRow
                  headers={headers}
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                  onSort={this.handleSort} />
              </TableHeader>
              {/* Table */}
              <TableBody>
                {(() => {
                  if(loading) return <LoadingTableRow />;
                  if(error) return <ErrorTableRow />;

                  return (
                    <InstitutionsTable
                      data={data}
                      refetch={refetch}
                      openEditModal={this.openEditModal}
                      del={(id, refetch) => {
                        this.setState({ confirm: true, id, refetch });
                      }} />
                  );
                })()}
              </TableBody>
            </Table>
          </div>
          {/* Pagination */}
          <PaginationBar
            data={data}
            onPrev={this.prev}
            onNext={ institutions => {
              this.next(institutions, data);
              }}
            current={current}
            label="instituciones"
            propertyKey="institutions" />
        </div>
        { this.state.editModal ?
          <EditInstitution
            idInstitution={this.state.id}
            isOpen={this.state.editModal}
            onClose={this.closeEditModal}
            onSave={() => {
              refetch();
              }} />
        : null }
        { this.state.confirm ?
          <Confirm
            open={this.state.confirm}
            onCancel={() => this.setState({ confirm: false })}
            content="Esta acción no es reversible"
            onConfirm={ async () => {
              await this._DELETE_INSTITUTION();
              refetch();
              }} />
        : null }
      </Segment>
    );
  }
}
