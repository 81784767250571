import gql from "graphql-tag";
import { client } from "../../index";

let today = new Date();
let dd = today.getDate();
let mm = today.getMonth() + 1; //January is 0!
let yyyy = today.getFullYear();

if (dd < 10) {
  dd = "0" + dd;
}

if (mm < 10) {
  mm = "0" + mm;
}

today = mm + "/" + dd + "/" + yyyy;

export default async ({ id, fullName, phone, email, systemRole }) => {
  await client.mutate({
    mutation: gql`
      mutation {
        updateUser(
          where:{ id: "${id}" }
          data:{
            fullName: "${fullName}"
            phone: "${phone}"
            email: "${email}"
            systemRole:${systemRole}
          }) {
          id
          fullName
        }
      }
    `,
  });
};
