import React from "react";
import { List, Checkbox } from "semantic-ui-react";

export default (props) => {
  return (
    <List>
      <List.Item>
        <List.Content floated="right">
          <Checkbox toggle />
        </List.Content>
        <List.Content>
          Notificaciones
        </List.Content>
      </List.Item>
    </List>
  );
}