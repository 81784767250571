import gql from "graphql-tag";
import { client } from "../../index";

export default async ({ 
    id, 
    programID,
    name,
    description,
    coordinator,
    type 
}) => {
  await client.mutate({
    mutation: gql`
            mutation {
                updateOrganism(
                    where: { id: "${id}" }
                    data: { program: { create: {
                            programID: "${programID}",
                            name: "${name}",
                            description: "${description}",
                            coordinator: {
                                            connect: {
                                                id:"${coordinator}"
                                            }
                                            },
                            type: "${type}"}
                            }}
                ) {
                    id
                }
            }
        `,
  });
};
