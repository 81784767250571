import React from "react";
import { useForm } from "react-hook-form";

const recursiveMap = (children, fn) => {
  return React.Children.map(children, child => {
    if (!React.isValidElement(child)) {
      return child;
    }

    if (child.props.children) {
      child = React.cloneElement(child, {
        children: recursiveMap(child.props.children, fn)
      });
    }

    return fn(child);
  });
};

export default ({children, onSubmit}) => {
  const initialValues = {};

  const registerInitialValues = child => {
    initialValues[child.props.name] = child.props.initialValue || "";
  };

  recursiveMap(children, registerInitialValues);

  const methods = useForm({defaultValues: initialValues});
  const {
    handleSubmit,
    setValue,
    triggerValidation,
    errors,
    register
  } = methods;

  const onChange = async(e, { name, value }) => {
    await setValue(name, value);
    await triggerValidation(name, true);
  };

  const renderChildren = child => {
    if(child.props.name) {
      const validate = child.props.validate ?
        child.props.validate(methods) :
        undefined;

      register(
        { name: child.props.name, type: "custom" },
        { required: (child.props.required), validate: validate }
      );

      return React.createElement(child.type, {
        ...{
          ...child.props,
          onChange: onChange,
          error: (errors[child.props.name] !== undefined),
          key: child.props.name
        }
      });
    }

    return child;
  };

  return (
    <form
      className="ui form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate>
      { recursiveMap(children, renderChildren) }
    </form>
  );
};
