import React from 'react';
import { Form, Input } from 'semantic-ui-react';

export default ({handleChange, data, errors, disabled}) => {
  disabled = disabled || {};

  return (
    <div>
      <Form.Group widths="equal">
        <Form.Field
          width={11}
          control={Input}
          label="Correo electrónico"
          type="text"
          name="email"
          required
          onChange={handleChange}
          defaultValue={data.email}
          error={data.email !== "" ? false : errors.email}
          disabled={disabled.email} />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          width={11}
          control={Input}
          label="Contraseña"
          type="password"
          name="password"
          defaultValue={data.password}
          onChange={handleChange}
          required
          error={data.password !== "" ? false : errors.password}
          disabled={disabled.password} />
      </Form.Group>
    </div>
  );
}
