import React, { useState, useEffect } from "react";
import {
  Grid,
  Icon,
  Modal,
  Button,
  Dropdown,
  Container,
  Header,
  Label,
} from "semantic-ui-react";
import _ from "lodash";
import { client } from "../../../../graphql";
//import { LINK_ORGANISM } from "../../../../graphql/mutations/strategies";
import { ORGANISMS } from "../../../../graphql/queries/organisms";

const formStyle = {
  marign: "0 auto",
  width: "95%",
  marginBottom: "100px",
};

const organismsContainer = {
  minHeight: "200px",
};

export default props => {
  const [organisms, setOrganisms] = useState([]);
  const [selectedOrganism, setSelectedOrganism] = useState({
    id: null,
    name: null,
  });
  const [data, setData] = useState({ id: null, organisms: [] });

  // call once in mount
  useEffect(() => {
    resetHooks();
    getCurrentLinkedOrganisms();
    getOrganisms();
  }, []);

  const resetHooks = () => {
    setData({ id: null, organisms: [] });
    setSelectedOrganism({ id: null, name: null });
    setOrganisms([]);
  };

  const getOrganisms = async () => {
    const queryResult = await client.query({ query: ORGANISMS });
    queryResult.data.organisms.map(({ id, name }, index) => {
      organisms.push({
        key: index,
        value: JSON.stringify({ id, name }),
        text: name,
      });
    });
    setOrganisms(organisms);
  };

  const getCurrentLinkedOrganisms = () => {
    props.organisms.map(organism => {
      data.organisms.push({ id: organism.id, name: organism.name });
    });
    setData({ ...data });
  };

  const handleOrganismChange = (e, { value }) => {
    const v = JSON.parse(value);
    selectedOrganism["id"] = v.id;
    selectedOrganism["name"] = v.name;
    setSelectedOrganism({ ...selectedOrganism });
  };

  const add = () => {
    const alreadyExists = data["organisms"].some(item =>
      _.isEqual(item, selectedOrganism)
    );
    if (!alreadyExists) {
      data["organisms"].push(selectedOrganism);
      setData({ ...data });
      setSelectedOrganism({ id: null, name: null });
    }
  };

  const save = async () => {
    data["id"] = props.objectiveId;
    const _data = [];
    data.organisms.map(({ id }) => {
      _data.push({
        id,
      });
    });
    data["organisms"] = _data;
    if (data["organisms"].length > 0) {
      //await LINK_ORGANISM(data);
      window.location.reload();
    }
  };

  return (
    <Modal size="tiny" open={props.open}>
      <Modal.Header as="h1">
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} floated="left">
              Disponibilidad
            </Grid.Column>
            <Grid.Column width={2} floated="right">
              <Icon
                name="close"
                onClick={() => props.handleLinkModal({ active: !props.open })}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <Grid columns={2} style={formStyle}>
          <Grid.Column width={11} floated="left">
            <span style={{ color: "#999999" }}>Buscar organisma</span>
            <Dropdown
              icon="search"
              placeholder="Escribe el nombre del organisma"
              search
              selection
              onChange={handleOrganismChange}
              options={organisms}
              fluid
            />
          </Grid.Column>
          <Grid.Column width={4} floated="right">
            <span />
            <Button content="Agregar" onClick={add} primary />
          </Grid.Column>
        </Grid>
        <Container style={organismsContainer}>
          <Header as="h3" dividing>
            <span style={{ color: "#5f39de" }}>Organismas</span>
          </Header>
          <Container>
            {data.organisms.length > 0 ? (
              <Grid>
                {data.organisms.map(({ name }, index) => (
                  <Grid.Row key={index}>
                    <Grid.Column>
                      <Label as="a" basic color="blue" style={{ width: "40%" }}>
                        <p style={{ color: "#4c4c4c" }}>{name}</p>
                      </Label>
                    </Grid.Column>
                  </Grid.Row>
                ))}
              </Grid>
            ) : (
              <p>No has asignado este módulo a ningún organisma</p>
            )}
          </Container>
        </Container>
        <div style={{ textAlign: "right" }}>
          <Button primary onClick={save}>
            Guardar
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};
