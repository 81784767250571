import gql from "graphql-tag";
import { client } from "../../index";

export default async ({
  name,
  instID,
  address,
  zone,
  description,
  coordinator,
  avatarUri,
}) => {
  await client.mutate({
    mutation: gql`
      mutation {
        createInstitution(
          data: {
            name: "${name}"
            instID: "${instID}"
            address: "${address}"
            zone: ${zone}
            description: "${description}"
            avatarUri: "${avatarUri}"
            coordinator:{
              connect:{
                id:"${coordinator}"
              }
            }
          }
        ) {
          id
        }
      }
    `,
  });
};
