import React from "react";
import { Icon, Label } from "semantic-ui-react";

const EmptyMessage = () => {
  const styles = {
    emptyChart: {
      display: "block",
      position: "relative",
      textAlign: "center",
      verticalAlign: "middle",
      marginTop: "35%"
    },
    label: {
      margin: "10px"
    }
  };

  return (
    <div
      style={styles.emptyChart}>
      <Icon name="pie chart" size="huge" />
      <br />
      <div style={styles.label}>
        <Label>No hay suficientes datos</Label>
      </div>
    </div>
  );
};

export default EmptyMessage;
