import React, { useState } from "react";
import {
  InstitutionDropdown,
  OrganismsDropdown,
  ProgramDropdown } from "../../atoms";
import { Form, Button } from "semantic-ui-react";

export default ({onFiltering, clearFilters}) => {

  const [selectedFilters, setSelectedFilters] = useState({
    institutionId: undefined,
    organismId: undefined
  });

  /**
   * @param {*} event Event data
   * @param {*} { name } Control name
   * @param {*} { value } Selected value
   */
  const onFilterChange = (event, { name, value }) => {
    onFiltering(name, value);
    selectedFilters[name] = value.length < 1 ? undefined : value;
    setSelectedFilters(selectedFilters);
  };

  return (
    <Form>
      <Form.Group inline>
        <Form.Field>
          <InstitutionDropdown
            clearable
            name="institutionId"
            onChange={onFilterChange}
            placeholder="Todas las instituciones" />
        </Form.Field>
        <Form.Field>
          <OrganismsDropdown
            clearable
            name="organismId"
            selectedInstitutionId={selectedFilters.institutionId}
            onChange={onFilterChange}
            placeholder="Todos los organismos" />
        </Form.Field>
        <Form.Field>
          <ProgramDropdown
            clearable
            name="programId"
            selectedInstitutionId={selectedFilters.institutionId}
            selectedOrganismId={selectedFilters.organismId}
            onChange={onFilterChange}
            placeholder="Todos los programas" />
        </Form.Field>
        <Form.Field>
          <Button
            className="filterButtons selectedFilterButtons"
            onClick={clearFilters}>Remover filtros</Button>
        </Form.Field>
      </Form.Group>
    </Form>
  );
};
