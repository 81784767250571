import React from "react";
import { List, Button, Icon } from "semantic-ui-react";

export default ({user, editable}) => {
  editable = editable !== undefined ? editable : true;
  return (
    <List>
      <List.Item>
        <List.Content verticalAlign="middle" floated="right">
          { user && editable ? <Button icon="edit" verticalAlign="middle" /> : null }
        </List.Content>
        <List.Content verticalAlign="middle">
          <List.Header>{user ? user.fullName : <Icon name="circle notched" size="small" loading={true}></Icon>}</List.Header>
          <List.Description>{user ? user.email : null}</List.Description>
        </List.Content>
      </List.Item>
    </List>
  );
}