import React, { useState } from "react";
import {
  Container,
  Grid,
  Feed,
  Dropdown,
  Confirm
} from "semantic-ui-react";
import Identicon from "react-identicons";
import { PlusButton } from "../../../atoms";
import SessionContext from "../../../../context/userSessionContext";
import {
  getUserRoleFromUserOdes,
  getOdeRelationFromUserOdes
} from "../helpers/getUserRoleFromUserOdes";
import { EditOdeMemberModal } from "../../../organisms";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import {
  DISCONNECT_TEAM_MEMBER_FROM_ODE
} from "../../../../graphql/mutations/users";

export default props => {
  const { team, openUserModal, odeId, refetch } = props;
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState({
    odeId: "",
    fullName: "",
    id: "",
    role: "",
    userOdeRelationId: ""
  });

  const disconnectUserFromTeam = async () => {
    const { id, odeId, userOdeRelationId } = selectedMember;
    await DISCONNECT_TEAM_MEMBER_FROM_ODE({
      odeId: odeId,
      teamId: id,
      userOdeRelationId: userOdeRelationId
    });

    refetch();
    setConfirmModalIsOpen(false);
  };

  const onOpenConfirmModal = () => {
    setConfirmModalIsOpen(true);
  };

  const onInputChange = event => {
    selectedMember[event.target.name] = event.target.value;
    setSelectedMember(selectedMember);
  };

  const onCloseModal = () => {
    setEditModalIsOpen(false);
  };

  const onClickEdit = (member, userOdeRelationId) => {
    setSelectedMember(prevState => ({
      ...prevState,
      odeId,
      id: member.id,
      fullName: member.fullName,
      odes: member.odes,
      role:
        getUserRoleFromUserOdes(member.odes, odeId),
      userOdeRelationId: userOdeRelationId
    }));
    setEditModalIsOpen(true);
  };

  const onClickDelete = (member, odeId, userOdeRelationId) => {
    setSelectedMember(prevState => ({
      ...prevState,
      odeId,
      id: member.id,
      userOdeRelationId
    }));
    onOpenConfirmModal();
  };

  return (
    <Container
      style={{
        width: "100%",
        background: "white",
        padding: "10px"
      }}>
      <Grid style={{ borderBottom: "1px solid #dddddd" }}>
        <Grid.Row>
          <Grid.Column
            width={8}
            floated="left"
            style={{ fontSize: "10px" }}>
            Miembros del equipo
          </Grid.Column>
          <Grid.Column width={3} floated="right">
            <SessionContext.Consumer>
              {({ role }) =>
                role === "SUPER_ADMIN" || role === "COORDINATOR" || role === "ADVISER" ? (
                  <PlusButton open={openUserModal} />
                ) : null
              }
            </SessionContext.Consumer>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Feed>
              {team.map((member, index) => {
                const odeRelation =
                  getOdeRelationFromUserOdes(member.odes, odeId) || {};
                const userOdeRelationId = odeRelation.id || "";

                return (
                  <Feed.Event key={index}>
                    <Feed.Label>
                      <Identicon size={35} string={member.fullName} />
                    </Feed.Label>
                    <Feed.Content
                      style={{ fontSize: "11px", marginTop: "-2.5px" }}>
                      {member.fullName}
                      <Feed.Meta style={{display: "block"}}>
                        {getUserRoleFromUserOdes(member.odes, odeId)}
                      </Feed.Meta>
                    </Feed.Content>
                    <div>
                      <React.Fragment>
                        <Dropdown
                          icon={<DotsVerticalIcon color="#5F39DE" />}
                          pointing="right">
                          <Dropdown.Menu>
                            <Dropdown.Item
                              icon="edit"
                              text="Editar"
                              onClick={() => {
                                onClickEdit(member, userOdeRelationId);
                              }} />
                            <Dropdown.Item
                              icon="delete"
                              text="Eliminar"
                              onClick={() => {
                                onClickDelete(member, odeId, userOdeRelationId);
                              }} />
                          </Dropdown.Menu>
                        </Dropdown>
                      </React.Fragment>
                    </div>
                  </Feed.Event>
                );
              })}
            </Feed>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {
        <EditOdeMemberModal
          relation="TEAM"
          refetch={refetch}
          data={selectedMember}
          isOpen={editModalIsOpen}
          onClose={onCloseModal}
          onInputChange={onInputChange} />
      }
      {
        <Confirm
          open={confirmModalIsOpen}
          onCancel={() => {
            setConfirmModalIsOpen(false);
            }}
          onConfirm={disconnectUserFromTeam}
          header="Eliminar miembro"
          content={"Esta acción no es reversible."} />
      }
    </Container>
  );
};
