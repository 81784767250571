import React from 'react';
import { Button, Container, Grid, Header, Search, Confirm } from 'semantic-ui-react';
import {
  CreateAdmin,
  Dashboard,
  EntityToolbar,
} from "../../organisms";
import { default as TableBody } from "../../molecules/adminTableBody"
import {
  ORGANISM_TEAMMATES_USER,
  USERS_FILTERED_BY_ROLE
} from "../../../graphql/queries/users";
import { DELETE_USER } from "../../../graphql/mutations/users";
import SessionContext from "../../../context/userSessionContext";
import EntityList from "../../organisms/entityList";
import headers from '../../organisms/administratorsList/headers';
import { BreadcrumbPath } from "./helpers";

export default class UsersView extends React.Component {
  state = {
    openModal: false,
    closeModal: false,
    userData: {},
    id: null,
    isEdit: false,
    confirm: false,
    shouldRefetch: false,
    // This properties are used for the pagination
    first: 10,
    skip: 0,
    current: 1,
    searchQuery: '',
    orderBy: null
  };


  onSearchChange = value => {
    this.setState({ searchQuery: value || '' })
  }

  onChangeOrderBy = (orderBy) => {
    this.setState(data => ({ ...data, orderBy }));
  }

  onPrev = () => {
    if (this.state.current > 1) {
      let skip = this.state.skip - 10;
      let current = this.state.current - 1;
      this.setState(data => ({
        ...data,
        current: current,
        skip: skip
      }));
    }
  }

  onNext = (users, totalRecords) => {
    if (
      users.length > 0 &&
      (users ? users.length === 10 : false)
    ) {
      if((this.state.skip + 10) < totalRecords) {
        let skip = this.state.skip + 10;
        let current = this.state.current + 1;

        this.setState(data => ({
          ...data,
          current: current,
          skip: skip
        }));
      }
    }
  }

  onAdd = () => {
    this.setState(data => ({ 
      ...data,
      openModal: true 
    }));
  }

  closeModal = () => {
    this.setState(data => ({
      ...data,
      openModal: false,
      confirm: false,
      userData: {},
      isEdit: false,
    }));
  }

  deleteUserById = async () => {
    try {
      await DELETE_USER(this.state.userData.id);
      this.requestRefetch();
      this.closeModal();
    } catch (error) {
      throw new Error(error);
    }
  }

  onEdit = async (userData) => {
      if(userData) {
        this.setState(data => ({
          ...data,
          userData,
          openModal: true,
          detailModal: false,
          isEdit: true,
        }));
      }
  };

  onDelete = async (id) => {
    let userData = this.state.userData;
    userData.id = id;
    this.setState({
      confirm: true,
      userData,
      shouldRefetch: true,
     })
  }

  handleChange = (e, { name, value }) => {
    const userData = this.state.userData;
    userData[name] = value;
    this.setState(data => ({ ...data, userData }));
  };
  
  requestRefetch = refetch => {
    if(refetch !== undefined) {
      if (this.state.shouldRefetch) {
        this.state.shouldRefetch = false;
        refetch();
      }
    }
  }

  onClickRow = () => {

  }

  onRowEvent = (event) => {
    const params = event.args || null;
    switch(event.type) {
      case 'onEdit':
        this.onEdit(params ? params.data : null);
        break;
      case 'onDelete':
        this.onDelete(params ? params.id : '');
        break;
      default:
        // To be defined
        break;
    }
  }

  getQuery = (role) => {
      if(role === 'SUPER_ADMIN') {
        return USERS_FILTERED_BY_ROLE;
      } else {
        return ORGANISM_TEAMMATES_USER;
      }
  }

  afterUpdate = () => {
    this.setState({ shouldRefetch: true })
  }

  render() {
    return (
      <SessionContext.Consumer>
        {({ role, id }) => {
          if(role) {
            return role === "ADVISER" || role === "SUPER_ADMIN" || "COORDINATOR" ? (
              <Dashboard
                breadcrumbPath={BreadcrumbPath}
                active="admin">
                <Container className="Body__Container">
                  <EntityToolbar
                    addText="Agregar administrador"
                    searchLabel="Buscar"
                    onSearchChange={this.onSearchChange}
                    onAdd={ role === 'SUPER_ADMIN' || 'COORDINATOR' ? this.onAdd : null} />
                  {/* Users list */}
                  <EntityList
                    query={{
                      query: this.getQuery(role),
                      variables: {
                        id: id,
                        systemRole: ['SUPER_ADMIN', 'COORDINATOR', 'ADVISER'],
                        first: this.state.first,
                        skip: this.state.skip,
                        filter: this.state.searchQuery,
                        orderBy: this.state.orderBy
                      },
                      fetchPolicy: "network-only",
                      errorPolicy: "ignore",
                      pollInterval: 10000,
                    }}
                    paginationLabel="Administradores"
                    tableBodyComponent={
                      <TableBody
                        showOptions={(role === 'COORDINATOR' || role === 'SUPER_ADMIN')}/>
                    }
                    headers={headers}
                    requestRefetch={this.requestRefetch}
                    onRowEvent={this.onRowEvent}
                    onClickRow={this.onClickRow}
                    propertyKey="users"
                    current={this.state.current}
                    onPrev={this.onPrev}
                    onNext={this.onNext} />
                </Container>
                {/* Create user modal */}
                {this.state.openModal ? (
                  <CreateAdmin
                    userId={this.state.userData.id}
                    userID={this.state.userData.id}
                    open={this.state.openModal}
                    onClose={this.state.closeModal}
                    close={this.closeModal}
                    data={this.state.userData}
                    update={this.state.isEdit}
                    defaultRole={ role === 'COORDINATOR' ? 'COORDINATOR' : null}
                    requestRefetch={this.requestRefetch}
                    afterUpdate={this.afterUpdate}
                  />
                ) : null}
                {this.state.confirm ? (
                  <Confirm
                    open={this.state.confirm}
                    onCancel={() => {
                      this.closeModal();
                    }}
                    onConfirm={this.deleteUserById}
                    content="Esta acción no es reversible"
                  />
                ) : null}
              </Dashboard>
            ) : (
              (window.location = "/admin/ode")
            )
          }
        }}
      </SessionContext.Consumer>
    );
  }
}
