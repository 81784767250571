import React from "react";
import "c3/c3.css";
import { GridLayout, PercentageTable } from "../../organisms";
import { GET_ENTREPRENEURS } from "../../../graphql/queries/users";
import { useQuery } from "react-apollo-hooks";
import { GET_ENTREPRENEURS_DATASOURCE as getDataSource } from "./helpers";
import _ from "lodash";

const columns = [
  {title: "", width: 10},
  {title: "", width: 3},
  {title: "Total", width: 3, textAlign: "right"}
];

export default ({filterVariables}) => {
  const { data, loading, error } = useQuery(GET_ENTREPRENEURS, {
                                              variables: filterVariables
                                            });
  return (
    <GridLayout>
      <GridLayout.Item
        isLoading={loading}
        hasErrors={error}>
        <PercentageTable
          dataSource={
            getDataSource(
              _.get(data, "users", []).length,
              data)
          }
          columns={columns} />
      </GridLayout.Item>
    </GridLayout>
  );
};
