import { gql } from "apollo-boost";

export default gql`
  query($id: ID!) {
    invitation(where: { id: $id }) {
      id
      email
      redeemed
      systemRole
    }
  }
`;
