import React from "react";
import { Grid, Icon, Modal, Tab, Container } from "semantic-ui-react";
import InviteForm from "./components/invite/invite";
import ManualForm from "./components/manual/manual";

const panes = [
  {
    menuItem: "Invitar por correo electrónico",
    render: () => (
      <Container>
        <InviteForm />
      </Container>
    )
  },
  {
    menuItem: "Creación manual",
    render: () => (
      <Container>
        <ManualForm />
      </Container>
    )
  }
];

export default props => {
  return (
    <Modal
      className="Modal__Form-SubModal"
      centered={false}
      open={props.openModal}
      onClose={props.closeModal}
      size="tiny"
      autoComplete="new-password">
      <Modal.Header as="h1">
        <Grid>
          <Grid.Row>
            <Grid.Column width={2} floated="right">
              <Icon name="close" onClick={props.close} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="center">
              Crea una nueva compañía
              <br />
              <p className="Form__Subtitle">
                Puedes invitar por correo electrónico o realizar una creación
                manual
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <Tab
          className="Tab__Menu"
          menu={{ secondary: true, pointing: true }}
          panes={panes} />
      </Modal.Content>
    </Modal>
  );
};
