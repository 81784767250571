import React, { useState } from "react";
import {
  Modal,
  Image,
  Input,
  Form,
  Dropdown,
  Button,
  Header,
  Confirm,
} from "semantic-ui-react";
import { UserID, DeleteButton } from "../../../atoms";
import { CONSTANTS } from "../../../../helpers";
import { DELETE_USER } from "../../../../graphql/mutations/users";
import SessionContext from "../../../../context/userSessionContext";

const { GENDER_OPTIONS, AREA_OPTIONS, STATUS_OPTIONS } = CONSTANTS.OPTIONS;

export default props => {
  const [openConfirm, handleConfirm] = useState(false);
  const deleteUser = async () => {
    await DELETE_USER(props.data.id);
    handleConfirm(() => false);
    window.location = "/admin/emprendedores";
  };
  return (
    <Modal.Content>
      <Image
        src="https://react.semantic-ui.com/images/wireframe/square-image.png"
        size="tiny"
        circular
        centered
      />
      <Form className="Modal__Form">
        <Header as="h3">Datos generales</Header>
        <Form.Group widths="2">
          <Form.Field
            width={11}
            control={Input}
            label="Nombre completo"
            type="text"
            defaultValue={props.data.fullName}
            name="fullName"
            onChange={props.handleChange}
            required
            error={props.data.fullName ? false : props.errors1.fullName}
          />
          <Form.Field
            id="user-id"
            width={5}
            control={props.update ? Input : UserID}
            name="userID"
            label="ID"
            defaultValue={props.data.userID}
            type="text"
            required
            onChange={props.handleChange}
            readOnly={props.update ? true : false}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            control={Dropdown}
            selection
            label="Género"
            name="gender"
            defaultValue={props.data.gender}
            options={GENDER_OPTIONS}
            onChange={props.handleChange}
            required
            error={props.data.gender ? false : props.errors1.gender}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            control={Dropdown}
            options={AREA_OPTIONS}
            selection
            label="Area de especialidad"
            error={props.data.area ? false : props.errors1.area}
            name="area"
            defaultValue={props.data.area}
            onChange={props.handleChange}
            required
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            type="text"
            label="Teléfono"
            name="phone"
            defaultValue={props.data.phone}
            onChange={props.handleChange}
            error={props.data.phone ? false : props.errors1.phone}
            required
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            control={Dropdown}
            label="Estado de la oportunidad"
            name="status"
            fluid
            selection
            options={STATUS_OPTIONS}
            defaultValue={props.data.status}
            onChange={props.handleChange}
            error={props.data.status ? false : props.errors1.status}
            required
          />
        </Form.Group>
        <SessionContext>
          {({ role }) =>
            role === "SUPER_ADMIN" ? (
              <Form.Group widths="equal">
                <Form.Field
                  control={Dropdown}
                  label="Rol de usuario"
                  name="systemRole"
                  fluid
                  selection
                  options={[
                    { key: 2, text: "Emprendedor", value: "ENTREPRENEUR" },
                  ]}
                  defaultValue={props.data.systemRole}
                  onChange={props.handleChange}
                  error={props.data.status ? false : props.errors1.status}
                  required
                />
              </Form.Group>
            ) : null
          }
        </SessionContext>

        <Form.Group widths="equal">
          {
            (props.update && props.canDelete)  &&
            <DeleteButton del={() => handleConfirm(() => true)} />
          }
          <Button
            style={{ width: "150px", marginLeft: "248px", marginTop: "8px" }}
            type="button"
            onClick={() => props.next1(props.data)}
            fluid
            primary
          >
            Siguiente
          </Button>
        </Form.Group>
      </Form>
      <Confirm
        open={openConfirm}
        onCancel={() => handleConfirm(() => false)}
        onConfirm={() => deleteUser()}
        header="Eliminar usuario"
        content={"Esta acción no es reversible."}
      />
    </Modal.Content>
  );
};
