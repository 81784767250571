import React, { useState, useEffect } from "react";
import { Segment, Grid } from "semantic-ui-react";
import "./styles.css";

const styles = {
  container: {
    margin: "0 auto",
    width: "100%",
    paddingTop: "0px",
    padding: 0,
  },
  grid: { height: "100%", padding: 0 },
  column: {
    height: "100%",
    padding: 0,
    width: "100%",
    margin: "0px"
  }
};

/**
 * @description Detail list component
 * @props
 * 1. rightColumn: Set this prop to show the right column
 * 2. leftContent: The content to be rendred on left column
 *
 */
export default ({rightContent, leftContent}) => {
  const { column, container, grid } = styles;

  return (
    <Segment style={container}>
      {/* Content */}
      <Grid style={grid} className="detail-grid">
        {leftContent !== undefined ? (
          <Grid.Column
            width={4}
            style={{
              ...column,
              borderRight: "1px solid #dddddd",
              margin: "0px"
            }}
          >
            {leftContent}
          </Grid.Column>
        ) : null}
        <Grid.Column
          width={leftContent !== undefined ? 12 : 16}
          style={column}
          floated="left">
          {rightContent}
        </Grid.Column>
      </Grid>
    </Segment>
  );
};
