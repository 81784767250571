import React from "react";
import { Query } from "react-apollo";
import { Dropdown } from "semantic-ui-react";
import { INSTITUTIONS_DROP } from "../../../graphql/queries/institutions";

export default props => {
  let options = [];
  return (
    <Query query={INSTITUTIONS_DROP} fetchPolicy="network-only">
      {({ loading, error, data }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        data.institutions.map(({ id, name }) => {
          options.push({ value: id, text: name, key: id });
        });

        return (
          <Dropdown
            clearable={props.clearable || false}
            selection
            options={options}
            name={props.name}
            value={props.value}
            error={props.error}
            onChange={props.onChange}
            placeholder={props.placeholder} />
        );
      }}
    </Query>
  );
};
