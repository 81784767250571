import { gql } from "apollo-boost";
import { client } from "../../index";

export default async (expertId, data) => {
  delete data.ignore_whitespace;

  const odes = data.ode.map(o => { return { id: o }; });

  data.expert = { connect: { id: expertId } };
  data.expertService = { connect: { id: data.expertServiceId } };
  data.odes = { connect: odes };
  data.status = data.status || "ASSIGNED";

  delete data.ode;
  delete data.expertServiceId;

  await client.mutate({
    mutation: gql`
      mutation CreateAssignedExpertService(
        $data: AssignedExpertServiceCreateInput!
      ){
        createAssignedExpertService(data: $data) {
          id
        }
      }
    `,
    variables: {
      data: data
    }
  });
};
