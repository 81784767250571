import React from "react";
import { Form, Input, Dropdown, Button, Header } from "semantic-ui-react";

const personTypeOptions = [
  { key: "FISICA", text: "Física", value: "FISICA" },
  { key: "MORAL", text: "Moral", value: "MORAL" },
];

export default props => {
  const { handleChange, next, prev, errors, data, turnoffautocomplete } = props;
  return (
    <Form className="Padded__Form">
      <Header>Datos fiscales</Header>
      <Form.Group widths="equal">
        <Form.Field
          name="businessName"
          label="Razón social"
          selection
          control={Input}
          onChange={handleChange}
          error={data["businessName"] ? false : errors.businessName}
          defaultValue={data.businessName}
          required
          onFocus={turnoffautocomplete}
        />
      </Form.Group>

      {props.nationalityCondition ? (
        <Form.Group widths="equal">
          <Form.Field
            name="personType"
            label="Tipo de persona"
            control={Dropdown}
            options={personTypeOptions}
            onChange={handleChange}
            selection
            defaultValue={data.personType}
            error={data["personType"] ? false : errors.personType}
            required
          />
          <Form.Field
            name="rfc"
            label="RFC"
            onChange={handleChange}
            control={Input}
            selection
            defaultValue={data.rfc}
            error={data["rfc"] ? false : errors.rfc}
            required
            onFocus={turnoffautocomplete}
          />
        </Form.Group>
      ) : (
        <Form.Group widths="equal">
          <Form.Field
            name="ein"
            label="EIN"
            control={Input}
            onChange={handleChange}
            selection
            defaultValue={data.ein}
            error={data["ein"] ? false : errors.ein}
            required
            onFocus={turnoffautocomplete}
          />
          <Form.Field
            name="inc"
            label="INC"
            onChange={handleChange}
            control={Input}
            selection
            defaultValue={data.inc}
            error={data["inc"] ? false : errors.inc}
            required
            onFocus={turnoffautocomplete}
          />
        </Form.Group>
      )}
      <Form.Group widths="equal">
        <Form.Field
          name="commercialAddress"
          label="Dirección comercial"
          defaultValue={data.commercialAddress}
          onChange={handleChange}
          control={Input}
          error={data["commercialAddress"] ? false : errors.commercialAddress}
          required
          onFocus={turnoffautocomplete}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          name="physicalAddress"
          defaultValue={data.physicalAddress}
          label="Dirección fiscal"
          onChange={handleChange}
          control={Input}
          error={data["physicalAddress"] ? false : errors.physicalAddress}
          required
          onFocus={turnoffautocomplete}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Button type="button" id="ode-form-prev" onClick={prev} primary fluid>
          Anterior
        </Button>
        <Button type="button" id="ode-form-next1" onClick={next} primary fluid>
          Siguiente
        </Button>
      </Form.Group>
    </Form>
  );
};
