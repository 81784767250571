import React from 'react';
import { Grid, Feed } from 'semantic-ui-react';

export default () => {
  return (
    <Grid.Row className="sidebar-entity-list">
      <Grid.Column>
        <Feed size="large">
          <Feed.Event>
            <Feed.Label image="https://s3.us-east-2.amazonaws.com/jaxitank-dev/image-placeholder-350x350.png" />
            <Feed.Content>
              <Feed.Summary>
                <Feed.User>Nova</Feed.User>
              </Feed.Summary>
              <Feed.Meta>
                <Feed.Like>Mentor</Feed.Like>
              </Feed.Meta>
            </Feed.Content>
          </Feed.Event>
        </Feed>
      </Grid.Column>
    </Grid.Row>
  );
}
