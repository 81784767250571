import gql from "graphql-tag";

export default gql`
  query($id: ID!) {
    oDE(where: { id: $id }) { # Selected ODE by ID
      id
      batches { # Batches connected to the ODE
        id
        name
        startDate
        finishDate
      }
      network {
        id
        systemRole
      }
      activities { # The ODE Activities
        id
        isComplete
        startDate
        finishDate
        responsibles {
          id
          fullName
          userID
        }
        batch {
          id
          name
        }
        objective { # BatchObjective
          id
          startDate
          finishDate
          objective {
            id
            name
          }
          batch {
            id
            name
          }
        }
        activity {
          id
          name
          description
          activityType
          files {
            id
            url
            type
          }
          hasFiles
        }
        files {
          id
          url
          type
        }
      }
    }
  }
`;
