import React from 'react';
import { MenuButton } from '../../atoms';
import { Dropdown } from 'semantic-ui-react';
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon';

export default ({id, onEdit, onDelete, style, simple}) => {
  let icon = !simple ? <MenuButton /> : <DotsVerticalIcon size={16} color="#121212" />

  return (
    <Dropdown icon={icon} style={style} className="icon pointing List__Menu">
        <Dropdown.Menu style={{ marginLeft: "-35px" }}>
          { typeof onEdit == 'function' ?
            <Dropdown.Item
              icon="edit"
              text="Editar"
              onClick={() => { onEdit(id) }} />
          : null }
          { typeof onDelete == 'function' ?
            <Dropdown.Item
              icon="trash"
              text="Eliminar"
              onClick={() => { onDelete(id) }} />
          : null }
        </Dropdown.Menu>
      </Dropdown>
  );
}