import React, { useState } from "react";
import { Dropdown, Confirm } from "semantic-ui-react";
import EditInstitution from "../editInstitution";
import { MenuButton } from "../../atoms";
import "./style.css";

export default props => {
  const { idInstitution } = props;

  return (
    <div>
      <Dropdown icon={<MenuButton />} className="icon pointing List__Menu">
        <Dropdown.Menu style={{ marginLeft: "-35px" }}>
          <Dropdown.Item
            icon="edit"
            text="Editar"
            onClick={() => props.openEditModal(idInstitution)}
          />
          {props.hasPrograms ? (
            <Dropdown.Item
              icon="trash"
              text="Eliminar"
              onClick={() =>
                alert(
                  "Esta institución tiene organismos asociados y no puede ser elminada. Por favor elimina el organismo e intenta de nuevo."
                )
              }
            />
          ) : (
            <Dropdown.Item
              icon="trash"
              text="Eliminar"
              onClick={() => props.del(idInstitution, props.refetch)}
            />
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
