import React, { useState, useContext } from "react";
import { CreateOrganismObjective } from "../../../../organisms";
import { List, HeaderOptions } from "./components";
import SessionContext from "../../../../../context/userSessionContext";
import { getPermissionVariables } from "./helpers";
import {
  verifyUserPermission
} from "../../../../../helpers/permissionsValidator";

export default props => {
  const loggedUser = useContext(SessionContext);
  const { active, setActive, data, organismData } = props;
  const [boolean, setBoolean] = useState(false);
  const permissionVariables = getPermissionVariables(organismData);
  const { id: userId, role: userRole } = loggedUser;

  const canCreateAndEdit = verifyUserPermission(
    permissionVariables,
    { id: userId, role: userRole });

  return (
    <>
      {boolean ? (
        <CreateOrganismObjective
          open={boolean}
          openModal={() => setBoolean(prevState => !prevState)}
          close={() => setBoolean(prevState => !prevState)}
          refetch={props.refetch} />)
        : null}
      <HeaderOptions
        canCreateAndEdit={canCreateAndEdit}
        setBoolean={setBoolean} />
      <List
        data={data}
        active={active}
        setActive={setActive} />
    </>
  );
};
