import React, { useState } from "react";
import { Query } from "react-apollo";
import {
  Container,
  Header,
  Grid,
  Button,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import { Dashboard, CreateObjective, ObjectivesList } from "../../organisms";
import { OBJECTIVES } from "../../../graphql/queries/strategies";
import "./styles.css";

export default props => {
  const [createObjectiveModal, handleObjectiveModal] = useState({
    isOpen: false,
    isUpdate: false,
  });
  return (
    <Query query={OBJECTIVES} fetchPolicy="network-only" pollInterval={60000}>
      {({ data, loading, error }) => {
        if (loading) {
          return (
            <Container style={{ height: "100%", background: "white" }}>
              <div
                style={{
                  width: "20%",
                  textAlign: "center",
                  position: "absolute",
                  top: "50%",
                  left: "40%",
                }}
              >
                <Dimmer>
                  <Loader />
                </Dimmer>
              </div>
            </Container>
          );
        }
        if (error)
          return (
            <Container style={{ height: "100%", background: "white" }}>
              <div
                style={{
                  width: "20%",
                  textAlign: "center",
                  position: "absolute",
                  top: "50%",
                  left: "40%",
                }}
              >
                <Dimmer>
                  <Loader content="Error..." />
                </Dimmer>
              </div>
            </Container>
          );

        const objectives = data.objectives;
        const objectivesExists = objectives.length > 0;
        const activeObjective = window.location.pathname.split("active=")[1];
        const objective = objectives[activeObjective]
          ? objectives[activeObjective]
          : [];

        return (
          <Dashboard active="strategies">
            <Container className="Body__Container">
              {/* Header */}
              <Grid columns="equal">
                <Grid.Column floated="left">
                  <Header as="h1">Objetivos</Header>
                </Grid.Column>
                <Grid.Column floated="right" textAlign="right">
                  <Button
                    color="blue"
                    onClick={() =>
                      handleObjectiveModal({
                        isOpen: !createObjectiveModal.isOpen,
                        isUpdate: false,
                      })
                    }
                  >
                    Crear objetivo
                  </Button>
                </Grid.Column>
              </Grid>{" "}
              {objectivesExists ? (
                <div style={{ height: "80%" }}>
                <ObjectivesList
                  objectives={objectives}
                  createModal={CreateObjective}
                  createModalProps={{
                    open: createObjectiveModal.isOpen,
                    close: () =>
                      handleObjectiveModal({
                        isOpen: !createObjectiveModal.isOpen,
                        isUpdate: true,
                      }),
                    update: createObjectiveModal.isUpdate,
                  }}
                />
                </div>
              ) : (
                <Container style={{ height: "100%", background: "white" }}>
                  <div
                    style={{
                      width: "20%",
                      textAlign: "center",
                      position: "absolute",
                      top: "50%",
                      left: "40%",
                    }}
                  >
                    <i
                      className="icon-training"
                      style={{ fontSize: "50px", color: "#dddddd" }}
                    />
                    <p>
                      <b>No hay objetivos disponibles aún</b>
                    </p>
                    <span>Agrega un objetivo para comenzar</span>
                  </div>
                </Container>
              )}
            </Container>
            {createObjectiveModal.isOpen ? (
              <CreateObjective
                open={createObjectiveModal.isOpen}
                close={() =>
                  handleObjectiveModal({
                    isOpen: !createObjectiveModal.isOpen,
                  })
                }
                isUpdate={createObjectiveModal.isUpdate}
                objectiveId={objective.id}
                activeObjective={activeObjective}
              />
            ) : null}
          </Dashboard>
        );
      }}
    </Query>
  );
};
