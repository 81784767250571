import React from "react";
import { Grid, Container } from "semantic-ui-react";

const GridLayout = ({ children }) => (
  <Container className="Body__Container">
    <Grid>{children}</Grid>
  </Container>
);

export default GridLayout;
