import React from "react";
import { Form, Input, Dropdown } from "semantic-ui-react";
import EXPERT_TYPES from "../../../helpers/expertTypes";
import TEC_PROFILES from "../../../helpers/tecProfiles";
import EXPERT_CATEGORIES from "../../../helpers/expertCategories";
import { EXPERT_STATUSES } from "../../../helpers";

export default ({handleChange, data, errors}) => {
  return (
    <div>
      <Form.Group widths="equal">
        <Form.Field
          width={11}
          control={Input}
          label="Nombre"
          type="text"
          name="fullName"
          required
          onChange={handleChange}
          defaultValue={data.fullName}
          error={data.fullName !== "" ? false : errors.fullName} />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          width={11}
          control={Input}
          label="Area de especialidad"
          type="text"
          name="specialtyArea"
          onChange={handleChange}
          defaultValue={data.specialtyArea}
          required
          error={data.specialtyArea !== "" ? false : errors.specialtyArea} />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          control={Dropdown}
          options={EXPERT_TYPES}
          name="expertType"
          selection
          label="Tipo de experto"
          type="text"
          onChange={handleChange}
          value={data.expertType}
          required
          error={data.expertType !== "" ? false : errors.expertType} />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          control={Dropdown}
          options={EXPERT_STATUSES}
          name="status"
          selection
          label="Estatus"
          type="text"
          onChange={handleChange}
          value={data.status}
          required
          error={data.status !== "" ? false : errors.status} />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          control={Dropdown}
          options={EXPERT_CATEGORIES}
          name="expertCategory"
          selection
          label="Categoría"
          type="text"
          onChange={handleChange}
          value={data.expertCategory}
          required
          error={data.expertCategory !== "" ? false : errors.expertCategory} />
      </Form.Group>
      { data.expertCategory == "INTERN" ?
        <Form.Group widths="equal">
          <Form.Field
            control={Dropdown}
            options={TEC_PROFILES}
            name="profileTec"
            selection
            label="Perfil Tec"
            type="text"
            onChange={handleChange}
            defaultValue={data.profileTec}
            required
            error={data.profileTec !== "" ? false : errors.profileTec} />
        </Form.Group>
      : null }
      { data.expertCategory == "EXTERN" ?
        <Form.Group widths="equal">
          <Form.Field
            width={11}
            control={Input}
            label="Específicar"
            type="text"
            name="specify"
            onChange={handleChange}
            value={data.specify}
            required
            error={data.specify !== "" ? false : errors.specify} />
        </Form.Group>
      : null }
      { data.expertCategory == "INTERN" ?
        <Form.Group widths="equal">
          <Form.Field
            width={11}
            control={Input}
            label="Matrícula o nómina"
            type="text"
            name="enrollment"
            onChange={handleChange}
            defaultValue={data.enrollment}
            required
            error={data.enrollment !== "" ? false : errors.enrollment} />
        </Form.Group>
      : null }
    </div>
  );
};
