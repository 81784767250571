import gql from "graphql-tag";
import { client } from "../../index";
import { USERS } from "../../queries/users";

let today = new Date();
let dd = today.getDate();
let mm = today.getMonth() + 1; //January is 0!
let yyyy = today.getFullYear();

if (dd < 10) {
  dd = "0" + dd;
}

if (mm < 10) {
  mm = "0" + mm;
}

today = mm + "/" + dd + "/" + yyyy;

export default async ({
  userID,
  fullName,
  gender,
  phone,
  email,
  password,
  systemRole,
}) => {
  await client.mutate({
    mutation: gql`
      mutation {
        createUser(
          data:{
            userID: "${userID}"
            fullName: "${fullName}"
            phone: "${phone}"
            email: "${email}"
            registrationDate: "${new Date(today).toISOString()}"
            graduationDate: "${new Date(today).toISOString()}"
            enrollment: "null"
            password:"${password}"
            systemRole:${systemRole}
          }) {
          id
          fullName
        }
      }
    `,
    refetchQueries: [{ query: USERS }],
  });
};
