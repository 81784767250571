import React, { useState } from "react";
import {
  Modal,
  Input,
  Form,
  Dropdown,
  Button,
  Header,
  Confirm,
} from "semantic-ui-react";
import { DeleteButton } from "../../../atoms";
import { CONSTANTS, SCHOOL_OPTIONS } from "../../../../helpers";
import { DELETE_USER } from "../../../../graphql/mutations/users";

const { CATEGORY_OPTIONS, CAREER_OPTIONS } = CONSTANTS.OPTIONS;

export default props => {
  const [openConfirm, handleConfirm] = useState(false);
  const deleteUser = async () => {
    await DELETE_USER(props.data.id);
    handleConfirm(() => false);
    window.location = "/admin/emprendedores";
  };
  return (
    <Modal.Content>
      <Form className="Modal__Form">
        <Header as="h3">Académico</Header>
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            label="Año de graduación"
            type="number"
            name="graduationDate"
            error={
              props.data.graduationDate ? false : props.errors2.graduationDate
            }
            defaultValue={props.data.graduationDate}
            onChange={props.handleChange}
            required
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            control={Dropdown}
            selection
            label="Escuela"
            name="school"
            error={props.data.school ? false : props.errors2.school}
            defaultValue={props.data.school}
            options={SCHOOL_OPTIONS.sort()}
            onChange={props.handleChange}
            required
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            control={Dropdown}
            selection
            label="Carrera"
            name="career"
            error={props.data.career ? false : props.errors2.career}
            options={CAREER_OPTIONS}
            defaultValue={props.data.career}
            onChange={props.handleChange}
            required
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            type="text"
            name="enrollment"
            error={props.data.enrollment ? false : props.errors2.enrollment}
            label="Matrícula"
            defaultValue={props.data.enrollment}
            onChange={props.handleChange}
            required
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            control={Dropdown}
            options={CATEGORY_OPTIONS}
            search
            selection
            name="category"
            error={props.data.category ? false : props.errors2.category}
            defaultValue={props.data.category}
            label="Perfil Tec(Dependiente de ESCUELA)"
            onChange={props.handleChange}
            required
          />
        </Form.Group>
        <Form.Group widths="equal">
          {props.update && (
            <DeleteButton del={() => handleConfirm(() => true)} />
          )}
          <Button
            style={{ width: "150px", marginLeft: "100px" }}
            type="button"
            onClick={() => props.prev1()}
            fluid
            primary
          >
            Anterior
          </Button>
          <Button
            type="button"
            onClick={() => props.next2(props.data)}
            fluid
            primary
            style={{ width: "150px" }}
          >
            Siguiente
          </Button>
        </Form.Group>
      </Form>
      <Confirm
        open={openConfirm}
        onCancel={() => handleConfirm(() => false)}
        onConfirm={() => deleteUser()}
        header="Eliminar usuario"
        content={"Esta acción no es reversible."}
      />
    </Modal.Content>
  );
};

/* 

*/
