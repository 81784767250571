import React from "react";
import {
  EntityDetailContent,
  EntityDetailSidebar,
  EntityDetailTab
} from "../../atoms";
import { SidebarHeader } from "../../molecules";
import { default as AssignedServicesList } from "./assignedServicesList";
import { CreateAssignedExpertService, AssignedExpertServiceDetail } from "..";
import { Confirm } from "semantic-ui-react";
import {
  onActionClick,
  onEdit,
  onClose,
  onCreate,
  requestRefetch,
  onClickService,
  confirmDelete,
  deleteAssignedService,
  getSelectedId,
  onServicesLoad
} from "./methods";

export default class AssignedExpertServicesTab extends React.Component {
  constructor(props) {
    super(props);

    this.onActionClick = onActionClick.bind(this);
    this.onEdit = onEdit.bind(this);
    this.onClose = onClose.bind(this);
    this.onCreate = onCreate.bind(this);
    this.requestRefetch = requestRefetch.bind(this);
    this.onClickService = onClickService.bind(this);
    this.confirmDelete = confirmDelete.bind(this);
    this.deleteAssignedService = deleteAssignedService.bind(this);
    this.getSelectedId = getSelectedId.bind(this);
    this.onServicesLoad = onServicesLoad.bind(this);

    this.state = {
      isModalOpen: false,
      requestRefetch: false,
      selectedService: undefined,
      isConfirmOpen: false,
      editService: undefined
    };
  }

  render() {
    const { expert } = this.props;

    return (
      <EntityDetailTab>
        <EntityDetailSidebar>
          <SidebarHeader
            title="Servicios asignados"
            actionText="Asignar"
            onActionClick={this.onActionClick}
            big />
          <AssignedServicesList
            expert={expert}
            requestRefetch={this.requestRefetch}
            onClickService={this.onClickService}
            selectedId={this.getSelectedId()}
            onLoad={this.onServicesLoad} />
        </EntityDetailSidebar>
        <EntityDetailContent>
          <AssignedExpertServiceDetail
            service={this.state.selectedService}
            onEdit={this.onEdit} />
        </EntityDetailContent>
        <CreateAssignedExpertService
          onClose={this.onClose}
          onCreate={this.onCreate}
          open={this.state.isModalOpen}
          onDelete={this.confirmDelete}
          expert={expert}
          edit={this.state.editService} />
        <Confirm
          open={this.state.isConfirmOpen}
          onCancel={() => { this.isConfirmOpen = false; }}
          onConfirm={() => this.deleteAssignedService(this.state.editService)}
          header="Eliminar servicio asignado"
          content={"Esta acción no es reversible."} />
      </EntityDetailTab>
    );
  }
}
