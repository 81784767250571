import gql from "graphql-tag";

export default gql`
  {
    institutions {
      id
      name
    }
  }
`;
