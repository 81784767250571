import gql from "graphql-tag";
import { client } from "../../index";

export default async ({ name, description }) => {
  await client.mutate({
    mutation: gql`
      mutation{
        createObjective(
            data:{
                name:"${name}"
                description:"${description}"
                }){
            id
        }
    }
    `,
  });
};
