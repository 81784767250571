import React from "react";
import { Grid } from "semantic-ui-react";

export default props => {
  const { label, totalRecords, pageRecords } = props;
  return (
    <Grid>
      <Grid.Column>
        <p style={{ color: "#000000" }}>
          Mostrando&nbsp;
          {pageRecords}
          &nbsp;de&nbsp;
          {totalRecords}
          &nbsp;{label}
        </p>
      </Grid.Column>
    </Grid>
  );
}