import React from 'react';
import { Form, Input } from 'semantic-ui-react';

export default ({handleChange, data, errors}) => {
  return (
    <div>
      <Form.Group widths="equal">
        <Form.Field
          width={11}
          control={Input}
          label="Tabulador"
          type="text"
          name="tabulation"
          required
          onChange={handleChange}
          value={data.tabulation}
          error={data.tabulation !== "" ? false : errors.tabulation} />
      </Form.Group>
    </div>
  );
}
