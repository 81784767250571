import React, { useState } from "react";
import gql from "graphql-tag";
import { Redirect } from "react-router";
import { Query } from "react-apollo";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import { DetailList } from "../../../../organisms";
import { ObjectivesList, ObjectiveDetail } from "./index";

const id = window.location.pathname.split("/")[3];

export default props => {
  const [active, setActive] = useState(0);
  return (
    <Query
      query={gql`
          {
            organism(where:{
                id:"${id}"
                }
                ){
                  id
                  name
                  objectives {
                    id
                    name
                    type
                    description
                    activities {
                      id
                      name
                      description
                      activityType
                      files{
                        id
                        url
                      }
                    }
                  }
                }
          }
        `}
      fetchPolicy="network-only">
      {({ loading, error, data, refetch }) => {
        if (loading) {
          return (
            <Segment className="Ode__Detail__ContainerTabs">
              <Dimmer>
                <Loader />
              </Dimmer>
            </Segment>
          );
        }
        if (error) {
          return <p>Error...</p>;
        }

        if (data.organism === null) {
          return <Redirect to="/404" />;
        }

        const _data = data.organism.objectives ? data.organism.objectives : [];
        return (
          <div>
            <DetailList
              leftContent={
                <ObjectivesList
                  organismData={props.organismData}
                  active={active}
                  setActive={setActive}
                  data={_data}
                  refetch={refetch} />
              }
              leftColumn
              rightContent={
                <ObjectiveDetail
                  organismData={props.organismData}
                  data={_data[active]}
                  refetch={refetch} />
              } />
          </div>
        );
      }}
    </Query>
  );
};
