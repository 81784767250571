import React from "react";
import { Table } from "semantic-ui-react";

export const SelectableCell = ({ children, activity, onClick }) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick(activity);
    }
  };

  return (
    <Table.Cell
      width={4}
      onClick={handleOnClick}>
      {children}
    </Table.Cell>
  );
};
