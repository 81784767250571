import gql from "graphql-tag";
import { client } from "../../index";

export default async (id, userID, action, timestamp, type) => {
  await client.mutate({
    mutation: gql`
        mutation{
            updateODE(
            where:{
                id:"${id}"
            }
            data:{
              team:{
                disconnect:{
                  id:"${userID}"
                }
              }
              timeline:{
                create:{
                  action:"${action}"
                  type:"${type}"
                  timestamp:"${timestamp}"
                }
              }
            }){
                id
            }
        }        
    `,
  });
};
