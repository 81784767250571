export default [
  {
    field: "fullName",
    method: "isEmpty",
    validWhen: false,
    message: "",
  },
  {
    field: "systemRole",
    method: "isEmpty",
    validWhen: false,
    message: "",
  },
  {
    field: "phone",
    method: "isEmpty",
    validWhen: false,
    message: "",
  },
  {
    field: "email",
    method: "isEmail",
    validWhen: true,
    message: "",
  },
];
