import React from "react";
import { Icon, List, Grid, Image } from "semantic-ui-react";
import DomainIcon from "mdi-react/DomainIcon";
import CreationIcon from "mdi-react/CreationIcon";
import HexagonSlice5Icon from "mdi-react/HexagonSlice5Icon";
import AccountCircleIcon from "mdi-react/AccountCircleIcon";
import AccountCardDetailsOutlineIcon
  from "mdi-react/AccountCardDetailsOutlineIcon";
import ChartIcon from "mdi-react/ChartAreasplineIcon";
import { Link } from "react-router-dom";
import LOGO from "../../../assets/EOSWHITE.svg";
import { MEDIA } from "../../../helpers";
import SessionContext from "../../../context/userSessionContext";
import { getEntrepreneursLink } from "./getEntrepreneursLink";

const SidebarComponent = props => {
  const { active } = props;
  return (
    <Grid.Column
      width={2}
      className="Wrapper__SideBar"
      style={{ padding: 0, display: MEDIA.SIDEBAR_HIDDEN() }}>
      <SessionContext.Consumer>
        {({ role }) => (
          <List
            selection
            verticalAlign="middle"
            style={{ width: "100%", padding: 0 }}>
            <List.Item className="Sidebar__Logo__Container">
              <List.Content>
                <List.Header>
                  <Image
                    src={LOGO}
                    centered
                    width={60}
                    verticalAlign="middle"
                    alt="logo"
                    className="Logo" />
                </List.Header>
              </List.Content>
            </List.Item>

            {/* Institutciones */}
            {role === "SUPER_ADMIN" || role === "COORDINATOR" ? (
              <List.Item>
                <List.Content
                  className={
                    active === "institution" ?
                    "Active" : "List__Item"}>
                  <List.Header>
                    <Link to="/admin/instituciones" className="Sidebar__Link">
                      <Grid style={{ padding: "1px" }}>
                        <Grid.Column width={1} style={{ padding: "2px" }}>
                          <DomainIcon color="#fff" size={16} />
                        </Grid.Column>
                        <Grid.Column width={4} style={{ padding: "2px" }}>
                          <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Instituciones
                          </p>
                        </Grid.Column>
                        <Grid.Column
                          width={2}
                          floated="right"
                          style={{ padding: "2px" }}>
                          {active === "institution" ? (
                            <p>
                              <Icon
                                name="circle"
                                size="mini"
                                color="green" />
                            </p>
                          ) : null}
                        </Grid.Column>
                      </Grid>
                    </Link>
                  </List.Header>
                </List.Content>
              </List.Item>
            ) : null}

            {/* Organisms */}
            {role === "SUPER_ADMIN" || role === "COORDINATOR" ? (
              <List.Item>
                <List.Content
                  className={active === "organisms" ? "Active" : "List__Item"}>
                  <List.Content
                    className={active === "organism" ? "Active" : "List__Item"}>
                    <List.Header>
                      <Link to="/admin/organism" className="Sidebar__Link">
                        <Grid style={{ padding: "1px" }}>
                          <Grid.Column width={1} style={{ padding: "2px" }}>
                            <HexagonSlice5Icon color="#fff" size={16} />
                          </Grid.Column>
                          <Grid.Column width={4} style={{ padding: "2px" }}>
                            <p>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Organismos
                            </p>
                          </Grid.Column>
                          <Grid.Column
                            width={2}
                            floated="right"
                            style={{ padding: "2px" }}>
                            {active === "organisms" || active === "organism" ? (
                              <p>
                                <Icon
                                  name="circle"
                                  size="mini"
                                  color="green" />
                              </p>
                            ) : null}
                          </Grid.Column>
                        </Grid>
                      </Link>
                    </List.Header>
                  </List.Content>
                </List.Content>
              </List.Item>
            ) : null}

            <List.Item>
              <List.Content
                className={active === "ode" ? "Active" : "List__Item"}>
                <List.Header>
                  <Link to="/admin/ode" className="Sidebar__Link">
                    <Grid style={{ padding: "1px" }}>
                      <Grid.Column width={1} style={{ padding: "2px" }}>
                        <CreationIcon color="#fff" size={16} />
                      </Grid.Column>
                      <Grid.Column width={4} style={{ padding: "2px" }}>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ODE</p>
                      </Grid.Column>
                      <Grid.Column
                        width={2}
                        floated="right"
                        style={{ padding: "2px" }}>
                        {active === "ode" ? (
                          <p>
                            <Icon
                              name="circle"
                              size="mini"
                              color="green" />
                          </p>
                        ) : null}
                      </Grid.Column>
                    </Grid>
                  </Link>
                </List.Header>
              </List.Content>
            </List.Item>

            <SessionContext.Consumer>
              {({ role }) =>
                role !== "ADVISER" ? (
                  <List.Item>
                    <List.Content
                      className={
                        active === "users" ?
                        "Active" : "List__Item"}>
                      <List.Content
                        className={
                          active === "users" ?
                          "Active" : "List__Item"}>
                        <List.Header>
                          <Link
                            to={getEntrepreneursLink(role)}
                            className="Sidebar__Link">
                            <Grid style={{ padding: "1px" }}>
                              <Grid.Column width={1} style={{ padding: "2px" }}>
                                <AccountCircleIcon color="#fff" size={16} />
                              </Grid.Column>
                              <Grid.Column width={4} style={{ padding: "2px" }}>
                                <p>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Emprendedores
                                </p>
                              </Grid.Column>
                              <Grid.Column
                                width={2}
                                floated="right"
                                style={{ padding: "2px" }}>
                                {active === "user" ? (
                                  <p>
                                    <Icon
                                      name="circle"
                                      size="mini"
                                      color="green" />
                                  </p>
                                ) : null}
                                {active === "users" ? (
                                  <p>
                                    <Icon
                                      name="circle"
                                      size="mini"
                                      color="green" />
                                  </p>
                                ) : null}
                              </Grid.Column>
                            </Grid>
                          </Link>
                        </List.Header>
                      </List.Content>
                    </List.Content>
                  </List.Item>
                ) : null
              }
            </SessionContext.Consumer>

            <SessionContext.Consumer>
              {({ role }) =>
                role === "SUPER_ADMIN" ? (
                  <List.Item>
                    <List.Content
                      style={{ marginTop: "50px" }}
                      className={active === "admin" ? "Active" : "List__Item"}>
                      <List.Header>
                        <Link
                          to="/admin/administradores"
                          className="Sidebar__Link">
                          <Grid style={{ padding: "1px" }}>
                            <Grid.Column width={1} style={{ padding: "2px" }}>
                              <AccountCardDetailsOutlineIcon
                                color="#fff"
                                size={16} />
                            </Grid.Column>
                            <Grid.Column width={4} style={{ padding: "2px" }}>
                              <p>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Administradores
                              </p>
                            </Grid.Column>
                            <Grid.Column
                              width={2}
                              floated="right"
                              style={{ padding: "2px" }}>
                              {active === "admin" ? (
                                <p>
                                  <Icon
                                    name="circle"
                                    size="mini"
                                    color="green" />
                                </p>
                              ) : null}
                            </Grid.Column>
                          </Grid>
                        </Link>
                      </List.Header>
                    </List.Content>
                  </List.Item>
                ) : null
              }
            </SessionContext.Consumer>

            {/* Reports */}
            {role === "SUPER_ADMIN" ? (
              <List.Item>
                <List.Content
                  className={active === "reports" ? "Active" : "List__Item"}>
                  <List.Content
                    className={active === "reports" ? "Active" : "List__Item"}>
                    <List.Header>
                      <Link to="/admin/estadisticas" className="Sidebar__Link">
                        <Grid style={{ padding: "1px" }}>
                          <Grid.Column width={1} style={{ padding: "2px" }}>
                            <ChartIcon color="#fff" size={16} />
                          </Grid.Column>
                          <Grid.Column width={4} style={{ padding: "2px" }}>
                            <p>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Estadísticas
                            </p>
                          </Grid.Column>
                          <Grid.Column
                            width={2}
                            floated="right"
                            style={{ padding: "2px" }}>
                            {active === "reports" ? (
                              <p>
                                <Icon
                                  name="circle"
                                  size="mini"
                                  color="green" />
                              </p>
                            ) : null}
                          </Grid.Column>
                        </Grid>
                      </Link>
                    </List.Header>
                  </List.Content>
                </List.Content>
              </List.Item>
            ) : null}
          </List>
        )}
      </SessionContext.Consumer>
    </Grid.Column>
  );
};

export default SidebarComponent;
